<div class="container-fluid cnam">
    <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-6">
                        <label>Durée:</label>
                   
                    </div>
                    <div class="col-lg-6">
                      <input type="number" [(ngModel)]="consultation.ap4Duree" class="form-input" name="" id="" />
                    
                    </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-6">
                        <label>Debut Reeducation:</label>
                   
                    </div>
                    <div class="col-lg-6">
                        <date-picker [(ngModel)]="consultation.ap4DebutReeducation"></date-picker>                    
                    </div>
                  </div>
            </div> 
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-6">
                        <label>Seance Semaine:</label>
                   
                    </div>
                    <div class="col-lg-6">
                      <input type="number" class="form-input" [(ngModel)]="consultation.ap4NbSeanceSemaine" name=""  id="" />
                    
                    </div>
                  </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-6">
                        <label>Seance Total:</label>
                   
                    </div>
                    <div class="col-lg-6">
                      <input type="number" [(ngModel)]="consultation.ap4NbSeanceTotal" class="form-input" name="" id="" />
                    
                    </div>
                  </div>
            </div> 
        </div>   </div>
<div class="row">
    <div class="col-lg-6">
        <div class="form-group">
            <div class="row">
                <div class="col-lg-11">
                    <label>Reeducation Fonctionnelle</label>
               
                </div>
                <div class="col-lg-1">
                  <input type="checkbox" [(ngModel)]="consultation.ap4Reeducationfonctionnelle" name="" id="" />
                
                </div>
              </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-lg-11">
                    <label>Reeducation Physiologique</label>
               
                </div>
                <div class="col-lg-1">
                  <input type="checkbox" [(ngModel)]="consultation.ap4Reeducationphysiologique" name="" id="" />
                
                </div>
              </div>
        </div><div class="form-group">
            <div class="row">
                <div class="col-lg-11">
                    <label>Demande Initial</label>
               
                </div>
                <div class="col-lg-1">
                  <input type="checkbox" [(ngModel)]="consultation.ap4DemandeInitial" name="" id="" />
                
                </div>
              </div>
        </div><div class="form-group">
            <div class="row">
                <div class="col-lg-11">
                    <label>Demande Renouvelable</label>
               
                </div>
                <div class="col-lg-1">
                  <input type="checkbox" [(ngModel)]="consultation.ap4DemandeRenouvlable" name="" id="" />
                
                </div>
              </div>
        </div><div class="form-group">
            <div class="row">
                <div class="col-lg-11">
                    <label>APCI</label>
               
                </div>
                <div class="col-lg-1">
                  <input type="checkbox" [(ngModel)]="consultation.ap4APCI" name="" id="" />
                
                </div>
              </div>
        </div><div class="form-group">
            <div class="row">
                <div class="col-lg-11">
                    <label>Accident Travail</label>
               
                </div>
                <div class="col-lg-1">
                  <input type="checkbox" [(ngModel)]="consultation.ap4AccidentTravail" name="" id="" />
                
                </div>
              </div>
        </div><div class="form-group">
            <div class="row">
                <div class="col-lg-11">
                    <label>Accident Circulation</label>
               
                </div>
                <div class="col-lg-1">
                  <input type="checkbox" [(ngModel)]="consultation.ap4AccidentCirculation" name="" id="" />
                
                </div>
              </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-lg-11">
                    <label>Autres</label>
               
                </div>
                <div class="col-lg-1">
                  <input type="checkbox" [(ngModel)]="consultation.ap4Autres" name="" id="" />
                
                </div>
              </div>
        </div>
        <div class="form-group">
              <label for="">Autres Infos :
              </label>
              
       
          <textarea name="" rows="1" [(ngModel)]="consultation.ap4AutersInfo" class="form-control" ></textarea>

      </div>
    </div>
    <div class="col-lg-6">
        <div class="form-group">
          <div class="flex">
              <label for="">ET ce à la suite de (pathologie à l’origine du déficit et sa date de début):</label>  
              <button class="openDialoge open" (click)="valueParDefautQuestion1()">
                <i class="feather-arrow-down-circle"></i>
                </button> 
                <button class="openDialoge open"  (click)="openDialoge('Question1')" >
                    <i class="feather-plus-circle"></i>
                    </button>
           <div class="question">

                <app-liste-question-sahred
                  [filter]="'Question1'"
                  [(value)]="consultation.ap4Question1"
                  [(listeQuestionsConsultations)]="tableauTextFillings"
                  [options]="{right:'-19px',top:'24px'}"

                ></app-liste-question-sahred>
              </div>
          </div>
            <textarea name="" [(ngModel)]="consultation.ap4Question1" rows="1" class="form-control" ></textarea>

        </div>
        <div class="form-group">
            <div class="flex">
                    <label for="">  Ayant été traitée par (traitement médical, chirurgical, fonctionnel etc…) et en
                        précisant la durée : </label>
                        <button class="openDialoge open" (click)="valueParDefautQuestion2()">
                          <i class="feather-arrow-down-circle"></i>
                          </button> 
                        <button class="openDialoge open" (click)="openDialoge('Question2')">
                            <i class="feather-plus-circle"></i>
                            </button>
                        
                    <div class="question" >
    
                        <app-liste-question-sahred
                          [filter]="'Question2'"
                          [(value)]="consultation.ap4Question2"
                          [(listeQuestionsConsultations)]="tableauTextFillings"
                          [options]="{right:'-19px',top:'24px'}"

                        ></app-liste-question-sahred>
                      </div>
            </div>
            <textarea name="" rows="1"[(ngModel)]="consultation.ap4Question2" class="form-control" ></textarea>

        </div>
        <div class="form-group">
            <div class="flex">
                <label for="">Etat de Santé général actuel (notamment l’existence de pathologies associées) :
                </label>
                <!-- <button class="openDialoge open" [title]="'Histoire question3'" (click)="valueParDefautQuestion3()">
                  <i class="feather-arrow-down-circle"></i>
                  </button>  -->
                <button class="openDialoge open" (click)="openDialoge('Question3')">
                    <i class="feather-plus-circle"></i>
                    </button>
                <div class="question">
    
                    <app-liste-question-sahred
                      [filter]="'Question3'"
                      [(value)]="consultation.ap4Question3"
                      [(listeQuestionsConsultations)]="tableauTextFillings"
                      [options]="{right:'-19px',top:'24px'}"

                    ></app-liste-question-sahred>
                  </div>
           </div>
            <textarea name="" rows="1" [(ngModel)]="consultation.ap4Question3" class="form-control" ></textarea>

        </div>
        <div>
          <div class="flex">
       
                 
                        <label for="">Bilan fonctionnel (précisant le siége et l’évaluation de la fonction objet de la
                            Rééducation):</label>                     
                           
                        <button class="openDialoge open" (click)="openDialoge('Question4')">
                            <i class="feather-plus-circle"></i>
                            </button>

                <div class="question" >
    
                    <app-liste-question-sahred
                      [filter]="'Question4'"
                      [(value)]="consultation.ap4Question4"
                      [(listeQuestionsConsultations)]="tableauTextFillings"
                      [options]="{right:'-19px',top:'24px'}"
                    ></app-liste-question-sahred>
                  </div>
          </div>
            <textarea name="" rows="1" [(ngModel)]="consultation.ap4Question4" class="form-control" ></textarea>

        </div>
        <div class="form-group">
           <div class="flex">
            <label for="">En cas de demande de prolongation : préciser les motifs et les objectifs :</label>
            <button class="openDialoge open" (click)="openDialoge('Question5')">
                <i class="feather-plus-circle"></i>
                </button>
            <div class="question" >
    
                <app-liste-question-sahred
                  [filter]="'Question5'"
                  [(value)]="consultation.ap4Question5"
                  [(listeQuestionsConsultations)]="tableauTextFillings"
                  [options]="{right:'-19px',top:'24px'}"

                ></app-liste-question-sahred>
              </div>
           </div>
            <textarea name="" rows="1" class="form-control" [(ngModel)]="consultation.ap4Question5" ></textarea>

        </div>
      
    </div>
</div>
  
</div>
<app-modal [title]="questions.id==''?'Ajouter nouvelle Question':'Mise a jour  Question'" #dialog>
  <app-text-fillings-form *ngIf="questions.id!==''" [submitName]="'Mise a jour  Question'" [textFilligns]="questions" (submit)="callUpdateQuestion($event)"></app-text-fillings-form>
  <app-text-fillings-form *ngIf="questions.id==''" [submitName]="'Ajouter Question'" [textFilligns]="questions" (submit)="callAddQuestion($event)"></app-text-fillings-form>

</app-modal>