

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DoctorAvailability } from "src/app/entities/doctor-disponiblity.entity/doctor-availability";

@Component({
  selector: "app-add-heure-rdv",
  templateUrl: "./add-heure-rdv.component.html",
  styleUrls: ["./add-heure-rdv.component.css"],
})
export class AddHeureRdvComponent implements OnInit {
  @Input() availabilities: Array<DoctorAvailability> = new Array();
  @Input() availability!: DoctorAvailability;
  @Output() availabilityChange: EventEmitter<DoctorAvailability> = new EventEmitter();
  @Output() onSubmit: EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    this.availability = this.availabilities[0]
  }

  submit() {
    this.availabilityChange.emit(this.availability);
    this.onSubmit.emit();
  }
}
