import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorAppointment } from 'src/app/entities/appointment.entity/doctor-appointment.entity/doctor-appointment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Input() appointment: DoctorAppointment = new DoctorAppointment
  @Input() collapsedSideBar: boolean = true;

  constructor(private router: Router) { }
  get url(): string {
    return this.router.url;
  }
  ngOnInit(): void {
  }

}
