import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { PediatricVaccination } from 'src/app/entities/pediatric-vaccination.entity/pediatric-vaccination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PediatricVaccinationSettingsService extends ApiService {

  switchAlert(id: string) {
    return this.http.patch<ApiResponse>(`${environment.API_URL}/doctor/settings/consultation/vaccination/pediatric/${id}/alert`, {}).pipe(this.responsePipe);
  }

  switchActivation(id: string) {
    return this.http.patch<ApiResponse>(`${environment.API_URL}/doctor/settings/consultation/vaccination/pediatric/${id}/activation`, {}).pipe(this.responsePipe);
  }

  all(): Observable<Array<PediatricVaccination>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/settings/consultation/vaccination/pediatric`).pipe(this.responsePipe);
  }

  one(id: string): Observable<PediatricVaccination> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/settings/consultation/vaccination/pediatric/${id}`).pipe(this.responsePipe);
  }

  add(pediatricVaccination: PediatricVaccination): Observable<PediatricVaccination> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/settings/consultation/vaccination/pediatric`, pediatricVaccination.requestPayload).pipe(this.responsePipe);
  }

  update(id: string, updates: Partial<PediatricVaccination>): Observable<PediatricVaccination> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/settings/consultation/vaccination/pediatric/${id}`, updates.requestPayload).pipe(this.responsePipe);
  }

  delete(id: string): Observable<PediatricVaccination> {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/settings/consultation/vaccination/pediatric/${id}`).pipe(this.responsePipe);
  }

}
