export enum EPediatricVaccinationType {
    UNKNOWN = 0,
    BCG = 1,
    HBV = 2,
    PENT = 3,
    VPI = 4,
    PCV = 5,
    VPO = 6,
    RR = 7,
    DTC = 8,
    HepA = 9,
    DT = 10
}

export interface IPediatricVaccination {
    id: string;
    label: string;
    minOld: number;
    observation: string;
    isAlerted: boolean;
    isActivated: boolean;
}

export class PediatricVaccination implements IPediatricVaccination {
    id: string;
    label: string;
    minOld: number;
    observation: string;
    isAlerted: boolean;
    isActivated: boolean;

    constructor(other?: Partial<IPediatricVaccination>) {
        this.id = other?.id || '';
        this.label = other?.label || '';
        this.minOld = other?.minOld || 0;
        this.observation = other?.observation || '';
        this.isAlerted = other?.isAlerted || false;
        this.isActivated = other?.isActivated || false;
    }

    static fromResponse(response: any): PediatricVaccination {
        return new PediatricVaccination({
            id: response.id,
            label: response.label,
            minOld: response.minOld,
            observation: response.observation,
            isAlerted: response.alerted,
            isActivated: response.activated
        });
    }

    get requestPayload(): Object {
        return ({
            id: this.id,
            label: this.label,
            minOld: this.minOld,
            observation: this.observation,
            alerted: this.isAlerted,
        });
    }
}
