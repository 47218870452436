import { Component, Input, OnInit } from '@angular/core';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';

@Component({
  selector: 'app-patient-appointment-success',
  templateUrl: './patient-appointment-success.component.html',
  styleUrls: ['./patient-appointment-success.component.css']
})

export class PatientAppointmentSuccessComponent implements OnInit {
  @Input() patientAppointment!: PatientAppointment;

  constructor() { }

  ngOnInit(): void {
  }

}
