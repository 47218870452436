import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicFilter'
})
export class MedicFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {

    if(!items){
      return []
    }
    if(!searchText){
      return items;
    }
    searchText=searchText.toLowerCase();
    return items.filter(item=>{
      return (item.nom.toLowerCase().includes(searchText))
    })
  }

}
