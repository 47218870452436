export class Fillings {
    id:string;
    label:string;
    textContent:string;
    constructor(other?:Partial<Fillings>){
        this.id=other?.id||'';
        this.label=other?.label||'';
        this.textContent=other?.textContent||''
    }
}
