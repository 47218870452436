import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { DocumentAttachment } from 'src/app/entities/document-attachment.entity/document-attachment';
import { DoctorPatient } from 'src/app/entities/doctor-patient.entity/doctor-patient';
import { User } from 'src/app/entities/user.entity';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../core/services/api.service/api.service';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DoctorPatientsService extends ApiService {

  search(searchQuery: { birthDay?: Date | undefined; query: string; country?: string }, pageRequest?: PageRequest) {
    let params = new HttpParams();
    if (searchQuery.birthDay) params = params.append('birthdate', searchQuery.birthDay.getTime());
    if (searchQuery.country != undefined && searchQuery.country.length > 0) params = params.append('country', searchQuery.country);
    if (searchQuery.query.length != 0) params = params.set('q', searchQuery.query);
    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }


  all(pageRequest?: PageRequest): Observable<any> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients`, { params: params }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/all`).pipe(this.responsePipe)
  }

  oneByUserName(userName: string): Observable<User> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients`, { params: { userName: userName } }).pipe(this.responsePipe)
  }


  one(id: string): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/${id}`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  pediatricVaccinations(id: string): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/${id}/pediatric-vaccinations`, { responseType: 'json' }).pipe(this.responsePipe);
  }


  add(data: FormData, accompanistUserName?: string): Observable<User> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/patients${accompanistUserName ? '?accompanistUserName=' + accompanistUserName : ''}`, data, { responseType: 'json' }).pipe(this.responsePipe);
  }

  update(id: string, data: FormData): Observable<User> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/patients/${id}`, data, { responseType: 'json' }).pipe(this.responsePipe);
  }

  switchStatus(id: string): Observable<any> {
    return this.http.put(`${environment.API_URL}/doctor/patients/${id}`, { id: id }, { responseType: 'json' })
  }

  attachments(patientId: string): Observable<Array<DocumentAttachment>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/${patientId}/attachments`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  appointments(patientId: string, searchQuery?: { date?: number, query?: string }): Observable<Array<DocumentAttachment>> {
    if (searchQuery) {
      let params = new HttpParams();
      if (searchQuery.date) params = params.append('date', searchQuery.date)
      if (searchQuery.query) params = params.append('query', searchQuery.query);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/${patientId}/appointments`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
    }
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/${patientId}/appointments`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  searchinvoice(): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/searchInvoice`).pipe(this.responsePipe)
  }

  prescriptions(patientId: string): Observable<Array<DocumentAttachment>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/${patientId}/prescriptions`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  assign(patientId: string): Observable<Array<DocumentAttachment>> {
    return this.http.patch<ApiResponse>(`${environment.API_URL}/doctor/patients/${patientId}`, { responseType: 'json' }).pipe(this.responsePipe)
  }


  historics(patientId: string): Observable<Array<any>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/${patientId}/historics`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  documentShared(): Observable<Array<any>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/storage/shared`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  delete(doctorPatient: string): Observable<DoctorPatient> {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/patients/${doctorPatient}`, { responseType: 'json' }).pipe(this.responsePipe);
  }
}
