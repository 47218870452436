<div class="modal-body">
  <div>
    <label class="mt-1">Heure Rdv</label>
    <div class="col-lg-6">
      <select [(ngModel)]="availability" class="form-control">
        <option *ngFor="let availability of availabilities ;" [ngValue]="availability"><span>{{availability.startTime |time}}</span>
        </option>
      </select>
    </div>
  </div>
  <button style="margin: 12px 8px;" class="btn btn-sm btn-primary" (click)="submit()">Ajouter Rendez-vous</button>
</div>