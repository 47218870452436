import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Biology } from 'src/app/entities/biology.entity/biology';

@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.css']
})
export class CkeditorComponent implements OnInit {
  @Input() content!: any;
  @Output() contentChange: EventEmitter<Biology | string> = new EventEmitter();

  public Editor = ClassicEditor;


  constructor() { }

  ngOnInit(): void {
  }

}
