import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { PatientRequestedAppointment } from 'src/app/entities/appointment.entity';
import { DalyTimeSlot } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import Speciality from 'src/app/entities/user.entity/doctor.entity/speciality.entity';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PublicApisService extends ApiService {

  searchDoctors(query?: string, government?: string, specialities?: Array<string>): Observable<Array<Doctor>> {
    let queryParams: HttpParams = new HttpParams()
    if (query) queryParams = queryParams.append('q', query!);
    if (government) queryParams = queryParams.append('government', government!);
    if (specialities && specialities.length) for (let speciality of specialities) queryParams = queryParams.append('specialities', speciality)
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/search`, {
      params: queryParams
    }).pipe(this.responsePipe);
  }

  getSpecialities(): Observable<Array<Speciality>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/specialities`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  getSpecialityAppointmentMotifs(specialityId: string): Observable<Array<any>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/specialities/${specialityId}/motifs`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  getSpecialityAppointmentDiagnostics(specialityId: string): Observable<Array<any>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/specialities/${specialityId}/diagnotics`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  getSubSpecialities(): Observable<Array<any>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/specialities/sub`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  getDoctorProfile(doctorId: string): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/${doctorId}`, { responseType: 'json' }).pipe(this.responsePipe);
  }
  getDoctorCalender(doctorId: string): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/${doctorId}/calender`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  getDoctoravailabilities(doctorId: string, date: Date): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/${doctorId}/calender?date=${date.getTime()}`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  requestAppointment(doctorId: string, requestedAppointment: PatientRequestedAppointment) {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctors/${doctorId}/appointments/request`, requestedAppointment.requestPayload(), { responseType: 'json' }).pipe(this.responsePipe);
  }

  verify(appointmentId: string, code: string) {
    return this.http.get<ApiResponse>(`${environment.API_URL}/patients/appointments/${appointmentId}/verify?verificationCode=${code}`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  updateAppointment(patientId: string, appointmentId: string) {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/patients/${patientId}/assign?appointment=${appointmentId}`, { responseType: "json" }).pipe(this.responsePipe);
  }

  updateAppointmentSecretary(patientId: string, appointmentId: string) {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/secretary/patients/${patientId}/assign?appointment=${appointmentId}`, { responseType: "json" }).pipe(this.responsePipe);
  }

  getDoctorActualTimeBoarding(doctorId: string): Observable<Array<DalyTimeSlot>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/${doctorId}/time-boarding`, { responseType: 'json' }).pipe(this.responsePipe);
  }
}
