<!-- Prescription Item -->
<div class="card card-table">
    <div class="card-body">
        <table class="table table-hover table-center">
            <thead>
                <tr>
                    <th style="min-width: 200px">Radiologie</th>
                    <th>Date</th>
                    <th style="min-width: 80px;">Notes </th>

                    <th style="min-width: 80px;"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let radiologyConsultationItem of radiologyConsultationItems;let i = index">
                    <td style="width:41%">
                        <app-radiology-select-dropdown [(value)]="radiologyConsultationItem.radio"
                            [placeHolder]="'Radiology is here'">
                        </app-radiology-select-dropdown>
                        <div
                            *ngIf="validateConsultationItem(radiologyConsultationItem).controlsValidation['radio'] == 'required'  && submitted">
                            <span style="color: red"> Radio est obligatoire </span>
                        </div>
                    </td>
                    <td>
                        <!-- <datepicker [(date)]="radiologyConsultationItem.date_consultation_radio"></datepicker> -->
                        <input type="date" placeholder="Select Date" class="form-control datetimepicker"
                            [value]="radiologyConsultationItem.date_consultation_radio.getFullYear()+'-'+(radiologyConsultationItem.date_consultation_radio.getMonth()+1<=9?'0'+(radiologyConsultationItem.date_consultation_radio.getMonth()+1):(radiologyConsultationItem.date_consultation_radio.getMonth())+1)+'-'+(radiologyConsultationItem.date_consultation_radio.getDate()<=9?'0'+radiologyConsultationItem.date_consultation_radio.getDate():radiologyConsultationItem.date_consultation_radio.getDate())"
                            style="cursor: pointer;">
                    </td>
                    <td style="width:41%">
                        <textarea style="height: 45px" (ngModelChange)="_change()" (input)="onEditPrescriptionItem(i)"
                            [(ngModel)]="radiologyConsultationItem.note_consultation_radio"
                            class="form-control"></textarea>
                        <div
                            *ngIf="validateConsultationItem(radiologyConsultationItem).controlsValidation['note'] == 'required'  && submitted">
                            <span style="color: red"> Note est obligatoire </span>
                        </div>
                    </td>

                    <td>
                        <a class="btn bg-danger-light trash" (click)="deletePrescriptionItem(i)"><i
                                class="feather-trash-2"></i></a>
                    </td>
                <tr>
                    <td colspan="5">
                        <div class="add-more-item text-center">
                            <a href="javascript:void(0);" (click)="addPrescriptionItem()"><i
                                    class="feather-plus-circle"></i> Ajouter </a>
                        </div>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<!-- /Prescription Item -->