import { Directive } from '@angular/core';

@Directive({
  selector: '[page]'
})
export class PageDirective {


  constructor() { }

}
