import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'time-counter',
  templateUrl: './time-counter.component.html',
})
export class TimeCounterComponent implements OnInit {
  @ContentChild(TemplateRef, { static: false }) customTemplate!: TemplateRef<any>;
  @Input() startTime: number = Date.now();
  @Input() direction: 'up' | 'down' = 'up';
  time: number = 0;
  private intervalId: any;
  constructor() { }
  ngOnInit(): void {
    this.time = this.startTime;
    this.startCounter();
  }

  startCounter() {
    this.intervalId = setInterval(() => {
      this.addTime(this.direction == 'down' ? -1000 : 1000)
    }, 1000);
  }

  addTime(amount: number): void {
    this.time = this.time + amount;
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed to prevent memory leaks
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
