<!-- Prescription Item -->
<div class="card card-table">
  <div class="card-body">
    <table class="table table-hover table-center">
      <thead>
        <tr>
          <th style="min-width: 200px">Acte</th>
          <th>Date</th>
          <th>Montant</th>
          <th>Note</th>

          <th style="min-width: 80px"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="
                let actesConsultationItem of actesConsultationItems;
                let i = index
              ">
          <td class="style-act-select">
            <app-act-dropdown-select [value]="actesConsultationItem.act"
              (valueChange)="onDoctorActSelected(actesConsultationItem,$event)" [placeHolder]="'act is here'">
            </app-act-dropdown-select>
            <div
              *ngIf="validateConsultationItem(actesConsultationItem).controlsValidation['act'] == 'required' && submitted">
              <span style="color: red"> act est obligatoire </span>
            </div>
          </td>
          <td>
            <!-- <datepicker [(date)]="radiologyConsultationItem.date_consultation_radio"></datepicker> -->
            <input type="date" placeholder="Select Date" class="form-control datetimepicker"
              [ngStyle]="(validateConsultationItem(actesConsultationItem).controlsValidation['date']&&submitted==true)?{'margin-bottom':'22px'}:{'margin-bottom':'0px'}"
              [value]="
                  actesConsultationItem.date_consultation_acte.getFullYear() +
                    '-' +
                    (actesConsultationItem.date_consultation_acte.getMonth() +
                      1 <=
                    9
                      ? '0' +
                        (actesConsultationItem.date_consultation_acte.getMonth() +
                          1)
                      : actesConsultationItem.date_consultation_acte.getMonth() +
                        1) +
                    '-' +
                    (actesConsultationItem.date_consultation_acte.getDate() <=
                    9
                      ? '0' +
                      actesConsultationItem.date_consultation_acte.getDate()
                      : actesConsultationItem.date_consultation_acte.getDate())
                  " style="cursor: pointer" />
          </td>
          <td>
            <input type="number" class="form-control" style="height: 45px" min="1"
              [(ngModel)]="actesConsultationItem.montant_consultation_acte" (ngModelChange)="_change()"
              class="form-control">
            <span
              *ngIf="validateConsultationItem(actesConsultationItem).controlsValidation['montant'] == 'required'  && submitted"
              style="color: red"> Montant est obligatoire </span>
            <span
              *ngIf="validateConsultationItem(actesConsultationItem).controlsValidation['montant'] == 'number'  && submitted"
              style="color: red"> Montant invalide </span>
          </td>
          <td>
            <textarea style="height: 45px" [(ngModel)]="
                  actesConsultationItem.note_consultation_acte
                  " class="form-control"
              [ngStyle]="(validateConsultationItem(actesConsultationItem).controlsValidation['note'] && submitted==true)?{'margin-bottom':'22px'}:{'margin-bottom':'0px'}"></textarea>
          </td>

          <td>
            <a class="btn bg-danger-light trash" (click)="deleteConsultationActItem(i)">
              <i class="feather-trash-2"></i>
            </a>
          </td>
        </tr>

        <tr>
          <td colspan="5">
            <div class="add-more-item text-center">
              <a href="javascript:void(0);" (click)="addPrescriptionItem()"><i class="feather-plus-circle"></i> Ajouter
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- /Prescription Item -->