<div class="row" style="background-color: transparent;">
    <div class="col-lg-11">
        <select-drop-down [items]="values" [(ngModel)]="value" [options]="{ filter:{ byKey:'element_Bio' ,with:'containe'} }" name="biologie">
            <ng-template let-item="item">{{item.element_Bio}}</ng-template>
        </select-drop-down>
    </div>
    <div class="col-lg-1">
        <button class="openDialoge" (click)="openDialoge()">
            <i class="feather-plus-circle"></i>
        </button>
    </div>
</div>

<app-modal [title]="'Ajouter Analyse Biologique'" #dialog>
    <app-biology-form #biologyForm></app-biology-form>
    <button class="btn btn-outline-info mb-3 mt-3" (click)="onAddBiology()">Ajouter Analyse</button>
</app-modal>
