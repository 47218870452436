
    <div>
      <div class="submit-section text-center row mt-5">
        <h3>Assigner le RDV à un Patient <strong>Existant</strong>  ou Créer un <strong>Nouveau</strong></h3>
        
      </div>
      <div class="submit-section text-center row mt-5">
        <div class="col-lg-6">
          <button class="btn btn-primary submit-btn" (click)="openModel.emit()">Assigner à un patient Existant</button>
        </div>
        <div class="col-lg-6">
          <button class="btn btn-danger submit-btn" (click)="cancel.emit()">Créer un nouveau patient</button>
  
        </div>
    </div>
  
  