import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Fillings } from 'src/app/entities/fillings.entity/fillings';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DoctorAppointment } from 'src/app/entities/appointment.entity/doctor-appointment.entity/doctor-appointment';
import { DoctorAppointmentBill } from 'src/app/entities/appointment-bill.entity';
import { ActivatedRoute, Router } from '@angular/router';
import { DoctorAppointmentService } from 'src/app/services/doctor-appointments.service/doctor-appointments.service';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { AppointmentsBillingsService } from 'src/app/services/appointments-billings.service/appointments-billings.service';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { Patient } from 'src/app/entities/user.entity/patient.entity';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-model-form-payment',
  templateUrl: './model-form-payment.component.html',
  styleUrls: ['./model-form-payment.component.css']
})
export class ModelFormPaymentComponent implements OnInit {
  @Input() Idappointment: string = ''

  @Input() open: boolean = true
  @Input() fillingsItmes: Array<Fillings> = new Array(new Fillings({}))
  @Output() fillingsItmesChange: EventEmitter<Array<Fillings>> = new EventEmitter()
  public Editor = ClassicEditor;
  ckEditor = ClassicEditor;
  @Input() doctorAppointmentBill: DoctorAppointmentBill = new DoctorAppointmentBill();
  appointmentId: string = '';
  patientId: string = ''
  doctorName: string = ''
  PatientName: string = ''
  paidAmount: number = 0

  constructor(private authService: AuthService) { }

  get doctor(): Doctor {
    return this.authService.getCurrentUser() as Doctor;
  }

  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '18rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: '40rem',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,

    showToolbar: true,
    placeholder: 'Insérer le TEXT...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: false,

  };


  ngOnInit(): void {
    this.doctorName = this.doctor.lastName + ' ' + this.doctor.firstName
    this.open == true ? this.addFillingsItem() : ""
  }

  addFillingsItem(): void {

    this.fillingsItmes.push(new Fillings({}));
    this._change()
  }

  deleteFillingsItem(index: number): void {
    this.fillingsItmes.splice(index, 1);
    this._change()
  }
  _change() {
    this.fillingsItmesChange.emit(this.fillingsItmes)
  }

}
