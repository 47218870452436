import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-appointments-card-rest',
  templateUrl: './doctor-appointments-card-rest.component.html',
  styleUrls: ['./doctor-appointments-card-rest.component.css']
})
export class DoctorAppointmentsCardRestComponent implements OnInit {
  @Input() appointmentsData: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
