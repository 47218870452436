import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../core/services/api.service/api.service';
import { ApiResponse } from '../../core/entities/api-response.entity/api-response';
import { Radiology } from 'src/app/entities/radiology.entity/radiology';

@Injectable({
  providedIn: 'root'
})
export class RadiologiesService extends ApiService {


  add(data: Radiology): Observable<Radiology> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/radiologies`, {
      nom_Radio: data.nom_Radio,
      type_Radio: data.type_Radio,
    }, { responseType: 'json' }).pipe(this.responsePipe)
  }
  all(): Observable<Array<Radiology>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/radiologies`, { responseType: 'json' }).pipe(this.responsePipe);
  }
  allDoctor(): Observable<Array<Radiology>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/radiologies`, { responseType: 'json' }).pipe(this.responsePipe);

  }
}
