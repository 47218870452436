import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableHeadDirective } from '././directives/data-table-head.directive/data-table-head.directive';
import { PaginationComponent } from './components/pagination.component/pagination.component';
import { PromptDialogComponent } from './components/prompt-dialog.component/prompt-dialog.component';
import { DataTableComponent } from './components/data-table.component/data-table.component';
import { DataTableItemDirective } from './directives/data-table-item.directive/data-table-item.directive';
import { ResizableDirective } from './directives/resizable.directive/resizable.directive';
import { SearchPipe } from './pipes/filter.pipe/search.pipe';
import { PaginationPipe } from './pipes/pagination.pipe/pagination.pipe';
import { SortPipe } from './pipes/sort.pipe/sort.pipe';
import { ViewChangePipe } from './pipes/view-change.pipe/view-change.pipe';

@NgModule({
    declarations: [
        PromptDialogComponent,
        PaginationComponent,
        SearchPipe,
        PaginationPipe,
        ViewChangePipe,
        SortPipe,
        ResizableDirective,
        DataTableHeadDirective,
        DataTableComponent,
        DataTableItemDirective
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
    ], exports: [
        DataTableComponent,
        DataTableItemDirective,
        DataTableHeadDirective,
        PaginationComponent
    ],
    providers: [],
})
export class DataTableModule { }
