<div style="
width: 95%;
background-color: #FFFF;
padding: 15px;
">
  <div class="row rowlentille">
    <div class="col-lg-4 mt-2 typeLentille">
      Type de Lentille
    </div>
    <div class="col-lg-6">
      <select name="" id=""  [(ngModel)]="consultation.lentille_Type" class="form-control">
        <option value="Select type Lentille" >Select Type Lentille</option>

        <option value="Souple">Souple</option>
        <option value="Torique">Torique</option>
        <option value="Rigide">Rigide</option>
      </select>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table ">
      <thead class="table-bordered">
        <tr>
          <th scope="lentille"></th>
          <th class="text-center" style="background-color: #e6f7f0;color: black;font-size: 20px;font-family: Gotham Rounded Bold;">Œil Droit</th>
          <th class="text-center" style="background-color: #fff4cc;color:black;font-size: 20px;font-family: Gotham Rounded Bold;">Œil Gauche</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="background-color: #4400ff31;font-family: Gotham Rounded Bold;">Rayon de Courbure</td>
          <td>
            <input type="number" step="0.25" min="-0.25" max="20" class="inputtableau"
              [(ngModel)]="consultation.od_lentille_RC" />
          </td>
          <td>
            <input type="number" step="0.25" min="-0.25" max="20" class="inputtableau"
              [(ngModel)]="consultation.og_lentille_RC" />
          </td>
        </tr>
        <tr>
          <td style="background-color: #4400ff31;font-family: Gotham Rounded Bold;">Diamètre</td>
          <td>
            <input type="number" step="0.25" min="-0.25" max="20" class="inputtableau"
              [(ngModel)]="consultation.od_lentille_DC" />
          </td>
          <td>
            <input type="number" step="0.25" min="-0.25" max="20" class="inputtableau"
              [(ngModel)]="consultation.og_lentille_DC" />
          </td>
        </tr>
        <tr>
          <td style="background-color: #4400ff31;font-family: Gotham Rounded Bold;">Puissance</td>
          <td>
            <input type="number" step="-0.25" min="20" max="0.25" class="inputtableau"
              [(ngModel)]="consultation.od_lentille_Puissance" />
          </td>
          <td>
            <input type="number" step="-0.25" min="20" max="0.25" class="inputtableau"
              [(ngModel)]="consultation.og_lentille_Puissance" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="form-group">
      <label for="remarque-lentille" class="remarque-Lentille">Note Lentille</label>
      <textarea name="remarque-lentille" class="form-control" [(ngModel)]="consultation.lentille_remarque"
     ></textarea>
    </div>
  </div>
</div>