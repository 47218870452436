import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TextFillings } from 'src/app/entities/text-fillings.entity/text-fillings';
import { TextFillingsService } from 'src/app/services/fillings.service/text-fillings.service';
export interface IStyleInput {
  right?: string;
  top?: string;

}
@Component({
  selector: 'app-liste-question-sahred',
  templateUrl: './liste-question-sahred.component.html',
  styleUrls: ['./liste-question-sahred.component.css']
})

export class ListeQuestionSahredComponent implements OnInit {

  @Input() listeQuestionsConsultations: Array<TextFillings> = new Array()
  listeQuestionsConsultationsFilter: Array<TextFillings> = new Array()
  @Output() listeQuestionsConsultationsChange: EventEmitter<Array<TextFillings>> = new EventEmitter()
  @Input() filter: String = ""
  @Input() text: string = ""
  @Input() value: string = "";
  @Input() options: Partial<IStyleInput> = {
    right: "0",
    top: "0"
  }
  @Output() valueChange: EventEmitter<string> = new EventEmitter()
  @Output() textChange: EventEmitter<string> = new EventEmitter()
  @Input() open: boolean = false
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();


  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  constructor(private textFillingsService: TextFillingsService, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.textFillingsService.getAllTextFillingsDoctor().subscribe(
      res => {
        this.listeQuestionsConsultations = res
        this.listeQuestionsConsultationsChange.emit(this.listeQuestionsConsultations)
        this.listeQuestionsConsultationsFilter = this.listeQuestionsConsultations.filter(elm => elm.label == this.filter)

      }
    )
  }

  label(event: any) {
    this.textChange.emit(event.target.value)
    this.valueChange.emit((this.value.length == 0) ? this.value.concat(event.target.value) : this.value.concat("\n", event.target.value))
    this.open = false
    this.openChange.emit(this.open);
  }

}
