<div class="drop-down-select" style="height:50%">
    <div class="drop-down-input" (click)="toggleModal(true,'test')">
        <input type="text" [placeholder]="placeHolder || ''" [value]="value.nom" readonly>
        <span>
            <i class="feather-plus-circle"></i>
        </span>
    </div>
    <div *ngIf="modalToggled" class="drop-down-modal" >
        <div class="drop-down-modal-header">
            <span>Choose a Medic</span>
            <span (click)="toggleModal(false)">X</span>
        </div>
        <div class="drop-down-modal-body">
            <div class="tab-view">
                <ul class="nav-tabs">
                    <li class="nav-item" (click)="currentTabIndex = 0" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="{'active':currentTabIndex == 0}" data-bs-toggle="tab">All</a>
                    </li>
                    <li class="nav-item" (click)="currentTabIndex = 1" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="{'active':currentTabIndex == 1}" data-bs-toggle="tab">New</a>
                    </li>
                </ul>
                <div *ngIf="currentTabIndex == 0" class="tab-content">
                    <div class="input-control" style="height: 40px;">
                        <input type="text" placeholder="search" [(ngModel)]="searchPattern" id="test">
                        <span>
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                    <!--<input class="form-control" placeholder="search medic" [(ngModel)]="searchPattern">-->
                    <ng-container *ngIf="medics.length else empty">
                        <scrollbar>
                            <div *ngFor="let medic of medics" class="list-group-item"  >
                               <div class="row" >
                                <div class="col-lg-4">
                                    <span
                                    style="color: #d86800;font-size:14px;text-overflow: ellipsis;">{{medic.nom}}</span>
                                <p style="color: #6e6e6e;font-size:10px;text-overflow: ellipsis;">{{medic.labo}}</p>
                                </div>
                                <div class="col-lg-8">
                                    <input type="checkbox" name="{{medic.nom}}" id="" style="margin-left:15px"   (change)="onValuesSelected(medic,$event)">
                                </div>
                               </div>
                            </div>
                        </scrollbar>
                    </ng-container>
                    <ng-template #empty>
                        <span>No item found</span>
                    </ng-template>
                </div>
               
            </div>
        </div>
    </div>
</div>