import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Biology } from 'src/app/entities/biology.entity/biology';

@Component({
  selector: 'app-biology-form',
  templateUrl: './biology-form.component.html',
  styleUrls: ['./biology-form.component.css']
})
export class BiologyFormComponent implements OnInit {

  public Editor = ClassicEditor;

  @Input() biology: Biology = new Biology();
  @Output() biologyChanged: EventEmitter<Biology> = new EventEmitter();
  @Input() isSubmitted: boolean = false;

  constructor() { }

  ngOnInit(): void { }
  get isValid(): boolean {
    return this.biology.element_Bio.length >0
  }
  _change(): void {
    this.biologyChanged.emit(this.biology);
  }
}
