import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.css']
})
export class VideoChatComponent implements OnInit {

  messages: Map<number, { text: string, isReply?: boolean }> = new Map();

  constructor() { }

  messageContent: string = ''

  ngOnInit(): void {
  }

  onSendMessage() {
    if (this.messageContent.length) this.messages.set(Date.now(), { text: this.messageContent.trim(), isReply: true })
    this.messageContent = '';
  }
}
