import * as moment from "moment";

export class ConsultatioCnamAp2 {
  public ap2ScannerRad1: boolean;
  public ap2ScannerRad2: boolean;
  public ap2ScannerRad3: boolean;
  public ap2ScannerRad4: boolean;
  public ap2ScannerRad5: boolean;
  public ap2ScannerRad6: boolean;
  public ap2ScannerRad7: boolean;
  public ap2ScannerRad8: boolean;
  public ap2ScannerRad9: boolean;
  public ap2ScannerRad10: boolean;
  public ap2OtherScannerRegion: string;
  public ap2OtherScannerCode: string;
  public irmRegion: string;
  public seintigraphie: string;
  public otherAct: string;
  public ap2APCI: boolean;
  public ap2APCICode: string;
  public ap2Question1: string;
  public ap2Question2: string;
  public ap2Question3: string;
  public ap2ExamNature1: string;
  public ap2ExamNature2: string;
  public ap2ExamNature3: string;
  public ap2ExamDate1: string;
  public ap2ExamDate2: string;
  public ap2ExamDate3: string;
  constructor(other?: Partial<ConsultatioCnamAp2>) {
    this.ap2ScannerRad1 = other?.ap2ScannerRad1 || false;
    this.ap2ScannerRad2 = other?.ap2ScannerRad2 || false;
    this.ap2ScannerRad3 = other?.ap2ScannerRad3 || false;
    this.ap2ScannerRad4 = other?.ap2ScannerRad4 || false;
    this.ap2ScannerRad5 = other?.ap2ScannerRad5 || false;
    this.ap2ScannerRad6 = other?.ap2ScannerRad6 || false;
    this.ap2ScannerRad7 = other?.ap2ScannerRad7 || false;
    this.ap2ScannerRad8 = other?.ap2ScannerRad8 || false;
    this.ap2ScannerRad9 = other?.ap2ScannerRad9 || false;
    this.ap2ScannerRad10 = other?.ap2ScannerRad10 || false;
    this.ap2OtherScannerCode = other?.ap2OtherScannerCode || "";
    this.ap2OtherScannerRegion = other?.ap2OtherScannerRegion || "";
    this.irmRegion = other?.irmRegion || "";
    this.seintigraphie = other?.seintigraphie || "";
    this.otherAct = other?.otherAct || "";
    this.ap2APCI = other?.ap2APCI || false;
    this.ap2APCICode = other?.ap2APCICode || "";
    this.ap2Question1 = other?.ap2Question1 || "";
    this.ap2Question2 = other?.ap2Question2 || "";
    this.ap2Question3 = other?.ap2Question3 || "";
    this.ap2ExamNature1 = other?.ap2ExamNature1 || "";
    this.ap2ExamNature2 = other?.ap2ExamNature2 || "";
    this.ap2ExamNature3 = other?.ap2ExamNature3 || "";
    this.ap2ExamDate1 = other?.ap2ExamDate1 || moment().format("YYYY/MM/DD");
    this.ap2ExamDate2 = other?.ap2ExamDate2 || moment().format("YYYY/MM/DD");
    this.ap2ExamDate3 = other?.ap2ExamDate3 || moment().format("YYYY/MM/DD");
  }
  public static fromResponse(res: any): ConsultatioCnamAp2 {
    return new ConsultatioCnamAp2({
      ap2ScannerRad1: res.ap2ScannerRad1,
      ap2ScannerRad2: res.ap2ScannerRad2,
      ap2ScannerRad3: res.ap2ScannerRad3,
      ap2ScannerRad4: res.ap2ScannerRad4,
      ap2ScannerRad5: res.ap2ScannerRad5,
      ap2ScannerRad6: res.ap2ScannerRad6,
      ap2ScannerRad7: res.ap2ScannerRad7,
      ap2ScannerRad8: res.ap2ScannerRad8,
      ap2ScannerRad9: res.ap2ScannerRad9,
      ap2ScannerRad10: res.ap2ScannerRad10,
      ap2OtherScannerRegion: res.ap2OtherScannerRegion,
      ap2OtherScannerCode: res.ap2OtherScannerCode,
      irmRegion: res.irmRegion,
      seintigraphie: res.seintigraphie,
      otherAct: res.otherAct,
      ap2APCI: res.ap2APCI,
      ap2APCICode: res.ap2APCICode,
      ap2Question1: res.ap2Question1,
      ap2Question2: res.ap2Question2,
      ap2Question3: res.ap2Question3,
      ap2ExamNature1: res.ap2ExamNature1,
      ap2ExamNature2: res.ap2ExamNature2,
      ap2ExamNature3: res.ap2ExamNature3,
      ap2ExamDate1: res.ap2ExamDate1,
      ap2ExamDate2: res.ap2ExamDate2,
      ap2ExamDate3: res.ap2ExamDate3,
    });
  }
}
