import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EAppointmentState } from 'src/app/entities/appointment.entity';
import { DoctorAppointment } from 'src/app/entities/appointment.entity/doctor-appointment.entity/doctor-appointment';
import { EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { DoctorAppointmentService } from 'src/app/services/doctor-appointments.service/doctor-appointments.service';
import { DoctorPatientsService } from 'src/app/services/doctor-patients.service/doctor-patients.service';

@Component({
  selector: 'app-doctor-appointments-list',
  templateUrl: './doctor-appointments-list.component.html',
  styleUrls: ['./doctor-appointments-list.component.css']
})
export class DoctorAppointmentsListComponent implements OnInit {

  @Input() currentPage: number = 0;
  @Input() perPage: number = 10;
  @Input() doctorAppointments: Array<DoctorAppointment> = new Array();
  @Input() actions: Partial<{ accept: boolean, reject: boolean, edit: boolean, delete: boolean, prescription: boolean, consultations: boolean, attachmentsView: boolean, payementsView: boolean }> = {}
  @Output() onEditRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onAccept: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onReject: EventEmitter<DoctorAppointment> = new EventEmitter();
  constructor(private doctorAppointmentService: DoctorAppointmentService, private toasterService: ToastrService,) { }

  ngOnInit(): void {
  }

  acceptAppointment(appointment: DoctorAppointment): void {
    if (confirm(`Etes-vous sûr de vouloir confirmer le RDV de: ${appointment.patient.firstName + ' ' + appointment.patient.lastName} ?`))
      this.doctorAppointmentService
        .updateStatus(appointment.id, EAppointmentState.ACCEPTED)
        .subscribe(res => { this.doctorAppointments[this.doctorAppointments.indexOf(appointment)].status = EAppointmentState.ACCEPTED; this.toasterService.success(`RDV Confimé:   ${appointment.motif}`); },
          error => this.toasterService.warning('Problème de mise à jour Etat RDV :' + appointment.patient.firstName + ' ' + appointment.patient.lastName)
        );
  }

  rejectAppointment(appointment: DoctorAppointment): void {
    if (confirm(`Etes-vous sûr de vouloir annuler ${appointment.patient.firstName + ' ' + appointment.patient.lastName} RDV ?`))
      this.doctorAppointmentService
        .updateStatus(appointment.id, EAppointmentState.REJECTED)
        .subscribe(
          (res) => { this.doctorAppointments[this.doctorAppointments.indexOf(appointment)].status = EAppointmentState.REJECTED; this.toasterService.error(`RDV ${appointment.motif}  Annulé`) },
          (error) => this.toasterService.warning('Problème de mise à jour Etat RDV :' + appointment.patient.firstName + ' ' + appointment.patient.lastName)
        );
  }

  canOpenTeleconsultation(appointment: DoctorAppointment): boolean {
    return appointment.status == EAppointmentState.ACCEPTED && (appointment.location == EDalyTimeSlotLocation.BOTH || appointment.location == EDalyTimeSlotLocation.ONLINE)
  }

  canAssignPrescription(appointemnt: DoctorAppointment): boolean {
    return !appointemnt.hasPrescription
  }

}
