import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileViewerComponent } from './file-viewer.component/file-viewer.component';
import { AudioViewerComponent } from './audio-viewer.component/audio-viewer.component';
import { ImageViewerComponent } from './image-viewer.component/image-viewer.component';
import { PdfViewerComponent } from './pdf-viewer.component/pdf-viewer.component';
import { TextViewerComponent } from './text-viewer.component/text-viewer.component';
import { VideoViewerComponent } from './video-viewer.component/video-viewer.component';
import { WordViewerComponent } from './word-viewer.component/word-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  declarations: [
    FileViewerComponent,
    ImageViewerComponent,
    VideoViewerComponent,
    AudioViewerComponent,
    PdfViewerComponent,
    WordViewerComponent,
    TextViewerComponent
  ],
  imports: [
    CommonModule,
    NgxDocViewerModule,
  ],
  exports: [
    FileViewerComponent
  ]
})
export class FileViewerModule { }
