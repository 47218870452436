<nav class="tree-nav scroll" >
    <details *ngFor="let patientHistoric of patientHistorics" class="tree-nav-item is-expandable" open >
        <summary class="tree-nav-item-title">Date rdv : {{patientHistoric.date | date:'dd/MM/yyyy'}}</summary> 
        <div *ngIf="patientHistoric.motif.length" class="tree-nav-item">Motif : {{patientHistoric.motif}}</div>
        <div *ngIf="patientHistoric.diagnostic.length" class="tree-nav-item">Diagno : {{patientHistoric.diagnostic}}
        </div>
        <div *ngIf="patientHistoric.examenDemande.length" class="tree-nav-item">Examen demandé :
            {{patientHistoric.examenDemande}}</div>
        <details  *ngIf="patientHistoric.hasConsultation" class="tree-nav-item is-expandable" open>
            <summary class="tree-nav-item-title">Consultation : </summary>
            <details *ngIf="patientHistoric.consultationRadiologies.length>0" class="tree-nav-item is-expandable" open> 
                <summary class="tree-nav-item-title">Radiologies</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>Radio</th>
                            <th>Note</th>
                        </tr>
                        <tr *ngFor="let consultationRadiologie of patientHistoric.consultationRadiologies">
                            <td>{{consultationRadiologie.radio.nom_Radio}}</td>
                            <td>{{consultationRadiologie.note_consultation_radio}}</td>
                        </tr>
                    </table>
                </div>
            </details>
            <details *ngIf="patientHistoric.consultationBiologies.length" class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Biologies</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>Bioglogie</th>
                            <th>Note</th>
                        </tr>
                        <tr *ngFor="let consultationBiologie of patientHistoric.consultationBiologies">
                            <td>{{consultationBiologie.biology.element_Bio}}</td>
                            <td>{{consultationBiologie.note}}</td>
                        </tr>
                    </table>
                </div>
            </details>
            <details *ngIf="patientHistoric.consultationActes.length" class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Actes</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>Acte</th>
                            <th>Montant</th>
                            <th>Note</th>
                            <th>Date</th>
                        </tr>
                        <tr *ngFor="let consultationActe of patientHistoric.consultationActes">
                            <td>{{consultationActe.act.famille_Acte}}</td>
                            <td>{{consultationActe.montant_consultation_acte}}</td>
                            <td>{{consultationActe.note_consultation_acte}}</td>
                            <td>{{consultationActe.date_consultation_acte | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </table>
                </div>
            </details>
            <details  *ngIf="patientHistoric.consultationVaccinations.length" class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Vaccin</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>Vaccin</th>
                            <th>Lot N°</th>
                            <th>Date</th>
                            <th>Note</th>
                        </tr>
                        <tr *ngFor="let consultationVaccination of patientHistoric.consultationVaccinations">
                            <td>{{consultationVaccination.vaccin.nom_Vaccin}}</td>
                            <td>{{consultationVaccination.numLot_consultation_vaccin}}</td>
                            <td>{{consultationVaccination.note_consultation_vaccin}}</td>
                            <td>{{consultationVaccination.date_consultation_vaccin}}</td>
                        </tr>
                    </table>
                </div>
            </details>
            <details *ngIf="patientHistoric.consultationParamSanteValues.length && doctor.speciality.label!=='Ophtalmologue'"  class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Paramètres Santé</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>Paramètre</th>
                          
                            <th>Valeur</th>
                        </tr>
                        <tr *ngFor="let consultationParamSante of patientHistoric.consultationParamSanteValues">
                           <ng-container >
                            <td>{{consultationParamSante.parametre.label}}</td>
                          
                            <td>{{consultationParamSante.value}}</td>
                           </ng-container>
                        </tr>
                    </table>
                </div>
            </details>
            <details *ngIf="patientHistoric.consultationParamSanteValues.length && doctor.speciality.label=='Ophtalmologue'"  class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Paramètres Santé</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>OG</th>

                            <th>Paramètre</th>
                          
                            <th>OD</th>
                        </tr>
                        <tr *ngFor="let consultationParamSante of patientHistoric.consultationParamSanteValues">
                            <td>{{consultationParamSante.value_OG}}</td>

                            <td>{{consultationParamSante.parametre.label}}</td>
                          
                            <td>{{consultationParamSante.value_OD}}</td>
                        </tr>
                    </table>
                </div>
            </details>
            <details *ngIf="doctor.speciality.label=='Ophtalmologue'"  class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Lunette</summary>
                <div class="tree-nav-item">
                    <div style="display: flex;flex-direction: column; gap: 15px;">
                        <h4 style="font-family: 'Gotham Rounded Bold';">OD:</h4>
                        <div style="display: flex;flex-direction: column;">
                          <h4 style="margin-left: 15px;"><strong>VL:</strong> {{patientHistoric.od_vl_avac_Sphere}}  ({{patientHistoric.od_vl_avac_Cylindre}})  {{patientHistoric.od_vl_avac_Axe}}°</h4>
                          <h4 style="margin-left: 15px;"><strong>VP:</strong> {{patientHistoric.od_vp_avac_Sphere}}  ({{patientHistoric.od_vp_avac_Cylindre}})  {{patientHistoric.od_vp_avac_Axe}}°</h4>
              
                        </div>
                        <h4 style="font-family: 'Gotham Rounded Bold';">OG:</h4>
                        <div style="display: flex;flex-direction: column;">
                          <h4 style="margin-left: 15px;"><strong>VL:</strong> {{patientHistoric.og_vl_avac_Sphere}}  ({{patientHistoric.og_vl_avac_Cylindre}})  {{patientHistoric.og_vl_avac_Axe}}°</h4>
                          <h4 style="margin-left: 15px;"><strong>VP:</strong> {{patientHistoric.og_vp_avac_Sphere}}  ({{patientHistoric.og_vp_avac_Cylindre}})  {{patientHistoric.og_vp_avac_Axe}}°</h4>
                          <span style=" font-family: 'Gotham Rounded Bold'; margin-left: 15px;">
                            Add:{{patientHistoric.og_vp_avac_Addition}}
                          </span>
                        </div>
                      </div>
                      
                      <span style=" font-family: 'Gotham Rounded Bold'; text-align: center;justify-content: center;display: flex;">Note Lunette : {{patientHistoric.lunette_remarque}}</span>
                </div>
            </details>
            <details *ngIf="doctor.speciality.label=='Ophtalmologue'"  class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Lentille</summary>
                <div class="tree-nav-item">

                <div  style="display: flex;flex-direction: column; gap: 15px;">
                
                  <div >
                    <h4 style="font-family: 'Gotham Rounded Bold';">OD:</h4>
                    <Label class="mx-3">RC:{{patientHistoric.od_lentille_RC}}</Label>
                    <Label>DC:{{patientHistoric.od_lentille_DC}}</Label>
                    <Label class="mx-3">Puissance:{{patientHistoric.od_lentille_Puissance}}</Label>
                  </div>
                  <div >
                    <h4 style="font-family: 'Gotham Rounded Bold';">OG:</h4>
                    <Label class="mx-3">RC:{{patientHistoric.og_lentille_RC}}</Label>
                    <Label>DC:{{patientHistoric.og_lentille_DC}}</Label>
                    <Label class="mx-3">Puissance:{{patientHistoric.og_lentille_Puissance}}</Label>
        
                  
                  </div>
                  <span style=" font-family: 'Gotham Rounded Bold'; text-align: center;justify-content: center;">Note Lentille : {{patientHistoric.lentille_remarque}}</span>
        
                </div>
                </div>
            </details>
        </details>
        <details *ngIf="patientHistoric.hasPrescription" class="tree-nav-item is-expandable" open>
            <summary class="tree-nav-item-title">Prescription : </summary>
            <details *ngIf="patientHistoric.prescriptionMedics.length" class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Medics</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>Médicament</th>
                            <th>Posologie</th>
                            <th>Durée</th>
                            <th>Note</th>
                        </tr>
                        <tr *ngFor="let prescriptionMedic of patientHistoric.prescriptionMedics">
                            <td>{{prescriptionMedic.medic.nom}}</td>
                            <td>{{prescriptionMedic.posology}}</td>

                            <td>{{prescriptionMedic.duration}}</td>
                            <td>{{prescriptionMedic.note}}</td>
                        </tr>
                    </table>
                </div>
            </details>
            <details *ngIf="patientHistoric.prescriptionRadiologies.length" class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Radiologies</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>Radio</th>
                            <th>Note</th>
                        </tr>
                        <tr *ngFor="let prescriptionRadiologie of patientHistoric.prescriptionRadiologies">
                            <td>{{prescriptionRadiologie.radio.nom_Radio}}</td>
                            <td>{{prescriptionRadiologie.note}}</td>
                        </tr>
                    </table>
                </div>
            </details>
            <details *ngIf="patientHistoric.prescriptionBiologies.length" class="tree-nav-item is-expandable" open>
                <summary class="tree-nav-item-title">Biologies</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>Bioglogie</th>
                            <th>Note</th>
                        </tr>
                        <tr *ngFor="let prescriptionRadiologie of patientHistoric.prescriptionBiologies">
                            <td>{{prescriptionRadiologie.biology.element_Bio}}</td>
                            <td>{{prescriptionRadiologie.note}}</td>
                        </tr>
                    </table>
                </div>
            </details>
        </details>


    </details>
</nav>