import { Pipe, PipeTransform } from "@angular/core";
import { Doctor } from "../../../entities/user.entity/doctor.entity";
import Speciality from "../../../entities/user.entity/doctor.entity/speciality.entity";

export interface DoctorFilter {
  specialties: Array<Speciality>;
  searchPattern: string;
}

@Pipe({
  name: "doctorsFilter",
  pure: false,
})
export class DoctorFilterPipe implements PipeTransform {
  transform(
    list: Array<Doctor>,
    filter: DoctorFilter = { searchPattern: "", specialties: [] }
  ): Array<Doctor> {
    if (filter) {
      const data = list.filter((doctor) => {
        return (
          this.includes(doctor.email!, filter.searchPattern)
        ) &&
          filter.specialties.length
          ? filter.specialties.find((s) => s.id === doctor.speciality.id) !=
          null
          : true;
      });
      return data;
    } else return list;
  }

  includes(source: string, pattern: string): boolean {
    return source.trim().toUpperCase().includes(pattern.trim().toUpperCase());
  }
}
