<div class="row" style="background-color: #ffffff;">
  <button (click)="importer()" class="btn pull-right styleImporter">
    Importer
  </button>
  <div class="tete-session">
    <span
      style="font-family: 'Gotham Rounded Bold'; font-size: 25px; color: black;"
      >Œil Droit</span
    >
    <span
      style="font-family: 'Gotham Rounded Bold'; font-size: 25px; color: black;"
      >Œil Gauche
    </span>
    <button
      (click)="importer()"
      class="btn pull-right"
      style="
        color: black;
        font-family: ' Gotham Rounded Bold';
        background-color: #e6f7e6;
        padding: 5px;
      "
    >
      Importer
    </button>
  </div>
  <app-accordion-tab class="accordion-header">
    <h4 header style="font-family: 'Gotham Rounded Bold'; color: black;">
      Refractometrie
    </h4>
    <div body class="row" class="height-session">
   
      <div class="headerRefractometrie">
        <div class="form-group">
          <label for="" style="margin-top: 5px;">VD</label>
          <input
            type="number"
            step="0.25"
            min="-20"
            max="20"
            class="t"
            [(ngModel)]="consultation.refractionVd
        "
          />
        </div>
        <div class="form-group">
          <label for="" style="margin-top: 5px;">PD</label>
          <input
            type="number"
            step="0.25"
            min="-20"
            max="20"
            class="t"
            [(ngModel)]="consultation.refractionPd
        "
          />
        </div>
        <div class="form-group">
          <label for="" style="margin-top: 5px;">NPD</label>
          <input
            type="number"
            step="0.25"
            min="-20"
            max="20"
            class="t"
            [(ngModel)]="consultation.refractionNpd
        "
          />
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-6"
          style="
            border: 1px solid transparent;
            padding: 15px;
            border-radius: 8px;
            text-align: center;
          "
        >
          <div class="card-header ŒilDroit">
            <span
              style="
                font-family: 'Gotham Rounded Bold';
                text-align: center;
                font-size: 20px;
              "
              >Œil Droit</span
            >
          </div>

          <div class="card-body card-body-tableau">
            <table
              class="table table-bordered table-responsive"
              style="border-radius: 0.5rem;"
            >
              <thead class="table-bordered">
                <tr style="background-color: #e6f7e6;">
                  <th>Sphere</th>
                  <th>Cylindre</th>
                  <th>Axe</th>
                </tr>
              </thead>
              <tbody style="padding: 15px;">
                <tr>
                  <td style="background-color: #efefef;">
                    <input
                      type="number"
                      step="5"
                      min="0"
                      class="inputtableau"
                      [(ngModel)]="consultation.od_refraction_Sphere"
                    />
                  </td>
                  <td style="background-color: #efefef;">
                    <input
                      type="number"
                      step="5"
                      min="0"
                      class="inputtableau"
                      [(ngModel)]="consultation.od_refraction_Cylindre"
                    />
                  </td>
                  <td style="background-color: #efefef;">
                    <input
                      type="number"
                      step="5"
                      min="0"
                      class="inputtableau"
                      [(ngModel)]="consultation.od_refraction_Axe"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="card col-lg-6"
          style="
            border: 1px solid transparent;
            padding: 15px;
            border-radius: 8px;
            text-align: center;
          "
        >
          <div class="card-header ŒilGauche">
            <span
              style="
                font-family: 'Gotham Rounded Bold';
                text-align: center;
                font-size: 20px;
              "
              >Œil Gauche
            </span>
          </div>
          <div class="card-body card-body-tableau">
            <table class="table table-bordered table-responsive">
              <thead>
                <tr style="background-color: #fff4cc;">
                  <th>Sphére</th>
                  <th>Cylindre</th>
                  <th>Axe</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="background-color: #efefef;">
                    <input
                      type="number"
                      step="25"
                      min="-100"
                      max="100"
                      class="inputtableau"
                      [(ngModel)]="consultation.og_refraction_Sphere"
                    />
                  </td>
                  <td style="background-color: #efefef;">
                    <input
                      type="number"
                      step="25"
                      min="-100"
                      max="100"
                      class="inputtableau"
                      [(ngModel)]="consultation.og_refraction_Cylindre"
                    />
                  </td>
                  <td style="background-color: #efefef;">
                    <input
                      type="number"
                      step="5"
                      min="0"
                      max="180"
                      class="inputtableau"
                      [(ngModel)]="consultation.og_refraction_Axe"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </app-accordion-tab>

  <app-accordion-tab>
    <h4 header style="font-family: 'Gotham Rounded Bold'; color: black;">
      Subjective Refractometrie
    </h4>
    <div
     class="row"
      body
    >

      <div
        class="col-lg-6"
        style="
          border: 1px solid transparent;
          padding: 15px;
          border-radius: 8px;
          text-align: center;
        "
      >
        <div class="card-header ŒilDroit">
          <span
            style="
              font-family: 'Gotham Rounded Bold';
              text-align: center;
              font-size: 20px;
            "
            >Œil Droit</span
          >
        </div>
        <div class="card-body card-body-tableau">
          <table
            class="table table-bordered table-responsive"
            style="border-radius: 0.5rem;"
          >
            <thead class="table-bordered">
              <tr style="background-color: #e6f7e6;">
                <th>Sphere</th>
                <th>Cylindre</th>
                <th>Axe</th>
              </tr>
            </thead>
            <tbody style="padding: 15px;">
              <tr>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="5"
                    min="0"
                    class="inputtableau"
                    [(ngModel)]="consultation.od_refraction_Sphere_Subj"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="5"
                    min="0"
                    class="inputtableau"
                    [(ngModel)]="consultation.od_refraction_Cylindre_Subj"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="5"
                    min="0"
                    class="inputtableau"
                    [(ngModel)]="consultation.od_refraction_Axe_Subj"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="card col-lg-6"
        style="
          border: 1px solid transparent;
          padding: 15px;
          border-radius: 8px;
          text-align: center;
        "
      >
        <div class="card-header ŒilGauche">
          <span
            style="
              font-family: 'Gotham Rounded Bold';
              text-align: center;
              font-size: 20px;
            "
            >Œil Gauche
          </span>
        </div>
        <div class="card-body card-body-tableau">
          <table class="table table-bordered table-responsive">
            <thead>
              <tr style="background-color: #fff4cc;">
                <th>Sphére</th>
                <th>Cylindre</th>
                <th>Axe</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="25"
                    min="-100"
                    max="100"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_refraction_Sphere_Subj"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="25"
                    min="-100"
                    max="100"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_refraction_Cylindre_Subj"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="5"
                    min="0"
                    max="180"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_refraction_Axe_Subj"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </app-accordion-tab>

  <app-accordion-tab>
    <h4 header style="font-family: 'Gotham Rounded Bold'; color: black;">
      Keratometrie
    </h4>
    <div class="row" style="background-color: #ffffff;" body>
      <!-- <caption
        style="width: 98.2%; margin-left: 13px; font-family: 'Gotham Rounded Bold'; color: black; font-size: 25px;"
        class="text-center"
      >
        
        KERATOMETRIE
      </caption> -->
      <table
        class="table table-bordered table-responsive"
        style="
          border-radius: 0.5rem;
          border-style: none;
          border-collapse: collapse;
          margin-left: 5%;
          width: 90%;
        "
      >
        <tr>
          <th
            style="
              font-family: 'Gotham Rounded Bold';
              font-size: 16px;

              padding-right: 0%;
            "
          >
            Index
          </th>
          <td style="background-color: #efefef; width: 20%;">
            <input
              type="number"
              step="0.25"
              min="-20"
              max="20"
              class="inputtableau"
              [(ngModel)]="consultation.kerato_Index"
            />
          </td>
        </tr>
      </table>
      <div
        class="col-lg-6"
        style="
          border: 1px solid transparent;
          border-radius: 8px;
          text-align: center;
        "
      >
        <div class="card-header ŒilDroit">
          <span
            style="
              font-family: 'Gotham Rounded Bold';
              text-align: center;
              font-size: 20px;
            "
            >Œil Droit</span
          >
        </div>
<div class="card-body card-body-tableau">
 <table class="table table-bordered table-responsive">
          <thead class="table-bordered">
            <tr style="background-color: #e6f7e6;">
              <th scope="apres"></th>
              <th>mm</th>
              <th>D</th>
              <th>Axe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="colum"
                style="
                  background-color: #e6f7e6;
                  font-family: 'Gotham Rounded Bold';
                "
              >
                R1
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="0.25"
                  min="-20"
                  max="20"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_R1_Mm"
                />
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="0.25"
                  min="-6"
                  max="6"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_R1_D"
                />
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="5"
                  min="0"
                  max="180"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_R1_Ax"
                />
              </td>
            </tr>
            <tr>
              <td
                scope="row"
                style="
                  background-color: #e6f7e6;
                  font-family: 'Gotham Rounded Bold';
                "
              >
                R2
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="0.25"
                  min="-20"
                  max="20"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_R2_Mm"
                />
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="0.25"
                  min="-6"
                  max="6"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_R2_D"
                />
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="5"
                  min="0"
                  max="180"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_R2_Ax"
                />
              </td>
            </tr>
            <tr>
              <td
                scope="row"
                style="
                  background-color: #e6f7e6;
                  font-family: 'Gotham Rounded Bold';
                "
              >
                Avg
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="0.25"
                  min="-20"
                  max="20"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_AVG_Mm"
                />
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="0.25"
                  min="-6"
                  max="6"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_AVG_D"
                />
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="5"
                  min="0"
                  max="180"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_AVG_Ax"
                />
              </td>
            </tr>
            <tr>
              <td
                scope="row"
                style="
                  background-color: #e6f7e6;
                  font-family: 'Gotham Rounded Bold';
                "
              >
                CYl
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="0.25"
                  min="-20"
                  max="20"
                  class="inputtableau"
                />
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="0.25"
                  min="-6"
                  max="6"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_CYL_D"
                />
              </td>
              <td style="background-color: #efefef;">
                <input
                  type="number"
                  step="5"
                  min="0"
                  max="180"
                  class="inputtableau"
                  [(ngModel)]="consultation.od_kerato_CYL_Ax"
                />
              </td>
            </tr>
          </tbody>
        </table>
</div>
       
      </div>
      <div
        class="card col-lg-6"
        style="
          border: 1px solid transparent;
          border-radius: 8px;
          text-align: center;
        "
      >
        <div class="card-header ŒilGauche">
          <span
            style="
              font-family: 'Gotham Rounded Bold';
              text-align: center;
              font-size: 20px;
            "
            >Œil Gauche
          </span>
        </div>
        <div class="card-body card-body-tableau">
          <table class="table table-bordered table-responsive">
            <thead class="table-bordered">
              <tr style="background-color: #fff4cc;">
                <th scope="apres"></th>
                <th>mm</th>
                <th>D</th>
                <th>Axe</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  scope="colum"
                  style="
                    background-color: #fff4cc;
                    font-family: 'Gotham Rounded Bold';
                  "
                >
                  R1
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="0.25"
                    min="-20"
                    max="20"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_R1_Mm"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="0.25"
                    min="-6"
                    max="6"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_R1_D"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="5"
                    min="0"
                    max="180"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_R1_Ax"
                  />
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  style="
                    background-color: #fff4cc;
                    font-family: 'Gotham Rounded Bold';
                  "
                >
                  R2
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="0.25"
                    min="-20"
                    max="20"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_R2_Mm"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="0.25"
                    min="-6"
                    max="6"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_R2_D"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="5"
                    min="0"
                    max="180"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_R2_Ax"
                  />
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  style="
                    background-color: #fff4cc;
                    font-family: 'Gotham Rounded Bold';
                  "
                >
                  Avg
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="0.25"
                    min="-20"
                    max="20"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_AVG_Mm"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="0.25"
                    min="-6"
                    max="6"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_AVG_D"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="5"
                    min="0"
                    max="180"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_AVG_Ax"
                  />
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  style="
                    background-color: #fff4cc;
                    font-family: 'Gotham Rounded Bold';
                  "
                >
                  CYl
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="0.25"
                    min="-20"
                    max="20"
                    class="inputtableau"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="0.25"
                    min="-6"
                    max="6"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_CYL_D"
                  />
                </td>
                <td style="background-color: #efefef;">
                  <input
                    type="number"
                    step="5"
                    min="0"
                    max="180"
                    class="inputtableau"
                    [(ngModel)]="consultation.og_kerato_CYL_Ax"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </app-accordion-tab>

  <app-accordion-tab>
    <h4 header style="font-family: 'Gotham Rounded Bold'; color: black;">
      Prescription Correction
    </h4>
    <div body class="row">
      <div class="row" style="background-color: #ffffff;">
        <!-- <caption
            style="width: 98.2%; margin-left: 13px; font-family: 'Gotham Rounded Bold'; color: black; font-size: 25px;"
            class="text-center"
          >
            CORRECTION
          </caption> -->
        <div
          class="col-lg-6"
          style="
            border: 1px solid transparent;
            border-radius: 8px;
            text-align: center;
          "
        >
          <div class="card-header ŒilDroit">
            <span
              style="
                font-family: 'Gotham Rounded Bold';
                text-align: center;
                font-size: 20px;
              "
              >Œil Droit</span
            >
          </div>
          <div class="card-body card-body-tableau">
            <table class="table table-bordered table-responsive">
              <thead class="table-bordered">
                <tr style="background-color: #e6f7e6;">
                  <th scope="apres"></th>
                  <th>Sphére</th>
                  <th>Cylindre</th>
                  <th>Axe</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    scope="row"
                    style="
                      background-color: #e6f7e6;
                      font-family: 'Gotham Rounded Bold';
                    "
                  >
                    Vision de Loin
                  </td>
                  <td style="background-color: #efefef;">
                    <!-- <input
                        type="number"
                        step="0.25"
                        min="-20"
                        max="20"
                        class="inputtableau"
                        [(ngModel)]="consultation.od_vl_avac_Sphere"
                      /> -->
                    <app-input-lunette
                      [(value)]="consultation.od_vl_avac_Sphere"
                    ></app-input-lunette>
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.od_vl_avac_Cylindre"
                    ></app-input-lunette>
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.od_vl_avac_Axe"
                    ></app-input-lunette>
                  </td>
                </tr>
                <tr>
                  <td
                    scope="row"
                    style="
                      background-color: #e6f7e6;
                      font-family: 'Gotham Rounded Bold';
                    "
                  >
                    Vision de Prés
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.od_vp_avac_Sphere"
                    ></app-input-lunette>
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.od_vp_avac_Cylindre"
                    ></app-input-lunette>
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.od_vp_avac_Axe"
                    ></app-input-lunette>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="add" style="display: flex;flex-direction: row;justify-content: space-between;">
            <div class="col-lg-3 col-sm-6">
              <label class="label-add">ADD-OD:</label>
            </div>

            <div class="col-lg-9 col-sm-6">
              <input
                type="number"
                step="0.5"
                min="0.5"
                max="30"
                class="ADD-OD"
                [(ngModel)]="consultation.od_vp_avac_Addition"
              />
            </div>
          </div>
        </div>
        <div
          class="card col-lg-6"
          style="
            border: 1px solid transparent;
            border-radius: 8px;
            text-align: center;
          "
        >
          <div class="card-header ŒilGauche">
            <span
              style="
                font-family: 'Gotham Rounded Bold';
                text-align: center;
                font-size: 20px;
              "
              >Œil Gauche
            </span>
          </div>
          <div class="card-body card-body-tableau">
            <table class="table table-bordered table-responsive">
              <thead class="table-bordered">
                <tr style="background-color: #fff4cc;">
                  <th scope="apres"></th>
                  <th>Sphére</th>
                  <th>Cylindre</th>
                  <th>Axe</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    scope="row"
                    style="
                      background-color: #fff4cc;
                      font-family: 'Gotham Rounded Bold';
                    "
                  >
                    Vision de Loin
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.og_vl_avac_Sphere"
                    ></app-input-lunette>
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.og_vl_avac_Cylindre"
                    ></app-input-lunette>
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.og_vl_avac_Axe"
                    ></app-input-lunette>
                  </td>
                </tr>
                <tr>
                  <td
                    scope="row"
                    style="
                      background-color: #fff4cc;
                      font-family: 'Gotham Rounded Bold';
                    "
                  >
                    Vision de Prés
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.og_vp_avac_Sphere"
                    ></app-input-lunette>
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.og_vp_avac_Cylindre"
                    ></app-input-lunette>
                  </td>
                  <td style="background-color: #efefef;">
                    <app-input-lunette
                      [(value)]="consultation.og_vp_avac_Axe"
                    ></app-input-lunette>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="add" style="display: flex;flex-direction: row;justify-content: space-between;">
            <div class="col-lg-3 col-sm-6">
              <label class="label-add">ADD-OG:</label>
            </div>

            <div class="col-lg-9 col-sm-6">
              <input
                type="number"
                step="0.5"
                min="0.5"
                max="30"
                class="ADD-OD"

                [(ngModel)]="consultation.og_vp_avac_Addition"
              />
            </div>
          </div>
          <div class="row add">
            <div class="col-lg-2">
              <label class="remarque-lunette" for="remarque-lunette"
                >Note Lunette</label
              >
            </div>
            <div class="col-lg-10">
              <textarea
                name="remarque-lunette"
                class="form-control"
                [(ngModel)]="consultation.lunette_remarque"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-accordion-tab>
</div>
