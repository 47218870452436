<tr>
  <td>
    <app-act-dropdown-select
      [(value)]="actConsultationItem.act"
      [ngStyle]="(validation['act'] == 'required')?{'margin-bottom':'22px'}:{'margin-bottom':'0px'}"
      (valueChange)="onChange()"
      [placeHolder]="'act is here'"
    >
    </app-act-dropdown-select>
    <div *ngIf="validation['act'] == 'required' && submitted">
      <span style="color: red;"> act est obligatoire </span>
    </div>
  </td>
  <td>
    <!-- <datepicker [(date)]="radiologyConsultationItem.date_consultation_radio"></datepicker> -->
    <input
      type="date"
      placeholder="Select Date"
      class="form-control datetimepicker"
      [ngStyle]="(validation['date']&& submitted==true)?{'margin-bottom':'22px'}:{'margin-bottom':'0px'}"
      [value]="actConsultationItem.date_consultation_acte.getFullYear() +'-' +(actConsultationItem.date_consultation_acte.getMonth() +1 <=9? '0' +(actConsultationItem.date_consultation_acte.getMonth() +1): actConsultationItem.date_consultation_acte.getMonth() +1) +'-' +(actConsultationItem.date_consultation_acte.getDate() <=9? '0' +actConsultationItem.date_consultation_acte.getDate(): actConsultationItem.date_consultation_acte.getDate())"
      style="cursor: pointer;"
    />
  </td>
  <td>
    <input
      type="number"
      class="form-control"
      style="height: 45px;"
      (input)="onChange()"
      [(ngModel)]="actConsultationItem.montant_consultation_acte
  "
      class="form-control"
    />
    <div *ngIf="validation['montant'] && submitted">
      <span *ngIf="validation['montant'] == 'required'" style="color: red;">
        Montant est obligatoire
      </span>
    </div>
  </td>
  <td>
    <textarea
      style="height: 45px;"
      [(ngModel)]="
  actConsultationItem.note_consultation_acte
  "
      class="form-control"
      [ngStyle]="(validation['note'] && submitted==true)?{'margin-bottom':'22px'}:{'margin-bottom':'0px'}"
    ></textarea>
  </td>

  <td>
    <a class="btn bg-danger-light trash" (click)="onDelete.emit()">
      <i class="far fa-trash-alt"></i>
    </a>
  </td>
</tr>
