<div class="row row-grid">
    <div *ngFor="let doctorPatient of doctorPatients | paginate: { itemsPerPage: perPage, currentPage: currentPage }; let i = index" class="col-md-6 col-lg-4 col-xl-3">
        <div class="card widget-profile pat-widget-profile">
            <div class="card-body">
                <div class="pro-widget-content">
                    <div class="profile-info-widget">
                        <a [routerLink]="['../','patients',doctorPatient.id]" class="booking-doc-img">
                            <img [src]="doctorPatient | avatarSrc" [alt]="doctorPatient.getFullName()" image />
                        </a>
                        <div class="profile-det-info">
                            <a [routerLink]="['../','patients',doctorPatient.id]" style="font-size: 18px;font-weight: bold;font-family: 'Gotham Rounded Bold';">{{
                                doctorPatient.getFullName()}}</a>

                            <div class="patient-details">
                                <h5>
                                    <b>{{ doctorPatient.birthDate | date: "dd/MM/yyyy"}}</b>
                                </h5>
                                <h5>{{ doctorPatient.email }}</h5>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="patient-info">
                    <ul>
                        <li>
                            Der.RDV
                            <span>{{ doctorPatient.last_appointment?(doctorPatient.last_appointment|
                                date:"dd/MM/yyyy"):'---' }}({{doctorPatient.appointments_amount }})</span>
                        </li>
                        <li>
                            Der.Consultation
                            <span>
                                {{ doctorPatient.last_consultation?(doctorPatient.last_consultation | date:
                                "dd/MM/yyyy"):'---' }} ({{
                                doctorPatient.consultations_amount }})</span>
                        </li>
                        <li>
                            Der.Prescription
                            <span>{{ doctorPatient.last_prescription?(doctorPatient.last_prescription | date:
                                "dd/MM/yyyy" ):'---'}} ({{
                                doctorPatient.prescriptions_amount }})</span>
                        </li>
                    </ul>
                </div>
                <div class="table-action text-center">
                    <a *ngIf="actions.edit" title="Modifer Profil" [routerLink]="['../','patients',doctorPatient.id, 'edit']" class="btn btn-sm bg-info-light">
                        <i class="feather-edit"> </i>
                    </a>
                    <a *ngIf="actions.antecentendsView" title="Create Antecedent" [routerLink]="['../','patients',doctorPatient.id, 'antecedent']"
                        routerLinkActive="router-link-active" class="btn btn-sm bg-danger-light">
                        <i class="feather-book-open"></i>
                    </a>

                    <a *ngIf="actions.assignAppointment" title="Create appointment" [routerLink]="['../','patients',doctorPatient.id,'assign-appointment']"
                        routerLinkActive="router-link-active" class="btn btn-sm bg-success-light">
                        <i class="feather-plus"></i>
                    </a>
                    <a *ngIf="actions.delete" title="Supprimer Patient" (click)="onDelete(i)" routerLinkActive="router-link-active" class="btn btn-sm bg-danger-light">
                        <i class="feather-trash"></i>
                    </a>
                    <a *ngIf="actions.prescriptionsView" title="Patient prescriptions" class="btn btn-sm bg-warning-light"
                        [routerLink]="['../','patients',doctorPatient.id, 'prescriptions']"><i class="feather-clipboard"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<pagination-controls *ngIf="doctorPatients.length>=perPage" (pageChange)="currentPage = $event"></pagination-controls>