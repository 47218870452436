<div class="row" style="background-color: transparent;">
    <div class="col-lg-11">
        <select-drop-down [items]="actsList" [(ngModel)]="value" [options]="{ filter:{ byKey:'desc_Acte',with:'containe' } }" name="acte">
            <ng-template let-item="item">{{item.desc_Acte}}</ng-template>
        </select-drop-down>
    </div>
    <div class="col-lg-1">
        <button class="openDialoge" title="Ajouter Acte" (click)="dialog.open()">
            <i class="feather-plus-circle"></i>
        </button>
    </div>
</div>
<app-modal [title]="'Ajouter Nouvel Acte'" #dialog>
    <app-act-form #doctorActForm></app-act-form>
    <button class="btn btn-outline-info mb-3 mt-3" (click)="onAddAct()">Ajouter Acte</button>
</app-modal>