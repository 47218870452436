import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageRequest, PaginationPage } from 'src/app/common/modules/data-table.module/interfaces';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { DocumentAttachment } from 'src/app/entities/document-attachment.entity/document-attachment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DoctorDocumentsService extends ApiService {

  all(pageRequest?: Partial<PageRequest>): Observable<PaginationPage<DocumentAttachment>> {
    let params = new HttpParams();

    if (pageRequest && pageRequest.pageOffset) params = params.append('page', pageRequest.pageOffset);
    if (pageRequest && pageRequest.pageSize) params = params.append('size', pageRequest.pageSize);

    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/documents`, { params: params }).pipe(this.responsePipe)
  }

  search(pageRequest?: Partial<PageRequest>, searchQuery?: { query?: string, date?: Date, subject?: number }): Observable<PaginationPage<DocumentAttachment>> {
    let params = new HttpParams();

    if (pageRequest && pageRequest.pageOffset) params = params.append('page', pageRequest.pageOffset);
    if (pageRequest && pageRequest.pageSize) params = params.append('size', pageRequest.pageSize);

    if (searchQuery && searchQuery.query && searchQuery.query.length > 0) params = params.append('q', searchQuery.query);
    if (searchQuery && searchQuery.date) params = params.append('date', searchQuery.date.getTime());
    if (searchQuery && searchQuery.subject != undefined) params = params.append('subject', searchQuery.subject);

    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/documents/search`, { params: params }).pipe(this.responsePipe)
  }


  add(formData: FormData): Observable<any> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/documents`, formData).pipe(this.responsePipe)
  }

  update(documentId: string, formData: FormData): Observable<DocumentAttachment> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/documents/${documentId}`, formData).pipe(this.responsePipe)
  }

  delete(documentId: string): Observable<DocumentAttachment> {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/documents/${documentId}`).pipe(this.responsePipe)
  }

  share(documentId: String, patientId: string) {
    return this.http.patch<ApiResponse>(`${environment.API_URL}/doctor/documents/${documentId}/share?target=${patientId}`, { responseType: 'json' }).pipe(this.responsePipe)
  }
  addDocumentsPatient(formData: FormData, id: string): Observable<any> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/patients/${id}/attachments`, formData).pipe(this.responsePipe)
  }
}

