import { Component, Input, OnInit } from '@angular/core';
import { CabinetOrganisation } from 'src/app/entities/cabinet-organisation.entity/cabinet-organisation';
import { Language } from 'src/app/services/localization.service/localization.service';

@Component({
  selector: 'app-doctors-list',
  templateUrl: './doctors-list.component.html',
  styleUrls: ['./doctors-list.component.css']
})
export class DoctorsListComponent implements OnInit {
  @Input() doctors: Array<CabinetOrganisation> = new Array();
  @Input() pageRequest: any
  @Input() currentPage: number = 0;
  @Input() perPage: number = 10;
  @Input() language: Language = 'fr';
  constructor() { }

  ngOnInit(): void {

  }

  getProfileLink(cabientOrganization: CabinetOrganisation): string {
    return `https://altabib.tn/${this.language == "fr" ? '' : 'ar/'}organizations/cabinets/${cabientOrganization.speciality.ref}/${cabientOrganization.government.replace('/ /g', '-').toLowerCase().trim()}/${cabientOrganization.city.replace('/ /g', '-').toLowerCase().trim()}/${cabientOrganization.name.toLowerCase()}`;
  }
}
