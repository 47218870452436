<div>
    <input type="text" class="form-control my-2" [(ngModel)]="biology"  placeholder="Recherche Biologie">
    <nav class="tree-nav scroll" >

        <div class="flex" *ngFor="let bio of values | filterBiologie:biology;let i= index" >
            <span class="label">{{bio.element_Bio}}</span>
            <input type="checkbox" name="" id=""  class="checkbox" (change)="changed(bio,$event,i)"   >
        </div>
        </nav>
        <button class="btn btn-primary ajouter" (click)="close()">Ajouter</button>
</div>
