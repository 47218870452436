<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="dir=='fr'">
    <div class="form-group">
        <label>Utilisateur</label>
        <form-input name="name" [append]="'fi feather-user'" class="input" id="userName" formControlName="userName" name="userName"></form-input>
        <div *ngIf="submited">
            <span *ngIf="f['userName'].invalid && f['userName'].errors!['required']" style="color: red;">
                Email / telephone est requis
            </span>
            <span *ngIf="f['userName'].invalid && f['userName'].errors!['notFound']" style="color: red;">
                E-mail / telephone ne correspond a aucun utilisateur.
            </span>
        </div>
    </div>
    <div class="form-group">
        <label>Mot de Passe</label>
        <div class="password">
            <form-input [type]="'password'" class="input" name="password" id="password" formControlName="userPassword" name="userPassword"></form-input>
        </div>
        <div *ngIf="submited">
            <span *ngIf="f['userPassword'].invalid && f['userPassword'].errors!['required']" style="color: red;">
                Mot de passe est requis
            </span>
            <span *ngIf="f['userPassword'].invalid && f['userPassword'].errors!['minlength']" style="color: red;">
                Mot de passe doit avoir au moins 6 caractères
            </span>
            <span *ngIf="f['userPassword'].invalid && f['userPassword'].errors!['invalidPassword']" style="color: red;">
                Mot de passe invalid
            </span>
        </div>
    </div>
    <div class="text-start">
        <a class="forgot-link" [routerLink]="['../','forgot-password']">Oublie Mot de Passe ?</a>
    </div>
</form>

<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="dir=='ar'">
    <div class="form-group">
        <label>مستخدم</label>
        <form-input name="name" [append]="'fi feather-user'" class="input" id="userName" formControlName="userName" name="userName"></form-input>
        <div *ngIf="submited">
            <span *ngIf="f['userName'].invalid && f['userName'].errors!['required']" style="color: red;">
                مطلوب البريد الإلكتروني / الهاتف            </span>
            <span *ngIf="f['userName'].invalid && f['userName'].errors!['notFound']" style="color: red;">
                البريد الإلكتروني/الهاتف لا يتطابق مع أي مستخدم.
            </span>
        </div>
    </div>
    <div class="form-group">
        <label>كلمة السر</label>
        <div class="password">
            <form-input [type]="'password'" class="input" name="password" id="password" formControlName="userPassword" name="userPassword"></form-input>
        </div>
        <div *ngIf="submited">
            <span *ngIf="f['userPassword'].invalid && f['userPassword'].errors!['required']" style="color: red;">
                كلمة المرور مطلوبة            </span>
            <span *ngIf="f['userPassword'].invalid && f['userPassword'].errors!['minlength']" style="color: red;">
                الرقم السري يجب الا يقل عن 6 احرف على الاقل            </span>
            <span *ngIf="f['userPassword'].invalid && f['userPassword'].errors!['invalidPassword']" style="color: red;">
                رمز مرور خاطئ            </span>
        </div>
    </div>
    <div class="text-start">
        <a class="forgot-link" [routerLink]="['../','forgot-password']">كلمة سر منسية؟</a>
    </div>
</form>