<div *ngIf="doctor" [dir]="language == 'ar'?'rtl':'ltr'" class="col-lg-8 row" style="background-color: #fff;padding:6px;border-radius: 6px;
display:flex;
 align-content: center;
 align-items:center;
 justify-content:center;
 justify-items:center;
 flex-direction: column;
 margin-top: 12px;">
    <app-stepper-progress-bar *ngIf="doctor" [steps]="steps" [activeIndex]="activeIndex" [dir]="language" style="width: 100%;"></app-stepper-progress-bar>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-4" *ngIf="language=='fr'">
                <div class="card" style="background-color: #e0f5f3;">
                    <div class="col-md-12 col-sm-6 col-12 mb-sm-3 mb-3">
                        <div class="d-flex flex-column h-100">
                            <div class="doctor-info" style="background-color: #ffff;margin: 8px;border-radius: 8px;">
                                <div class="doctor-info-header">
                                    <img [src]="doctor | avatarSrc">
                                    <div class="doctor-name-group">
                                        <span class="name"
                                            style="font-family: 'Gotham Rounded Light';color:#008d93;font-size: 22px;font-weight: 600;">{{doctor.getFullName()}}</span>
                                        <span class="specialtiy" style="font-family: 'Gotham Rounded Bold';font-size: 16px;">{{doctor.speciality.label}}</span>
                                    </div>
                                </div>
                                <div class="doctor-info-body">
                                    <div>
                                        <strong>Adresse :</strong>
                                        <span>
                                            {{doctor.address}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="activeIndex >= 1">
                                <div class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    {{patientAppointment.location == 0? 'En Cabinet':'A distance'}}
                                </div>
                                <div class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    {{patientAppointment.motif}}
                                </div>
                                <div class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    Date rendez-vous : {{patientAppointment.date | date:'dd/MM/yyyy'}}
                                </div>
                                <div *ngIf="doctor.appointmentTiming != 1" class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    Heure du rendez-vous : {{patientAppointment.startTime | time}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4" *ngIf="language=='ar'">
                <div class="card" style="background-color: #e0f5f3;">
                    <div class="col-md-12 col-sm-6 col-12 mb-sm-3 mb-3">
                        <div class="d-flex flex-column h-100">
                            <div class="doctor-info" style="background-color: #ffff;margin: 8px;border-radius: 8px;">
                                <div class="doctor-info-header">
                                    <img [src]="doctor | avatarSrc">
                                    <div class="doctor-name-group">
                                        <span class="name"
                                            style="font-family: 'Gotham Rounded Light';color:#008d93;font-size: 22px;font-weight: 600;">{{doctor.arabicProfessionalCivility}}
                                            {{doctor.arabicLastName}} {{doctor.arabicFirstName}}</span>
                                        <span class="specialtiy" style="font-family: 'Gotham Rounded Bold';font-size: 16px;">{{doctor.speciality?.arabicName}}</span>
                                    </div>
                                </div>
                                <div class="doctor-info-body">
                                    <div>
                                        <strong>عنوان :</strong>
                                        <span>
                                            {{doctor.arabicAddress}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="activeIndex >= 1 && language !=='ar' ">
                                <div class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    {{patientAppointment.location == 0? 'En Cabinet':'A distance'}}
                                </div>
                                <div class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    {{patientAppointment.motif}}
                                </div>
                                <div class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    Date rendez-vous : {{patientAppointment.date | date:'dd/MM/yyyy'}}
                                </div>
                                <div *ngIf="doctor.appointmentTiming != 1" class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    Heure du rendez-vous : {{patientAppointment.startTime | time}}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="activeIndex >= 1 && language =='ar'">
                                <div class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    {{patientAppointment.location == 0? 'في المكتب':'عن بعد'}}
                                </div>
                                <div class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    {{patientAppointment.motif}}
                                </div>
                                <div class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    تاريخ الموعد : {{patientAppointment.date | date:'dd/MM/yyyy'}}
                                </div>
                                <div *ngIf="doctor.appointmentTiming != 1" class="appointment-info" style="margin: 8px;"><i class="feather-check"></i>
                                    وقت الموعد : {{patientAppointment.startTime | time}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-8">
                <div class="card" style="padding: 12px;">
                    <app-appointment-booking-calender *ngIf="activeIndex == 0" [doctor]="doctor" [patientAppointment]="patientAppointment"
                        [dir]="language"></app-appointment-booking-calender>
                    <app-appointment-booking-verification *ngIf="activeIndex == 1" [doctor]="doctor" [dir]="language"
                        [patientAppointment]="patientAppointment"></app-appointment-booking-verification>
                    <app-appointment-patient-verification *ngIf="activeIndex == 2" [dir]="language"
                        [patientAppointment]="patientAppointment"></app-appointment-patient-verification>
                    <app-appointment-booking-payment *ngIf="activeIndex == 3" [dir]="language" [patientAppointment]="patientAppointment"
                        (complete)="activeIndex = 4"></app-appointment-booking-payment>
                    <app-appointment-success-booking *ngIf="activeIndex == 4" [doctor]="doctor" [dir]="language"
                        [patientAppointment]="patientAppointment"></app-appointment-success-booking>

                    <div class="step-navigation-container" *ngIf="language=='fr'">

                        <a *ngIf="activeIndex == 0" href="javascript:void(0)" class="btn button-pra small btn-navbar"
                            [ngClass]="{'disabled':!appointmentBookingCalenderComponent?.valid}" (click)="onAppointmentVerificationRequested()">Enregistrer</a>
                        <a *ngIf="activeIndex == 1" href="javascript:void(0)" class="btn button-pra small btn-navbar"
                            [ngClass]="{'disabled':!appointmentBookingVerificationComponent?.valid}" (click)="onAppointmentConfirmationRequested()">Confirmer</a>
                        <a *ngIf="activeIndex == 2" href="javascript:void(0)" class="btn button-pra small btn-navbar"
                            [ngClass]="{'disabled':!appointmentPatientVerificationComponent?.valid}" (click)="onAppointmentConfirmed()">Confirmer</a>

                        <a *ngIf="activeIndex == 4 && !!patient" [routerLink]="['/patient','appointments']" class="btn button-pra small btn-navbar">Retourner à mes
                            rendez-vous</a>
                        <div style="flex:1"></div>
                        <a href="javascript:void(0)" *ngIf="activeIndex == 1" class="btn button-pra small btn-navbar" (click)="activeIndex=0">Retourner vers Rendez-vous</a>
                    </div>
                    <div class="step-navigation-container" *ngIf="language=='ar'">

                        <a *ngIf="activeIndex == 0" href="javascript:void(0)" class="btn button-pra small btn-navbar"
                            [ngClass]="{'disabled':!appointmentBookingCalenderComponent?.valid}" (click)="onAppointmentVerificationRequested()">تأكيد</a>
                        <a *ngIf="activeIndex == 1" href="javascript:void(0)" class="btn button-pra small btn-navbar"
                            [ngClass]="{'disabled':!appointmentBookingVerificationComponent?.valid}" (click)="onAppointmentConfirmationRequested()">تأكيد</a>
                        <a *ngIf="activeIndex == 2" href="javascript:void(0)" class="btn button-pra small btn-navbar"
                            [ngClass]="{'disabled':!appointmentPatientVerificationComponent?.valid}" (click)="onAppointmentConfirmed()">تأكيد</a>

                        <a *ngIf="activeIndex == 4 && !!patient" [routerLink]="['/patient','appointments']" class="btn button-pra small btn-navbar">العودة إلى مواعيد</a>
                        <div style="flex:1"></div>
                        <a href="javascript:void(0)" *ngIf="activeIndex == 1" class="btn button-pra small btn-navbar" (click)="activeIndex=0">العودة إلى الموعد</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>