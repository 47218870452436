import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextFillings } from 'src/app/entities/text-fillings.entity/text-fillings';

@Component({
  selector: 'app-text-fillings-form',
  templateUrl: './text-fillings-form.component.html',
  styleUrls: ['./text-fillings-form.component.css']
})
export class TextFillingsFormComponent implements OnInit {
  @Input() submitName: string = 'Submit';
  @Input() textFilligns:TextFillings=new TextFillings({})
  @Output() submit: EventEmitter<TextFillings> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  _submit(){

    this.submit.emit(this.textFilligns)
  }
}
