import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ConsultationParametreSanteItem, DoctorParametereSante } from "src/app/entities/consultation.entity";
import { ParametereSante } from "src/app/entities/parametre-sante.entity/paramtere-sante";
import { Doctor } from "src/app/entities/user.entity/doctor.entity";
import { AuthService } from "src/app/services/auth.service/auth.service";
import { DoctorParametersSanteService } from "src/app/services/doctor-parametres-sante.service/doctor-parameters-sante.service";

export interface IParametreSanteFormControler {
  id: string;
  target: ParametereSante;
  value: any;
  note: string;
}

export class ParametreSanteFormControler
  implements IParametreSanteFormControler {
  id: string;
  target: ParametereSante;
  value: any;
  note: string;
  constructor(other?: Partial<IParametreSanteFormControler>) {
    this.id = other?.id || "";
    this.target = other?.target || new ParametereSante({});
    this.value = [
      null,
      Number(isNaN(other?.value) ? 0 : other?.value),
      String(other?.value || ""),
      Boolean(other?.value || false),
    ][this.target.type];
    this.note = other?.note || '';
  }
}


export interface IParametreSanteFormGroupControler {
  label: string;
  controlers: Array<ParametreSanteFormControler>;
}
export class ParametreSanteFormGroupControler
  implements IParametreSanteFormGroupControler {
  label: string;
  controlers: Array<ParametreSanteFormControler>;
  constructor(other?: Partial<IParametreSanteFormGroupControler>) {
    this.label = other?.label || "";
    this.controlers = other?.controlers || [];
  }
}

export interface IParametreSanteForm {
  controlers: Array<ParametreSanteFormGroupControler>;
}
export class ParametreSanteForm implements IParametreSanteForm {
 
  controlers: Array<ParametreSanteFormGroupControler>;

  constructor(other?: Partial<IParametreSanteForm>) {
    this.controlers = other?.controlers || new Array();
  }

  get value(): Array<ConsultationParametreSanteItem> {
    let value: Array<ConsultationParametreSanteItem> = new Array();
    this.controlers.forEach(
      (group) =>
      (value = value.concat(
        Array.from(
          group.controlers,
          (controler) =>
            new ConsultationParametreSanteItem({
              id: controler.id,
              parametre: controler.target,
              value: controler.value,
              note: controler.note
            })
        )
      ))
    );
    return value;
  }

  set value(values: Array<ConsultationParametreSanteItem>) {
    this.controlers = this.controlers.map((group) => {
      group.controlers = group.controlers.map((controler) => {
        let controlerValue = values.find((value) => {
          return value.parametre.id == controler.target.id
        });
        if (controlerValue) {
          controler.id = controlerValue.id;
          controler.value = controlerValue.value;
          controler.note = controlerValue.note
        }
        return controler;
      });
      return group;
    });
  }

  public static fromListParametreSante(
    doctorParametresSante: Array<DoctorParametereSante>,
    values?: Array<ConsultationParametreSanteItem>
  ): ParametreSanteForm {
    let parametreSanteForm: ParametreSanteForm = new ParametreSanteForm();
    if (values) {
      values.forEach(value => {
        if (!doctorParametresSante.find(doctorParametreSante => value.parametre.id == doctorParametreSante.target.id)) {
          doctorParametresSante.push(new DoctorParametereSante({ order: -1, target: value.parametre }));
        }
      })
    }
    let groupNames: Array<string> = doctorParametresSante
      .map((item: DoctorParametereSante) => item.target.group)
      .filter((item, index, self) => self.indexOf(item) === index);

    groupNames.forEach((groupName) => {
      let items: Array<ParametreSanteFormControler> = new Array();

      doctorParametresSante.forEach((doctorParametreSante) => {
        if (groupName.toUpperCase() == doctorParametreSante.target.group.toUpperCase())
          items.push(new ParametreSanteFormControler({ target: doctorParametreSante.target }));
      });

      parametreSanteForm.controlers.push(new ParametreSanteFormGroupControler({ label: groupName, controlers: items, }));
    });
    return parametreSanteForm;
  }
}
@Component({
  selector: "app-parametre-sante-consultation-form",
  templateUrl: "./parametre-sante-consultation-forms.component.html",
  styleUrls: ["./parametre-sante-consultation-forms.component.css"],
})
export class ParametreSanteConsultationFormsComponent implements OnInit, OnChanges {
result:String="";
  @Input() parameteresSante: Array<ConsultationParametreSanteItem> = new Array();
  @Input() consultationParametreSanteItems!: Array<ConsultationParametreSanteItem>;
  @Output() consultationParametreSanteItemsChange: EventEmitter<Array<ConsultationParametreSanteItem>> = new EventEmitter();
  @Input() imc:number=0
  parametreSanteForm: ParametreSanteForm = new ParametreSanteForm();
  colora = 'red';
  colorb = 'jaune';
  colorc = 'vert';

  get doctor(): Doctor { return this.authService.getCurrentUser() as Doctor; }

  constructor(private doctorParametersSanteService: DoctorParametersSanteService, private authService: AuthService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.consultationParametreSanteItems.length)
      this.parametreSanteForm.value = this.consultationParametreSanteItems;
    
  }

  ngOnInit(): void {
    this.doctorParametersSanteService
      .getActivatedParametresSante()
      .subscribe(
        (res) => {
          this.parametreSanteForm = ParametreSanteForm.fromListParametreSante(
            Array.from(res, elm => DoctorParametereSante.fromResponse(elm as any)).sort((a, b) => a.order - b.order), this.consultationParametreSanteItems.length ? this.consultationParametreSanteItems : undefined
          );
          if (this.consultationParametreSanteItems.length)
            this.parametreSanteForm.value = this.consultationParametreSanteItems;
        },
        (error) => alert("Enable to load parametres de sante !")
      );
     
  }

  _onChange(): void {
    this.consultationParametreSanteItems = [];
   

    this.parametreSanteForm.controlers.forEach(

      (group) =>
      (this.consultationParametreSanteItems = this.consultationParametreSanteItems.concat(
        Array.from(
          group.controlers,
          (controler) => {
            return new ConsultationParametreSanteItem({
              value: controler?.value,
              parametre: controler.target,
              id: controler.id,
              note: controler.note
            })
          }
        )
      ))
    );

    this.consultationParametreSanteItemsChange.emit(
      this.consultationParametreSanteItems
    );
  }
  


}

