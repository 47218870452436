import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { PatientPrescription } from 'src/app/entities/prescription.entity/patient-prescription.entity/patient-prescription';
import { Prescription } from 'src/app/entities/prescription.entity';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../core/entities/api-response.entity/api-response';
import { ApiService } from '../../core/services/api.service/api.service';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';
import { HttpParams } from '@angular/common/http';
import { ModelPresciption } from 'src/app/entities/prescription.entity/model-presciption.entity/model-presciption';
import { DoctorPrescription } from 'src/app/entities/prescription.entity/doctor-prescription.entity/doctor-prescription';

@Injectable({
  providedIn: 'root'
})
export class DoctorPrescriptionService extends ApiService {

  all(pageRequest?: PageRequest): Observable<any> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/prescriptions`, { params: params }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/prescriptions/all`).pipe(this.responsePipe)
  }

  search(searchQuery: { birthDay?: Date | undefined; query: string; }, pageRequest?: PageRequest) {
    let params = new HttpParams();
    if (searchQuery.birthDay) params = params.append('birthdate', searchQuery.birthDay.getTime());
    if (searchQuery.query.length != 0) params = params.set('query', searchQuery.query);
    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/prescriptions/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }

  one(prescriptionId: string): Observable<Prescription> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/prescriptions/${prescriptionId}`).pipe(this.responsePipe);
  }

  add(appointmentId: string, prescription: Prescription, modelName?: string): Observable<Prescription> {
    let params = new HttpParams();
    if (modelName && modelName.length) params = params.append('modelName', modelName)
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/appointments/${appointmentId}/prescriptions?modelName=${modelName}`, prescription.requestPayload, { responseType: 'json', params: params }).pipe(this.responsePipe);
  }

  allByDoctorPatient(idpatient: string): Observable<Array<Prescription>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/${idpatient}/prescriptions`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  deleteOne(prescriptionId: string): Observable<Prescription> {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/prescriptions/${prescriptionId}`).pipe(this.responsePipe)
  }
  getModel(): Observable<Array<ModelPresciption>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/prescriptions/models`, { responseType: 'json' }).pipe(this.responsePipe)

  }
  getModelById(id: string): Observable<DoctorPrescription> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/prescriptions/models/${id}`, { responseType: 'json' }).pipe(this.responsePipe)

  }
  updateOne(prescriptionId: string, updates: Prescription): Observable<Prescription> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/prescriptions/${prescriptionId}`, updates.requestPayload, { responseType: 'json' }).pipe(this.responsePipe);
  }
  getAllPresciption(): Observable<Array<Prescription>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/prescriptions`, { responseType: 'json' }).pipe(this.responsePipe)
  }

}
