import { Pipe, PipeTransform } from '@angular/core';
import { DocumentAttachment } from 'src/app/entities/document-attachment.entity/document-attachment';

@Pipe({
  name: 'fileCover'
})
export class FileCoverPipe implements PipeTransform {

  transform(attachment: DocumentAttachment, relativeAssetsPath: string): unknown {
    return relativeAssetsPath + ['unknown.png', 'image.png', 'video.png', 'text.png', 'word.png', 'pdf.png', 'audio.png'][attachment.contentType]
  }

}
