<div class="patient-history">
  <h1 style="text-align: center;">Patient : Historique</h1>
  <button class="btn btn-primary" (click)="print()" id="print-button">
    Imprimer
  </button>
 <div class="flex" *ngIf="pdfViewSettings.antecedant">
    <h4 class="title">Patient Antécédent</h4>
    <input type="checkbox" [(ngModel)]="pdfViewSettings.antecedant" />
  </div>

  <div class="h-list">
    <ng-container
      *ngIf="doctorPatientAntecedents && antcedents.length;else empty"
    >
      <div *ngFor="let patientAntecedent of antcedents" class="antecedent-card">
        <h4 class="label">{{patientAntecedent.label}}</h4>
        <textarea
          class="textarea"
          [value]="patientAntecedent.text"
          disabled
        ></textarea>
      </div>
    </ng-container>
    <ng-template #empty>
      <span>Aucun antecedant</span>
    </ng-template>
  </div>
  <div
    *ngFor="let patientHistoric of patientHistorics,let i=index"
    class="historique"
  >
    <div class="content" >
      <div class="flex">
        <h4 class="title">
          Date rdv : {{ patientHistoric.date | date:'dd/MM/yyyy' }}
        </h4>
        <input type="checkbox" [(ngModel)]="pdfViewSettings.Rdv[i]" />
      </div>

      <div class="flex" *ngIf="patientHistoric.motif">
        <p >
          <strong>Motif:</strong> {{ patientHistoric.motif }}
        </p>
        <input type="checkbox" [(ngModel)]="pdfViewSettings.motif[i]" />
      </div>

      <div class="flex" *ngIf="patientHistoric.examenClinique">
        <p >
          <strong>Examen Clinique:</strong>{{patientHistoric.examenClinique}}
        </p>
        <input
          type="checkbox"
          [(ngModel)]="pdfViewSettings.examenClinique[i]"
        />
      </div>

      <div class="flex" *ngIf="patientHistoric.examenDemande">
        <p >
          <strong>Examen Demande:</strong>{{patientHistoric.examenDemande}}
        </p>
        <input type="checkbox" [(ngModel)]="pdfViewSettings.examenDemande[i]" />
      </div>

      <div class="flex" *ngIf="patientHistoric.diagnostic">
        <p *ngIf="patientHistoric.diagnostic">
          <strong>Diagnostic:</strong> {{ patientHistoric.diagnostic }}
        </p>
        <input type="checkbox" [(ngModel)]="pdfViewSettings.diagnostic[i]" />
      </div>

      <!-- Consultation card view -->
      <div *ngIf="patientHistoric.hasConsultation">
        <h2>Consultation</h2>
        <ul>
          <!-- Radiologies -->
          <li *ngIf="patientHistoric.consultationRadiologies.length">
            <div class="flex">
              <strong>Radiologies:</strong
              ><input
                type="checkbox"
                [(ngModel)]="pdfViewSettings.radioConsultation[i]"
              />
            </div>
            <ul>
              <li
                *ngFor="let consultationRadiologie of patientHistoric.consultationRadiologies"
              >
                {{ consultationRadiologie.radio.nom_Radio }} - {{
                consultationRadiologie.note_consultation_radio }}
              </li>
            </ul>
          </li>

          <!-- Biologies -->
          <li *ngIf="patientHistoric.consultationBiologies.length ">
            <div class="flex">
              <strong>Biologies:</strong
              ><input
                type="checkbox"
                [(ngModel)]="pdfViewSettings.biologieConsultation[i]"
              />
            </div>
            <ul>
              <li
                *ngFor="let consultationBiologie of patientHistoric.consultationBiologies"
              >
                {{ consultationBiologie.biology.element_Bio }} - {{
                consultationBiologie.note }}
              </li>
            </ul>
          </li>
        </ul>

        <div *ngIf="patientHistoric.consultationActes.length>0">
          <div class="flex">
            <h2 class="tree-nav-item-title">Actes</h2>
            <input type="checkbox" [(ngModel)]="pdfViewSettings.actes[i]" />
          </div>
          <div class="tree-nav-item">
            <table>
              <tr>
                <th>Montant</th>
                <th>Date</th>
                <th>Note</th>
              </tr>
              <tr
                *ngFor="let consultationActe of patientHistoric.consultationActes"
              >
                <td>{{consultationActe.act.famille_Acte}}</td>
                <td>{{consultationActe.montant_consultation_acte}}</td>
                <td>
                  {{consultationActe.date_consultation_acte |
                  date:'dd/MM/yyyy'}}
                </td>

                <td>{{consultationActe.note_consultation_acte}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="patientHistoric.consultationVaccinations.length">
          <div class="flex">
            <h2 class="tree-nav-item-title">Vaccin</h2>
            <input type="checkbox" [(ngModel)]="pdfViewSettings.vaccin[i]" />
          </div>
          <div class="tree-nav-item">
            <table>
              <tr>
                <th>Lot N°</th>
                <th>Date</th>
                <th>Note</th>
              </tr>
              <tr
                *ngFor="let consultationVaccination of patientHistoric.consultationVaccinations"
              >
                <td>{{consultationVaccination.vaccin.nom_Vaccin}}</td>
                <td>{{consultationVaccination.numLot_consultation_vaccin}}</td>
                <td>{{consultationVaccination.date_consultation_vaccin}}</td>
                <td>{{consultationVaccination.note_consultation_vaccin}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="doctor.speciality.label == 'Ophtalmologue'" style="margin-bottom: 10px;">
        <div class="flex">
          <h2 class="tree-nav-item-title">Lunette</h2>

        <input type="checkbox" [(ngModel)]="pdfViewSettings.lunette[i]" />

        </div>
      <div style="display: flex;flex-direction: row; gap: 15px;">
          <h4 style="font-family: 'Gotham Rounded Bold';">OD</h4>
          <div style="display: flex;flex-direction: row;">
            <h4 style="margin-left: 15px;"><strong>VL:</strong> {{patientHistoric.od_vl_avac_Sphere}}  ({{patientHistoric.od_vl_avac_Cylindre}})  {{patientHistoric.od_vl_avac_Axe}}°</h4>
            <h4 style="margin-left: 15px;"><strong>VP:</strong> {{patientHistoric.od_vp_avac_Sphere}}  ({{patientHistoric.od_vp_avac_Cylindre}})  {{patientHistoric.od_vp_avac_Axe}}°</h4>

          </div>
          <h4 style="font-family: 'Gotham Rounded Bold';">OG</h4>
          <div style="display: flex;flex-direction: row;">
            <h4 style="margin-left: 15px;"><strong>VL:</strong> {{patientHistoric.og_vl_avac_Sphere}}  ({{patientHistoric.og_vl_avac_Cylindre}})  {{patientHistoric.og_vl_avac_Axe}}°</h4>
            <h4 style="margin-left: 15px;"><strong>VP:</strong> {{patientHistoric.og_vp_avac_Sphere}}  ({{patientHistoric.og_vp_avac_Cylindre}})  {{patientHistoric.og_vp_avac_Axe}}°</h4>
            <span >
              Add:{{patientHistoric.og_vp_avac_Addition}}
            </span>
          </div>
        </div>
        
        <span style=" font-family: 'Gotham Rounded Bold'; text-align: center;justify-content: center;display: flex;">Note Lunette : {{patientHistoric.lunette_remarque}}</span>

      </div>
      <div *ngIf="doctor.speciality.label == 'Ophtalmologue'" style="margin-bottom: 10px;">
        <div class="flex">
          <h2 class="tree-nav-item-title">Lentille</h2>

        <input type="checkbox" [(ngModel)]="pdfViewSettings.lentille[i]" />

        </div>
        <div class="row">
          <div class="col-lg-6">
           <div >
            <h4 style="font-family: 'Gotham Rounded Bold';">OG</h4>
            <Label class="mx-3">RC:{{patientHistoric.og_lentille_RC}}</Label>
            <Label>DC:{{patientHistoric.og_lentille_DC}}</Label>
            <Label class="mx-3">Puissance:{{patientHistoric.og_lentille_Puissance}}</Label>

           </div>
          
          </div>
          <div class="col-lg-6">
            <h4 style="font-family: 'Gotham Rounded Bold';">OD</h4>
            <Label class="mx-3">RC:{{patientHistoric.od_lentille_RC}}</Label>
            <Label>DC:{{patientHistoric.od_lentille_DC}}</Label>
            <Label class="mx-3">Puissance:{{patientHistoric.od_lentille_Puissance}}</Label>
          </div>
          <span style=" font-family: 'Gotham Rounded Bold'; text-align: center;justify-content: center;">Note Lentille : {{patientHistoric.lentille_remarque}}</span>

        </div>
      </div>
      <div
        *ngIf="patientHistoric.consultationParamSanteValues.length && doctor.speciality.label!=='Ophtalmologue'"
        class="tree-nav-item is-expandable"
        open
      >
        <div class="flex">
          <h2 class="tree-nav-item-title">Paramètres Santé</h2>
          <input
            type="checkbox"
            [(ngModel)]="pdfViewSettings.parametreSante[i]"
          />
        </div>
        <div class="tree-nav-item">
          <table style="width: 100%;">
            <tr
              *ngFor="let consultationParamSante of patientHistoric.consultationParamSanteValues"
            >
              <ng-container *ngIf="consultationParamSante.value.length>0">
                <td>{{consultationParamSante.parametre.label}}</td>

                <td>{{consultationParamSante.value}}</td>
              </ng-container>
            </tr>
          </table>
        </div>
      </div>
      <div
        *ngIf="patientHistoric.consultationParamSanteValues.length && doctor.speciality.label=='Ophtalmologue'"
        class="tree-nav-item is-expandable"
        open
      >
        <div class="flex">
          <h2 class="tree-nav-item-title">Paramètres Santé</h2>
          <input
            type="checkbox"
            [(ngModel)]="pdfViewSettings.parametreSante[i]"
          />
        </div>
        <div class="tree-nav-item">
          <table style="width: 100%;">
            <tr
              *ngFor="let consultationParamSante of patientHistoric.consultationParamSanteValues"
            >
              <td>{{consultationParamSante.value_OG}}</td>

              <td>{{consultationParamSante.parametre.label}}</td>

              <td>{{consultationParamSante.value_OD}}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- Prescription card view -->
      <div *ngIf="patientHistoric.hasPrescription">
        <h2>Prescription</h2>
        <ul>
          <!-- Medications -->
          <li *ngIf="patientHistoric.prescriptionMedics.length">
            <div class="flex">
              <strong>Medications:</strong
              ><input type="checkbox" [(ngModel)]="pdfViewSettings.medics[i]" />
            </div>
            <ul>
              <li
                *ngFor="let prescriptionMedic of patientHistoric.prescriptionMedics"
              >
                {{ prescriptionMedic.medic.nom }} - {{
                prescriptionMedic.posology }} - {{ prescriptionMedic.duration }}
                - {{ prescriptionMedic.note }}
              </li>
            </ul>
          </li>

          <!-- Radiologies -->
          <li *ngIf="patientHistoric.prescriptionRadiologies.length">
            <div class="flex">
              <strong>Radiologies:</strong
              ><input
                type="checkbox"
                [(ngModel)]="pdfViewSettings.radioPres[i]"
              />
            </div>
            <ul>
              <li
                *ngFor="let prescriptionRadiologie of patientHistoric.prescriptionRadiologies"
              >
                {{ prescriptionRadiologie.radio.nom_Radio }} - {{
                prescriptionRadiologie.note }}
              </li>
            </ul>
          </li>

          <!-- Biologies -->
          <li *ngIf="patientHistoric.prescriptionBiologies.length">
            <div class="flex">
              <strong>Biologies:</strong
              ><input
                type="checkbox"
                [(ngModel)]="pdfViewSettings.biologiePresc[i]"
              />
            </div>
            <ul>
              <li
                *ngFor="let prescriptionBiologie of patientHistoric.prescriptionBiologies"
              >
                {{ prescriptionBiologie.biology.element_Bio }} - {{
                prescriptionBiologie.note }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div style="visibility: hidden;">
  <!-- <div id="pdf">
      
              <header>
                <table
                  class="table tst fixed-header"
                  
                  cellspacing="0"
                >
                  <tr class="noborder">
                    <td class="noborder name" style="text-align: left;">
                      <span
                  
                        style="font-size: 17px;"
                        >{{doctor.printFullName}}</span
                      >
                    </td>
                    <td class="docStyle name-arbic noborder">
                      <span
                     
                        style="font-size: 20px;"
                      >
                        {{doctor.printArabicFullName}}
                      </span>
                    </td>
                  </tr>
  
                  <tr class="noborder">
                    <td class="font noborder">
                      <span
                        style="font-size: 14px;"
                      
                      >
                        {{doctor.printSpeciality}}</span
                      >
                    </td>
                    <td class="docStyle font noborder">
                      <span
                        style="font-size: 14px;"
                        
                        >{{doctor.printArabicSpeciality}}</span
                      >
                    </td>
                  </tr>
                  <tr class="noborder">
                    <td class="font noborder">
                      <span
                        style="font-size: 14px;"
                        
                        >{{doctor.fixPhone}}</span
                      >
                    </td>
                    <td class="docStyle font noborder">
                      <span
                        style="font-size: 14px;"
                       
                      >
                        {{doctor.fixPhone}}</span
                      >
                    </td>
                  </tr>
                  <tr class="font noborder" style="font-size: 11px;">
                    <span >
                      Code CNAM:{{doctor.cnam}}</span
                    >
                  </tr>
                </table>
              </header>
          
       
              <div class="content" >

              <div *ngFor="let patientHistoric of patientHistorics; let i=index"  >
              
                <div class="row">
                  <div class="col-lg-6">
                    <h5 class="title"  > Date rdv : {{ patientHistoric.date | date:'dd/MM/yyyy' }}</h5>   
                  </div>
                  <div class="col-lg-6">
                    <p *ngIf="patientHistoric.motif"><strong>Motif:</strong> {{ patientHistoric.motif }}</p>
                  </div>
                 </div>   
                  <p *ngIf="patientHistoric.diagnostic"><strong>Diagnostic:</strong> {{ patientHistoric.diagnostic }}</p>
                  <p *ngIf="patientHistoric.examenDemande"><strong>Examen demandé:</strong> {{ patientHistoric.examenDemande }}</p>
                
            
                <div   *ngIf="patientHistoric.hasConsultation">
                  <h5 class="label">Consultation</h5>
                  <ul>
                    <li *ngIf="patientHistoric.consultationRadiologies.length"><strong>Radiologies:</strong>
                      <ul>
                        <li *ngFor="let consultationRadiologie of patientHistoric.consultationRadiologies">
                          {{ consultationRadiologie.radio.nom_Radio }} - {{ consultationRadiologie.note_consultation_radio }}
                        </li>
                      </ul>
                    </li>
            
                    <li *ngIf="patientHistoric.consultationBiologies.length"><strong>Biologies:</strong>
                      <ul>
                        <li *ngFor="let consultationBiologie of patientHistoric.consultationBiologies">
                          {{ consultationBiologie.biology.element_Bio }} - {{ consultationBiologie.note }}
                        </li>
                      </ul>
                    </li>
                  </ul>
        
                  <div *ngIf="patientHistoric.consultationActes.length>0"  >
                      <h5 class="tree-nav-item-title label">Actes</h5>
                    <div class="tree-nav-item">
                      <table >
                          <tr>
                              <th>Montant</th>
                              <th>Date</th>
                              <th>Note</th>
                          </tr>
                          <tr *ngFor="let consultationActe of patientHistoric.consultationActes">
                              <td>{{consultationActe.act.famille_Acte}}</td>
                              <td>{{consultationActe.montant_consultation_acte}}</td>
                              <td>{{consultationActe.date_consultation_acte | date:'dd/MM/yyyy'}}</td>
        
                              <td>{{consultationActe.note_consultation_acte}}</td>
                          </tr>
                      </table>
                  
                    </div>
                  </div>
                  <div *ngIf="patientHistoric.consultationVaccinations.length" >
                      <h5 class="tree-nav-item-title label">Vaccin</h5>
                      <div class="tree-nav-item">
                          <table >
                              <tr>
                                  <th>Lot N°</th>
                                  <th>Date</th>
                                  <th>Note</th>
                              </tr>
                              <tr *ngFor="let consultationVaccination of patientHistoric.consultationVaccinations">
                                  <td>{{consultationVaccination.vaccin.nom_Vaccin}}</td>
                                  <td>{{consultationVaccination.numLot_consultation_vaccin}}</td>
                                  <td>{{consultationVaccination.date_consultation_vaccin}}</td>
                                  <td>{{consultationVaccination.note_consultation_vaccin}}</td>
                              </tr>
                          </table>
                      </div>
        
                  </div>
                </div>
                <div *ngIf="patientHistoric.consultationParamSanteValues.length && doctor.speciality.label!=='Ophtalmologue'"  class="tree-nav-item is-expandable" open>
                  <h5 class="tree-nav-item-title label">Parametres Sante</h5>
                  <div class="tree-nav-item">
                      <table style="width:100%">
                          <tr>
                              <th>Parametre</th>
                            
                              <th>Value</th>
                          </tr>
                          <tr *ngFor="let consultationParamSante of patientHistoric.consultationParamSanteValues">
                             <ng-container *ngIf="consultationParamSante.value.length>0">
                              <td class="text-center">{{consultationParamSante.parametre.label}}</td>
                            
                              <td class="text-center">{{consultationParamSante.value}}</td>
                             </ng-container>
                          </tr>
                      </table>
                  </div>
                </div>
              <div *ngIf="patientHistoric.consultationParamSanteValues.length && doctor.speciality.label=='Ophtalmologue'"  class="tree-nav-item is-expandable" open>
                  <h5 class="tree-nav-item-title label">Parametres Sante</h5>
                  <div class="tree-nav-item">
                      <table style="width:100%">
                          <tr>
                              <th>Value-Go</th>
        
                              <th>Parametre</th>
                            
                              <th>Value-Do</th>
                          </tr>
                          <tr *ngFor="let consultationParamSante of patientHistoric.consultationParamSanteValues">
                              <td>{{consultationParamSante.value_OG}}</td>
        
                              <td>{{consultationParamSante.parametre.label}}</td>
                            
                              <td>{{consultationParamSante.value_OD}}</td>
                          </tr>
                      </table>
                  </div>
                </div>  
                <div *ngIf="patientHistoric.hasPrescription">
                  <h5 class="label">Prescription</h5>
                  <ul>
                    <li *ngIf="patientHistoric.prescriptionMedics.length"><strong>Medications:</strong>
                      <ul>
                        <li *ngFor="let prescriptionMedic of patientHistoric.prescriptionMedics">
                          {{ prescriptionMedic.medic.nom }} - {{ prescriptionMedic.posology }} - {{ prescriptionMedic.duration }} - {{ prescriptionMedic.note }}
                        </li>
                      </ul>
                    </li>
            
                    <li *ngIf="patientHistoric.prescriptionRadiologies.length"><strong>Radiologies:</strong>
                      <ul>
                        <li *ngFor="let prescriptionRadiologie of patientHistoric.prescriptionRadiologies">
                          {{ prescriptionRadiologie.radio.nom_Radio }} - {{ prescriptionRadiologie.note }}
                        </li>
                      </ul>
                    </li>
            
                    <li *ngIf="patientHistoric.prescriptionBiologies.length"><strong>Biologies:</strong>
                      <ul>
                        <li *ngFor="let prescriptionBiologie of patientHistoric.prescriptionBiologies">
                          {{ prescriptionBiologie.biology.element_Bio }} - {{ prescriptionBiologie.note }}
                        </li>
                      </ul>
                    </li>
                  </ul>
             
               </div>

      
              </div>
              </div>
         
        <footer>
         
          
                <table class="table tableheader fixed-footer" cellspacing="0">
                  <tr class="noborder">
                    <td class="adresseFrFooter font">
                      {{doctor.printArabicAddress}}
                    </td>
                  </tr>
                  <tr class="noborder">
                    <td class="adresseFrFooter font">
                      {{doctor.printAddress}}
                    </td>
                  </tr>
                  <tr class="noborder">
                    <td class="adresseFrFooter font">
                      Tél : {{doctor.fixPhone}}
                    </td>
                  </tr>
                </table>
          
       
        </footer>
  
    
    </div> -->

  <div id="pdf">
    <div class="page-header">
      <table class="table tst" cellspacing="0">
        <tr class="noborder">
          <td class="noborder name" style="text-align: left;">
            <span style="font-size: 17px;">{{doctor.printFullName}}</span>
          </td>
          <td class="docStyle name-arbic noborder">
            <span style="font-size: 20px;">
              {{doctor.printArabicFullName}}
            </span>
          </td>
        </tr>

        <tr class="noborder">
          <td class="font noborder">
            <span style="font-size: 14px;"> {{doctor.printSpeciality}}</span>
          </td>
          <td class="docStyle font noborder">
            <span style="font-size: 14px;"
              >{{doctor.printArabicSpeciality}}</span
            >
          </td>
        </tr>
        <tr class="noborder">
          <td class="font noborder">
            <span style="font-size: 14px;">{{doctor.fixPhone}}</span>
          </td>
          <td class="docStyle font noborder" style="left: 50%;">
            <span style="font-size: 14px;"> {{doctor.fixPhone}}</span>
          </td>
        </tr>
        <tr class="font noborder" style="font-size: 11px;">
          <span> Code CNAM:{{doctor.cnam}}</span>
        </tr>
      </table>
    </div>
    <div class="page-footer">
      <table class="table tableheader fixed-footer" cellspacing="0">
        <tr class="noborder">
          <td class="adresseFrFooter font">
            {{doctor.printArabicAddress}}
          </td>
        </tr>
        <tr class="noborder">
          <td class="adresseFrFooter font">
            {{doctor.printAddress}}
          </td>
        </tr>
        <tr class="noborder">
          <td class="adresseFrFooter font">
            Tél : {{doctor.fixPhone}}
          </td>
        </tr>
      </table>
    </div>

    <table>
      <thead>
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <div>
              <div class="content">
                <div *ngIf="pdfViewSettings.antecedant && antcedents.length ">
                  <h5 class="label">Antcedents</h5>

                  <div class="h-list">
                    <ng-container
                      *ngIf="doctorPatientAntecedents && antcedents.length"
                    >
                      <div
                        *ngFor="let patientAntecedent of antcedents"
                        class="antecedent-card"
                      >
                        <h5 class="label">{{patientAntecedent.label}}</h5>
                        <textarea
                          class="textarea"
                          [value]="patientAntecedent.text"
                          disabled
                        ></textarea>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div
                  *ngFor="let patientHistoric of patientHistorics; let i=index"
                >
                  <div *ngIf="pdfViewSettings.Rdv[i] && patientHistoric">
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="title" *ngIf="pdfViewSettings.Rdv[i]">
                          Date rdv : {{ patientHistoric.date | date:'dd/MM/yyyy'
                          }} 
                        </h5>
                      </div>
                      <div class="col-lg-6">
                        <p
                          *ngIf="pdfViewSettings.Rdv[i] && pdfViewSettings.motif[i] && patientHistoric.motif"
                        >
                          <strong>Motif:</strong> {{ patientHistoric.motif }}
                        </p>
                      </div>
                    </div>
                    <p
                      *ngIf="pdfViewSettings.Rdv[i] &&  pdfViewSettings.diagnostic[i]"
                    >
                      <strong *ngIf="patientHistoric.diagnostic"
                        >Diagnostic:</strong
                      >
                      {{ patientHistoric.diagnostic }}
                    </p>
                    <p
                      *ngIf=" pdfViewSettings.Rdv[i] &&  pdfViewSettings.examenDemande[i]"
                    >
                      <strong *ngIf="patientHistoric.examenDemande"
                        >Examen demandé:</strong
                      >
                      {{ patientHistoric.examenDemande }}
                    </p>
                    <p
                      *ngIf=" pdfViewSettings.Rdv[i] &&  pdfViewSettings.examenClinique[i]"
                    >
                      <strong *ngIf="patientHistoric.examenClinique"
                        >Examen Clinique:</strong
                      >
                      {{ patientHistoric.examenClinique }}
                    </p>

                    <!-- Consultation card view -->
                    <div *ngIf="patientHistoric.hasConsultation ">
                      <h5 class="label">Consultation</h5>
                      <ul>
                        <!-- Radiologies -->
                        <li
                          *ngIf="patientHistoric.consultationRadiologies.length && pdfViewSettings.radioConsultation[i]"
                        >
                          <strong>Radiologies:</strong>
                          <ul>
                            <li
                              *ngFor="let consultationRadiologie of patientHistoric.consultationRadiologies"
                            >
                              {{ consultationRadiologie.radio.nom_Radio }} - {{
                              consultationRadiologie.note_consultation_radio }}
                            </li>
                          </ul>
                        </li>

                        <!-- Biologies -->
                        <li
                          *ngIf="patientHistoric.consultationBiologies.length && pdfViewSettings.biologieConsultation[i]"
                        >
                          <strong>Biologies:</strong>
                          <ul>
                            <li
                              *ngFor="let consultationBiologie of patientHistoric.consultationBiologies"
                            >
                              {{ consultationBiologie.biology.element_Bio }} -
                              {{ consultationBiologie.note }}
                            </li>
                          </ul>
                        </li>
                      </ul>

                      <div
                        *ngIf="patientHistoric.consultationActes.length>0 && pdfViewSettings.actes[i] "
                      >
                        <h5 class="tree-nav-item-title label">Actes</h5>
                        <div class="tree-nav-item">
                          <table>
                            <tr>
                              <th>Montant</th>
                              <th>Date</th>
                              <th>Note</th>
                            </tr>
                            <tr
                              *ngFor="let consultationActe of patientHistoric.consultationActes"
                            >
                              <td>{{consultationActe.act.famille_Acte}}</td>
                              <td>
                                {{consultationActe.montant_consultation_acte}}
                              </td>
                              <td>
                                {{consultationActe.date_consultation_acte |
                                date:'dd/MM/yyyy'}}
                              </td>

                              <td>
                                {{consultationActe.note_consultation_acte}}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div *ngIf="doctor.speciality.label == 'Ophtalmologue' && pdfViewSettings.lunette[i]">
                        <h2 class="tree-nav-item-title">Lunette</h2>
                
                        <div class="flex">
                          <div class="col-lg-6">
                            <h4 style="font-family: 'Gotham Rounded Bold';">OG</h4>
                            <table
                              class="table table-bordered table-responsive"
                              
                            >
                              <thead class="table-bordered">
                                <tr >
                                  <th scope="apres"></th>
                                  <th>Sphére</th>
                                  <th>Cylindre</th>
                                  <th>Axe</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    scope="row"
                                    style="
                                    
                                      font-family: 'Gotham Rounded Bold';
                                    "
                                  >
                                    VL
                                  </td>
                                  <td >
                                    {{patientHistoric.og_vl_avac_Sphere}}
                                  </td>
                                  <td >
                                    {{patientHistoric.og_vl_avac_Cylindre}}
                                  </td>
                                  <td >
                                    {{patientHistoric.og_vl_avac_Axe}}°
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    scope="row"
                                    style="
                                      
                                      font-family: 'Gotham Rounded Bold';
                                    "
                                  >
                                    VP
                                  </td>
                                  <td >
                                    {{patientHistoric.og_vp_avac_Sphere}}
                                  </td>
                                  <td >
                                    {{patientHistoric.og_vp_avac_Cylindre}}
                                  </td>
                                  <td >
                                    {{patientHistoric.og_vp_avac_Axe}}°
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <h3 style="font-family: 'Gotham Rounded Bold';margin-top: 10px;">
                              Add:{{patientHistoric.og_vp_avac_Addition}}
                            </h3>
                          </div>
                          <div class="col-lg-6">
                            <h4 style="font-family: 'Gotham Rounded Bold';">
                              OD
                            </h4>
                            <table
                              class="table table-bordered table-responsive"
                              style="margin-top: 25px;"
                            >
                              <thead class="table-bordered">
                                <tr >
                                  <th scope="apres"></th>
                                  <th>Sphére</th>
                                  <th>Cylindre</th>
                                  <th>Axe</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    scope="row"
                                    style="
                                      
                                      font-family: 'Gotham Rounded Bold';
                                    "
                                  >
                                    VL
                                  </td>
                                  <td >
                                    {{patientHistoric.od_vl_avac_Sphere}}
                                  </td>
                                  <td >
                                    {{patientHistoric.od_vl_avac_Cylindre}}
                                  </td>
                                  <td >
                                    {{patientHistoric.od_vl_avac_Axe}}°
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    scope="row"
                                    style="
                                     
                                      font-family: 'Gotham Rounded Bold';
                                    "
                                  >
                                    VP
                                  </td>
                                  <td >
                                    {{patientHistoric.od_vp_avac_Sphere}}
                                  </td>
                                  <td >
                                    {{patientHistoric.od_vp_avac_Cylindre}}
                                  </td>
                                  <td >
                                    {{patientHistoric.od_vp_avac_Axe}}°
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="notes">Note Lunette : {{patientHistoric.lunette_remarque}}</div>
                      </div>
                      <div *ngIf="doctor.speciality.label == 'Ophtalmologue' && pdfViewSettings.lentille[i]">
                        <h2 class="tree-nav-item-title">Lentille</h2>
                      <div class="flex">
                        <div class="col-lg-6">
                         <div >
                          <h4 style="font-family: 'Gotham Rounded Bold';">OG</h4>
                          <Label class="mx-3 espace-label">RC:{{patientHistoric.og_lentille_RC}}</Label>
                          <Label class="espace-label">DC:{{patientHistoric.og_lentille_DC}}</Label>
                          <Label class="mx-3  espace-label">Puissance:{{patientHistoric.og_lentille_Puissance}}</Label>
              
                         </div>
                        
                        </div>
                        <div class="col-lg-6">
                          <h4 style="font-family: 'Gotham Rounded Bold';">OD</h4>
                          <Label class="mx-3  espace-label">RC:{{patientHistoric.od_lentille_RC}}</Label>
                          <Label class="espace-label">DC:{{patientHistoric.od_lentille_DC}}</Label>
                          <Label class="mx-3  espace-label">Puissance:{{patientHistoric.od_lentille_Puissance}}</Label>
                        </div>
              
                      </div>
                      <div class="notes">Note Lentille : {{patientHistoric.lentille_remarque}}</div>

                      </div>
                      <div
                        *ngIf="patientHistoric.consultationVaccinations.length && pdfViewSettings.vaccin[i] "
                      >
                        <h5 class="tree-nav-item-title label">Vaccin</h5>
                        <div class="tree-nav-item">
                          <table>
                            <tr>
                              <th>Lot N°</th>
                              <th>Date</th>
                              <th>Note</th>
                            </tr>
                            <tr
                              *ngFor="let consultationVaccination of patientHistoric.consultationVaccinations"
                            >
                              <td>
                                {{consultationVaccination.vaccin.nom_Vaccin}}
                              </td>
                              <td>
                                {{consultationVaccination.numLot_consultation_vaccin}}
                              </td>
                              <td>
                                {{consultationVaccination.date_consultation_vaccin}}
                              </td>
                              <td>
                                {{consultationVaccination.note_consultation_vaccin}}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="patientHistoric.consultationParamSanteValues.length && doctor.speciality.label!=='Ophtalmologue' && pdfViewSettings.parametreSante[i]"
                      class="tree-nav-item is-expandable"
                      open
                    >
                      <h5 class="tree-nav-item-title label">
                        Paramètres Santé
                      </h5>
                      <div class="tree-nav-item">
                        <table style="width: 100%;">
                          <tr
                            *ngFor="let consultationParamSante of patientHistoric.consultationParamSanteValues"
                          >
                            <ng-container
                              *ngIf="consultationParamSante.value.length>0"
                            >
                              <td class="text-center">
                                {{consultationParamSante.parametre.label}}
                              </td>

                              <td class="text-center">
                                {{consultationParamSante.value}}
                              </td>
                            </ng-container>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      *ngIf="patientHistoric.consultationParamSanteValues.length && doctor.speciality.label=='Ophtalmologue' && pdfViewSettings.parametreSante[i]"
                      class="tree-nav-item is-expandable"
                      open
                    >
                      <h5 class="tree-nav-item-title label">
                        Paramètres Santé
                      </h5>
                      <div class="tree-nav-item">
                        <table style="width: 100%;">
                          <tr
                            *ngFor="let consultationParamSante of patientHistoric.consultationParamSanteValues"
                          >
                            <td>{{consultationParamSante.value_OG}}</td>

                            <td>{{consultationParamSante.parametre.label}}</td>

                            <td>{{consultationParamSante.value_OD}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <!-- Prescription card view -->
                    <div *ngIf="patientHistoric.hasPrescription">
                      <h5 class="label">Prescription</h5>
                      <ul>
                        <!-- Medications -->
                        <li
                          *ngIf="patientHistoric.prescriptionMedics.length  && pdfViewSettings.medics[i]"
                        >
                          <strong>Medications:</strong>
                          <ul>
                            <li
                              *ngFor="let prescriptionMedic of patientHistoric.prescriptionMedics"
                            >
                              {{ prescriptionMedic.medic.nom }} - {{
                              prescriptionMedic.posology }} - {{
                              prescriptionMedic.duration }} - {{
                              prescriptionMedic.note }}
                            </li>
                          </ul>
                        </li>

                        <!-- Radiologies -->
                        <li
                          *ngIf="patientHistoric.prescriptionRadiologies.length && pdfViewSettings.radioPres[i] "
                        >
                          <strong>Radiologies:</strong>
                          <ul>
                            <li
                              *ngFor="let prescriptionRadiologie of patientHistoric.prescriptionRadiologies"
                            >
                              {{ prescriptionRadiologie.radio.nom_Radio }} - {{
                              prescriptionRadiologie.note }}
                            </li>
                          </ul>
                        </li>

                        <!-- Biologies -->
                        <li
                          *ngIf="patientHistoric.prescriptionBiologies.length && pdfViewSettings.biologiePresc[i]"
                        >
                          <strong>Biologies:</strong>
                          <ul>
                            <li
                              *ngFor="let prescriptionBiologie of patientHistoric.prescriptionBiologies"
                            >
                              {{ prescriptionBiologie.biology.element_Bio }} -
                              {{ prescriptionBiologie.note }}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td>
            <!--place holder for the fixed-position footer-->
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
