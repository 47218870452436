import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { IMedic, Medic } from 'src/app/entities/medic.entity/medic';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../core/services/api.service/api.service';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MedicsService extends ApiService {
  prescriptions() {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/prescriptions/medics`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  all(pageRequest?: PageRequest): Observable<any> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/medics`, { params: params }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/medics/all`).pipe(this.responsePipe)
  }

  search(searchQuery: { query: string; }, pageRequest?: PageRequest): Observable<any> {
    let params = new HttpParams();
    if (searchQuery.query.length != 0) params = params.set('q', searchQuery.query);
    if (pageRequest) {
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/medics/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/medics/all/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }

  add(medic: Medic): Observable<IMedic> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/medics`, {
      dosage: medic.dosage,
      co: medic.co,
      nom: medic.nom,
      presentation: medic.presentation,
      nom_generique: medic.nom_generique,
      labo: medic.labo,
      pays: medic.pays,
      amm: medic.amm,
      date_amm: medic.date_amm,
      g_p: medic.g_p,
      rcp: medic.rcp
    }).pipe(this.responsePipe);
  }

  update(id: string, medic: Medic): Observable<IMedic> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/medics/${id}`, {
      dosage: medic.dosage,
      co: medic.co,
      nom: medic.nom,
      presentation: medic.presentation,
      nom_generique: medic.nom_generique,
      labo: medic.labo,
      pays: medic.pays,
      amm: medic.amm,
      date_amm: medic.date_amm,
      g_p: medic.g_p,
      rcp: medic.rcp
    }, { responseType: 'json' }).pipe(this.responsePipe);
  }

  one(medicId: string): Observable<IMedic> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/medics/${medicId}`).pipe(this.responsePipe);
  }

  delete(medicId: number): Observable<Medic> {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/medics/${medicId}`).pipe(this.responsePipe);
  }
}
