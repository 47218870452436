import { Pipe, PipeTransform } from "@angular/core";
import { Consultation } from "src/app/entities/consultation.entity";

@Pipe({
  name: "searchConsultation",
})
export class FilterConsultationMotifPipe implements PipeTransform {
  transform(
    Consultations: Array<Consultation>,
    motif: string
  ): Array<Consultation> {
    motif = motif || "";
    return Consultations.filter((elm: any) => {
      let res = elm.motifTags.map((elm: any) => {
        return elm.text.toLowerCase().startsWith(motif.toLowerCase().trim());
      });
      return res;
    });
  }
}
