<div class="docs-grid">
    <ng-container *ngIf="documents.length;else empty">
        <div *ngFor="let document of documents" class="doc-item">
            <div class="doc-overlay">
                <a title="Tele-consultation" (click)="onViewRequested($event,document)" class="btn btn-sm bg-success-light">
                    <i class="feather-play-circle"></i>
                </a>
                <a *ngIf="options.canEdit" title="Mise à Jour" class="btn btn-sm bg-warning-light" (click)="onEditRequest.emit(document)">
                    <i class="feather-edit"></i>
                </a>
                <a *ngIf="options.canShare" title="Paiement" class="btn btn-sm bg-primary-light" (click)="onShareRequest.emit(document)">
                    <i class="feather feather-share-2"></i>
                </a>
                <a *ngIf="options.canDelete" title="Annuler" class="btn btn-sm bg-danger-light" (click)="onDeleteRequest.emit(document)">
                    <i class="feather-x"></i>
                </a>
            </div>
            <img [src]="getIconUrl(document)" (click)="onViewRequested($event,document)">
            <label (click)="onViewRequested($event,document)">{{document.fileName}}</label>
        </div>
        <div *ngIf="options.canUpload" class="empty-docs">
            <img src="assets/img/icons/upload-icon.png" (click)="onUploadRequest.emit()">
            <label style="width: 100%;" (click)="onUploadRequest.emit()">{{langue=='fr'?'Charger un document':'مشاركة المستند'}}</label>
        </div>
    </ng-container>
    <ng-template #empty>
        <div class="empty-docs">
            <h3>{{emptyMessage ||langue=='fr'?'Aucun document trouvé':'لم يتم العثور على وثائق'}}</h3>
            <img *ngIf="options.canUpload" src="assets/img/icons/upload-icon.png" (click)="onUploadRequest.emit()">
            <label *ngIf="options.canUpload" style="width: 100%;" (click)="onUploadRequest.emit()">Charger un document</label>
        </div>
    </ng-template>
</div>
<app-pagination [pageRequest]="pageRequest" (content)="documents = $event" [refresh]="refresh"></app-pagination>
<app-file-viewer *ngIf="documentPreview" [src]="_getDocumentAttachmentPreviewSource(documentPreview)" (close)="documentPreview = null"></app-file-viewer>