<div class="card card-table">
    <div class="card-body">
        <div class="prescription-items-container">
            <table class="table  table-center">
                <thead>
                    <tr >
                        <th style="width: min-content;">Nom  </th>
                       <!-- <th  >Contenu </th>-->
                       <th *ngFor="let fillingsItme of fillingsItmes;let i = index"   style="width:25rem">
                        <input style="height:15px" class="form-control" 
                     [(ngModel)]="fillingsItme.label">
                    </th>

                        <th > </th>
                    </tr>
                    <tr *ngFor="let fillingsItme of fillingsItmes;let i = index">
                        <th> Contenu </th>
                        <div style="margin-right: 0%;">
                            <angular-editor ondrop="drop(event)" ondragover="allowDrop(event)" [(ngModel)]="fillingsItme.textContent" (ngModelChange)="_change()"
                                [config]="config"></angular-editor>
                        </div>
                        <td style="padding-left: 2%;width: 5rem;">
                            <a href="javascript:void(0)" class="btn bg-danger-light trash" (click)="deleteFillingsItem(i)"><i class="feather-trash-2">
                                </i>
                            </a>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td> <textarea style="border: transparent;" class="form-control" id="drag1" draggable="true"
                                ondragstart="drag(event)"> *[nom docteur] [nom patient] [date] [montant] [repos] [age]</textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>