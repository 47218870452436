import { AfterViewInit, Component, ContentChild, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css']
})
export class DrawerComponent implements AfterViewInit {
  @Input() size: number = 30;
  @ContentChild('header', { read: HTMLElement, static: false }) header!: HTMLElement;
  @ContentChild('body', { read: HTMLElement, static: false }) body!: HTMLElement;
  @Input() active: boolean = true;
  @Input() title: string = '';
  @Input() modal: boolean = true;
  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() opened: EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngAfterViewInit(): void { }

  close() {
    this.active = !this?.active;
    if (this.active == false) this.closed.emit();
    else this.opened.emit()
  }
}
