import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBiologie'
})
export class FilterBiologiePipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(item => {
      return (item.element_Bio.toLowerCase().includes(searchText) || item.norme_Bio.toLowerCase().includes(searchText)  || item.indication_Bio.toLowerCase().includes(searchText) 
||   item.param_Bio.toLowerCase().includes(searchText)  );

    });
    
  }

}
