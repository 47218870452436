import { Component, DoCheck, ElementRef, forwardRef, Input, OnInit, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ColorsUtils } from "src/app/utils/colors.utils";

export interface ITagInputItem {
  id?: string;
  value?: any;
  text: string;
  color?: string;
}
export interface ITagsInputOptions {
  strictMode?: boolean;
  uniqueTags?: boolean;
  filter?: "case-sensitive";
  autoColor?: boolean;
  defaultColor?: string;
  colors?: Array<string>;
}
@Component({
  selector: "app-tags-input",
  templateUrl: "./tags-input.component.html",
  styleUrls: ["./tags-input.component.css"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => TagsInputComponent)
  }]
})
export class TagsInputComponent implements ControlValueAccessor, DoCheck, OnInit {

  @Input() disabled: boolean = false;
  @ViewChild("tagInput", { static: false }) tagInput!: ElementRef;
  @Input() options: Partial<ITagsInputOptions> = {
    strictMode: false,
    uniqueTags: false,
    filter: "case-sensitive",
    autoColor: true,
    defaultColor: "#0080ff",
    colors: [
      "800000",
      "FF0000",
      "800080",
      "FF00FF",
      "008000",
      "00FF00",
      "808000",
      "FFFF00",
      "000080",
      "0000FF",
      "008080",
    ]
  };
  items: Array<ITagInputItem> = new Array();
  errors: any = {};
  @Input() itemsSource: Array<ITagInputItem> = new Array();

  filterdInputAutoCompleteTags: Array<ITagInputItem> = new Array();
  constructor() { }
  ngOnInit(): void {

  }

  ngDoCheck(): void {
    if (this.items.length == 0) this.errors.required = true;
    else this.errors = {};
  }

  removeTag(index: number): ITagInputItem {
    const tag = this.items.splice(index, 1)[0];
    this.itemsChange && this.itemsChange(this.items);
    return tag;
  }

  addTag($event: any): void {
    const text = $event.target.value;
    $event.target.value = "";
    this.items.push({
      text: text,
      color: this.options.autoColor ? this._getColor() : "",
    });
    this.itemsChange && this.itemsChange(this.items);
    this.focus();
  }

  filter($event: any): void {
    const pattern: string = String($event.target.value || '').trim().toLocaleLowerCase().toString();
    if ($event.target.value.length === 0) {
      this.filterdInputAutoCompleteTags = [];
    } else {
      this.filterdInputAutoCompleteTags = this.options.uniqueTags ? this.itemsSource.filter((tag) => !this.items.find(t => t.id === tag.id)) : this.itemsSource;
      this.filterdInputAutoCompleteTags = this.filterdInputAutoCompleteTags.filter(tag => String(tag.text).trim().toLocaleLowerCase().includes(pattern));
    }
  }

  pickFiltredTag(tag: ITagInputItem): void {
    this.tagInput.nativeElement.value = "";
    tag.color = tag.color || this._getColor()
    this.items.push(tag);
    this.itemsChange && this.itemsChange(this.items);
    this.filterdInputAutoCompleteTags = [];
    this.focus();
  }

  focus(): void {
    this.tagInput.nativeElement.focus();
  }

  writeValue(tags: Array<ITagInputItem>): void {
    this.items = (tags || []).map(e => { e.color = e.color || this._getColor(); e.id = e.id != undefined ? e.id : String(Date.now()); return e });
  }
  registerOnChange(fn: (tags: Array<ITagInputItem>) => void): void {
    this.itemsChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private itemsChange?: (values: Array<ITagInputItem>) => void;

  _getColor(): string {
    return this.options.autoColor ? (this.options.colors ? ColorsUtils.random(this.options.colors) : "#48b9b2") : this.options.defaultColor || "#48b9b2";
  }

  _handleTagInputKeyEnter($event): void {
    if ($event.key === 'Enter') {
      if (!this.options.strictMode) this.addTag($event);
      $event.stopPropagation();
      $event.preventDefault()
    }
  }
}