<div class="content success-page-cont">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="success-cont">
          <img src="assets/img/icons/check-circle.png" alt="logo">
          <h3>RDV réservé avec succés!</h3>
          <p>RDV réservé avec <span class="text-info"><strong>{{patientAppointment.doctor.getFullName()}}</strong></span><br>le
            <span>{{ patientAppointment.date | date:'dd/MM/yyyy'}}<strong class="text-info apt-time">{{patientAppointment.startTime |time}}</strong></span>
          </p>
          <button class="btn align-content-lg-center btn-sm bg-success" [routerLink]="['/patient/appointments']" routerLinkActive="router-link-active">Retourner à mes
            rendez-vous</button>
        </div>
      </div>
    </div>
  </div>
</div>