import { Component, Input, OnInit } from '@angular/core';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';

@Component({
  selector: 'app-patient-appointments-list',
  templateUrl: './patient-appointments-list.component.html',
  styleUrls: ['./patient-appointments-list.component.css']
})
export class PatientAppointmentsListComponent implements OnInit {
  @Input() patientAppointments: Array<PatientAppointment> = new Array();
  currentPage: number = 0;
  perPage: number = 0;
  constructor() { }

  ngOnInit(): void {
  }

}
