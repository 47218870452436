
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageRequest, PaginationPage } from 'src/app/common/modules/data-table.module/interfaces';
import { Appointment, EAppointmentState } from 'src/app/entities/appointment.entity';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { Language } from 'src/app/services/localization.service/localization.service';

@Component({
  selector: 'app-patient-appointments-table',
  templateUrl: './patient-appointments-table.component.html',
  styleUrls: ['./patient-appointments-table.component.css']
})
export class PatientAppointmentsTableComponent implements OnInit {
  @Input() currentPage: number = 0;
  @Input() perPage: number = 10;
  @Input() pageRequest!: (pageRequest: PageRequest) => Promise<PaginationPage<PatientAppointment>>;
  @Output() onEditRequest: EventEmitter<PatientAppointment> = new EventEmitter();
  @Output() onViewPrescriptionRequest: EventEmitter<PatientAppointment> = new EventEmitter();
  @Output() onViewAttachmentsRequest: EventEmitter<PatientAppointment> = new EventEmitter();
  @Output() onViewTeleConsultationRequest: EventEmitter<PatientAppointment> = new EventEmitter();
  @Output() onBillingRequest: EventEmitter<PatientAppointment> = new EventEmitter();
  @Output() onCancel: EventEmitter<PatientAppointment> = new EventEmitter()
  @Input() language: Language = 'fr';
  @Input() actions: Partial<{ edit: boolean, cancel: boolean, teleconsultation: boolean, attachmentsView: boolean, payementsView: boolean }> = {}
  patientAppointments: Array<PatientAppointment> = new Array();

  constructor() { }

  ngOnInit(): void { }

  canEdit(appointment: Appointment): boolean {
    if (!this.actions.edit) return false;
    else if (appointment.location == EDalyTimeSlotLocation.ATSITE) {
      return appointment.status == 0 && !(appointment.hasConsultation || appointment.hasPrescription);
    } else {
      return appointment.status == 0 && !(appointment.hasConsultation || appointment.hasPrescription) && !appointment.isPaid;
    }
  }

  isEditActionDisabled(appointment: Appointment): boolean { return false }

  canViewAttachments(appointment: Appointment): boolean {
    return this.actions.attachmentsView == true && appointment.status == 1;
  }

  isViewAttachmentsActionDisabled(appointment: Appointment): boolean { return false }

  canPay(appointment: Appointment): boolean {
    return this.actions.payementsView == true && this._isOnlineAppointment(appointment)
  }

  isPayActionDisabled(appointment: Appointment): boolean {
    return appointment.isPaid == true;
  }

  canOpenTeleconsultation(appointment: PatientAppointment): boolean {
    return this.actions.teleconsultation == true && (this._isOnlineAppointment(appointment)) && appointment.status == EAppointmentState.ACCEPTED;
  }

  isTeleconsultationDisabled(appointment: PatientAppointment): boolean { return appointment.isPaid == false }

  getAppointmentRemainingTime(appointment: Appointment): number {
    const remaining = appointment.startDateTime.getTime() - Date.now();
    return remaining > 0 ? remaining : 0;
  }

  canCancelAppointment(appointment: Appointment): boolean {
    return this.actions.cancel == true && (appointment.status == EAppointmentState.ACCEPTED || appointment.status == EAppointmentState.PENDING) && !(appointment.hasConsultation || appointment.hasPrescription) && !appointment.isPaid;
  }

  private _isOnlineAppointment(appointment: Appointment): boolean {
    return appointment.location == EDalyTimeSlotLocation.ONLINE || appointment.location == EDalyTimeSlotLocation.BOTH;
  }
}
