import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorPatient } from 'src/app/entities/doctor-patient.entity/doctor-patient';
import { User, UserRole } from 'src/app/entities/user.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { DoctorPatientsService } from 'src/app/services/doctor-patients.service/doctor-patients.service';
import { DoctorSecretaryPatientsService } from 'src/app/services/doctor-secretary-appointments.service/doctor-secretary-patients.service/doctor-secretary-patients.service';

@Component({
  selector: 'app-doctor-patients-grid',
  templateUrl: './doctor-patients-grid.component.html',
  styleUrls: ['./doctor-patients-grid.component.css']
})
export class DoctorPatientsGridComponent implements OnInit {

  @Input() doctorPatients: Array<DoctorPatient> = new Array();
  @Input() perPage: number = 10;
  currentPage: number = 0;
  @Output() onAssignAppointmentRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onAntecedentsRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onUpdateRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onDeleteRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onPrescriptionsRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onConsultationsRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Input() actions: Partial<{ edit: boolean, delete: boolean, assignAppointment: boolean, prescriptionsView: boolean, consultationsView: boolean, antecentendsView: boolean, payementsView: boolean }> = {}

  constructor(private doctorPatientsService: DoctorPatientsService, private doctorSecretaryPatientsService: DoctorSecretaryPatientsService, private authService: AuthService, private toasterService: ToastrService) { }
  ngOnInit(): void {
  }
  get currentUser(): User {
    return this.authService.getCurrentUser()!;
  }
  onDelete(index: number): void {
    if (confirm(`Etez vous sure de vouloir supprimer ${this.doctorPatients[index].getFullName()} de votre liste des patients ?`))
      (this.currentUser.role == UserRole.DOCTOR_SECRETARY ? this.doctorSecretaryPatientsService : this.doctorPatientsService).delete(this.doctorPatients[index].id).subscribe(deletetion => {
        const deleted = this.doctorPatients[index];
        this.doctorPatients = this.doctorPatients.filter((e, i) => i != index)
        this.toasterService.success(`patient ${deleted.getFullName()} Supprimé`);
      }, error => {
        if (error.status === 501)
          this.toasterService.warning('Patient à des consultations ou bien des prescriptions')
        else
          this.toasterService.error(`Problème de suppression patient`);
      })

}}
