<ng-container *ngIf="isShown">
    <div class="modal fade custom-modal show" aria-modal="true" role="dialog" style="display: block;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>

    <div class="sidebar-overlay"></div>
    <div class="modal-backdrop fade show"></div>
</ng-container>