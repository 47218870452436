import { Component, DoCheck, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'form-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => InputComponent)
  }]
})
export class InputComponent implements OnInit, ControlValueAccessor, DoCheck {

  @Input() placeholder: string = '';
  @Input() prepend!: string;
  @Input() append!: string;

  @Input() prependHtml!: string;
  @Input() appendHtml!: string;

  @Input() type: "number" | "tel" | "text" | "email" | "password" = "text";

  @Input() value: string | number = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter();
  errors: Array<any> = [];
  constructor() { }
  ngOnInit(): void { }

  onChange(value: string | number) {
    this.value = value;
  }
  onInputChange($event: any): void {
    this.value = $event.target.value;
    this.valueChange.emit(this.value as string);
    if (this.onChange) this.onChange(this.value)
  }


  onKeyDown(event: KeyboardEvent) {

    if (this.type == "number") {
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+C
        (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+V
        (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+X
        (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
        // Allow: home, end, left, right
        (event.keyCode >= 35 && event.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        event.preventDefault();
      }
    } else if (this.type == "tel") {
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+C
        (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+V
        (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+X
        (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
        // Allow: home, end, left, right
        (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
        // Allow: space and +
        (event.keyCode >= 35 && event.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        event.preventDefault();
      }
    }
  }

  writeValue(value: string | number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {

  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngDoCheck(): void {
    if ((this.value || '').toString().length == 0 && this.required) this.errors = ['required'];
    else this.errors = []
  }

}
