import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { PageRequest, PageRequestFn, PaginationPage } from '../../interfaces';
import { Language } from 'src/app/services/localization.service/localization.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent<T extends any> implements OnInit {

  @Input() page: PaginationPage<T> = new PaginationPage({ hasNext: false, hasPrevious: false, totalPages: 0, currentPage: 0, items: [] });
  @Input() paginationSize: number = 5;
  @Input() pageSize: number = 10;
  @Input() currentPage: number = 0;
  @Input() stepsMax: number = 5;
  @Input() set pageRequest(request: PageRequestFn<T>) {
    this._pageRequest = request;
    if (request != undefined) this._requestPage({ pageOffset: 0, pageSize: this.pageSize });
  }
  @Input() refresh?: Observable<PageRequest> = new Observable();
  @Output() pageChange: EventEmitter<PaginationPage<T>> = new EventEmitter();
  /**
   * @deprecated
   */
  @Output() content: EventEmitter<Array<T>> = new EventEmitter();
  _pageRequest!: PageRequestFn<T>;
  step0: number = 0;
  stepN: number = 0;
  @Input() language: Language = 'fr';

  get steps(): Array<number> {
    let steps: Array<number> = new Array();
    for (let step = this.step0; step < this.stepN; step++)steps.push(step);
    return steps;
  }

  constructor() { }

  ngOnInit(): void {
    if (this._pageRequest != undefined)
      this._requestPage({ pageOffset: this.page.currentPage, pageSize: this.pageSize });

    this.refresh?.subscribe(next => {
      this._requestPage(next);
    })

    this.pageChange.subscribe(page => this.content.emit(page.items))
  }

  onNextRequest() {
    this._requestPage({ pageOffset: this.page.currentPage + 1, pageSize: this.pageSize })
  }

  onPreviousRequest() {
    this._requestPage({ pageOffset: this.page.currentPage - 1, pageSize: this.pageSize })
  }

  _requestPage(pageRequest: PageRequest): void {
    if (this._pageRequest != undefined) this._pageRequest(pageRequest).then(page => {
      this.page = page;

      this.step0 = page.currentPage >= this.stepsMax ? (page.currentPage - this.stepsMax + 1) : 0;
      this.stepN = this.step0 + this.stepsMax > page.totalPages ? page.totalPages : (this.step0 + this.stepsMax);

      this.pageChange.emit(page)
    }).catch(e => console.error(e))
  }

  gotTo(pageIndex: number) {
    this._requestPage({ pageOffset: pageIndex, pageSize: this.pageSize })
  }
}
