import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { LiteralDays, LiteralDaysArab } from 'src/app/common/pipes/french-date.pipe/french-date.pipe';
import { DalyTimeSlot, EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { DoctorAvailability } from 'src/app/entities/doctor-disponiblity.entity/doctor-availability';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { addDaysToDate, getDayDate, MILLISECONDS_IN_DAY } from 'src/app/utils/date.util';
;;

export type DoctorAvailabilitiesRequestPromise = (date?: Date) => Promise<{
  date: number,
  availabilities: Array<DoctorAvailability>
}>;

export declare type DoctorDisponibilityCalander = Array<{ day: Date; availabilities: Array<DoctorAvailability>; }>


export enum EDoctoravailabilitiesCalander {
  DATE_TIME,
  DATE
}

@Component({
  selector: 'app-doctor-availabilities-calender',
  templateUrl: './doctor-availabilities-calender.component.html',
  styleUrls: ['./doctor-availabilities-calender.component.css'],
})
export class DoctoravailabilitiesCalenderComponent implements OnInit {
  MONTHS = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  DAYS_IN_WEEK_CONFIG = 7;
  @Input() availabilitiesRequest: DoctorAvailabilitiesRequestPromise = () => Promise.resolve({ date: this.date.getTime(), availabilities: [] });
  @Input() location: EDalyTimeSlotLocation = EDalyTimeSlotLocation.ATSITE;
  @Input() navigationStep: number = 1;
  @Input() privacy: boolean | null = null;
  @Input() doctor: Doctor = new Doctor();
  @Input() mode: EDoctoravailabilitiesCalander = EDoctoravailabilitiesCalander.DATE_TIME;
  @Input() disponibility?: DoctorAvailability;
  @Input() updateDisponibility?: DoctorAvailability;
  @Input() strict: boolean = true;
  @Output() disponibilityChange: EventEmitter<DoctorAvailability> = new EventEmitter();
  @Output() onTimeSlotSelect: EventEmitter<DalyTimeSlot> = new EventEmitter();
  @Output() onError: EventEmitter<Error> = new EventEmitter();
  @Input() set date(date: Date) {
    const fixed = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    if (fixed.getTime() != this.date.getTime()) {
      this._date = fixed;
      this.fillCalander()
    }
  }
  get date(): Date { return this._date; }
  get currentDate(): Date { return new Date(); }
  private _date: Date = new Date();
  @Input() dir: string = "fr"
  selectedDay: Date = new Date();
  calender: Array<{ day: Date; availabilities: Array<DoctorAvailability>; }> = new Array();
  displayMode: number = 1;
  isLoading: boolean = false;

  constructor(private elementRef: ElementRef) { }


  ngOnInit(): void {
    this.onResize(window.screen.width)
    if (this.dir == "fr") {
      this.MONTHS = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

    } else {
      (this.MONTHS = ["جانفي", "فيفري", "مارس", "أفريل", "ماي", "جوان", "جويلية", "أوت", "سبتمبر", "اكتوبر", "نوفمبر", "ديسمبر"])
    }
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    if (width <= 500) {
      this.DAYS_IN_WEEK_CONFIG = 3;
      this.fillCalander()
    }
    else {
      this.DAYS_IN_WEEK_CONFIG = 7;
      this.fillCalander()
    }
  }

  selectDay($event: any, item: any) {
    this.selectedDay = item.day;
    $event.preventDefault();
  }

  fillCalander(): void {
    let weekavailabilitiesPromises = Array.from([].constructor(this.DAYS_IN_WEEK_CONFIG), (_v: any, i: number) => {
      let date = addDaysToDate(this.date, i);

      return this.availabilitiesRequest(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    });
    this.calender = [];
    this.isLoading = true;
    Promise.all(weekavailabilitiesPromises).then((
      weekavailabilities: Array<{ date: number, availabilities: Array<DoctorAvailability> }>) => {
      weekavailabilities = weekavailabilities.map(e => {
        e.availabilities = e.availabilities.map(d => { d.date = new Date(e.date); return d })
        return e;
      })
      this.calender = Array.from(weekavailabilities, (dayDisponibility: { date: number, availabilities: Array<DoctorAvailability> }) => {
        if (this.updateDisponibility && this.updateDisponibility?.date.getTime() === dayDisponibility.date) {
          dayDisponibility.availabilities.push(this.updateDisponibility)
        }
        return {
          day: getDayDate(new Date(dayDisponibility.date)),
          availabilities: dayDisponibility.availabilities.sort((a: DoctorAvailability, b: DoctorAvailability) => a.startTime - b.startTime).filter(d => !this.isDisabled(d)).filter(d => this.privacy != null ? d.private == this.privacy : true),
        };
      }
      );
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      alert('Enable to load availabilities')
      this.onError.emit(new Error('Enable to load availabilities'));
    }
    );
  }

  onDisponibilitySelected(disponibility: DoctorAvailability): void {
    if (this.isDisabled(disponibility)) return;
    if (!this.isSelected(disponibility)) {
      this.disponibility = disponibility;
      this.selectedDay = disponibility.date;
      this.disponibilityChange.emit(disponibility);
    }

  }

  dateInWeek(day: number): Date {
    return new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.date.getDate() + day
    );
  }

  isSelected(disponibility: DoctorAvailability): boolean {
    return this.disponibility
      ? this.disponibility?.startTime === disponibility.startTime &&
      this.disponibility?.date.getTime() === disponibility.date.getTime()
      : false;
  }

  isDisabled(disponibility: DoctorAvailability): boolean {
    return this.strict ? (disponibility.startTime + disponibility.date.getTime()) < Date.now() : (disponibility.date.getTime() < getDayDate(Date.now()).getTime());
  }

  _dateChangeHandler(date: any): void {
    let datenew = new Date(date.target.value)
    if (datenew.getTime() >= new Date().getTime()) {
      if (this.date.getTime() != datenew.getTime()) {
        this.date = datenew;
        this.fillCalander();
      }
    }
    else this.onError.emit(Error("date invalide change date"))
  }
  __moveToNext(): void {
    this.date = new Date(this.date.getTime() + (this.navigationStep * MILLISECONDS_IN_DAY));
    this.fillCalander();
  }
  __moveToBack(): void {
    this.date = new Date(this.date.getTime() - (this.navigationStep * MILLISECONDS_IN_DAY));
    this.fillCalander();
  }

  __canMoveBack(): boolean {
    let backDate = new Date(this.date.getTime() - (this.navigationStep * MILLISECONDS_IN_DAY));
    backDate = new Date(backDate.getFullYear(), backDate.getMonth(), backDate.getDate());
    return backDate.getTime() >= new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()
  }
  __getLiteralDay(i: number): string {
    return this.dir == 'fr' ? LiteralDays[i] : LiteralDaysArab[i]
  }
}
