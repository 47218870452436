<div class="">
  <button class="btn button-more" (click)="open=!open"><i class="feather-more-horizontal"></i></button>
  <div class="body" [ngStyle]="{'position': 'absolute' ,'right':options.right,'top':options.top}" *ngIf="open==true">
    <ng-container *ngIf="listeQuestionsConsultationsFilter.length;else empty">
      <select class="select" name="pets" *ngFor="let fillings of listeQuestionsConsultationsFilter" multiple="multiple select example" (change)="label($event)" id="pet-select">
        <option *ngFor="let label of fillings.fillings" [value]="label.textContent">{{label.label}}</option>
      </select>
    </ng-container>
    <ng-template #empty>
      <select class="select-vide">
        <option [value]="">Ajouter nouvelle-{{filter}}</option>
      </select>
    </ng-template>
  </div>
</div>