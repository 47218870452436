<div class="card card-table">
  <div class="card-body">
    <table class="table table-hover table-center">
      <thead>
        <tr>
          <th style="min-width: 200px;">Vaccin</th>
          <th>Date</th>
          <th>Numéro de Lot</th>
          <th style="min-width: 80px;">Notes</th>
          <th style="min-width: 80px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let vaccinesConsultationItem of vaccinesConsultationItems;let i = index">
          <td style="width:41%">
            <app-vaccinaion-drop-down-select [(value)]="vaccinesConsultationItem.vaccin" [placeHolder]="'Vaccin is here'"
              (valueChange)="_change()"></app-vaccinaion-drop-down-select>
            <div *ngIf="validateConsultationItem(vaccinesConsultationItem).controlsValidation['vaccin'] == 'required'  && submitted">
              <span style="color: red;">Vaccin est obligatoire </span>
            </div>
          </td>
          <td>
            <input type="date" placeholder="Select Date" class="form-control datetimepicker"
              [value]="vaccinesConsultationItem.date_consultation_vaccin.getFullYear() + '-' + (vaccinesConsultationItem.date_consultation_vaccin.getMonth() +1 <=9? '0' +(vaccinesConsultationItem.date_consultation_vaccin.getMonth() +1): vaccinesConsultationItem.date_consultation_vaccin.getMonth() +1) +'-' +(vaccinesConsultationItem.date_consultation_vaccin.getDate() <=9? '0' +vaccinesConsultationItem.date_consultation_vaccin.getDate(): vaccinesConsultationItem.date_consultation_vaccin.getDate())"
              style="cursor: pointer;" />
          </td>
          <td>
            <textarea style="height: 45px;" [(ngModel)]="vaccinesConsultationItem.numLot_consultation_vaccin" (ngModelChange)="_change()" class="form-control"></textarea>
            <div *ngIf="validateConsultationItem(vaccinesConsultationItem).controlsValidation.numLot == 'required' && submitted">
              <span style="color: red;">Numéro de Lot est obligatoire </span>
            </div>
          </td>
          <td>
            <textarea style="height: 45px;" [(ngModel)]="vaccinesConsultationItem.note_consultation_vaccin" class="form-control"></textarea>
          </td>
          <td>
            <a class="btn bg-danger-light trash" (click)="deletePrescriptionItem(i)"><i class="feather-trash-2"></i></a>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <div class="add-more-item text-center">
              <a href="javascript:void(0);" (click)="addConsultationVaccinationItem()"><i class="feather-plus-circle"></i> Ajouter </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>