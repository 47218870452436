import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MedicFormComponent } from './medic-form.component/medic-form.component';
import { MedicSelectDropdownComponent } from './medic-select-dropdown.module/medic-select-dropdown.component/medic-select-dropdown.component';
import { MedicCheckboxComponentComponent } from './medic-checkbox.module/medic-checkbox-component/medic-checkbox-component.component';
import { AppCommonModule } from 'src/app/common/common.module';
import { MedicSelectDrawerComponent } from './medic-select-drawer.component/medic-select-drawer.component';

@NgModule({
    declarations: [
        MedicFormComponent,
        MedicSelectDropdownComponent,
        MedicCheckboxComponentComponent,
        MedicSelectDrawerComponent
    ],
    imports: [
        AppCommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        MedicFormComponent,
        MedicSelectDropdownComponent,
        MedicCheckboxComponentComponent,
        MedicSelectDrawerComponent

    ]
})
export class MedicFormModule { }
