import * as moment from "moment";
import {
  MILLISECONDS_IN_HOUR,
  MILLISECONDS_IN_MINUTE,
} from "src/app/utils/date.util";
import { Act } from "../act.entity/act";
import { DoctorAppointment } from "../appointment.entity/doctor-appointment.entity/doctor-appointment";
import { Biology } from "../biology.entity/biology";
import { ParametereSante } from "../parametre-sante.entity/paramtere-sante";
import { Radiology } from "../radiology.entity/radiology";
import { Vaccin } from "../vaccin.entity/vaccin";
import { ConsultationCnam } from "./consultation-cnam.entity/consultation-cnam";
import { ConsultationMotif } from "./consultation-motif.entity/consultation-motif";
import { ConsultationCnamAp3 } from "./consultation-cnamAp3.entity/consultation-cnam-ap3";
import { ConsultatioCnamAp2 } from "./consultation-cnamAp2.entity/consultatio-cnam-ap2";
import { PediatricVaccination } from "../pediatric-vaccination.entity/pediatric-vaccination";

export enum consultationEtat {
  UNKNOWN,
  A_DISTANCE,
  EN_PRESENTIEL,
}

export interface IRadiologyConsultationItem {
  id_consultation_radio: string;
  radio: Radiology;
  note_consultation_radio: string;
  date_consultation_radio: Date;
}

export class RadiologyConsultationItem implements IRadiologyConsultationItem {
  id_consultation_radio: string;
  note_consultation_radio: string;
  date_consultation_radio: Date;
  radio: Radiology;

  constructor(other?: Partial<IRadiologyConsultationItem>) {
    this.id_consultation_radio = other?.id_consultation_radio || "";
    this.note_consultation_radio = other?.note_consultation_radio || "";
    this.radio = other?.radio || new Radiology();
    this.date_consultation_radio = other?.date_consultation_radio || new Date();
  }

  static fromResponse(res: any): RadiologyConsultationItem {
    return new RadiologyConsultationItem({
      id_consultation_radio: res.id_consultation_radio,
      note_consultation_radio: res.note_consultation_radio,
      radio: Radiology.fromResponse(res.radio),
      date_consultation_radio: res.date_consultation_radio,
    });
  }
}

export interface IVaccinConsultationItem {
  id: string;
  date_consultation_vaccin: Date;
  note_consultation_vaccin: string;
  numLot_consultation_vaccin: string;
  vaccin: Vaccin;
}
export class VaccinConsultationItem implements IVaccinConsultationItem {
  id: string;
  date_consultation_vaccin: Date;
  note_consultation_vaccin: string;
  numLot_consultation_vaccin: string;
  vaccin: Vaccin;

  constructor(other?: Partial<IVaccinConsultationItem>) {
    this.id = other?.id || "";
    this.date_consultation_vaccin = other?.date_consultation_vaccin || new Date();
    this.note_consultation_vaccin = other?.note_consultation_vaccin || "";
    this.numLot_consultation_vaccin = other?.numLot_consultation_vaccin || "";
    this.vaccin = other?.vaccin || new Vaccin();
  }
}

export interface IConsultationPediatricVaccinationItem {
  id: string;
  date: Date;
  lotNumber: string;
  note: string;
  vaccination: Vaccin | null;
  vaccinated: boolean;
  setting: PediatricVaccination;
}
export class ConsultationPediatricVaccinationItem implements IConsultationPediatricVaccinationItem {
  id: string;
  date: Date;
  lotNumber: string;
  note: string;
  vaccination: Vaccin;
  vaccinated: boolean
  setting: PediatricVaccination;

  constructor(other?: Partial<IConsultationPediatricVaccinationItem>) {
    this.id = other?.id || "";
    this.date = other?.date || new Date();
    this.note = other?.note || "";
    this.lotNumber = other?.lotNumber || "";
    this.vaccination = other?.vaccination || new Vaccin();
    this.vaccinated = other?.vaccinated || false;
    this.setting = other?.setting || new PediatricVaccination();
  }

  static fromResponse(response: any): ConsultationPediatricVaccinationItem {
    return new ConsultationPediatricVaccinationItem({
      id: response.id,
      date: response.daten,
      note: response.note,
      lotNumber: response.lotNumber,
      vaccination: response.vaccination,
      vaccinated: response.vaccinated,
      setting: response.setting
    })
  }
}

export interface IBiologyConsultationItem {
  id: string;
  note: string;
  biology: Biology;
  date: Date;
  value: string;
}
export class BiologyConsultationItem implements IBiologyConsultationItem {
  id: string;
  note: string;
  date: Date;
  constructor(other?: Partial<IBiologyConsultationItem>) {
    this.id = other?.id || "";
    this.note = other?.note || "";
    this.biology = other?.biology || new Biology();
    this.value = other?.value || "";
    this.date = other?.date || new Date();
  }
  biology: Biology;
  value: string;

  static fromResponse(res: any): BiologyConsultationItem {
    return new BiologyConsultationItem({
      id: res.id,
      note: res.note,
      biology: Biology.fromResponse(res.bio),
      value: res.value,
      date: res.date,
    });
  }
}

export interface IActeConsultationItem {
  id_consultation_acte: string;
  montant_consultation_acte: Number;
  note_consultation_acte: string;
  date_consultation_acte: Date;
  act: Act;
}

export class ActeConsultationItem implements IActeConsultationItem {
  id_consultation_acte: string;
  montant_consultation_acte: Number;
  note_consultation_acte: string;
  date_consultation_acte: Date;
  act: Act;

  constructor(other?: Partial<IActeConsultationItem>) {
    this.date_consultation_acte = other?.date_consultation_acte || new Date();
    this.id_consultation_acte = other?.id_consultation_acte || "";
    this.montant_consultation_acte =
      other?.montant_consultation_acte || other?.act?.cnam_ACT_PRIX_U || 0;
    this.note_consultation_acte = other?.note_consultation_acte || "";
    this.act = other?.act || new Act();
  }
}

export interface IConsultationParametreSanteItem {
  id: string;
  parametre: ParametereSante;
  value: any;
  value_OD: any;
  value_OG: any;
  note: string;
}
export class DoctorParametereSante {
  id: string;
  target: ParametereSante;
  isPrescription: boolean;
  order: number;
  prescriptionName: string;
  activated: boolean;
  constructor(other?: Partial<DoctorParametereSante>) {
    this.target = other?.target || new ParametereSante();
    this.order = other?.order || 0;
    this.prescriptionName = other?.prescriptionName || "";
    this.isPrescription = other?.isPrescription || false;
    this.id = other?.id || "";
    this.activated = other?.activated || false;
  }

  static fromResponse(res: any): DoctorParametereSante {
    return new DoctorParametereSante({
      id: res.psmId,
      order: res.rangeIndex,
      isPrescription: res.pprescription,
      prescriptionName: res.pprescriptionnom,
      target: ParametereSante.fromResponse(res.param || new ParametereSante()),
      activated: res.state,
    });
  }
}

export class ConsultationParametreSanteItem implements IConsultationParametreSanteItem {
  id: string;
  parametre: ParametereSante;
  value: any;
  value_OD: any;
  value_OG: any;
  note: string;
  constructor(other?: Partial<IConsultationParametreSanteItem>) {
    this.id = other?.id || "";
    this.parametre = other?.parametre || new ParametereSante();
    this.value = other?.value || [null, 0, "", false][this.parametre.type];
    this.note = other?.note || "";
    other?.value_OD || [0, "", "", false][this.parametre.type];
    this.value_OG = other?.value_OG || [0, "", "", false][this.parametre.type];
    this.note = other?.note || "";
  }

  static fromResponse(res: any) {
    const parametereSante: ParametereSante = ParametereSante.fromResponse(
      res.paramSante || new ParametereSante()
    );
    return new ConsultationParametreSanteItem({
      id: res.paramSanteValueId,
      parametre: parametereSante,
      value: [
        "",
        Number(res.value),
        res.value,
        res.value == "" || res.value == "false"
          ? false
          : res.value === "true"
            ? true
            : "",
      ][parametereSante.type],
      note: res.note,
    });
  }
}
export class Consultation extends ConsultationCnam {
  id: string;
  date: Date;
  startTime: number;
  endTime: number;
  assurance: string;
  assuranceMatricule: string;
  motifTags: Array<ConsultationMotif>;
  consultationHistoireMaladie: string;
  consultationExamenClinique: string;
  diagnosticTags: Array<any>;
  diagnostic: string;
  consultationExamenDemande: string;
  montant: number;
  appointment: DoctorAppointment;
  montantRegle: string;
  note: string;
  parametresSante: Array<ConsultationParametreSanteItem>;
  radiologies: Array<IRadiologyConsultationItem>;
  biologies: Array<IBiologyConsultationItem>;
  vaccines: Array<IVaccinConsultationItem>;
  pediatricVaccinations: Array<ConsultationPediatricVaccinationItem>;
  actes: Array<IActeConsultationItem>;
  ap2: ConsultatioCnamAp2;
  ap3: ConsultationCnamAp3;

  get dateTime(): Date {
    return new Date(this.date.getTime() + this.startTime)
  }
  constructor(other?: Partial<Consultation>) {
    super(other);
    this.id = other?.id || "";
    this.date = other?.date || new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.startTime = other?.startTime || 0;
    this.endTime = other?.endTime || 0;
    // this.consultationHeureFin = other?.consultationHeureFin || new Date();
    this.assurance = other?.assurance || "";
    this.motifTags = other?.motifTags || Array<any>();
    this.assuranceMatricule = other?.assuranceMatricule || "";
    this.consultationHistoireMaladie = other?.consultationHistoireMaladie || "";
    this.consultationExamenClinique = other?.consultationExamenClinique || "";
    this.diagnosticTags = other?.diagnosticTags || Array<any>();
    this.diagnostic = other?.diagnostic || "";
    this.montant = other?.montant || 0;
    this.montantRegle = other?.montantRegle || "0";
    this.consultationExamenDemande = other?.consultationExamenDemande || "";
    this.note = other?.note || "";
    this.radiologies = other?.radiologies || [];
    this.biologies = other?.biologies || [];
    this.vaccines = other?.vaccines || [];
    this.pediatricVaccinations = other?.pediatricVaccinations || [];
    this.parametresSante = other?.parametresSante || [];
    this.appointment = other?.appointment || new DoctorAppointment();
    this.actes = other?.actes || [];
    this.ap2 = other?.ap2 || new ConsultatioCnamAp2({});
    this.ap3 = other?.ap3 || new ConsultationCnamAp3({});
  }
  public static fromResponse(res: any): Consultation {

    return new Consultation({
      appointment: DoctorAppointment.fromResponse(res.appointment),
      id: res.consultationId,
      date: new Date(res.date),
      startTime: res.startTime,
      endTime: res.endTime,// consultationHeureFin: new Date(res.consultationHeureFin),
      motifTags: res.motifTags,
      consultationHistoireMaladie: res.consultationHistoireMaladie,
      consultationExamenClinique: res.consultationExamenClinique,
      // diagnosticTags: res.diagnosticTags,
      diagnostic: res.diagnostic,
      consultationExamenDemande: res.consultationExamenDemande,
      assuranceMatricule: res.assuranceMatricule,
      assurance: res.assurance,
      montant: Number(res.montant),
      montantRegle: res.montantRegle,
      note: res.note,
      radiologies:
        Array.from(res.consultationRadiologies, (radio: any) => {
          return {
            id_consultation_radio: radio.id_consultation_radio,
            note_consultation_radio: radio.note_consultation_radio,
            date_consultation_radio: new Date(
              Number(String(radio.date_consultation_radio).split("/")[2]),
              Number(String(radio.date_consultation_radio).split("/")[1]) - 1,
              Number(String(radio.date_consultation_radio).split("/")[0])
            ),
            radio: radio.radio,
          };
        }) || "PAS RADIO",
      biologies: Array.from(res.consultationBiologies, (bio: any) => {
        return {
          id: bio.id_consultation_bio,
          date: new Date(
            Number(String(bio.date_consultation_bio).split("/")[2]),
            Number(String(bio.date_consultation_bio).split("/")[1]) - 1,
            Number(String(bio.date_consultation_bio).split("/")[0])
          ),
          note: bio.note_consultation_bio,
          value: bio.valeur_consultation_bio,
          biology: bio.bio,
        };
      }),
      vaccines: Array.from(res.consultationVaccins, (vaccination: any) => {
        return {
          id: vaccination.id_consultation_vaccin,
          date_consultation_vaccin: new Date(
            Number(String(vaccination.date_consultation_vaccin).split("/")[2]),
            Number(String(vaccination.date_consultation_vaccin).split("/")[1]) - 1,
            Number(String(vaccination.date_consultation_vaccin).split("/")[0])
          ),
          note_consultation_vaccin: vaccination.note_consultation_vaccin,
          numLot_consultation_vaccin: vaccination.numLot_consultation_vaccin,
          vaccin: vaccination.vaccin,
        };
      }),
      pediatricVaccinations: Array.from(res.pediatricVaccinations, e => ConsultationPediatricVaccinationItem.fromResponse(e)),
      actes: Array.from(res.consultationActes, (acte: any) => {
        return {
          id_consultation_acte: acte.id_consultation_acte,
          date_consultation_acte: new Date(
            Number(String(acte.date_consultation_acte).split("/")[2]),
            Number(String(acte.date_consultation_acte).split("/")[1]) - 1,
            Number(String(acte.date_consultation_acte).split("/")[0])
          ),
          montant_consultation_acte: acte.montant_consultation_acte,
          act: acte.act,
          note_consultation_acte: acte.note_consultation_acte,
        };
      }),
      parametresSante: Array.from(
        res.paramSanteValue || [],
        (parametreSanteValue: any) => {
          let parametreSante: ParametereSante = ParametereSante.fromResponse(
            parametreSanteValue.paramSante
          );
          return {
            id: parametreSanteValue.paramSanteValueId,
            value: [
              Number,
              String,
              String,
              (v: string) => v.toLowerCase() === "true",
              String,
            ][parametreSante.type](parametreSanteValue.value),
            value_OD: [
              Number,
              String,
              String,
              (v: string) => v.toLowerCase() === "true",
              String,
            ][parametreSante.type](parametreSanteValue.value),
            value_OG: [
              Number,
              String,
              String,
              (v: string) => v.toLowerCase() === "true",
              String,
            ][parametreSante.type](parametreSanteValue.value),

            parametre: parametreSante,
            note: parametreSanteValue.note,
          };
        }
      ),
      ap4Reeducation: res.ap4?.ap4Reeducation,
      ap4Reeducationfonctionnelle: res.ap4?.ap4Reeducationfonctionnelle,
      ap4Reeducationphysiologique: res.ap4?.ap4Reeducationphysiologique,
      ap4Orthophonie: res.ap4?.ap4Orthophonie,
      ap4Orthoptie: res.ap4?.ap4Orthoptie,
      ap4Duree: res.ap4?.ap4Duree,
      ap4DebutReeducation: res.ap4?.ap4DebutReeducation,
      ap4NbSeanceSemaine: res.ap4?.ap4NbSeanceSemaine,
      ap4NbSeanceTotal: res.ap4?.ap4NbSeanceTotal,
      ap4DemandeInitial: res.ap4?.ap4DemandeInitial,
      ap4DemandeRenouvlable: res.ap4?.ap4DemandeRenouvlable,
      ap4Question1: res.ap4?.ap4Question1,
      ap4APCI: res.ap4?.ap4APCI,
      ap4AccidentTravail: res.ap4?.ap4AccidentTravail,
      ap4AccidentCirculation: res.ap4?.ap4AccidentCirculation,
      ap4Autres: res.ap4?.ap4Autres,
      ap4Question2: res.ap4?.ap4Question2,
      ap4Question3: res.ap4?.ap4Question3,
      ap4Question4: res.ap4?.ap4Question4,
      ap4Question5: res.ap4?.ap4Question5,
      ap4AutersInfo: res.ap4?.ap4AutersInfo,
      ap2: res.ap2 ? ConsultatioCnamAp2.fromResponse(res.ap2) : new ConsultatioCnamAp2,
      ap3: res.ap3 ? ConsultationCnamAp3.fromResponse(res.ap3) : undefined,
    });
  }

  get requestPayload(): any {
    const hours = Math.floor(Date.now() / MILLISECONDS_IN_HOUR);
    const minutes = Math.floor((Date.now() % MILLISECONDS_IN_HOUR) / MILLISECONDS_IN_MINUTE);
    return {
      date: new Date(this.date).getTime(),
      starTime: this.startTime,
      endTime: this.endTime,
      assurance: this.assurance,
      assuranceMatricule: this.assuranceMatricule,
      diagnostic: this.diagnostic,
      consultationExamenClinique: this.consultationExamenClinique,
      consultationExamenDemande: this.consultationExamenDemande,
      consultationHeureDebut: moment(new Date()).format('HH:mm'),
      consultationHistoireMaladie: this.consultationHistoireMaladie,
      motifTags: this.motifTags,
      montant: this.montant,
      montantRegle: this.montantRegle,
      note: this.note,
      consultationBiologies: Array.from(this.biologies, (bio) => {
        return {
          date_consultation_bio: moment(bio.date).format("DD/MM/YYYY"),
          note_consultation_bio: bio.note,
          valeur_consultation_bio: bio.value,
          bio: {
            id_BIO: bio.biology.id_BIO,
          },
        };
      }),
      consultationVaccins: Array.from(this.vaccines, (vaccin) => {
        return {
          date_consultation_vaccin: moment(
            vaccin.date_consultation_vaccin
          ).format("DD/MM/YYYY"),
          note_consultation_vaccin: vaccin.note_consultation_vaccin,
          numLot_consultation_vaccin: vaccin.numLot_consultation_vaccin,
          vaccin: {
            id_Vaccin: vaccin.vaccin.id_Vaccin,
          },
        };
      }),
      pediatricVaccinations: Array.from(this.pediatricVaccinations, pediatricVaccination => {
        return {
          date: pediatricVaccination.date.getTime(),
          lotNumber: pediatricVaccination.lotNumber,
          note: pediatricVaccination.note,
          vaccination: (pediatricVaccination.vaccination && pediatricVaccination.vaccination?.id_Vaccin.length > 0) ? { id_Vaccin: pediatricVaccination.vaccination.id_Vaccin } : undefined,
          vaccinated: pediatricVaccination.vaccinated,
          setting: {
            id: pediatricVaccination.setting.id
          }
        }
      }),
      consultationActes: Array.from(this.actes.filter(e => e.act && e.act.id_Acte != undefined && e.act.id_Acte.length > 0), (acte) => {
        return {
          date_consultation_acte: moment(acte.date_consultation_acte).format(
            "DD/MM/YYYY"
          ),
          montant_consultation_acte: acte.montant_consultation_acte,
          act: {
            id_Acte: acte.act.id_Acte,
          },
          note_consultation_acte: acte.note_consultation_acte,
        };
      }),
      consultationRadiologies: Array.from(this.radiologies, (radio) => {
        return {
          note_consultation_radio: radio.note_consultation_radio,
          date_consultation_radio: moment(radio.date_consultation_radio).format(
            "DD/MM/YYYY"
          ),
          radio: { id_RADIO: radio.radio.id_RADIO },
        };
      }),
      paramSanteValue: Array.from(this.parametresSante, (item) => {
        return {
          paramSante: {
            paramId: item.parametre.id,
          },

          value:
            item.parametre.type === 1
              ? moment(item.value).format("YYYY-MM-DD")
              : item.value,
          note: item.note,
        };
      }),
      ap4: {
        ap4Reeducation: this.ap4Reeducation,
        ap4Reeducationfonctionnelle: this.ap4Reeducationfonctionnelle,
        ap4Reeducationphysiologique: this.ap4Reeducationphysiologique,
        ap4Orthophonie: this.ap4Orthophonie,
        ap4Orthoptie: this.ap4Orthoptie,
        ap4Duree: this.ap4Duree,
        ap4DebutReeducation: new Date(this.ap4DebutReeducation).getTime(),
        ap4NbSeanceSemaine: this.ap4NbSeanceSemaine,
        ap4NbSeanceTotal: this.ap4NbSeanceTotal,
        ap4DemandeInitial: this.ap4DemandeInitial,
        ap4DemandeRenouvlable: this.ap4DemandeRenouvlable,
        ap4Question1: this.ap4Question1,
        ap4APCI: this.ap4APCI,
        ap4AccidentTravail: this.ap4AccidentTravail,
        ap4AccidentCirculation: this.ap4AccidentCirculation,
        ap4Autres: this.ap4Autres,
        ap4Question2: this.ap4Question2,
        ap4Question3: this.ap4Question3,
        ap4Question4: this.ap4Question4,
        ap4Question5: this.ap4Question5,
        ap4AutersInfo: this.ap4AutersInfo
      },
      ap2: {
        ap2ScannerRad1: this.ap2.ap2ScannerRad1,
        ap2ScannerRad2: this.ap2.ap2ScannerRad2,
        ap2ScannerRad3: this.ap2.ap2ScannerRad3,
        ap2ScannerRad4: this.ap2.ap2ScannerRad4,
        ap2ScannerRad5: this.ap2.ap2ScannerRad5,

        ap2ScannerRad6: this.ap2.ap2ScannerRad6,
        ap2ScannerRad7: this.ap2.ap2ScannerRad7,
        ap2ScannerRad8: this.ap2.ap2ScannerRad8,
        ap2ScannerRad9: this.ap2.ap2ScannerRad9,
        ap2ScannerRad10: this.ap2.ap2ScannerRad10,
        ap2OtherScannerRegion: this.ap2.ap2OtherScannerRegion,
        ap2OtherScannerCode: this.ap2.ap2OtherScannerCode,
        irmRegion: this.ap2.irmRegion,
        seintigraphie: this.ap2.seintigraphie,
        otherAct: this.ap2.otherAct,
        ap2APCI: this.ap2.ap2APCI,
        ap2APCICode: this.ap2.ap2APCICode,
        ap2Question1: this.ap2.ap2Question1,
        ap2Question2: this.ap2.ap2Question2,
        ap2Question3: this.ap2.ap2Question3,
        ap2ExamNature1: this.ap2.ap2ExamNature1,
        ap2ExamNature2: this.ap2.ap2ExamNature2,
        ap2ExamNature3: this.ap2.ap2ExamNature3,
        ap2ExamDate1: moment(this.ap2.ap2ExamDate1).format("YYYY/MM/DD"),
        ap2ExamDate2: moment(this.ap2.ap2ExamDate2).format("YYYY/MM/DD"),
        ap2ExamDate3: moment(this.ap2.ap2ExamDate3).format("YYYY/MM/DD"),
      },
      ap3: {
        ap3ListedDevice1Code: this.ap3.ap3ListedDevice1Code,
        ap3ListedDevice1Label: this.ap3.ap3ListedDevice1Label,
        ap3ListedDevice1Number: this.ap3.ap3ListedDevice1Number,

        ap3ListedDevice2Code: this.ap3.ap3ListedDevice2Code,
        ap3ListedDevice2Label: this.ap3.ap3ListedDevice2Label,
        ap3ListedDevice2Number: this.ap3.ap3ListedDevice2Number,

        ap3ListedDevice3Code: this.ap3.ap3ListedDevice3Code,
        ap3ListedDevice3Label: this.ap3.ap3ListedDevice3Label,
        ap3ListedDevice3Number: this.ap3.ap3ListedDevice3Number,

        ap3ListedDevice4Code: this.ap3.ap3ListedDevice4Code,
        ap3ListedDevice4Label: this.ap3.ap3ListedDevice4Label,
        ap3ListedDevice4Number: this.ap3.ap3ListedDevice4Number,

        ap3ListedDevice5Code: this.ap3.ap3ListedDevice5Code,
        ap3ListedDevice5Label: this.ap3.ap3ListedDevice5Label,
        ap3ListedDevice5Number: this.ap3.ap3ListedDevice5Number,

        ap3ListedDevice6Code: this.ap3.ap3ListedDevice6Code,
        ap3ListedDevice6Label: this.ap3.ap3ListedDevice6Label,
        ap3ListedDevice6Number: this.ap3.ap3ListedDevice6Number,

        ap3ListedDevice7Code: this.ap3.ap3ListedDevice7Code,
        ap3ListedDevice7Label: this.ap3.ap3ListedDevice7Label,
        ap3ListedDevice7Number: this.ap3.ap3ListedDevice7Number,

        ap3ListedDevice8Code: this.ap3.ap3ListedDevice8Code,
        ap3ListedDevice8Label: this.ap3.ap3ListedDevice8Label,
        ap3ListedDevice8Number: this.ap3.ap3ListedDevice8Number,

        ap3ListedDevice9Code: this.ap3.ap3ListedDevice9Code,
        ap3ListedDevice9Label: this.ap3.ap3ListedDevice9Label,
        ap3ListedDevice9Number: this.ap3.ap3ListedDevice9Number,

        ap3ListedDevice10Code: this.ap3.ap3ListedDevice10Code,
        ap3ListedDevice10Label: this.ap3.ap3ListedDevice10Label,
        ap3ListedDevice10Number: this.ap3.ap3ListedDevice10Number,

        ap3ListedDevice11Code: this.ap3.ap3ListedDevice11Code,
        ap3ListedDevice11Label: this.ap3.ap3ListedDevice11Label,
        ap3ListedDevice11Number: this.ap3.ap3ListedDevice11Number,

        ap3Incident: this.ap3.ap3Incident,
        ap3Professional: this.ap3.ap3Professional,
        ap3DPCI: this.ap3.ap3DPCI,
        ap3OtherNature: this.ap3.ap3OtherNature,
        ap3ClinicAndParaClinicData: this.ap3.ap3ClinicAndParaClinicData,

        ap3FirstAttribution: this.ap3.ap3FirstAttribution,
        ap3TemporalDevice: this.ap3.ap3TemporalDevice,
        ap3Renewal: this.ap3.ap3Renewal,
      },
    };
  }

  get calculerinitIMC() {

    const tailleParam = this.parametresSante.find(
      (param) => param.parametre.label === "Taille"
    );
    const poidsParam = this.parametresSante.find(
      (param) => param.parametre.label === "Poids"
    );

    if (tailleParam && poidsParam) {
      const taillev = tailleParam.value;
      const poidsv = poidsParam.value;

      var tailleEnMetres = taillev / 100;
      var poids = poidsv;
      var imc;
      imc = poids / (tailleEnMetres * tailleEnMetres);

      if (Number.isNaN(imc)) {
        imc = 0;
      }
    }

    return (imc = Math.round(imc * 100) / 100);
  }
}

