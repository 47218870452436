import { Pipe, PipeTransform } from '@angular/core';

declare type FNFilterPredict = (item: Object) => boolean;
export interface ListFilter {
  field: string;
  predicts: Array<any | FNFilterPredict>
}

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform<T extends Object>(list: Array<T>, ...filters: Array<ListFilter>): Array<T> {
    return filters && filters.length ? list.filter(item =>
      filters.some(filter => filter.predicts.some(predict =>
        typeof (predict) === 'function' ? predict(item) : (item.hasOwnProperty(filter.field) ? predict === item[filter.field] : false)
      ))) : list;
  }

}
