<div class="table-responsive" *ngIf="language=='fr'else arabe">
  <table class="table table-hover table-center mb-0">
    <thead>
      <tr>
        <th>Nom</th>
        <th>Date</th>
        <th>Télèphone</th>
        <th>Description</th>
        <th>Type</th>
        <th>Statut</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="patientAppointments.length;else empty">
        <tr
          *ngFor="let appointment of patientAppointments | paginate: { itemsPerPage: perPage, currentPage: currentPage }">
          <td>
            <h2 class="table-avatar">
              <a class="avatar avatar-sm me-2">
                <img class="avatar-img rounded-circle" [src]="appointment.doctor | avatarSrc"
                  [alt]="appointment.doctor.getFullName()" image />
              </a>
              <a>{{ appointment.doctor.getFullName()}}<span>{{appointment.doctor.speciality.label}}</span></a>
            </h2>
          </td>
          <td>{{ appointment.date | date:'dd/MM/yyyy'}}<span class="d-block text-info apt-time">{{appointment.startTime
              |time}}</span></td>
          <td>{{ appointment.doctor.mobilePhone1 }}</td>
          <td>{{ appointment.motif }}</td>
          <td>
            {{appointment.location? "A Distance": "Présentielle"}}
          </td>
          <td>
            <a *ngIf="appointment.status == 0" href="javascript:void(0);" class="btn btn-sm bg-warning-light">
              <i class="feather-clock"></i>
              En Attente
            </a>
            <a *ngIf="appointment.status == 1" href="javascript:void(0);" class="btn btn-sm bg-success-light">
              <i class="feather-check-circle"></i>
              Confirmé
            </a>
            <a *ngIf="appointment.status == 2" href="javascript:void(0);" class="btn btn-sm bg-danger-light">
              <i class="feather-x-circle"></i>
              Annulé
            </a>
            <a *ngIf="appointment.status == 3" href="javascript:void(0);" class="btn btn-sm bg-info-light">
              <i class="feather-check"></i>
              Cloturé
            </a>
          </td>
          <td class="text-start">
            <div class="table-action">
              <a *ngIf="canEdit(appointment)" title="Mise à Jour" class="btn btn-sm bg-warning-light"
                [ngClass]="{'disabled':isEditActionDisabled(appointment)}" (click)="onEditRequest.emit(appointment)">
                <i class="feather-edit"></i>
              </a>
              <a *ngIf="canPay(appointment)" title="Paiement" class="btn btn-sm bg-primary-light"
                [ngClass]="{'disabled':isPayActionDisabled(appointment)}" (click)="onBillingRequest.emit(appointment)">
                <i class="feather-credit-card"></i>
              </a>
              <a *ngIf="canViewAttachments(appointment)" title="Attacher un document medical"
                [ngClass]="{'disabled':isViewAttachmentsActionDisabled(appointment)}"
                (click)="onViewAttachmentsRequest.emit(appointment)" class="btn btn-sm bg-primary-light">
                <i class="feather-file-plus"></i>
              </a>
              <a *ngIf="canOpenTeleconsultation(appointment)" title="Tele-consultation"
                (click)="onViewTeleConsultationRequest.emit(appointment)" class="btn btn-sm bg-success-light"
                [ngClass]="{'disabled':isTeleconsultationDisabled(appointment)}">
                <i class="feather-play-circle"></i>
              </a>

              <a *ngIf="canCancelAppointment(appointment)"
                title="Annuler RDV" class="btn btn-sm bg-danger-light" (click)="onCancel.emit(appointment)">
                <i class="feather-x"></i>
              </a>
              <!--
              <span *ngIf="appointment.location == 1 && getAppointmentRemainingTime(appointment)>0">
                {{getAppointmentRemainingTime(appointment)}}
              </span>
              <a title="View details" class="btn btn-sm bg-warning-light">
                <i class="feather-eye"></i>
              </a>-->
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-template #empty>
        <tr>
          <td colspan="8" style="text-align: center;">
            <span style="text-align: center; width: 100%;">
              Pas de rendez-vous !
            </span>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <app-pagination [pageRequest]="pageRequest" (content)="patientAppointments = $event"></app-pagination>
</div>
<ng-template #arabe>
  <div class="table-responsive">
    <table class="table table-hover table-center mb-0">
      <thead>
        <tr>
          <th>اسم</th>
          <th>تاريخ</th>
          <th>هاتف</th>
          <th>الأستشارة</th>
          <th>النوعية</th>
          <th>الحالة</th>
          <th>أجراءات</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="patientAppointments.length;else empty">
          <tr
            *ngFor="let appointment of patientAppointments | paginate: { itemsPerPage: perPage, currentPage: currentPage }">
            <td>
              <h2 class="table-avatar">
                <a class="avatar avatar-sm me-2">
                  <img class="avatar-img rounded-circle" [src]="appointment.doctor | avatarSrc"
                    [alt]="appointment.doctor.getFullName(true)" image />
                </a>
                <a>{{ appointment.doctor.arabicProfessionalCivility}} {{ appointment.doctor.arabicLastName}} {{
                  appointment.doctor.arabicFirstName}}<span>{{appointment.doctor.speciality.arabicName}}</span></a>
              </h2>
            </td>
            <td>{{ appointment.date | date:'dd/MM/yyyy'}}<span
                class="d-block text-info apt-time">{{appointment.startTime |time}}</span></td>
            <td>{{ appointment.doctor.mobilePhone1 }}</td>
            <td>{{ appointment.motif }}</td>
            <td>
              {{appointment.location? " عن بعد": " في المكتب "}}
            </td>
            <td>
              <a *ngIf="appointment.status == 0" href="javascript:void(0);" class="btn btn-sm bg-warning-light">
                <i class="feather-clock"></i>
                في الانتظار
              </a>
              <a *ngIf="appointment.status == 1" href="javascript:void(0);" class="btn btn-sm bg-success-light">
                <i class="feather-check-circle"></i>
                متأكد
              </a>
              <a *ngIf="appointment.status == 2" href="javascript:void(0);" class="btn btn-sm bg-danger-light">
                <i class="feather-x-circle"></i>
                الموعد ملغى
              </a>
              <a *ngIf="appointment.status == 3" href="javascript:void(0);" class="btn btn-sm bg-info-light">
                <i class="feather-check"></i>
                موعد مغلق
              </a>
            </td>
            <td class="text-end">
              <div class="table-action">
                <a *ngIf="((appointment.status == 0  && !(appointment.hasConsultation || appointment.hasPrescription))) && actions.edit"
                  title="Mise à Jour" class="btn btn-sm bg-warning-light" (click)="onEditRequest.emit(appointment)">
                  <i class="feather-edit"></i>
                </a>
                <a *ngIf="appointment.status == 1 && actions.attachmentsView" title="Attacher un document medical"
                  (click)="onViewAttachmentsRequest.emit(appointment)" class="btn btn-sm bg-primary-light">
                  <i class="feather-file-plus"></i>
                </a>
                <a *ngIf="canOpenTeleconsultation(appointment)" title="Tele-consultation"
                  (click)="onViewTeleConsultationRequest.emit(appointment)" class="btn btn-sm bg-success-light">
                  <i class="feather-play-circle"></i>
                </a>
                <a title="View details" class="btn btn-sm bg-warning-light">
                  <i class="feather-eye"></i>
                </a>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-template #empty>
          <tr>
            <td colspan="8" style="text-align: center;">
              <span style="text-align: center; width: 100%;">
                لبس هناك موعد
              </span>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <app-pagination [pageRequest]="pageRequest" [language]="language"
      (content)="patientAppointments = $event"></app-pagination>
  </div>
</ng-template>
