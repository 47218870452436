import { Pipe, PipeTransform } from '@angular/core';
import { Row, Sort } from '../../interfaces';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(rows: Array<Row>, sort: Sort): any {
    return sort.sorter ? rows.sort((a, b) => {
      return this.compare(a.cells.find(c => c.header == sort.sorter), b.cells.find(c => c.header == sort.sorter), sort.order == "asc" ? '<' : '>');
    }) : rows;
  }

  compare(a: any, b: any, operator: '>' | '<'): number {
    if (!a || !b) return Number.MIN_VALUE;
    if (!isNaN(a)) a = parseInt(String(a));
    if (!isNaN(b)) b = parseInt(String(b));

    if (!isNaN(a) && !isNaN(b)) return a - b;
    return (operator == '>' ? a > b : a < b) ? 1 : -1;
  }

}
