<div class="card p-3" body>
    <div class="row">

    </div>
    <div class="row mt-3">
        <div class="col-lg-6 col-sm-12">
            <label class="mt-1">Nom Radio</label>
            <input type="text" class="form-control" [(ngModel)]="radiology.nom_Radio" (ngModel)="_change()" />
            <span *ngIf="isSubmitted && radiology.nom_Radio.length == 0" style="color:red">Nom Radio  est obligatoire</span>
        </div>
        <div class="col-lg-6 col-sm-12">
            <label class="mt-1">Type</label>
            <select name="parametresante" class="form-control" [(ngModel)]="radiology.type_Radio" (ngModel)="_change()">
                <option value="0">Selectionner type radio</option>
                <option value="1"> scanner</option>
                <option value="2"> radio</option>
                <option value="3"> IRM</option>
                <option value="4"> Endoscopique </option>
                <option value="5"> Scintigraphie </option>
            </select>
            <span *ngIf="isSubmitted && !radiology.type_Radio" style="color:red">Nom Radio est obligatoire</span>
        </div>
    </div>
</div>