<app-modal [title]="'Ajouter Nouveau Model'" (closed)="reject()" #modal>
    <div class="submit-section text-center row mt-5" [innerHtml]="subject"></div>
    <div>
        <input type="text" class="form-control" [(ngModel)]="value" (ngModelChange)="change.emit(value)">
        <div class="submit-section text-center row mt-5" style="width: 25%;margin-left: 35%;">
            <div class="col-lg-6">
                <button class="btn btn-primary submit-btn" (click)="accept()">OK</button>
            </div>
            <div class="col-lg-6">
                <button class="btn btn-danger submit-btn" (click)="reject()">Annuler</button>
            </div>
        </div>
    </div>
</app-modal>