<div class="table-responsive">
    <table class="table table-hover table-center mb-0">
        <thead>
            <tr>
                <th>Nom</th>
                <th>Date</th>
                <th>Télèphone</th>
                <th>Description</th>
                <th>Type</th>
                <th>Statut</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="doctorAppointments.length;else empty">
                <tr *ngFor="let appointment of doctorAppointments | paginate: { itemsPerPage: perPage, currentPage: currentPage }">
                    <td>
                        <h2 class="table-avatar">
                            <a [routerLink]="['../','patients',appointment.patient.id]" class="avatar avatar-sm me-2">
                                <img class="avatar-img rounded-circle" [src]="appointment.patient | avatarSrc" alt="User Image" image />
                            </a>
                            <a [routerLink]="['../','patients',appointment.patient.id]">{{
                                appointment.patient.getFullName(true)}}<span>{{appointment.patient.birthDate| date:
                                    "dd/MM/yyyy"}}</span></a>
                        </h2>
                    </td>
                    <td>
                        {{ appointment.date | date:'dd/MM/yyyy'}}<span class="d-block text-info apt-time">{{appointment.startTime |time}}</span>
                    </td>
                    <td>{{ appointment.patient.mobilePhone1 }}</td>
                    <td>{{ appointment.motif }}</td>
                    <td>
                        {{appointment.location? "A Distance": "Présentielle"}}
                    </td>
                    <td>
                        <a *ngIf="appointment.status == 0" href="javascript:void(0);" class="btn btn-sm bg-warning-light">
                            <i class="feather-clock"></i>
                            En Attente
                        </a>
                        <a *ngIf="appointment.status == 1" href="javascript:void(0);" class="btn btn-sm bg-success-light">
                            <i class="feather-check-circle"></i>
                            Confirmé
                        </a>
                        <a *ngIf="appointment.status == 2" href="javascript:void(0);" class="btn btn-sm bg-danger-light">
                            <i class="feather-x-circle"></i>
                            Annulé
                        </a>
                        <a *ngIf="appointment.status == 3" href="javascript:void(0);" class="btn btn-sm bg-info-light">
                            <i class="feather-check"></i>
                            Cloturé
                        </a>
                    </td>
                    <td class="text-start">
                        <div class="table-action">
                            <ng-container *ngIf="!appointment.isOld else oldAppointment">
                                <a *ngIf="appointment.status == 1  && !(appointment.hasConsultation || appointment.hasPrescription)" title="Mise à Jour"
                                    class="btn btn-sm bg-warning-light" [routerLink]="['../','patients',appointment.patient.id,'appointments',appointment.id,'update']">
                                    <i class="feather-edit"></i>
                                </a>
                                <a title="Paiement" class="btn btn-sm bg-primary-light" *ngIf="appointment.status == 1">
                                    <i class="feather-credit-card"></i>
                                </a>
                                <a title="Annuler" class="btn btn-sm bg-danger-light" *ngIf="
              appointment.status == 0 || appointment.status == 1  && !(appointment.hasConsultation || appointment.hasPrescription)
            " (click)="rejectAppointment(appointment)">
                                    <i class="feather-x"></i>
                                </a>
                                <a title="{{appointment.hasConsultation?'List consultations':'Ajout consultation'}}" *ngIf="appointment.status == 1"
                                    class="btn btn-sm bg-info-light"
                                    [routerLink]="appointment.hasConsultation?['../','patients',appointment.patient.id,'consultations']:['../','patients',appointment.patient.id,'appointments',appointment.id,'assign-consultation']">
                                    <i class="{{appointment.hasConsultation?'feather-eye':'feather-plus-circle'}}"></i>
                                </a>
                                <a title="{{appointment.hasPrescription?'List prescriptions':'Ajout prescription'}}" *ngIf="appointment.status == 1"
                                    class="btn btn-sm bg-success-light"
                                    [routerLink]="appointment.hasPrescription?['../','patients',appointment.id,appointment.patient.id,'prescriptions']:['../','patients',appointment.patient.id,'appointments',appointment.id,'assign-prescription']">
                                    <i class="{{appointment.hasPrescription?'feather-eye':'feather-clipboard'}}"></i>
                                </a>
                                <a *ngIf="appointment.status == 0" title="Confirmé" (click)="acceptAppointment(appointment)" class="btn btn-sm bg-success-light">
                                    <i class="feather-check-circle"></i>
                                </a>

                                <a title="Attacher un document medical" [routerLink]="['appointments',appointment.id,'attachment']" class="btn btn-sm bg-primary-light"
                                    *ngIf="appointment.status == 1">
                                    <i class="feather-file-plus"></i>
                                </a>
                            </ng-container>
                            <ng-template #oldAppointment>
                                <a title="View details" class="btn btn-sm bg-warning-light">
                                    <i class="feather-eye"></i>
                                </a>
                            </ng-template>
                        </div>
                    </td>
                </tr>
            </ng-container>
            <ng-template #empty>
                <tr>
                    <td colspan="8" style="text-align: center;">
                        <span style="text-align: center; width: 100%;">
                            Pas de rendez-vous !
                        </span>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <pagination-controls *ngIf="doctorAppointments.length >= perPage" (pageChange)="currentPage = $event"></pagination-controls>
</div>