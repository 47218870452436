import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "../../core/services/api.service/api.service";
import { ApiResponse } from "../../core/entities/api-response.entity/api-response";
import { Act, DoctorAct } from "src/app/entities/act.entity/act";
import { PageRequest } from "src/app/common/modules/data-table.module/interfaces";

@Injectable({
  providedIn: "root",
})
export class DoctorActsService extends ApiService {
  delete(doctorActId: string): Observable<DoctorAct> {
    return this.http
      .delete<ApiResponse>(
        `${environment.API_URL}/doctor/acts/${doctorActId}`,
        { responseType: "json" }
      )
      .pipe(this.responsePipe);
  }

  globals(pageRequest?: PageRequest): Observable<Array<Act>> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/global/acts`, { responseType: "json", params: params }).pipe(this.responsePipe);
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/global/acts/all`, { responseType: "json", }).pipe(this.responsePipe);
  }

  globalSearch(query: string, pageRequest?: PageRequest): Observable<Array<Act>> {
    let params = new HttpParams();
    if (pageRequest) {
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/global/acts/search`, { params: params.append("q", query), responseType: "json" }).pipe<Array<Act>>(this.responsePipe);
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/global/acts/all/search`, { params: params.append("q", query), responseType: "json" }).pipe<Array<Act>>(this.responsePipe);
  }
  update(id: string, updates: DoctorAct): Observable<any> {
    return this.http.put(
      `${environment.API_URL}/doctor/acts/${id}`,
      {
        act: {
          confirmed: true,
          famille_Acte: updates.actes.famille_Acte,
          desc_Acte: updates.actes.desc_Acte,
          priseenCharge_Acte: updates.actes.priseenCharge_Acte,
          act_LIB_USE: updates.actes.act_LIB_USE,
          cnam_ACT_AHJ: updates.actes.cnam_ACT_AHJ,
          cnam_ACT_APR: updates.actes.cnam_ACT_APR,
          cnam_ACT_COD: updates.actes.cnam_ACT_COD,
          cnam_ACT_DES: updates.actes.cnam_ACT_DES,
          cnam_ACT_PRIX_U: updates.actes.cnam_ACT_PRIX_U,
          cnam_CHP_DES: updates.actes.cnam_CHP_DES,
          cnam_COT_ACTM: updates.actes.cnam_COT_ACTM,
          cnam_LET_COD: updates.actes.cnam_LET_COD,
          cnam_NAT_COD: updates.actes.cnam_NAT_COD,
          cnam_NAT_DES: updates.actes.cnam_NAT_DES,
          cnam_REM_COD: updates.actes.cnam_REM_COD,
          cnam_REM_DES: updates.actes.cnam_REM_DES,
          cnam_SAR_COD: updates.actes.cnam_SAR_COD,
          cnam_SAR_DES: updates.actes.cnam_SAR_DES,
          cnam_SRM_COD: updates.actes.cnam_SRM_COD,
          cnam_SRM_DES: updates.actes.cnam_SRM_DES,
          cnam_TIT_COD: updates.actes.cnam_TIT_COD,
          cnam_TIT_DES: updates.actes.cnam_TIT_DES,
          cnam_ART_COD: updates.actes.cnam_ART_COD,
          cnam_ART_DES: updates.actes.cnam_ART_DES,
          cnam_CHP_COD: updates.actes.cnam_CHP_COD
        },
        price: updates.montant,
        isActivated: true,
      },
      { responseType: "json" }
    );
  }

  add(doctorAct: DoctorAct, actId?: string): Observable<DoctorAct> {
    if (actId) {
      return this.http
        .post<ApiResponse>(`${environment.API_URL}/doctor/acts/${actId}`, {
          price: Number(doctorAct.montant),
        })
        .pipe<DoctorAct>(this.responsePipe);
    } else
      return this.http
        .post<ApiResponse>(
          `${environment.API_URL}/doctor/acts`,
          {
            act: {
              confirmed: true,
              famille_Acte: doctorAct.actes.famille_Acte,
              desc_Acte: doctorAct.actes.desc_Acte,
              priseenCharge_Acte: doctorAct.actes.priseenCharge_Acte,
              act_LIB_USE: doctorAct.actes.act_LIB_USE,
              cnam_ACT_AHJ: doctorAct.actes.cnam_ACT_AHJ,
              cnam_ACT_APR: doctorAct.actes.cnam_ACT_APR,
              cnam_ACT_COD: doctorAct.actes.cnam_ACT_COD,
              cnam_ACT_DES: doctorAct.actes.cnam_ACT_DES,
              cnam_ACT_PRIX_U: doctorAct.actes.cnam_ACT_PRIX_U,
              cnam_CHP_DES: doctorAct.actes.cnam_CHP_DES,
              cnam_COT_ACTM: doctorAct.actes.cnam_COT_ACTM,
              cnam_LET_COD: doctorAct.actes.cnam_LET_COD,
              cnam_NAT_COD: doctorAct.actes.cnam_NAT_COD,
              cnam_NAT_DES: doctorAct.actes.cnam_NAT_DES,
              cnam_REM_COD: doctorAct.actes.cnam_REM_COD,

              cnam_REM_DES: doctorAct.actes.cnam_REM_DES,
              cnam_SAR_COD: doctorAct.actes.cnam_SAR_COD,
              cnam_SAR_DES: doctorAct.actes.cnam_SAR_DES,
              cnam_SRM_COD: doctorAct.actes.cnam_SRM_COD,
              cnam_SRM_DES: doctorAct.actes.cnam_SRM_DES,

              cnam_TIT_COD: doctorAct.actes.cnam_TIT_COD,
              cnam_TIT_DES: doctorAct.actes.cnam_TIT_DES,
              cnam_ART_COD: doctorAct.actes.cnam_ART_COD,
              cnam_ART_DES: doctorAct.actes.cnam_ART_DES,
              cnam_CHP_COD: doctorAct.actes.cnam_CHP_COD
            },
            price: doctorAct.montant,
            isActivated: true,
          },
          { responseType: "json" }
        )
        .pipe(this.responsePipe);
  }

  all(pageRequest?: PageRequest): Observable<any> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/acts`, { responseType: "json" }).pipe(this.responsePipe);
    }
    else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/acts/all`, { responseType: "json" }).pipe(this.responsePipe);
  }

  switchState(doctorActId: string): Observable<any> {
    return this.http.patch(
      `${environment.API_URL}/doctor/acts/${doctorActId}`,
      { responseType: "json" }
    );
  }

  one(actId: string): Observable<DoctorAct> {
    return this.http
      .get<ApiResponse>(`${environment.API_URL}/doctor/acts/${actId}`, {
        responseType: "json",
      })
      .pipe(this.responsePipe);
  }
}

/*


  all(): Observable<Array<Act>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/acts`, { responseType: 'json' }).pipe(this.responsePipe)
  }
  allDoctor(): Observable<Array<any>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/acts`, { responseType: 'json' }).pipe(this.responsePipe)
  }
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  add(doctorAct: DoctorAct): Observable<Act> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/acts`, doctorAct, { responseType: 'json' }).pipe(this.responsePipe)
  }
  deleteActe(idActe: string): Observable<any> {
    return this.http.delete(`${environment.API_URL}/acts/delete/${idActe}`)
  }
  one(idActe: string): Observable<any> {
    return this.http.get(`${environment.API_URL}/acts/getActes/${idActe}`)
  }

  */
