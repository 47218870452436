<div class="stepper-wrapper" >
    <div *ngFor="let step of steps;let i = index"  [ngClass]="{'active': i == activeIndex,completed:i<activeIndex,'stepper-item':dir=='fr','stepper-item-arabe':dir=='ar'}">
        <div class="step-counter" [innerHTML]="step.icon"> </div>
        <div class="step-name">{{step.label}}</div>
    </div>
</div>
<!-- <div class="stepper-wrapper" *ngIf="dir=='ar'">
    <div *ngFor="let step of steps;let i = index" class="stepper-item-arabe" [ngClass]="{'active': i == activeIndex,completed:i<activeIndex}">
        <div class="step-counter" [innerHTML]="step.icon"> </div>
        <div class="step-name">{{step.label}}</div>
    </div>
</div> -->