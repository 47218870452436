import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppCommonModule } from 'src/app/common/common.module';
import { AssginAppointmentCalenderComponent } from './assgin-appointment-calender.component/assgin-appointment-calender.component';
import { DoctorDaysCalanderComponent } from './doctor-days-calander.component/doctor-days-calander.component';
import { DoctorDateHoursCalanderComponent } from './doctor-date-hours-calander.component/doctor-date-hours-calander.component';
import { DoctoravailabilitiesCalenderComponent } from './doctor-availabilities-calender/doctor-availabilities-calender.component';
import { DoctorAvailabilitiesFilterPipe } from './doctor-disponibilities-filter.pipe/doctor-availabilities-filter.pipe';

@NgModule({
    declarations: [
        AssginAppointmentCalenderComponent,
        DoctorAvailabilitiesFilterPipe,
        DoctoravailabilitiesCalenderComponent,
        DoctoravailabilitiesCalenderComponent,
        DoctorDaysCalanderComponent,
        DoctorDateHoursCalanderComponent
    ],
    imports: [
        AppCommonModule,
        FormsModule,

    ],
    exports: [
        AssginAppointmentCalenderComponent,
        DoctoravailabilitiesCalenderComponent,
        DoctorDaysCalanderComponent,
        DoctorDateHoursCalanderComponent,
    ]
})
export class AppointmentFormModule { }
