<div id="doc-preview" (click)="close.emit()">
    <ng-container [ngSwitch]="contentType">
        <div *ngSwitchCase="0">
            <h1>Unknown file format</h1>
        </div>
        <app-image-viewer *ngSwitchCase="1" [src]="src"></app-image-viewer>
        <app-video-viewer *ngSwitchCase="2" [src]="src"></app-video-viewer>
        <app-audio-viewer *ngSwitchCase="3" [src]="src"></app-audio-viewer>
        <app-text-viewer *ngSwitchCase="4" [src]="src"></app-text-viewer>
        <app-word-viewer *ngSwitchCase="5" [src]="src"></app-word-viewer>
        <app-pdf-viewer *ngSwitchCase="6" [src]="src"></app-pdf-viewer>
    </ng-container>
</div>