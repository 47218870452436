import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.css']
})
export class SideModalComponent implements OnInit {

  @Input() isShown: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
