import { Pipe, PipeTransform } from '@angular/core';
import { DoctorPatient } from 'src/app/entities/doctor-patient.entity/doctor-patient';
export type SortOrder = 'asc' | 'desc';

@Pipe({
  name: 'doctorPatientSort'
})

export class DoctorPatientSortPipe implements PipeTransform {

  transform(ListPatients: Array<DoctorPatient>, order: number): Array<DoctorPatient> {

    ListPatients.sort((a: any, b: any) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase();
      if (order == -1 || nameA < nameB) {
        return -1;
      }
      if (order == 1 || nameA > nameB) {
        return 1;
      }

      return 0;

    });
    return ListPatients;
  }

}
