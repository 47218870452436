<!-- Prescription Item -->
<div class="card card-table">
  <div class="card-body">
    <table class="table table-hover table-center">
      <thead>
        <tr>
          <th style="min-width: 200px">Biologie</th>
          <th>Valeur</th>
          <th>Date</th>

          <th style="min-width: 80px">Notes</th>
          <th style="min-width: 80px"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let biologyConsultationItem of biologyConsultationItems;let i = index"
          [ngStyle]="{border:!validateConsultationItem(biologyConsultationItem).isValid &&submitted? '1px solid red': 'None'}">
          <td style="width:41%">
            <app-biology-select-dropdown [values]="biologies" [(value)]="biologyConsultationItem.biology" [ngStyle]="(!validateConsultationItem(biologyConsultationItem).isValid &&
            submitted)?{'margin-bottom':'30px'}:{'margin-bottom':'0px'}" (valueChange)="_change()" [placeHolder]="'Biology is here'">
            </app-biology-select-dropdown>
            <div *ngIf="validateConsultationItem(biologyConsultationItem).controlsValidation['biology'] == 'required'  && submitted">
              <span style="color: red"> Biology est obligatoire </span>
            </div>
          </td>

          <td>
            <input type="text" style="height: 45px" class="form-control" (ngModelChange)="_change()" (input)="onEditPrescriptionItem(i)"
              [(ngModel)]="biologyConsultationItem.value" />
            <div *ngIf="validateConsultationItem(biologyConsultationItem).controlsValidation['value'] == 'required'  && submitted">
              <span style="color: red"> value est obligatoire </span>
            </div>
          </td>

          <td>
            <input type="date" placeholder="Select Date" class="form-control datetimepicker"
              [ngStyle]="(validateConsultationItem(biologyConsultationItem).controlsValidation['value'] == 'required'  && submitted==true)?{'margin-bottom':'22px'}:{'margin-bottom':'0px'}"
              [value]="
                  biologyConsultationItem.date.getFullYear() +
                  '-' +
                  (biologyConsultationItem.date.getMonth() + 1 <= 9
                    ? '0' + (biologyConsultationItem.date.getMonth() + 1)
                    : biologyConsultationItem.date.getMonth() + 1) +
                  '-' +
                  (biologyConsultationItem.date.getDate() <= 9
                    ? '0' + biologyConsultationItem.date.getDate()
                    : biologyConsultationItem.date.getDate())
                " style="cursor: pointer" />
            <div *ngIf="validateConsultationItem(biologyConsultationItem).controlsValidation.date  && submitted">
              <span *ngIf="validateConsultationItem(biologyConsultationItem).controlsValidation.date == 'required'" style="color: red"> Durée est obligatoire </span>
              <span *ngIf="validateConsultationItem(biologyConsultationItem).controlsValidation.date == 'number'" style="color: red"> Durée doit etre Numérique </span>
              <span *ngIf="validateConsultationItem(biologyConsultationItem).controlsValidation.date == 'min'" style="color: red"> Durée doit etre > 0 </span>
            </div>
          </td>
          <td>
            <textarea class="form-control" style="height: 45px" [(ngModel)]="biologyConsultationItem.note"
              [ngStyle]="(validateConsultationItem(biologyConsultationItem).controlsValidation['value'] == 'required'  && submitted==true)?{'margin-bottom':'22px'}:{'margin-bottom':'0px'}"></textarea>
          </td>
          <td>
            <a class="btn bg-danger-light trash"
              [ngStyle]="(validateConsultationItem(biologyConsultationItem).controlsValidation['value'] == 'required'  && submitted==true)?{'margin-bottom':'22px'}:{'margin-bottom':'0px'}"
              (click)="deletePrescriptionItem(i)"><i class="feather-trash-2"></i></a>
          </td>
        </tr>

        <tr>
          <td colspan="5">
            <div class="add-more-item text-center">
              <a href="javascript:void(0);" (click)="addPrescriptionItem()"><i class="feather-plus-circle"></i> Ajouter
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- /Prescription Item -->
