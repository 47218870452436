import { Appointment, EAppointmentState } from "../appointment.entity";
import { User } from "../../user.entity";
import { DoctorAvailability } from "../../doctor-disponiblity.entity/doctor-availability";

export class DoctorAppointment extends Appointment {
    patient: User;
    doctorReminding: boolean = false;
    doctorRemindingTime: number = 0;


    constructor(other?: Partial<DoctorAppointment>) {
        super(other)
        this.patient = other?.patient || new User();
        this.doctorReminding = other?.doctorReminding || false;
        this.doctorRemindingTime = other?.doctorRemindingTime || 0;

    }

    public static fromResponse(res: any): DoctorAppointment {
        return new DoctorAppointment({
            patient: res.patient !== null ? User.fromResponse(res.patient) : new User(),
            doctorReminding: res.doctorReminding,
            doctorRemindingTime: res.doctorRemindingTime,
            ...Appointment.fromResponse(res),
        });
    }

    get doctorAvailability() {
        return new DoctorAvailability({
            date: this.date,
            startTime: this.startTime,
            location: this.location,
            private: false,
            duration: this.duration
        })
    }

    get requestPayload() {
        return {
            date: this.date.getTime(),
            startTime: this.startTime,
            duration: this.duration,
            location: this.location,
            isEmergency: this.isEmergency,
            status: EAppointmentState.PENDING,
            motif: this.motif,
            doctorReminding: this.doctorReminding,
            doctorRemindingTime: this.doctorRemindingTime
        }
    }

    get reminderDurations(): { days: number, minutes: number, hours: number } {
        const days = Math.floor(this.doctorRemindingTime / (24 * 60));
        const hours = Math.floor((this.doctorRemindingTime % (24 * 60)) / 60);
        const minutes = this.doctorRemindingTime % 60;
        return { days, hours, minutes };
    }

}
