<div class="calender-container" *ngIf="timeBoarding">

  <div class="calender-month" *ngIf="dir=='fr'">
    <i class="feather feather-chevron-left" (click)="__moveToBack()"></i> 
    <span style="flex: 1;text-align: center;">{{MONTHS[month.getMonth()]}}/{{month.getFullYear()}}</span>
    <i class="feather feather-chevron-right" (click)="__moveToNext()"></i>
  </div>
  <div class="calender-month" *ngIf="dir=='ar'">
    <i class="feather feather-chevron-right" (click)="__moveToNext()"></i>

    <span style="flex: 1;text-align: center;">{{MONTHS[month.getMonth()]}}/{{month.getFullYear()}}</span>
    <i class="feather feather-chevron-left" (click)="__moveToBack()"></i> 

  </div>
  <div class="calender-head">
    <div *ngFor="let dayInWeek of DAYS_IN_WEEK" class="calender-day-head">
      <span class="day font-weight-bold">{{ width>576?dayInWeek:dayInWeek.charAt(0)}}</span>
    </div>
  </div>
  <scrollbar>
    <div class="calender-content">
      <div *ngFor="let week of calender" class="week">
        <div *ngFor="let day of week" class="calender-day-content"
          (click)="(isBussinessWokingDay(day.date.getDay()) && day.date.getTime()>currentDate.getTime())?onDaySelected(day.date):null">
          <div class="calender-day-item"
            [ngClass]="{'disabled':(!isBussinessWokingDay(day.date.getDay()) || day.date.getTime() < currentDate.getTime()),'selected':isSelected(day.date)}">
            <a class="timing" style="cursor: pointer;">
              {{day.date.getDate()}}
            </a>
            <span class="badge"></span>
          </div>
        </div>
      </div>

    </div>
  </scrollbar>
  <ng-container *ngIf="day && mode == 'DATE_TIME_SLOT' ">
    <span *ngIf="day && dir=='fr' " style="font-size: 16px;color: black;font-family: 'Gotham Rounded Bold';padding: 16px 4px">Les
      plages horaires de {{day | date:'dd/MM/YYYY'}}</span>
      <span *ngIf="day && dir=='ar' " style="font-size: 16px;color: black;font-family: 'Gotham Rounded Bold';padding: 16px 4px">الفترات الزمنية ل {{day | date:'dd/MM/YYYY'}}</span>
    <ng-container *ngIf="dayTimeSlots.length>0;else emptyDay">
      <div *ngFor="let slot of dayTimeSlots" class="slot">
        <div class="calender-day-item" [ngClass]="{'selected':selectedTimeSlot?.id == slot.id}">
          <a class="timing" style="cursor: pointer;">
            {{slot.startTime | time }} vers {{slot.endTime | time}}
          </a>
          <span class="badge"></span>
        </div>
      </div>
    </ng-container>
    <ng-template #emptyDay>
      <div class="calender-day-content">
        <div class="calender-day-item" style="border:none">
          <a class="timing" style="cursor: pointer;">---</a>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>