import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LiteralDays } from 'src/app/common/pipes/french-date.pipe/french-date.pipe';
import { EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { DoctorAvailability } from 'src/app/entities/doctor-disponiblity.entity/doctor-availability';;;
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { DoctorAvailabilitiesService } from 'src/app/services/doctor-disponibilities.service/doctor-disponibilities.service';
import { DoctorsService } from 'src/app/services/doctors.service/doctors.service';
import { addDaysToDate, MILLISECONDS_IN_DAY } from 'src/app/utils/date.util';

@Component({
  selector: 'app-assgin-appointment-calender',
  templateUrl: './assgin-appointment-calender.component.html',
  styleUrls: ['./assgin-appointment-calender.component.css'],
})
export class AssginAppointmentCalenderComponent implements OnInit {
  @Input() filtre: EDalyTimeSlotLocation.ATSITE | EDalyTimeSlotLocation.ONLINE = EDalyTimeSlotLocation.ATSITE;
  @Input() isSubmitted: boolean = false;
  @Input() motifs!: [{ color?: string, motif: string }]
  @Input() appointmentDescription: string = 'Consultation Régulière'
  @Output() appointmentDescriptionChange: EventEmitter<string> = new EventEmitter()
  dataSource: Array<{
    day: Date;
    availabilities: Array<DoctorAvailability>;
  }> = new Array();
  @Input() weekstartDay: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  @Input() doctor!: Doctor;
  @Input() time: any[] = [];
  @Input() disponibility: DoctorAvailability | null | any = null;
  @Output() disponibilityChange: EventEmitter<DoctorAvailability> =
    new EventEmitter();
  date: Date = new Date();
  get isValid(): boolean {
    return this.appointmentDescription.length > 0;
  }
  constructor(
    private doctorAvailabilitiesService: DoctorAvailabilitiesService, private doctorService: DoctorsService, private toasterService: ToastrService
  ) { }

  ngOnInit(): void {

    this.fillCalander();
    // this.time=this.disponibility;

    this.doctorService.getMotifs().subscribe(
      res => {
        this.motifs = res.map((elm: any) => {
          let motfisColor
          return motfisColor = {
            "motif": elm.text,
            "color": elm.color
          }
        })
      }
    )
  }

  fillCalander(): void {
    let promises = Array.from([].constructor(7), (v: any, i: number) => {
      let date = addDaysToDate(this.weekstartDay, i);
      return this.doctorAvailabilitiesService
        .getAllByDatePatient(this.doctor.id,
          new Date(date.getFullYear(), date.getMonth(), date.getDate())
        )
        .toPromise();
    });

    this.dataSource = [];
    Promise.all(promises).then(
      (weekavailabilities: Array<{ date: number, availabilities: Array<DoctorAvailability> }>) => {
        this.dataSource = Array.from(
          weekavailabilities,
          (
            dayDisponibility: {
              date: number;
              availabilities: Array<DoctorAvailability>;
            },
            dayIndex: number
          ) => {
            return {
              day: new Date(dayDisponibility.date),
              availabilities:
                Array.from(dayDisponibility.availabilities, e => {
                  return DoctorAvailability.fromResponse(e)
                })
                  .sort(
                    (a: DoctorAvailability, b: DoctorAvailability) =>
                      a.startTime - b.startTime
                  ),
            };
          }
        );
      },
      (error) => {
        alert('Enable to load availabilities');
      }
    );
  }

  getLiteralDay(date: Date): string {
    return LiteralDays[date.getDay()];
  }
  get currentDate(): Date {
    return new Date();
  }
  // get DateDisponiblity():Date{return new Date(this.disponibility.date)};
  onDsiponibilityChange(disponibility: DoctorAvailability): void {
    if (!this.isSelected(disponibility)) {
      this.date = disponibility.date;
      this.disponibility = disponibility;
      this.disponibilityChange.emit(disponibility);
    }

  }
  dateInWeek(day: number): Date {
    return new Date(
      this.weekstartDay.getFullYear(),
      this.weekstartDay.getMonth(),
      this.weekstartDay.getDate() + day
    );
  }

  isSelected(disponibility: DoctorAvailability): boolean {
    return this.disponibility
      ? this.disponibility?.startTime === disponibility.startTime &&
      this.disponibility?.date.getTime() === disponibility.date.getTime()
      : false;
  }

  _dateChangeHandler(date: any): void {
    let datenew = new Date(date.target.value)
    if (datenew.getTime() >= new Date().getTime()) {
      if (this.weekstartDay.getTime() != datenew.getTime()) {
        this.weekstartDay = datenew;
        this.fillCalander();
      }
    }
    else {
      this.toasterService.error("date invalide change date")
    }
  }
  __moveToNextWeek(): void {
    this.weekstartDay = new Date(
      this.weekstartDay.getTime() + 7 * MILLISECONDS_IN_DAY
    );
    this.fillCalander();
  }
  _moveToLastWeek(): void {
    this.weekstartDay = new Date(
      this.weekstartDay.getTime() - 7 * MILLISECONDS_IN_DAY
    );
    this.fillCalander();
  }
  motifsSelect(event: any): void {
    this.appointmentDescription = event.target.value
    this.appointmentDescriptionChange.emit(this.appointmentDescription)
  }
}
