import { Pipe, EventEmitter, PipeTransform } from '@angular/core';
import { Row } from '../../interfaces';

@Pipe({
  name: 'viewChange'
})
export class ViewChangePipe implements PipeTransform {

  transform(rows: Array<Row>, event: EventEmitter<any>): Array<Row> {
    event.emit(rows);
    return rows;
  }

}
