import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Fillings } from 'src/app/entities/fillings.entity/fillings';

@Component({
  selector: 'app-fillings-form',
  templateUrl: './fillings-form.component.html',
  styleUrls: ['./fillings-form.component.css']
})
export class FillingsFormComponent implements OnInit {
  @Input()fillingsItmes!:Array<Fillings>
  @Output()fillingsItmesChange:EventEmitter<Array<Fillings>>=new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }
  addFillingsItem(): void {

    this.fillingsItmes.push(new Fillings({}));
    this._change()
  }

  deleteFillingsItem(index: number): void {
    this.fillingsItmes.splice(index, 1);
    this._change()
  }
  _change(){
    this.fillingsItmesChange.emit(this.fillingsItmes)
  }
}
