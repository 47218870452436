import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCallComponent } from './video-call.component/video-call.component';
import { VideoCallOptionsComponent } from './video-call-options.component/video-call-options.component';
import { VideoChatComponent } from './video-call-options.component/video-chat.component/video-chat.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    VideoCallComponent,
    VideoCallOptionsComponent,
    VideoChatComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    VideoCallComponent,
  ]
})
export class VideoCallModule { }