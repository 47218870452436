import { Pipe, PipeTransform } from '@angular/core';
import { Row } from '../../interfaces';

export interface PaginationOptions {
  perPage: number,
  maxPages: number,
  step: number,
  currentPage: number,
  pages?: Array<Array<Row>>
}


export class Pagination {

  splitToPages(rows: Array<Row>, options: PaginationOptions): Array<Array<Row>> {
    let pages = new Array<Array<Row>>();
    let inPage = 0;
    let bulk = new Array<any>();
    rows.forEach((row, index) => {
      if (inPage > options.perPage) {
        inPage = 0;
        pages.push(bulk);
        bulk = new Array();
      }
      bulk.push(row),
        inPage++;
    })
    if (bulk.length) pages.push(bulk);
    return pages;
  }
}

@Pipe({
  name: 'pagination'
})
export class PaginationPipe extends Pagination implements PipeTransform {


  transform(rows: Array<Row>, options: PaginationOptions): Array<Row> {
    options.pages = this.splitToPages(rows, options);
    return (options.pages || [])[options.currentPage] || [];
  }

}
