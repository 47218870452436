<div class="card center">
  <!-- <select class="selectpicker form-control mt-2" name="select" [(ngModel)]="locationApponitment"   data-width="auto">
        <option  value=""selected>Select Location</option>
        <option  *ngIf="disponibility" [value]="0"> Cabient</option>
        <option *ngIf="disponibility" [value]="1" >A Distance </option>
      </select> -->
  <fieldset>
    <legend>Select Location:</legend>
    <div>
      <input type="radio" id="huey" [(ngModel)]="locationApponitment" name="Cabient" [value]="0" checked>
      <label for="huey">Cabient</label>
    </div>

    <div>
      <input type="radio" id="dewey" [(ngModel)]="locationApponitment" name="A Distance" [value]="1">
      <label for="dewey">A Distance</label>
    </div>
  </fieldset>
  <button class="btn btn-success mt-2" (click)="disponibilityChange.emit(locationApponitment)">Add</button>
</div>