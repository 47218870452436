<div class="drawer" [ngStyle]="{'width':size+'%'}" [ngClass]="{'active':active}">
  <div class="header">
    <span id="title">{{title}}</span>
    <span class="feather-arrow-right deactivate" (click)="close()"></span>
  </div>
  <div class="body">
    <ng-content select="[header]"></ng-content>

    <ng-content select="[body]"></ng-content>
  </div>
  <div *ngIf="modal" class="overlay" [ngStyle]="{'width':(100-size)+'%'}" (click)="close()"></div>
</div>