import { NgModule } from '@angular/core';
import { PrescriptionFormComponent } from './prescription-form.component/prescription-form.component';
import { FormsModule } from '@angular/forms';
import { MedicPrescriptionFormComponent } from './medic-prescription-form.component/medic-prescription-form.component';
import { RadiologyPrescriptionFormComponent } from './radiology-prescription-form.component/radiology-prescription-form.component';
import { BiologyPrescriptionFormComponent } from './biology-prescription-form.component/biology-prescription-form.component';
import { PrescriptionDropdownComponent } from './prescription-dropdown.component/prescription-dropdown.component';
import { AppCommonModule } from 'src/app/common/common.module';
import { BiologyFormModule } from '../biology-form.module/biology-form.module';
import { RadiologyFormModule } from '../radiology-form.module/radiology-form.module';
import { MedicFormModule } from '../medic-form.module/medic-form.module';

@NgModule({
  declarations: [
    PrescriptionFormComponent,
    MedicPrescriptionFormComponent,
    RadiologyPrescriptionFormComponent,
    BiologyPrescriptionFormComponent,
    PrescriptionDropdownComponent,

  ],
  imports: [
    AppCommonModule,
    FormsModule,
    MedicFormModule,
    BiologyFormModule,
    RadiologyFormModule,
    
  ],
  exports: [
    MedicPrescriptionFormComponent,
    RadiologyPrescriptionFormComponent,
    BiologyPrescriptionFormComponent,
    PrescriptionFormComponent,
    PrescriptionDropdownComponent,
  ]

})
export class PrescriptionFormModule { }
