<div class="row">
  <div class="col-lg-4 col-sm-12">
    <label class="mt-1">Type</label>
    <select class="form-control" name="type" (change)="_change()" [(ngModel)]="documentUploadData.type">
      <option value="0">Autre</option>
      <option value="1">Radiologie</option>
      <option value="2">Biologie</option>
      <option value="3">Rapport</option>
    </select>
  </div>
  <div class="col-lg-8 col-sm-12">
    <label class="mt-1">Document</label>
    <input type="file" class="form-control" required accept="image/*" (change)="onFileSelect($event)" />
    <span *ngIf="isSubmitted && !documentUploadData.file" style="color:red" Number>Merci de charger un fichier</span>
  </div>
  <div class="col-lg-12 col-sm-12">
    <label class="mt-1">Description</label>
    <textarea type="text" class="form-control" [(ngModel)]="documentUploadData.note" (input)="_change()"></textarea>
  </div>
  <button (click)="_onSubmit()" style="margin: 12px 8px;">Charger</button>
</div>