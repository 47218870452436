import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VaccinationFormComponent } from './vaccination-form.component/vaccination-form.component';
import { FormsModule } from '@angular/forms';
import { VaccinaionDropDownSelectComponent } from './vaccinaion-drop-down-select.component/vaccinaion-drop-down-select.component';
import { AppCommonModule } from 'src/app/common/common.module';

@NgModule({
  declarations: [
    VaccinationFormComponent,
    VaccinaionDropDownSelectComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule
  ],
  exports: [
    VaccinationFormComponent,
    VaccinaionDropDownSelectComponent
  ]
})
export class VaccinationFormModule { }
