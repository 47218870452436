import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface StepperProgressBarItem<T> {
  label?: string;
  icon?: string;
  data?: T;
  status?: 'active' | 'completed'
}

@Component({
  selector: 'app-stepper-progress-bar',
  templateUrl: './stepper-progress-bar.component.html',
  styleUrls: ['./stepper-progress-bar.component.css']
})
export class StepperProgressBarComponent<T> implements OnInit {

  @Input() steps: Array<StepperProgressBarItem<T>> = new Array();
  @Input() dir:string="fr"
  @Input() activeIndex: number = 0;
  @Output() change: EventEmitter<StepperProgressBarItem<T>> = new EventEmitter();
  @Output() completed: EventEmitter<T> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    /*this.steps = this.steps.map((step, i) => {
      if (!step.icon) step.icon = String(i);
      if (!step.label) step.label = `Step ${i}`;
      return step;
    });

    const activeItemIndex = this.activeIndex;

    if (activeItemIndex > 0)
      this.steps = this.steps.map((step, i) => {
        if (i < activeItemIndex) step.status = 'completed';
        return step;
      })
    else if (activeItemIndex < 0 && this.steps.length > 0) this.steps[0].status = 'active';
      */
  }


  activateItem(item: StepperProgressBarItem<T>) {
    item.status = 'completed';
  }

  /*get activeIndex(): number {
    return this.steps.findIndex(e => e.status === 'active');
  }*/

  get activeItem(): StepperProgressBarItem<T> | null {
    return this.steps.find(e => e.status === 'active') || null;
  }

  next() {
    if (!this.steps.length) return;
    const index = this.activeIndex;
    this.steps[index].status = 'completed';
    if (index < this.steps.length) {
      this.steps[index + 1].status = 'active';
      this.change.emit(this.activeItem!)
    } else this.completed.emit(this.steps[this.steps.length - 1].data)
  }

}
