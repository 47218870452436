<div class="container-fluid cnam">
   <h3>Presciption Appareillages: 
</h3>
    <div class="table-responsive">
        <table  class="table table-bordered  table-hover table-center">
            <thead>
                <tr>
                  <th scope="col"class="style-th" >Code</th>
                  <th scope="col" class="style-th-label" >Libellé</th>
                  <th scope="col" class="style-th" >Nombre</th>
                </tr>
              </thead>
        <tbody>
            <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice1Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice1Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice1Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice2Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice2Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice2Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice3Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice3Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice3Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice4Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice4Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice4Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice5Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice5Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice5Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice6Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice6Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice6Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice7Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice7Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice7Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice8Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice8Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice8Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice9Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice9Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice9Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice10Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice10Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice10Number"></td>
              </tr>
              <tr>
                <td><input class="text input" [(ngModel)]="consultation.ap3.ap3ListedDevice11Code"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice11Label"></td>
                <td><input class="text input"[(ngModel)]="consultation.ap3.ap3ListedDevice11Number"></td>
              </tr>
        </tbody>
        </table>
    </div>
   
  <div class="row">
    <div class="col-lg-6">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-8">
              <label>Accident du travail</label>
            </div>
            <div class="col-lg-4">
              <input
                type="checkbox"
                [(ngModel)]="consultation.ap3.ap3Incident"
                name=""
                id=""
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-lg-8">
              <label>Professionnelle</label>
            </div>
            <div class="col-lg-4">
              <input
                type="checkbox"
                [(ngModel)]="consultation.ap3.ap3Professional"
                name=""
                id=""
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-lg-8">
              <label>APCI</label>
            </div>
            <div class="col-lg-4">
              <input
                type="checkbox"
                [(ngModel)]="consultation.ap3.ap3DPCI"
                name=""
                id=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-9">
              <label>Première Attribution</label>
            </div>
            <div class="col-lg-3">
              <input
                type="checkbox"
                [(ngModel)]="consultation.ap3.ap3FirstAttribution"
                name=""
                id=""
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-lg-9">
              <label>Appareil provisoire</label>
            </div>
            <div class="col-lg-3">
              <input
                type="checkbox"
                [(ngModel)]="consultation.ap3.ap3TemporalDevice"
                name=""
                id=""
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-lg-9">
              <label>Renouvellement</label>
            </div>
            <div class="col-lg-3">
              <input
                type="checkbox"
                [(ngModel)]="consultation.ap3.ap3Renewal"
                name=""
                id=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
     
    </div>
    <div class="col-lg-6">
      <div class="form-group">
        <div class="flex">
          <label for="">Autre Nature de Maladie à Préciser:</label>
          <button class="openDialoge open" (click)="openDialoge('OtherNature')">
            <i class="feather-plus-circle"></i>
          </button>
          <div class="question">
            <app-liste-question-sahred
              [filter]="'OtherNature'"
              [(value)]="consultation.ap3.ap3OtherNature"
              [(listeQuestionsConsultations)]="tableauTextFillings"
              [options]="{right:'-19px',top:'24px'}"
            ></app-liste-question-sahred>
          </div>
        </div>
        <textarea
          name=""
          [(ngModel)]="consultation.ap3.ap3OtherNature"
          rows="1"
          class="form-control"
        ></textarea>
      </div>
      <div class="form-group">
        <div class="flex">
          <label for="">Données Cliniques et Paracliniques: </label>
          <button
            class="openDialoge Clinic"
            (click)="openDialoge('ClinicAndParaClinicData')"
          >
            <i class="feather-plus-circle"></i>
          </button>

          <div class="question-clinic">
            <app-liste-question-sahred
              [filter]="'ClinicAndParaClinicData'"
              [(value)]="consultation.ap3.ap3ClinicAndParaClinicData"
              [(listeQuestionsConsultations)]="tableauTextFillings"
              [options]="{right:'-19px',top:'24px'}"
            ></app-liste-question-sahred>
          </div>
        </div>
        <textarea
          name=""
          rows="1"
          [(ngModel)]="consultation.ap3.ap3ClinicAndParaClinicData"
          class="form-control"
        ></textarea>
      </div>
    </div>
  </div>
</div>
<app-modal [title]="questions.id==''?'Ajouter nouvelle Question':'Mise a jour  Question'" #dialog>
  <app-text-fillings-form *ngIf="questions.id!==''" [submitName]="'Mise a jour  Question'" [textFilligns]="questions" (submit)="callUpdateQuestion($event)"></app-text-fillings-form>
  <app-text-fillings-form *ngIf="questions.id==''" [submitName]="'Ajouter Question'" [textFilligns]="questions" (submit)="callAddQuestion($event)"></app-text-fillings-form>

</app-modal>>
