import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[image]'
})
export class ImgDirective implements OnInit {
  @Input() default: string = "assets/img/user-avatar.png";
  @Input() src: string = this.default;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.setImageSrc(this.src || this.default);
  }

  @HostListener('error')
  onError() {
    this.setImageSrc(this.default);
  }

  private setImageSrc(src: string) {
    this.elementRef.nativeElement.src = src;
  }
}
