import { Pipe, PipeTransform } from '@angular/core';
import Speciality from '../../../entities/user.entity/doctor.entity/speciality.entity';

@Pipe({
  name: 'filterSpecialty'
})
export class FilterSpecialtyPipe implements PipeTransform {

  transform(value: Array<Speciality>, specialiteFilter: string): Array<Speciality> {
    return value.filter((elm) => {
      if (specialiteFilter) {
        return elm.label.toUpperCase().startsWith(specialiteFilter.trim().toUpperCase())

      }
      else {
        return value
      }
    });
  }

}
