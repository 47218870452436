import { Pipe, PipeTransform } from '@angular/core';
import { MILLISECONDS_IN_HOUR, MILLISECONDS_IN_MINUTE } from '../../../utils/date.util';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
  transform(date: Date | number | string, options?: { seconds?: boolean }): string {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (date instanceof Date) {
      hours = date.getHours();
      minutes = date.getMinutes();
      seconds = date.getSeconds();
    } else if (typeof (date) === 'number') {
      hours = Math.floor(date / MILLISECONDS_IN_HOUR);
      minutes = Math.floor((date % MILLISECONDS_IN_HOUR) / MILLISECONDS_IN_MINUTE);
      seconds = Math.floor(((date % MILLISECONDS_IN_HOUR) / MILLISECONDS_IN_MINUTE) % 1000)
    }

    return `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}${options?.seconds ? `:${seconds > 9 ? seconds : '0' + seconds}` : ''}`;

  }


}
