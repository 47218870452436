import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PageRequest, PaginationPage } from 'src/app/common/modules/data-table.module/interfaces';
import { Consultation } from 'src/app/entities/consultation.entity';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { ConsultationService } from 'src/app/services/consultation.service';

@Component({
  selector: 'app-doctor-consultation-card',
  templateUrl: './doctor-consultation-card.component.html',
  styleUrls: ['./doctor-consultation-card.component.css']
})
export class DoctorConsultationCardComponent implements OnInit {

  @Input() currentPage: number = 0;
  @Input() perPage: number = 10;
  doctorConsultations: Array<Consultation> = new Array();
  @Input() pageRequest!: (pageRequest: PageRequest) => Promise<PaginationPage<Consultation>>;
  constructor(
    private consultationsService: ConsultationService,
    private authService: AuthService,
    private toasterService: ToastrService
  ) { }

  get doctor(): Doctor {
    return this.authService.getCurrentUser() as Doctor;
  }
  ngOnInit(): void {
    this.currentPage = 0;
  }

  onDeleteConsultation(index: number) {
    window.confirm('Are you sure you will delete consultation');
    this.consultationsService.delete(this.doctorConsultations[index].id).subscribe((res) => {
      this.toasterService.success('Consultation has deleted');
      this.doctorConsultations.splice(index, 1);
    }, error => {
      this.toasterService.error('Enable to delete prescription')
    });
  }

}
