import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Appointment } from 'src/app/entities/appointment.entity';
import { DocumentAttachment } from 'src/app/entities/document-attachment.entity/document-attachment';

export interface DocumentUploadData {
  file: File | null,
  appointment: Appointment | null,
  type: number,
  note: string,
}
@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.css']
})
export class DocumentFormComponent implements OnInit {

  @Input() appointment!: Appointment;
  @Input() isSubmitted: boolean = false;
  @Input() file!: File;
  @Input() documentAttachment: DocumentAttachment | null = null;
  @Output() documentAttachmentChange: EventEmitter<DocumentUploadData> = new EventEmitter();
  @Output() onSubmit: EventEmitter<DocumentUploadData> = new EventEmitter();

  documentUploadData: DocumentUploadData = {
    file: null,
    note: '',
    type: 0,
    appointment: this.appointment
  }
  _fileUri: any;
  get isValid(): boolean {
    return !!this.documentUploadData.file;
  }

  constructor() { }

  ngOnInit(): void {

  }

  onFileSelect(event: any) {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event) =>
      this._fileUri = event.target?.result?.toString()!;
    this.documentUploadData.file = event.target.files[0];
    this._change();
  }

  _change(): void {
    this.documentAttachmentChange.emit(this.documentUploadData)
  }

  _onSubmit(): void {
    this.isSubmitted = true;
    if (this.isValid)
      this.onSubmit.emit(this.documentUploadData);
  }
}
