<div class="content success-page-cont">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="success-cont">
          <img src="assets/img/icons/check-circle.png" alt="logo">
          <h3>RDV réservé avec succés, En attente de validation du Médecin!</h3>
          <p>RDV réservé avec <span
              class="text-info"><strong>{{doctorAppointment.patient.getFullName(true)}}</strong></span><br>le
            <strong>{{doctorAppointment.startDateTime | date:'dd/MM/YYYY HH:MM'}}
            </strong>
          </p>
          <button class="btn align-content-lg-center btn-sm bg-success" [routerLink]="['/secretary/appointments']"
            routerLinkActive="router-link-active">Retourner aux rendez-vous avec
            {{doctorAppointment.patient.getFullName()}}</button>
        </div>
      </div>
    </div>
  </div>
</div>