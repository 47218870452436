<div class="mt-2">
    <div class="row">
       <div class="col-lg-12 col-sm-12">
            <input type="text"  class="form-control"[(ngModel)]="note!.label" disabled placeholder="Notes" style="background-color: transparent;width: 10rem; border: none; font-weight: bold;font-family: Arial, Helvetica, sans-serif;">
            
        </div>
        <div class="col-lg-12 col-sm-12 mt-3">
           <!-- <label class="Label">Les Documents </label>-->
            <app-model-form-payment [open]="open" [(fillingsItmes)]="note.fillings"></app-model-form-payment>
        </div>
    
    </div>
    <button (click)="_submit()" class="btn btn-sm btn-primary" >{{submitName}}</button>

</div>