import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ModalComponent } from "src/app/common/modules/modal.module/modal/modal.component";
import { FilterPipe } from "src/app/common/pipes/filter.pipe/filter.pipe";
import { Medic } from "src/app/entities/medic.entity/medic";
import { MedicsService } from "src/app/services/medics.service/medics.service";
import { MedicFormComponent } from "../../medic-form.component/medic-form.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-medic-select-dropdown",
  templateUrl: "./medic-select-dropdown.component.html",
  styleUrls: ["./medic-select-dropdown.component.css"],
})
export class MedicSelectDropdownComponent implements OnInit {
  drawerActive: boolean = false;
  src!: SafeResourceUrl;
  url: string = "";
  title: string = "";
  nav: { RCP: Boolean; deails: boolean; Notice: boolean } = { RCP: false, deails: false, Notice: false };
  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleModal(false);
    }
  }

  @Input() disabled: boolean = false;
  @Input() canAddMedic: boolean = this.disabled;

  @Input() set values(values: Array<Medic>) {
    this.doctorMedics = values;
  }

  @Input() placeHolder: string = "";
  @ViewChild("medicForm", { read: MedicFormComponent, static: false }) medicForm!: MedicFormComponent;
  searchPattern: string = "";
  doctorMedics: Array<Medic> = new Array();
  @ViewChild(ModalComponent, { read: ModalComponent, static: false })
  dialog!: ModalComponent;
  @Input() set value(value: Medic) {
    //if (value) {
    this._value = value;
    this.valueChange.emit(this._value);
    //}
  }
  modalToggled: boolean = false;
  currentTabIndex: number = 0;

  get value(): Medic {
    return this._value;
  }

  private _value!: Medic;
  @Output() valueChange: EventEmitter<Medic> = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    private medicsService: MedicsService,
    private sanitizer: DomSanitizer,
    private toasterService: ToastrService
  ) {}
  onFilterPatternChange(pattern: string) {
    if (pattern.length === 2)
      this.medicsService.search({ query: pattern }).subscribe((medics) => {
        this.doctorMedics = Array.from(medics, (m) => Medic.fromResponse(m));
      });
    else if (pattern.length < 2) this.doctorMedics = [];
  }

  ngOnInit(): void {
    /*this.medicsService.prescriptions().subscribe(medics => {
      this.medicsList = Array.from(medics, m => Medic.fromResponse(m));
    })*/
  }

  openDialoge(): void {
    this.dialog.open();
  }

  toggleModal(toggled?: boolean): void {
    if (toggled != undefined) this.modalToggled = toggled;
    else this.modalToggled = !this.modalToggled;
    if (toggled == true) this.currentTabIndex = 0;
  }

  onValuesSelected(value: Medic) {
    if (value) {
      this.value = value;
      this.toggleModal(false);
      this.searchPattern = "";
    }
  }

  onAddMedic(): void {
    this.medicForm.isSubmitted = true;
    if (this.medicForm.isValid) {
      this.medicsService.add(this.medicForm.medic).subscribe(
        (medic) => {
          this.medicForm.isSubmitted = false;
          this.onValuesSelected(Medic.fromResponse(medic));
          this.doctorMedics = [medic, ...this.doctorMedics];
          this.medicForm.medic = new Medic();
          this.dialog.close();
        },
        (error) => this.toasterService.error("Enable to add medic")
      );
    }
  }

  get medics(): Array<Medic> {
    return new FilterPipe().transform(this.doctorMedics, this.searchPattern, { keyword: "nom" });
  }
  urlMedic($event: any, title: string) {
    this.nav.Notice = this.value.notice.length > 0 ? true : false;
    this.nav.deails = this.value.details.length > 0 ? true : false;
    this.nav.RCP = this.value.rcp.length > 0 ? true : false;

    this.title = title + "  " + "Medicament";
    this.drawerActive = !this.drawerActive;
    this.url = $event;
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
  urlInter($event: any, title: string) {
    this.title = title + "  " + "Medicament";
    this.url = $event;
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
