import { query } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Medic } from "src/app/entities/medic.entity/medic";
import { MedicPrescription } from "src/app/entities/prescription.entity/medic-prescription.entity/medic-prescription";
import { MedicsService } from "src/app/services/medics.service/medics.service";
export class MedicFilter {
  medicPrescription: MedicPrescription;
  selected: boolean;
  index: number;
  constructor(other?: Partial<MedicFilter>) {
    this.medicPrescription = other?.medicPrescription || new MedicPrescription();
    this.selected = other?.selected || false;
    this.index = other?.index || 0;
  }
}
@Component({
  selector: "app-medic-select-drawer",
  templateUrl: "./medic-select-drawer.component.html",
  styleUrls: ["./medic-select-drawer.component.css"],
})
export class MedicSelectDrawerComponent implements OnInit {
  @Input() values: Array<any> = new Array();
  @Output() valuesChange: EventEmitter<any> = new EventEmitter();
  isChecked: boolean = false;
  medic: string = "";
  medics: Array<any> = new Array();
  select: Array<MedicFilter> = [];
  @Input() medicsSelected: Array<MedicFilter> = new Array();
  @Output() medicsSelectedChange: EventEmitter<Array<MedicFilter>> = new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter();
  constructor(private medicService: MedicsService) {}

  ngOnInit(): void {}
  search(event: any) {
    event.target.value.length > 0
      ? this.medicService.search({ query: event.target.value }).subscribe((medics) => {
          this.medics = Array.from(medics, (m) => Medic.fromResponse(m));
        })
      : (this.medics = []);
  }
  changed(medic: any, event, index): void {
    if (event.target.checked == true) {
      this.medicsSelected.push({
        medicPrescription: new MedicPrescription({ medic: medic }),
        selected: event.target.checked,
        index: index,
      });

      this.medicsSelectedChange.emit(this.medicsSelected.filter((elm) => elm.selected == true));
    } else {
      this.medicsSelected = this.medicsSelected.filter((elm) => elm.medicPrescription.medic !== medic && elm.selected != false);

      this.medicsSelectedChange.emit(this.medicsSelected);
    }
  }
  close(): void {
    this.medicsSelectedChange.emit(this.medicsSelected);

    this.closed.emit();
  }
}
