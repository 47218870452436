<div class="card mb-4">
  <div class="card-body">
    <div class="mt-2">
      <div class="row">
        <div class="form-group">
          <label for="">Libelle*</label>
          <input type="text" class="form-control" [(ngModel)]="doctorParameterSante.parametreSante.label">
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label for="type">Type*</label>
            <select name="type" class="form-control" [(ngModel)]="doctorParameterSante.parametreSante.type">
              <option value="" select>Select Type</option>
              <option value="numerique">Numerique</option>
              <option value="jour_mois_annee">Date</option>
              <option value="chaine_de_caractere">Texte</option>
              <option value="oui_non">Oui/Non</option>
              <option value="liste_choix">List de choix</option>
            </select>
          </div>
          <div class="form-group col-sm-6">
            <label for="">Famille*</label>
            <input type="text" class="form-control" [(ngModel)]="doctorParameterSante.parametreSante.group">
          </div>
        </div>
        
        
        <div class="row">
          
          <div *ngIf="doctorParameterSante.parametreSante.type.toString() === 'liste_choix'" class="col-sm-6">
            <label for="">Valeurs (separées par virgule)*</label>
            <input type="text" class="form-control" [(ngModel)]="doctorParameterSante.parametreSante.splitValues">
          </div>
          <div *ngIf="doctorParameterSante.parametreSante.type.toString() != 'liste_choix'" class="col-sm-6">
            <label for="">Unité</label>
            <input type="text" class="form-control" [(ngModel)]="doctorParameterSante.parametreSante.unit">
          </div>
          <div class="col-sm-6">
            <label for="">Ordre</label>
            <input type="number" min="1" class="form-control" [(ngModel)]="doctorParameterSante.order">
          </div>
        </div>
        <div class="form-group mt-3">
          <label for="">Description</label>
          <textarea  class="form-control" [(ngModel)]="doctorParameterSante.parametreSante.description"></textarea>
        </div>
      </div>
      <button (click)="_submit()" class="btn btn-sm btn-primary" style="margin-top: 25px;">{{submitName}}</button>
    </div>
  </div>
</div>