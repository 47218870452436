<div>
    <input type="text" class="form-control my-2" [(ngModel)]="radiologie"  placeholder="Recherche radiologie">
    <nav class="tree-nav scroll" >

        <div class="flex" *ngFor="let radio of values | radioFilter:radiologie;let i= index">
            <span class="label">{{radio.nom_Radio}}</span>
            <input type="checkbox" name="" id=""  class="checkbox" (change)="changed(radio,$event)"   >
        </div>
        </nav>
        <button class="btn btn-primary ajouter" (click)="close()">Ajouter</button>
</div>
