import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConsultationPediatricVaccinationItem } from 'src/app/entities/consultation.entity';
import { PediatricVaccination } from 'src/app/entities/pediatric-vaccination.entity/pediatric-vaccination';
import { User } from 'src/app/entities/user.entity';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { DoctorPatientsService } from 'src/app/services/doctor-patients.service/doctor-patients.service';
import { PediatricVaccinationSettingsService } from 'src/app/services/pediatric-vaccination-setting.service/pediatric-vaccination-settings.service';
import { VaccinConsultationnItemValidation } from '../consultation-vaccination-form.component/consultation-vaccination-form.component';

export class ConsultationItemValidation {
  controlsValidation: Record<symbol, symbol | null> = {}
  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every(elm => elm === null)
  }
}

export class ConsultationPediatricVaccinationItemValidation extends ConsultationItemValidation {
  controlsValidation: { numLot: null | 'required', vaccin: 'required' | null, date: 'required' | 'number' | 'min' | null } = {
    vaccin: null,
    numLot: null,
    date: null
  };
  constructor(controlsValidation: { numLot: null | 'required', vaccin: 'required' | null, date: 'required' | 'number' | 'min' | null }) {
    super()
    this.controlsValidation = controlsValidation
  }

  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every(elm => elm === null)
  }
}

@Component({
  selector: 'app-pediatric-consultation-vaccinations-form',
  templateUrl: './pediatric-consultation-vaccinations-form.component.html',
  styleUrls: ['./pediatric-consultation-vaccinations-form.component.css']
})
export class PediatricConsultationVaccinationsFormComponent implements OnInit {

  @Input() vaccines: Array<any> = new Array();
  @Input() patient: User = new User();
  @Input() consultationPediatricVaccinationItems: Array<ConsultationPediatricVaccinationItem> = new Array();
  @Output() consultationPediatricVaccinationItemsChange: EventEmitter<Array<ConsultationPediatricVaccinationItem>> = new EventEmitter();

  pediatricVaccinationSettings: Array<PediatricVaccination> = new Array();
  patientPediatricVaccinationItems: Array<ConsultationPediatricVaccinationItem> = new Array();
  consultationPediatricVaccinationFormItems: Array<ConsultationPediatricVaccinationItem> = new Array();

  get doctor(): Doctor {
    return this.authService.getCurrentUser() as Doctor;
  }

  @Output() validation: EventEmitter<boolean> = new EventEmitter()

  @Input() set submitted(v: boolean) {
    this._submitted = v;
    this._change();
  }

  private _submitted: boolean = false;

  get submitted(): boolean {
    return this._submitted;
  }

  get isValid(): boolean {
    return this.consultationPediatricVaccinationFormItems.every(item => this.validateConsultationItem(item).isValid)
  }

  get consultationPediatricVaccinatedItems(): Array<ConsultationPediatricVaccinationItem> {
    return this.consultationPediatricVaccinationFormItems.filter(e => !!e.vaccination?.id_Vaccin.length || e.vaccinated);
  }

  constructor(private pediatricVaccinationSettingsService: PediatricVaccinationSettingsService, private doctorPatientsService: DoctorPatientsService, private toasterService: ToastrService, private authService: AuthService) { }

  ngOnInit(): void {
    this.pediatricVaccinationSettingsService.all().subscribe(settings => {
      this.pediatricVaccinationSettings = Array.from(settings, e => PediatricVaccination.fromResponse(e)).filter(e => e.isActivated);
      this.doctorPatientsService.pediatricVaccinations(this.patient.id).subscribe(items => {
        this.patientPediatricVaccinationItems = Array.from(items, e => ConsultationPediatricVaccinationItem.fromResponse(e)).filter(e => !this.consultationPediatricVaccinationItems.find(item => item.id == e.id));

        this.consultationPediatricVaccinationFormItems = this.pediatricVaccinationSettings
          .filter(s => !this.patientPediatricVaccinationItems.find(e => e.setting.id === s.id))
          .map(s => {
            const inConsultationPediatricVaccinations = this.consultationPediatricVaccinationItems.find(e => e.setting.id == s.id);
            return inConsultationPediatricVaccinations ? inConsultationPediatricVaccinations : new ConsultationPediatricVaccinationItem({ setting: s })
          })
          .sort((a, b) => a.setting.minOld - b.setting.minOld);
      }, error => {
        this.toasterService.error('Enable to load patient pediatric vaccinations historic');
      })
    }, error => {
      this.toasterService.error('Enable to load settings')
    })
  }

  validateConsultationItem(consultationPediatricVaccinationItem: ConsultationPediatricVaccinationItem): ConsultationPediatricVaccinationItemValidation {
    return new VaccinConsultationnItemValidation({
      vaccin: null,
      numLot: null,
      date: null
    })
  }

  onEditPrescriptionItem(index: number): void {
    this._change()
  }

  _change() {
    this.consultationPediatricVaccinationFormItems = this.consultationPediatricVaccinationFormItems.map(e => { if (!e.vaccinated && e.id.length <= 0) e.vaccinated = !!(e.vaccination && e.vaccination.id_Vaccin.length > 0); return e; }).sort((a, b) => a.setting.minOld - b.setting.minOld)
    this.consultationPediatricVaccinationItemsChange.emit(this.consultationPediatricVaccinatedItems)
    this.validation.emit(this.isValid)
  }

  getItemColorIndex(item: ConsultationPediatricVaccinationItem): number {
    const patientOldInMonthsNumber = this.patient.calculateAge.months;
    return (patientOldInMonthsNumber >= item.setting.minOld) ? 1 : 0;
  }

  getOldFormat(pediatricVaccination: PediatricVaccination): { years: number, months: number } {
    return { months: pediatricVaccination.minOld % 12, years: Math.floor(pediatricVaccination.minOld / 12) }
  }
}
