import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-drop-down-modal',
  templateUrl: './drop-down-modal.component.html',
  styleUrls: ['./drop-down-modal.component.css']
})
export class DropDownModalComponent implements OnInit {

  @Input() trigger!: HTMLElement;
  @Input() space: number = 12;
  isActive: boolean = false;
  coordinations: { x: number, y: number } = { x: 0, y: 0 };
  @Input() orientation: 'left' | 'right' | 'top' | 'bottom' | 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom' = 'left-bottom';
  constructor(private rendrer: Renderer2, private element: ElementRef) { }

  ngOnInit(): void {
    if (this.trigger) {
      const triggerCoordinations = { x: this.trigger.getBoundingClientRect().x, y: this.trigger.getBoundingClientRect().y, width: this.trigger.clientWidth, height: this.trigger.clientHeight }
      switch (this.orientation) {
        case 'left': {
          this.coordinations = { x: triggerCoordinations.x - this.space - (this.element.nativeElement as Element).clientWidth, y: triggerCoordinations.y }
        } break;
        case 'right': {
          this.coordinations = { x: triggerCoordinations.x + this.space + (this.element.nativeElement as Element).clientWidth, y: triggerCoordinations.y }
        } break;
        case 'top': {
          this.coordinations = { x: triggerCoordinations.x, y: triggerCoordinations.y - this.space - (this.element.nativeElement as Element).clientHeight }
        } break;
        case 'bottom': {
          this.coordinations = { x: triggerCoordinations.x, y: triggerCoordinations.y + this.space + (this.element.nativeElement as Element).clientHeight }
        } break;
        case 'left-top': {
          this.coordinations = { x: triggerCoordinations.x, y: triggerCoordinations.y + this.space + (this.element.nativeElement as Element).clientHeight }
        } break;
        case 'left-bottom': { } break;
        case 'right-top': { } break;
        case 'right-bottom': { } break;
      }


      this.rendrer.listen(this.trigger, 'click', () => {
        this.isActive = !this.isActive;
      })
    }
  }

}
