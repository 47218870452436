export class ConsultationCnamAp3 {
  public ap3ListedDevice1Code: string;
  public ap3ListedDevice1Label: string;
  public ap3ListedDevice1Number: string;

  public ap3ListedDevice2Code: string;
  public ap3ListedDevice2Label: string;
  public ap3ListedDevice2Number: string;

  public ap3ListedDevice3Code: string;
  public ap3ListedDevice3Label: string;
  public ap3ListedDevice3Number: string;

  public ap3ListedDevice4Code: string;
  public ap3ListedDevice4Label: string;
  public ap3ListedDevice4Number: string;

  public ap3ListedDevice5Code: string;
  public ap3ListedDevice5Label: string;
  public ap3ListedDevice5Number: string;

  public ap3ListedDevice6Code: string;
  public ap3ListedDevice6Label: string;
  public ap3ListedDevice6Number: string;

  public ap3ListedDevice7Code: string;
  public ap3ListedDevice7Label: string;
  public ap3ListedDevice7Number: string;

  public ap3ListedDevice8Code: string;
  public ap3ListedDevice8Label: string;
  public ap3ListedDevice8Number: string;

  public ap3ListedDevice9Code: string;
  public ap3ListedDevice9Label: string;
  public ap3ListedDevice9Number: string;

  public ap3ListedDevice10Code: string;
  public ap3ListedDevice10Label: string;
  public ap3ListedDevice10Number: string;

  public ap3ListedDevice11Code: string;
  public ap3ListedDevice11Label: string;
  public ap3ListedDevice11Number: string;

  public ap3Incident: boolean;
  public ap3Professional: boolean;
  public ap3DPCI: boolean;
  public ap3OtherNature: string;
  public ap3ClinicAndParaClinicData: string;

  public ap3FirstAttribution: boolean;
  public ap3TemporalDevice: boolean;
  public ap3Renewal: boolean;
  constructor(other?: Partial<ConsultationCnamAp3>) {
    this.ap3ListedDevice1Code = other?.ap3ListedDevice1Code || "";
    this.ap3ListedDevice1Label = other?.ap3ListedDevice1Label || "";
    this.ap3ListedDevice1Number = other?.ap3ListedDevice1Number || "";
    this.ap3ListedDevice2Code = other?.ap3ListedDevice2Code || "";
    this.ap3ListedDevice2Label = other?.ap3ListedDevice2Label || "";
    this.ap3ListedDevice2Number = other?.ap3ListedDevice2Number || "";
    this.ap3ListedDevice3Code = other?.ap3ListedDevice3Code || "";
    this.ap3ListedDevice3Label = other?.ap3ListedDevice3Label || "";
    this.ap3ListedDevice3Number = other?.ap3ListedDevice3Number || "";
    this.ap3ListedDevice4Code = other?.ap3ListedDevice4Code || "";
    this.ap3ListedDevice4Label = other?.ap3ListedDevice4Label || "";
    this.ap3ListedDevice4Number = other?.ap3ListedDevice4Number || "";
    this.ap3ListedDevice5Code = other?.ap3ListedDevice5Code || "";
    this.ap3ListedDevice5Label = other?.ap3ListedDevice5Label || "";
    this.ap3ListedDevice5Number = other?.ap3ListedDevice5Number || "";
    this.ap3ListedDevice6Code = other?.ap3ListedDevice6Code || "";
    this.ap3ListedDevice6Label = other?.ap3ListedDevice6Label || "";
    this.ap3ListedDevice6Number = other?.ap3ListedDevice6Number || "";
    this.ap3ListedDevice7Code = other?.ap3ListedDevice7Code || "";
    this.ap3ListedDevice7Label = other?.ap3ListedDevice7Label || "";
    this.ap3ListedDevice7Number = other?.ap3ListedDevice7Number || "";
    this.ap3ListedDevice8Code = other?.ap3ListedDevice8Code || "";
    this.ap3ListedDevice8Label = other?.ap3ListedDevice8Label || "";
    this.ap3ListedDevice8Number = other?.ap3ListedDevice8Number || "";
    this.ap3ListedDevice9Code = other?.ap3ListedDevice9Code || "";
    this.ap3ListedDevice9Label = other?.ap3ListedDevice9Label || "";
    this.ap3ListedDevice9Number = other?.ap3ListedDevice9Number || "";
    this.ap3ListedDevice10Code = other?.ap3ListedDevice10Code || "";
    this.ap3ListedDevice10Label = other?.ap3ListedDevice10Label || "";
    this.ap3ListedDevice10Number = other?.ap3ListedDevice10Number || "";
    this.ap3ListedDevice11Code = other?.ap3ListedDevice11Code || "";
    this.ap3ListedDevice11Label = other?.ap3ListedDevice11Label || "";
    this.ap3ListedDevice11Number = other?.ap3ListedDevice11Number || "";
    this.ap3Incident = other?.ap3Incident || false;
    this.ap3Professional = other?.ap3Professional || false;
    this.ap3DPCI = other?.ap3DPCI || false;
    this.ap3OtherNature = other?.ap3OtherNature || "";
    this.ap3ClinicAndParaClinicData = other?.ap3ClinicAndParaClinicData || "";
    this.ap3FirstAttribution = other?.ap3FirstAttribution || false;
    this.ap3TemporalDevice = other?.ap3TemporalDevice || false;
    this.ap3Renewal = other?.ap3Renewal || false;
  }
  static fromResponse(res: any): ConsultationCnamAp3 {
    return new ConsultationCnamAp3({
      ap3ListedDevice1Code: res.ap3ListedDevice1Code,
      ap3ListedDevice1Label: res.ap3ListedDevice1Label,
      ap3ListedDevice1Number: res.ap3ListedDevice1Number,

      ap3ListedDevice2Code: res.ap3ListedDevice2Code,
      ap3ListedDevice2Label: res.ap3ListedDevice2Label,
      ap3ListedDevice2Number: res.ap3ListedDevice2Number,

      ap3ListedDevice3Code: res.ap3ListedDevice3Code,
      ap3ListedDevice3Label: res.ap3ListedDevice3Label,
      ap3ListedDevice3Number: res.ap3ListedDevice3Number,

      ap3ListedDevice4Code: res.ap3ListedDevice4Code,
      ap3ListedDevice4Label: res.ap3ListedDevice4Label,
      ap3ListedDevice4Number: res.ap3ListedDevice4Number,

      ap3ListedDevice5Code: res.ap3ListedDevice5Code,
      ap3ListedDevice5Label: res.ap3ListedDevice5Label,
      ap3ListedDevice5Number: res.ap3ListedDevice5Number,

      ap3ListedDevice6Code: res.ap3ListedDevice6Code,
      ap3ListedDevice6Label: res.ap3ListedDevice6Label,
      ap3ListedDevice6Number: res.ap3ListedDevice6Number,

      ap3ListedDevice7Code: res.ap3ListedDevice7Code,
      ap3ListedDevice7Label: res.ap3ListedDevice7Label,
      ap3ListedDevice7Number: res.ap3ListedDevice7Number,

      ap3ListedDevice8Code: res.ap3ListedDevice8Code,
      ap3ListedDevice8Label: res.ap3ListedDevice8Label,
      ap3ListedDevice8Number: res.ap3ListedDevice8Number,

      ap3ListedDevice9Code: res.ap3ListedDevice9Code,
      ap3ListedDevice9Label: res.ap3ListedDevice9Label,
      ap3ListedDevice9Number: res.ap3ListedDevice9Number,

      ap3ListedDevice10Code: res.ap3ListedDevice10Code,
      ap3ListedDevice10Label: res.ap3ListedDevice10Label,
      ap3ListedDevice10Number: res.ap3ListedDevice10Number,

      ap3ListedDevice11Code: res.ap3ListedDevice11Code,
      ap3ListedDevice11Label: res.ap3ListedDevice11Label,
      ap3ListedDevice11Number: res.ap3ListedDevice11Number,

      ap3Incident: res.ap3Incident,
      ap3Professional: res.ap3Professional,
      ap3DPCI: res.ap3DPCI,
      ap3OtherNature: res.ap3OtherNature,
      ap3ClinicAndParaClinicData: res.ap3ClinicAndParaClinicData,

      ap3FirstAttribution: res.ap3FirstAttribution,
      ap3TemporalDevice: res.ap3TemporalDevice,
      ap3Renewal: res.ap3Renewal,
    });
  }
}
