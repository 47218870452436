import { Doctor } from "../user.entity/doctor.entity";

enum ERadioType {
    OTHER,
    SCANNER,
    RADIO,
    IRM,
    ENDOSCOPY,
    SCINTIGRAPHY
}

export interface IRadiology {
    id_RADIO: string;
    nom_Radio: string;
    type_Radio: ERadioType;
    doctor: Doctor
}
export class Radiology implements IRadiology {

    id_RADIO: string;
    nom_Radio: string;
    type_Radio: ERadioType;
    doctor: Doctor
    constructor(other?: Partial<IRadiology>) {
        this.id_RADIO = other?.id_RADIO || '';
        this.nom_Radio = other?.nom_Radio || '';
        this.type_Radio = other?.type_Radio || ERadioType.OTHER;
        this.doctor = other?.doctor || new Doctor()
    }

    static fromResponse(res: any): Radiology {
        return new Radiology({
            id_RADIO: res.id,
            nom_Radio: res.nom_Radio || res.name,
            type_Radio: typeof (res.type) === 'number' ? res.type : ["OTHER", "SCANNER", "RADIO", "IRM", "ENDOSCOPY", "SCINTIGRAPHY"].indexOf[res.type || 'OTHER'],
            doctor: res.doctor
        })
    }

    stringType(): string {
        return ['Autre', 'Scanner', 'Radio', 'IRM', 'Endocscopy', 'Scintigraphy'][this.type_Radio];
    }
}