<button class="btn  color text-opacity-75 btn-sm" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
  aria-controls="offcanvasRight">
  <i class="fas fa-chart-line"></i>

</button>
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel"></h5>
    <ng-content class="title" select="[header]"></ng-content>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="card-body" style="background-color: rgb(228, 228, 228);">
    <app-medic-checkbox-component (values)="medicaments" (valueChange)="_change()"
      [placeHolder]="'Médicament'"></app-medic-checkbox-component>
  </div>
</div>