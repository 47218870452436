import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ModalComponent } from "src/app/common/modules/modal.module/modal/modal.component";
import { Antecedent } from "src/app/entities/atced.entity/antecedent";
import { Consultation } from "src/app/entities/consultation.entity";
import { ConsultationCnam } from "src/app/entities/consultation.entity/consultation-cnam.entity/consultation-cnam";
import { TextFillings } from "src/app/entities/text-fillings.entity/text-fillings";
import { DoctorPatientAntecedentsService } from "src/app/services/doctor-patient-antecedents.services/doctor-patient-antecedent.service";
import { TextFillingsService } from "src/app/services/fillings.service/text-fillings.service";

@Component({
  selector: "app-consultation-form-cnam",
  templateUrl: "./consultation-form-cnam.component.html",
  styleUrls: ["./consultation-form-cnam.component.css"],
})
export class ConsultationFormCnamComponent implements OnInit {
  @Input() consultation: Consultation = new Consultation();
  @Output() consultationChange: EventEmitter<Consultation> = new EventEmitter();
  @Output() Question1Ap4: EventEmitter<void> = new EventEmitter()
  @Output() Question2Ap4: EventEmitter<void> = new EventEmitter()
  @Output() Question3Ap4: EventEmitter<void> = new EventEmitter()

  open: boolean = false;
  tableauTextFillings!: Array<TextFillings>;
  questions: TextFillings | any = new TextFillings({});
  @ViewChild(ModalComponent, { read: ModalComponent, static: false })
  dialog!: ModalComponent;
  doctorPatientAntecedents: Antecedent = new Antecedent();
  constructor(
    private textFillingsServices: TextFillingsService,
    private toastrService: ToastrService,
    private doctorPatientAntecedentsService: DoctorPatientAntecedentsService
  ) { }

  ngOnInit(): void {
    // this.doctorPatientAntecedentsService.one(this.consultation.appointment.patient.id).subscribe(
    //   (res) => {
    //     this.doctorPatientAntecedents = Antecedent.fromResponse(res);
    //     if ((this.consultation.id || "").length) {

    //       this.consultation.ap4Question1 = this.getDefaultQuestion1Fill();
    //     } else {
    //       this.consultation.ap4Question1 = this.getdoctorPatientAntecedentsFill();
    //       this.consultation.ap4DebutReeducation=new Date().getTime()

    //     }
    //   },
    //   (error) => {}
    // );
  }
  valueParDefautQuestion1() {
    this.Question1Ap4.emit()
  }
  valueParDefautQuestion2() {
    this.Question2Ap4.emit()
  }
  valueParDefautQuestion3() {
    this.Question3Ap4.emit()
  }
  openDialoge(text: string): void {
    this.questions = this.tableauTextFillings.find((elm) => elm.label == String(text));
    this.questions == undefined ? (this.questions = new TextFillings({ label: text })) : "";

    this.dialog.open();
  }

  callUpdateQuestion(updateQuestion: TextFillings) {
    this.textFillingsServices.updateTextFillingsDoctor(String(updateQuestion.id), this.questions).subscribe((res) => {
      this.toastrService.info("Mise a jour  Question");
      this.questions = res;
      this.dialog.close();
    });
  }
  callAddQuestion(question: TextFillings): void {
    this.questions = question;
    this.textFillingsServices.addTextFillings(question).subscribe((res) => {
      this.toastrService.success("Question Ajouter");

      window.location.reload();
    });
  }
  getDefaultQuestion1Fill(): string {
    let tokens = new Array();
    if (this.doctorPatientAntecedents) {
      if (this.doctorPatientAntecedents.allergieAlert) tokens.push("Alergie \n" + this.doctorPatientAntecedents.allergie);
      if (this.doctorPatientAntecedents.familiauxAlert) tokens.push("Familiaux\n" + this.doctorPatientAntecedents.familiaux);
      if (this.doctorPatientAntecedents.medicauxAlert) tokens.push("Medicaux\n" + this.doctorPatientAntecedents.medicaux);
      if (this.doctorPatientAntecedents.pharmacieAlert) tokens.push("Pharmacy\n" + this.doctorPatientAntecedents.pharmacie);
      if (this.doctorPatientAntecedents.chirugicauxAlert) tokens.push("Chirugicaux\n" + this.doctorPatientAntecedents.chirugicaux);
      if (this.doctorPatientAntecedents.intoreranceAlert) tokens.push("Intorerance\n" + this.doctorPatientAntecedents.intorerance);
      if (this.doctorPatientAntecedents.rhumatoAlert) tokens.push("Rhumato\n" + this.doctorPatientAntecedents.rhumato);
    }
    tokens = [
      this.consultation.ap4Question1,
    ];
    return tokens.join("\n");
  }
  getdoctorPatientAntecedentsFill(): string {
    let tokens = new Array();
    if (this.doctorPatientAntecedents) {
      if (this.doctorPatientAntecedents.allergieAlert) tokens.push("Alergie \n" + this.doctorPatientAntecedents.allergie);
      if (this.doctorPatientAntecedents.familiauxAlert) tokens.push("Familiaux\n" + this.doctorPatientAntecedents.familiaux);
      if (this.doctorPatientAntecedents.medicauxAlert) tokens.push("Medicaux\n" + this.doctorPatientAntecedents.medicaux);
      if (this.doctorPatientAntecedents.pharmacieAlert) tokens.push("Pharmacy\n" + this.doctorPatientAntecedents.pharmacie);
      if (this.doctorPatientAntecedents.chirugicauxAlert) tokens.push("Chirugicaux\n" + this.doctorPatientAntecedents.chirugicaux);
      if (this.doctorPatientAntecedents.intoreranceAlert) tokens.push("Intorerance\n" + this.doctorPatientAntecedents.intorerance);
      if (this.doctorPatientAntecedents.rhumatoAlert) tokens.push("Rhumato\n" + this.doctorPatientAntecedents.rhumato);
    }
    tokens = [...tokens];
    return tokens.join("\n");
  }
}
