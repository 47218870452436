import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DoctorPatient } from 'src/app/entities/doctor-patient.entity/doctor-patient';

@Component({
  selector: 'app-doctor-patients-list',
  templateUrl: './doctor-patients-list.component.html',
  styleUrls: ['./doctor-patients-list.component.css']
})
export class DoctorPatientsListComponent implements OnInit {

  @Input() perPage: number = 10;
  @Input() doctorPatients: Array<DoctorPatient> = new Array();
  currentPage: number = 0;
  @Output() onAssignAppointmentRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onAntecedentsRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onUpdateRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onDeleteRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onPrescriptionsRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onConsultationsRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Input() actions: Partial<{ edit: boolean, delete: boolean, assignAppointment: boolean, prescriptionsView: boolean, consultationsView: boolean, antecentendsView: boolean, payementsView: boolean }> = {}

  constructor() { }

  ngOnInit(): void { }

}
