import { Pipe, PipeTransform } from '@angular/core';


export const LiteralMonths = ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];
export const LiteralDays = ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.']
export const LiteralDaysArab =["الأحد","الاثنين", "الثلاثاء","الأربعاء",'الخميس',"الجمعة","السبت"]

@Pipe({
  name: 'frenchDate'
})
export class FrenchDatePipe implements PipeTransform {
  transform(date: Date | number | string, options?: any): string {
    let d = new Date(date);
    
    return `${d.getDate()} ${LiteralMonths[d.getMonth()]} ${d.getFullYear()}`;
  }

  getLetiralMonth(date: Date): string {
    return LiteralMonths[date.getMonth()];
  }
}
