<div *ngFor="let parametreSanteFormGroupControler of parametreSanteForm.controlers;let i = index">
  <table class="table table-hover table-center">
    <thead>
      <tr >
        <th>Œil Droit</th>
        <th style="text-align: center;">
          <span> {{ parametreSanteFormGroupControler.label }}</span>
        </th>
        <th>Œil Gauche
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let parametreSanteFormControler of parametreSanteForm.controlers[i].controlers;let j = index">
        <td >
          <div *ngIf="parametreSanteFormControler.target.type !== 4">
            <input [type]="['number', 'date','text','checkbox'][parametreSanteFormControler.target.type]" style="width: 63px;"
              [(ngModel)]="parametreSanteForm.controlers[i].controlers[j].value_OD" (change)="_onChange()">
          </div>
          <select [name]="parametreSanteFormControler.target.label"
            *ngIf="parametreSanteFormControler.target.type === 4"
            [(ngModel)]="parametreSanteForm.controlers[i].controlers[j].value_OD" (change)="_onChange()">
            <option *ngFor="let option of parametreSanteFormControler.target.splitValues.split(',')" [value]="option">
              {{option}}</option>
          </select>
        </td>
        <td style="text-align: center;">
          <span>{{ parametreSanteFormControler.target.label }}</span>
        </td>
        <td>
          <div *ngIf="parametreSanteFormControler.target.type !== 4">
            <input [type]="[ 'number', 'date','input','checkbox'][parametreSanteFormControler.target.type]"
              [(ngModel)]="parametreSanteForm.controlers[i].controlers[j].value_OG" (change)="_onChange()">
          </div>
          <select [name]="parametreSanteFormControler.target.label"
            *ngIf="parametreSanteFormControler.target.type === 4"
            [(ngModel)]="parametreSanteForm.controlers[i].controlers[j].value_OG" (change)="_onChange()">
            <option *ngFor="let option of parametreSanteFormControler.target.splitValues.split(',')" [value]="option">
              {{option}}</option>
          </select>
        </td>
       
      </tr>
    </tbody>
  </table>
</div>