import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { DoctorAvailability } from 'src/app/entities/doctor-disponiblity.entity/doctor-availability';;;

@Component({
  selector: 'app-assign-location-both',
  templateUrl: './assign-location-both.component.html',
  styleUrls: ['./assign-location-both.component.css']
})

export class AssignLocationBothComponent implements OnInit {
  @Input() disponibility!: DoctorAvailability;
  @Input() bothAppointement!: boolean
  @Output() disponibilityChange: EventEmitter<EDalyTimeSlotLocation> = new EventEmitter()
  locationApponitment!: EDalyTimeSlotLocation
  constructor() { }

  ngOnInit(): void {

  }

}
