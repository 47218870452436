<div class="row" style="background-color: transparent;">
    <div class="col-lg-11">
        <select-drop-down [items]="radiologiesList" [(ngModel)]="value" [options]="{ filter:{ byKey:'nom_Radio',with:'containe' } }" name="nom_Radio">
            <ng-template let-item="item">{{item.nom_Radio}}</ng-template>
        </select-drop-down>
    </div>
    <div class="col-lg-1">
        <button class="openDialoge" (click)="openDialoge()">
            <i class="feather-plus-circle"></i>
        </button>
    </div>
</div>
<app-modal [title]="'Ajouter Nouveau Radio'" #dialog>
    <app-radiology-form #radiologyForm></app-radiology-form>
    <button class="btn btn-outline-info mb-3 mt-3" (click)="onAddRadio()">Ajouter Radio</button>
</app-modal>