import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

export enum EFileContentType {
  UNKNOWN,
  IMAGE,
  VIDEO,
  AUDIO,
  TEXT,
  MSWORD,
  PDF,
}
@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.css']
})
export class FileViewerComponent implements OnInit {

  @Input() src: string = '';
  @Output() close: EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  get extension(): string {
    return ((this.src || '').split('.').pop() || '').trim().toLocaleLowerCase();
  }

  get contentType(): EFileContentType {
    if ([
      "avif",
      "ase",
      "art",
      "bmp",
      "blp",
      "cd5",
      "cit",
      "cpt",
      "cr2",
      "cut",
      "dds",
      "dib",
      "djvu",
      "egt",
      "exif",
      "gif",
      "gpl",
      "grf",
      "icns",
      "ico",
      "iff",
      "jng",
      "jpeg",
      "jpg",
      "jfif",
      "jp2",
      "jps",
      "lbm",
      "max",
      "miff",
      "mng",
      "msp",
      "nef",
      "nitf",
      "ota",
      "pbm",
      "pc1",
      "pc2",
      "pc3",
      "pcf",
      "pcx",
      "pdn",
      "pgm",
      "PI1",
      "PI2",
      "PI3",
      "pict",
      "pct",
      "pnm",
      "pns",
      "ppm",
      "psb",
      "psd",
      "pdd",
      "psp",
      "px",
      "pxm",
      "pxr",
      "qfx",
      "raw",
      "rle",
      "sct",
      "sgi",
      "rgb",
      "int",
      "bw",
      "tga",
      "tiff",
      "tif",
      "vtf",
      "xbm",
      "xcf",
      "xpm",
      "3dv",
      "amf",
      "ai",
      "awg",
      "cgm",
      "cdr",
      "cmx",
      "dxf",
      "e2d",
      "egt",
      "eps",
      "fs",
      "gbr",
      "odg",
      "svg",
      "stl",
      "vrml",
      "x3d",
      "sxd",
      "v2d",
      "vnd",
      "wmf",
      "emf",
      "art",
      "xar",
      "png",
      "webp",
      "jxr",
      "hdp",
      "wdp",
      "cur",
      "ecw",
      "iff",
      "lbm",
      "liff",
      "nrrd",
      "pam",
      "pcx",
      "pgf",
      "sgi",
      "rgb",
      "rgba",
      "bw",
      "int",
      "inta",
      "sid",
      "ras",
      "sun",
      "tga",
      "heic",
      "heif"
    ].includes(this.extension)) return EFileContentType.IMAGE;
    else if ([
      "3g2",
      "3gp",
      "aaf",
      "asf",
      "avchd",
      "avi",
      "drc",
      "flv",
      "m2v",
      "m3u8",
      "m4p",
      "m4v",
      "mkv",
      "mng",
      "mov",
      "mp2",
      "mp4",
      "mpe",
      "mpeg",
      "mpg",
      "mpv",
      "mxf",
      "nsv",
      "ogg",
      "ogv",
      "qt",
      "rm",
      "rmvb",
      "roq",
      "svi",
      "vob",
      "webm",
      "wmv",
      "yuv"
    ].includes(this.extension)) return EFileContentType.VIDEO;
    else if ([
      "docx",
      "docm",
      "dotx",
      "dotm"
    ].includes(this.extension)) return EFileContentType.MSWORD;
    else if (this.extension == 'pdf') return EFileContentType.PDF;
    else if (this.extension == 'txt') return EFileContentType.TEXT;
    else if (["3ga", "a52", "aif", "aifc", "alac", "amr", "ape", "atrac", "au",
      "awb", "dct", "dss", "dvf", "dwa", "eac3", "flac", "gsm", "iklax",
      "ivs", "kar", "lvp", "m4a", "m4b", "m4p", "mka", "mlp", "mmf", "mp2",
      "mp3", "mpc", "mpga", "msv", "nra", "nsf", "oga", "ogg", "opus", "pjs",
      "ra", "ram", "raw", "sln", "tak", "tta", "u", "vox", "vqf", "w64", "wav",
      "webm", "wma", "wv"].includes(this.extension)) return EFileContentType.AUDIO;
    else return EFileContentType.UNKNOWN;
  }
}
