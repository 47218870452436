import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { Consultation } from 'src/app/entities/consultation.entity';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsultationService extends ApiService {



  all(pageRequest?: PageRequest): Observable<any> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/consultations`, { params: params }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/consultations/all`).pipe(this.responsePipe)
  }

  search(searchQuery: { birthDay?: Date | undefined; query: string; }, pageRequest?: PageRequest) {
    let params = new HttpParams();
    if (searchQuery.birthDay) params = params.append('birthdate', searchQuery.birthDay.getTime());
    if (searchQuery.query.length != 0) params = params.set('q', searchQuery.query);
    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/consultations/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }
  searchByDate(searchQuery: { dateDebut?: Date | undefined; dateFin?: Date | undefined; query: string; }, pageRequest?: PageRequest) {
    let params = new HttpParams();
    if (searchQuery.dateDebut) params = params.append('after', searchQuery.dateDebut.getTime());
    if (searchQuery.dateFin) params = params.append('before', searchQuery.dateFin.getTime());

    if (searchQuery.query.length != 0) params = params.set('q', searchQuery.query);
    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/consultations`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }
  add(appointmentId: string, consultation: Consultation): Observable<Consultation> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/appointments/${appointmentId}/consultations`, consultation.requestPayload).pipe(this.responsePipe)
  }

  update(consultationId: string, updates: Consultation): Observable<Consultation> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/consultations/${consultationId}`, updates.requestPayload).pipe(this.responsePipe);
  }

  getConsultationByMedecinPatient(

    patientId: string
  ): Observable<Array<Consultation>> {
    return this.http.get<ApiResponse>(

      `${environment.API_URL}/doctor/patients/${patientId}/consultations`
      , { responseType: 'json' }).pipe(this.responsePipe);
  }

  getConsultationPatientById(idConsultation: string): Observable<Consultation> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/consultations/${idConsultation}`
      , { responseType: 'json' }).pipe(this.responsePipe);

  }

  delete(idConsultation: string) {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/consultations/${idConsultation}`
    ).pipe(this.responsePipe);
  }

  getConsultationByDoctor(): Observable<Consultation> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/consultations`).pipe(this.responsePipe)
    //  http://102.219.178.237:8080/cabinet-0.0.1-SNAPSHOT_DEV/api/doctor/fff93183-a8e1-4f67-b0be-ad18a2ec03c6/consultations
  }

  getMotifs(): Observable<Array<any>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/consultations/tags/motif`).pipe(this.responsePipe)
  }


}
