import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../../modules/modal.module/modal/modal.component';
import { Observable, Subscriber } from 'rxjs';
import { ConfirmDialogResult } from '../confirm-dialog.component/confirm-dialog.component';

export interface PromptDialogResult {
  action: ConfirmDialogResult,
  value: string | number | null;
}

@Component({
  selector: 'prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.css']
})
export class PromptDialogComponent {

  @Input() title: string = '';
  @Input() subject: string = '';
  @Input() value: string | number = '';
  @Output() onAccept: EventEmitter<void> = new EventEmitter();
  @Output() onReject: EventEmitter<void> = new EventEmitter();
  @Output() change: EventEmitter<string | number> = new EventEmitter();
  @ViewChild(ModalComponent, { read: ModalComponent, static: false }) modal!: ModalComponent;

  private _subscriber!: Subscriber<PromptDialogResult>;

  constructor() { }

  accept(): void {
    this._subscriber.next({ action: ConfirmDialogResult.ACCEPTED, value: this.value });
    this.onAccept.emit();
    this._subscriber.complete();
    this.modal.close();
  }

  reject(): void {
    this._subscriber.next({ action: ConfirmDialogResult.REJECTED, value: null });
    this.onReject.emit()
    this._subscriber.complete();
    this.modal.close();
  }

  open(options?: { title?: string, subject?: string, value?: string | number }): Observable<PromptDialogResult> {
    if (options && options.title) this.title = options.title;
    if (options && options.subject) this.subject = options.subject;
    if (options && options.value) this.value = options.value;

    return new Observable<PromptDialogResult>(subscriber => {
      this._subscriber = subscriber;
      this.modal.open();
    })
  }



}
