<div class="card p-3">
    <div class="row">
        <div class="col-lg-6 col-sm-12">
            <label class="mt-1">Nom *</label>
            <input type="text" class="form-control" [(ngModel)]="medic.nom" (ngModel)="_change()" />
            <span *ngIf="isSubmitted && medic.nom.length==0" style="color:red">Medic name est obligatoire</span>
        </div>
        <div class="col-lg-6 col-sm-12">
            <label class="mt-1">Nom Génèrique</label>
            <input type="text" class="form-control" [(ngModel)]="medic.nom_generique" (ngModel)="_change()" />
            <!-- <span *ngIf="isSubmitted && medic.nom_generique.length==0" style="color:red">Medic generic name is
                required</span> -->
        </div>
        <div class="col-lg-6 col-sm-12">
            <label class="mt-1">Dosage</label>
            <input type="datetime" class="form-control" [(ngModel)]="medic.dosage" (ngModel)="_change()" />
            <!-- <span *ngIf="isSubmitted && medic.dosage.length==0" style="color:red">Medic dosage est obligatoire</span> -->
        </div>
        <div class="col-lg-6 col-sm-12">
            <label class="mt-1">Presentation</label>
            <input type="text" class="form-control" [(ngModel)]="medic.presentation" (ngModel)="_change()" />
            <!-- <span *ngIf="isSubmitted && medic.dosage.length==0" style="color:red">Medic presentation est obligatoire</span> -->

        </div>
        <div class="col-lg-6 col-sm-12">
            <label class="mt-1">Laboratoire</label>
            <input type="text" class="form-control" [(ngModel)]="medic.labo" (ngModel)="_change()" />
            <!-- <span *ngIf="isSubmitted && medic.labo.length==0" style="color:red">Medic labo est obligatoire</span> -->
        </div>
        <ng-template *ngIf="false">
            <div class="col-lg-6 col-sm-12">
                <label class="mt-1">Co</label>
                <input type="text" class="form-control" [(ngModel)]="medic.co" (ngModel)="_change()" />
            </div>
            <div class="col-lg-6 col-sm-12">
                <label class="mt-1">Pays</label>
                <input type="text" class="form-control" [(ngModel)]="medic.pays" (ngModel)="_change()" />
            </div>
            <div class="col-lg-6 col-sm-12">
                <label class="mt-1">Date Amm</label>
                <input type="date" class="form-control" [(ngModel)]="medic.date_amm" (ngModel)="_change()" />
            </div>
            <div class="col-lg-6 col-sm-12">
                <label class="mt-1">GP</label>
                <input type="text" class="form-control" [(ngModel)]="medic.g_p" (ngModel)="_change()" />
            </div>
            <div class="col-lg-6 col-sm-12">
                <label class="mt-1">RCP</label>
                <input type="text" class="form-control" [(ngModel)]="medic.rcp" (ngModel)="_change()" />
            </div>
        </ng-template>
    </div>
</div>