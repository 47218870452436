import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Biology } from "src/app/entities/biology.entity/biology";
import { BioPrescription } from "src/app/entities/prescription.entity/bio-prescription.entity/bio-prescription";
import { BiologiesService } from "src/app/services/biologies.service/biologies.service";
import { BiologyFormComponent } from "../../biology-form.module/biology-form.component/biology-form.component";
import { BiologesFilter } from "../../biology-form.module/biology-select-drawer/biology-select-drawer.component";

export class PrescriptionItemValidation {
  controlsValidation: Record<symbol, symbol | null> = {};
  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every((elm) => elm === null);
  }
}

export class BiologyPrescriptionItemValidation extends PrescriptionItemValidation {
  controlsValidation: { biology: "required" | null; notes: "required" | null } = {
    biology: null,
    notes: null,
  };

  constructor(controlsValidation: { biology: "required" | null; notes: "required" | null }) {
    super();
    this.controlsValidation = controlsValidation;
  }

  static validate(biologyItem: BioPrescription): BiologyPrescriptionItemValidation {
    return biologyItem.biology != undefined && biologyItem.biology.id_BIO != undefined
      ? new BiologyPrescriptionItemValidation({
          biology: null, //!String(biologiePrescriptionItem.biology.id_BIO).length ? 'required' : null,
          notes: null, //!biologiePrescriptionItem.note ? 'required' : null
        })
      : new BiologyPrescriptionItemValidation({ biology: null as any, notes: null as any });
  }
}

@Component({
  selector: "app-biology-prescription-form",
  templateUrl: "./biology-prescription-form.component.html",
  styleUrls: ["./biology-prescription-form.component.css"],
})
export class BiologyPrescriptionFormComponent implements OnInit {
  @Input() biologyPrescriptionItems: Array<BioPrescription> = new Array();
  @Output() biologyPrescriptionItemsChange: EventEmitter<Array<BioPrescription>> = new EventEmitter();
  @Input() submitted: boolean = false;
  @Output() validation: EventEmitter<boolean> = new EventEmitter();
  biologies: Array<Biology> = new Array();
  biologiesFilter: Array<BiologesFilter> = new Array({bioPrescription:new BioPrescription(),selected:false,index:0});
  biology: string = "";
  @ViewChild("biologyForm", { read: BiologyFormComponent, static: true }) biologyForm!: BiologyFormComponent;
  drawerActive: boolean = false;
  get isValid(): boolean {
    return this.biologyPrescriptionItems.every((item) => this.validatePrescriptionItem(item).isValid);
  }

  constructor(private biologiesService: BiologiesService, private toasterService: ToastrService) {}

  ngOnInit(): void {
    this.biologiesService.all().subscribe(
      (biologies) => {
        this.biologies = Array.from(biologies, (e) => Biology.fromResponse(e)).filter((e) => e.indication_Bio == "P" && e.norme_Bio == "P");

      },
      (error) => this.toasterService.error("Enable to laod biologies!")
    );
    
    // this.biologyPrescriptionItems.map((elm:BioPrescription)=> this.biologiesFilter.push({bioPrescription:elm,selected:true}))
  
  }

  addPrescriptionItem(): void {
    let bioPrescription: BioPrescription = new BioPrescription({ biology: null as any });
    bioPrescription.biology = null as any;
    this.biologyPrescriptionItems.push(new BioPrescription({ biology: null as any }));
    this._change();
  }
  closed(): void {
    this.biologiesFilter.map((elm) => {
      
      return this.biologyPrescriptionItems.push(elm.bioPrescription);
    });
    // Étape 1: Utiliser reduce pour compter les occurrences des objets biologie
    const countBiologies = this.biologiesFilter.reduce((acc, obj) => {
      const bioKey = JSON.stringify(obj.bioPrescription.biology);
      acc[bioKey] = (acc[bioKey] || 0) + 1;
      return acc;
    }, {});

    // Étape 2: Utiliser filter pour sélectionner les objets dont biologie apparaît plus d'une fois
    const biologies = this.biologiesFilter.filter((obj, index, self) => {
      const bioKey = JSON.stringify(obj.bioPrescription.biology);
      return (
        countBiologies[bioKey] > 1 &&
        self.findIndex((o) => this.deepEqual(o.bioPrescription.biology, obj.bioPrescription.biology)) === index
      );
    });
  

    this.biologiesFilter = biologies;
    const countBiologiesItem = this.biologyPrescriptionItems.reduce((acc, obj) => {
      const bioKey = JSON.stringify(obj.biology);
      acc[bioKey] = (acc[bioKey] || 0) + 1;
      return acc;
    }, {});

    // Étape 2: Utiliser filter pour sélectionner les objets dont biologie apparaît plus d'une fois
    const biologiesitem = this.biologyPrescriptionItems.filter((obj, index, self) => {
      const bioKey = JSON.stringify(obj.biology);
      return (
        countBiologiesItem[bioKey] > 1 &&
        self.findIndex((o) => this.deepEqual(o.biology, obj.biology)) === index
      );
    });
    this.biologyPrescriptionItems=this.biologyPrescriptionItems.filter(elm=>elm.biology.element_Bio!=="")   
    this.drawerActive = false;
  }
  deepEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  deletePrescriptionItem(index: number): void {
    this.biologyPrescriptionItems.splice(index, 1);
    this._change();
  }
  onEditPrescriptionItem(index: number): void {
    this.validation.emit(this.isValid);
  }

  validatePrescriptionItem(biologiePrescriptionItem: BioPrescription): BiologyPrescriptionItemValidation {
    return BiologyPrescriptionItemValidation.validate(biologiePrescriptionItem);
  }

  addBiologySubmit(): void {
    this.biologyForm.isSubmitted = true;
    if (this.biologyForm.isValid) {
      this.biologiesService.add(this.biologyForm.biology).subscribe(
        (bio) => {
          this.biologies = [bio, ...this.biologies];
          this.biologyForm.biology = new Biology();
          this.biologyForm.isSubmitted = false;
        },
        (error) => this.toasterService.error("Enable to add radiology")
      );
    }
  }

  _change(): void {
    this.biologyPrescriptionItemsChange.emit(this.biologyPrescriptionItems);
    this.validation.emit(this.isValid);
  }
}
