<div *ngIf="subscriber" class="prompt-dialog">
    <div class="overlay" (click)="dismiss(false)"></div>
    <div class="dialog">
        <label>{{title}}</label>
        <input *ngIf="type == 'file'" type="file" (change)="fileChanged($event)">
        <input *ngIf="type == 'number'" type="number" [(ngModel)]="data">
        <input *ngIf="type == 'text'" type="text" [(ngModel)]="data">
        <hr>
        <div class="dialog-actions">
            <button (click)="dismiss(false)" style="background-color: red;color: white;">Skip</button>
            <button (click)="dismiss(true)" style="background-color: green;color: white;">OK</button>
        </div>
    </div>
</div>