<nav class="tree-nav">

    <a class="tree-nav-item-title">
        <i class="icon ion-scissors"></i>
        THE INHERITANCE
    </a>
    <a class="tree-nav-item-title">
        <i class="icon ion-android-star"></i>
        The Willful Princess and the Piebald Prince
    </a>

    <details class="tree-nav-item is-expandable">
        <summary class="tree-nav-item-title">The Realm of the Elderlings</summary>
        <details class="tree-nav-item is-expandable">
            <summary class="tree-nav-item-title">The Six Duchies</summary>
            <details class="tree-nav-item is-expandable">
                <summary class="tree-nav-item-title">The Farseer Trilogy</summary>
                <div class="tree-nav-item">
                    <table style="width:100%">
                        <tr>
                            <th>IP Address</th>
                            <th>Object Name</th>
                            <th>Domain Name</th>
                            <th>Object Class</th>
                            <th>Status</th>
                            <th>Mac Address</th>
                        </tr>
                        <tr>
                            <td>Jill</td>
                            <td>Smith</td>
                            <td>50</td>
                        </tr>
                        <tr>
                            <td>Eve</td>
                            <td>Jackson</td>
                            <td>94</td>
                        </tr>
                        <tr>
                            <td>John</td>
                            <td>Doe</td>
                            <td>80</td>
                        </tr>
                    </table>
                </div>
            </details>

            <details class="tree-nav-item is-expandable">
                <summary class="tree-nav-item-title">The Tawny Man Trilogy</summary>
                <div class="tree-nav-item">
                    <a class="tree-nav-item-title"><i class="icon ion-cube"></i> FOOL'S ERRAND</a>
                    <a class="tree-nav-item-title"><i class="icon ion-ios-book"></i> GOLDEN FOOL</a>
                    <a class="tree-nav-item-title"><i class="icon ion-ios-bookmarks"></i> FOOL'S FATE</a>
                </div>
            </details>

            <details class="tree-nav-item is-expandable">
                <summary class="tree-nav-item-title">The Fitz and the Fool Trilogy</summary>
                <div class="tree-nav-item">
                    <a class="tree-nav-item-title"><i class="icon ion-ios-bookmarks"></i> FOOL'S ASSASSIN</a>
                    <a class="tree-nav-item-title"><i class="icon ion-ios-book"></i> FOOL'S QUEST</a>
                    <a class="tree-nav-item-title"><i class="icon ion-android-bookmark"></i> Assassin's Fate</a>
                </div>
            </details>


        </details>
    </details>

    <details class="tree-nav-item is-expandable">
        <summary class="tree-nav-item-title">The Soldier Son trilogy</summary>
        <div class="tree-nav-item">
            <a class="tree-nav-item-title"><i class="icon ion-edit"></i> SHAMAN'S CROSSING</a>
            <a class="tree-nav-item-title"><i class="icon ion-paperclip"></i> FOREST MAGE</a>
        </div>
    </details>
</nav>