<div class="input-control" style="height: 40px;">
    <div [innerHTML]="prependHtml" *ngIf="prependHtml">
    </div>
    <span *ngIf="prepend">
        <!-- fa fa-search -->
        <i [ngClass]="prepend"></i>
    </span>
    <input [type]="type" [placeholder]="placeholder" (input)="onInputChange($event)" (keydown)="onKeyDown($event)"
        [value]="value" [disabled]="disabled" >

    <span *ngIf="append">
        <!-- fa fa-search -->
        <i [ngClass]="append"></i>
    </span>
    <div [innerHTML]="appendHtml" *ngIf="appendHtml">
    </div>
</div>