import { Component, OnInit, Renderer2 } from "@angular/core";
import { Chart, ChartType } from "chart.js";
import * as moment from "moment";
import { DoctorDashboardService } from "src/app/services/doctor-dashboard.service/doctor-dashboard.service";

@Component({
  selector: "app-chart-consultation",
  templateUrl: "./chart-consultation.component.html",
  styleUrls: ["./chart-consultation.component.css"],
})
export class ChartConsultationComponent implements OnInit {
  constructor(private doctorDashboardService: DoctorDashboardService, private renderer: Renderer2) {}
  public chart?: Chart;
  public consultationsMotifsStatistics: Array<{ id: string; label: string; color: string; consultations: number }> = [];

  startDate = new Date(new Date().getFullYear(), new Date().getMonth()-1);
  finDate: Date =  new Date(new Date().getFullYear(), new Date().getMonth() , 0);
  ngOnInit() {
    this.staticRdv();
  }

  staticRdv() {
    const startDate = moment(this.startDate).format("DD/MM/YYYY");
    const endDate = moment(this.finDate).format("DD/MM/YYYY");
    this.doctorDashboardService.staticConsultations(new Date(this.startDate).getTime(), new Date(this.finDate).getTime()).subscribe((res) => {
      this.consultationsMotifsStatistics = res;
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart("consultations", {
        type: "pie" as ChartType,
        data: {
          labels: this.consultationsMotifsStatistics.map((elm) => elm.label),
          datasets: [
            {
              label: "Statistic des motifs des consultations",
              data: this.consultationsMotifsStatistics.map((elm) => elm.consultations),
              backgroundColor: this.consultationsMotifsStatistics.map((elm) => elm.color),
              hoverOffset: 4,
            },
          ],
        },
      });
    });
  }
}
