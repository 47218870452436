import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BioPrescription } from "src/app/entities/prescription.entity/bio-prescription.entity/bio-prescription";
export class BiologesFilter {
  bioPrescription: BioPrescription;
  selected: boolean;
  index: number
  constructor(other?: Partial<BiologesFilter>) {
    this.bioPrescription = other?.bioPrescription || new BioPrescription;
    this.selected = other?.selected || false;
    this.index = other?.index || 0
  }
}
@Component({
  selector: "app-biology-select-drawer",
  templateUrl: "./biology-select-drawer.component.html",
  styleUrls: ["./biology-select-drawer.component.css"],
})
export class BiologySelectDrawerComponent implements OnInit {
  @Input() values: Array<any> = new Array();
  @Output() valuesChange: EventEmitter<any> = new EventEmitter();
  isChecked: boolean = false;
  biology: string = "";
  select: Array<BiologesFilter> = []
  @Input() biologesSelected: Array<BiologesFilter> = new Array();
  @Output() biologesSelectedChange: EventEmitter<Array<BiologesFilter>> = new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  changed(biologie: any, event, index): void {
    if (event.target.checked == true) {
      this.biologesSelected.push({ bioPrescription: new BioPrescription({ biology: biologie }), selected: event.target.checked, index: index });

      this.biologesSelectedChange.emit(this.biologesSelected.filter((elm) => elm.bioPrescription.biology.element_Bio !== ''));

    } else {
      this.biologesSelected = this.biologesSelected.filter((elm) => elm.bioPrescription.biology !== biologie && elm.selected != false);

      this.biologesSelectedChange.emit(this.biologesSelected);
    }

  }
  close(): void {
    this.biologesSelectedChange.emit(this.biologesSelected);

    this.closed.emit();
  }
}
