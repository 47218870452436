import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { PageRequestFn, PageRequest } from 'src/app/common/modules/data-table.module/interfaces';
import { DocumentAttachment, EFileContentType } from 'src/app/entities/document-attachment.entity/document-attachment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents-grid-explorer',
  templateUrl: './documents-grid-explorer.component.html',
  styleUrls: ['./documents-grid-explorer.component.css']
})
export class DocumentsGridExplorerComponent implements OnInit {
  @Input() langue:string="fr"
  @Input() pageRequest!: PageRequestFn<DocumentAttachment>;
  @Input() emptyMessage?: string = 'Aucun document trouvé ';
  @Input() options: { canUpload: boolean, canEdit: boolean, canDelete: boolean, canShare: boolean } = { canUpload: true, canEdit: true, canDelete: true, canShare: true };
  @Output() onUploadRequest: EventEmitter<void> = new EventEmitter();
  @Output() onDeleteRequest: EventEmitter<DocumentAttachment> = new EventEmitter();
  @Output() onEditRequest: EventEmitter<DocumentAttachment> = new EventEmitter();
  @Output() onShareRequest: EventEmitter<DocumentAttachment> = new EventEmitter();
  @Input() refresh?: EventEmitter<PageRequest> = new EventEmitter();
  @Input() documents: Array<DocumentAttachment> = new Array();
  documentPreview: DocumentAttachment | null = null;
  constructor() { }

  ngOnInit(): void {
  }

  onViewRequested($event: Event, documentAttachment: DocumentAttachment): void {
    this.documentPreview = documentAttachment;
    $event.stopPropagation()
  }

  getIconUrl(documentAttachment: DocumentAttachment): string {
    switch (documentAttachment.contentType) {
      case EFileContentType.AUDIO: {
        return "assets/img/icons/audio-file.png"
      }
      case EFileContentType.MSWORD: {
        return "assets/img/icons/msword-file.png"
      }
      case EFileContentType.VIDEO: {
        return "assets/img/icons/video-file.png"
      }
      case EFileContentType.PDF: {
        return "assets/img/icons/pdf-file.png"
      }
      case EFileContentType.TEXT: {
        return "assets/img/icons/txt-file.png"
      }
      case EFileContentType.IMAGE: {
        return `http${environment.API_SSL ? 's' : ''}://${environment.API_HOST_NAME}${environment.PORT ? ":" + environment.PORT : ""}/${environment.STORAGE_ENDPOINT}/${documentAttachment.uri}`
      }
      default: {
        return "assets/img/icons/unknown-file.png"
      }
    }

  }

  _getDocumentAttachmentPreviewSource(documentAttachment: DocumentAttachment): string {
    return `http${environment.API_SSL ? 's' : ''}://${environment.API_HOST_NAME}${environment.PORT ? ":" + environment.PORT : ""}/${environment.STORAGE_ENDPOINT}/${documentAttachment.uri}`;
  }

  _onChange(): void {
    this.refresh?.next()
  }

}
