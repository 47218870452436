import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConsultationOphtalmo } from 'src/app/entities/consultation.entity/consultation-ophtalmo.entity/consultation-ophtalmo';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { parse } from 'csv-parse';
import * as csvParser from 'csv-parser';
@Component({
  selector: 'app-consultations-lunette-form',
  templateUrl: './consultations-lunette-form.component.html',
  styleUrls: ['./consultations-lunette-form.component.css']
})
export class ConsultationsLunetteFormComponent implements OnInit {
  patientData: { parameter: string; value: string | null }[] = [];

  @Input() consultation: ConsultationOphtalmo = new ConsultationOphtalmo()
  @Output() consultationChange: EventEmitter<ConsultationOphtalmo> = new EventEmitter
  od_refraction_Cylindre: boolean = true;

  configurableMin1: number = 20;
  configurableMin2: number = 20;
  control1 = new FormControl(20)
  control2 = new FormControl(20)
  value = 2;

  od_refraction_Axe: boolean = true;
  lunetteForm!: FormGroup
  constructor(private formBuilder: FormBuilder) {
    this.lunetteForm = this.formBuilder.group({
      od_refraction_Cylindre: ['', [Validators.min(1), Validators.max(4)]],
    });


  }
  setValues(value: number): void {
    this.control1.setValue(value);
    this.control2.setValue(value)
    this.value = value;

    document
      .querySelectorAll<HTMLInputElement>('input.native')
      .forEach(input => (input.value = '' + value));
  }

  get f() {
    return this.lunetteForm.controls;
  }
  ngOnInit(): void {


  }


  readXML(): void {
    const input = document.createElement('input');
    input.type = 'file';

    input.accept = '.xml ';
    input.addEventListener('change', (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const result = e.target?.result as string;

          this.parseXML(result);

        };

        reader.onerror = (error) => {
          console.error('File reading error:', error);
        };

        reader.onloadend = () => {
        };

        // Create a Blob from the file
        const blob = new Blob([file], { type: 'text/xml' });

        // Trigger the file read
        reader.readAsText(blob);

      }
    });

    input.click();
  }



  loadXML(): void {
    const filePath = 'assets/patient.xml'; // Adjust the path accordingly

    const reader = new FileReader();

    reader.onload = (e) => {
      const result = e.target?.result as string;
      this.parseXML(result);
    };

    reader.onerror = (error) => {
      console.error('File reading error:', error);
    };

    reader.onloadend = () => {
    };

    fetch(filePath)
      .then(response => response.text())
      .then(data => {
        reader.readAsText(new Blob([data], { type: 'text/xml' }));
      })
      .catch(error => {
        console.error('File fetching error:', error);
      });
  }
  parseXML(data: string): void {
    if (!data) {
      console.error('XML Content is empty or invalid.');
      return;
    }
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(data, 'text/xml');
      const errorElement = xmlDoc.getElementsByTagName('parsererror')[0];
      if (errorElement) {
        console.error('Error in XML:', errorElement.textContent);
        return;
      }

      const dataNode = xmlDoc.getElementsByTagName('DATA')[0];
      if (dataNode) {
        let pd;
        pd = dataNode.getElementsByTagName('PD')[0]?.textContent;
        this.consultation.refractionPd = parseFloat(pd);
        const refNode = dataNode.getElementsByTagName('REF')[0];
        if (refNode) {
          let vd
          vd = refNode.getElementsByTagName('VD')[0]?.textContent;
          this.consultation.refractionVd = parseFloat(vd);
          this.extractSCAValues('R', refNode.getElementsByTagName('R')[0]);
          this.extractSCAValues('L', refNode.getElementsByTagName('L')[0]);
        }

        const kerNode = dataNode.getElementsByTagName('KER')[0];
        if (kerNode) {
          let index
          index = kerNode.getElementsByTagName('INDEX')[0]?.textContent;
          this.consultation.kerato_Index = parseFloat(index);
          this.extractRRANode('R', kerNode.getElementsByTagName('R')[0]);
          this.extractRRANode('L', kerNode.getElementsByTagName('L')[0]);
          // Extract CYL value
          const cylValueR = kerNode.getElementsByTagName('CYL')[0].textContent;
          const cylValueL = kerNode.getElementsByTagName('CYL')[1].textContent;
          if (cylValueR) {
            const cylArray = cylValueR.split(' ');
            cylArray.forEach((value, index) => {

              if (index === 0) {
                const variable1 = value;
                this.consultation.od_kerato_CYL_D = parseFloat(variable1);
              }

              else if (index === 1) {
                const variable2 = value;
                this.consultation.od_kerato_CYL_Ax = parseFloat(variable2);
              } else if (index === 2) {

              }
            });
          }

          if (cylValueL) {
            const cylArray = cylValueL.split(' ');
            cylArray.forEach((value, index) => {
              if (index === 0) {
                const variable1 = value;
                this.consultation.og_kerato_CYL_D = parseFloat(variable1);
              }

              else if (index === 1) {
                const variable2 = value;
                this.consultation.og_kerato_CYL_Ax = parseFloat(variable2);
              } else if (index === 2) {

              }
            });
          }
        }
      }

    } catch (error) {
      console.error('Error parsing XML:', error);
    }
  }

  private extractSCAValues(type: string, node: Element): void {

    const avgValue = node.getElementsByTagName('AVG')[0]?.textContent;

    if (avgValue) {
      const avgArray = avgValue.split(' ');

      avgArray.forEach((value, index) => {
        if (type == 'R') {
          if (index === 0) {
            let variable1 = value;
            this.consultation.od_refraction_Sphere = parseFloat(variable1);
          } else if (index === 1) {
            let variable2 = value;
            this.consultation.od_refraction_Cylindre = parseFloat(variable2);
          } else if (index === 2) {
            let variable3 = value;
            this.consultation.od_refraction_Axe = parseFloat(variable3);
          }
        }
        else {
          if (index === 0) {
            let variable1 = value;
            this.consultation.og_refraction_Sphere = parseFloat(variable1);
          } else if (index === 1) {
            let variable2 = value;
            this.consultation.og_refraction_Cylindre = parseFloat(variable2);
          } else if (index === 2) {
            let variable3 = value;
            this.consultation.og_refraction_Axe = parseFloat(variable3);
          }
        }
      });
    }
  }

  private extractRRANode(type: string, node: Element): void {

    const r1Value = node.getElementsByTagName('R1')[0]?.textContent;
    const r2Value = node.getElementsByTagName('R2')[0]?.textContent;
    if (r1Value) {
      const avgArray = r1Value.split(' ');
      avgArray.forEach((value, index) => {
        if (type == 'R') {
          if (index === 0) {
            let variable1 = value;
            this.consultation.od_kerato_R1_Mm = parseFloat(variable1);
          } else if (index === 1) {
            let variable2 = value;
            this.consultation.od_kerato_R1_D = parseFloat(variable2);
          } else if (index === 2) {
            let variable3 = value;
            this.consultation.od_kerato_R1_Ax = parseFloat(variable3);
          }
        }
        else {
          if (index === 0) {
            let variable1 = value;
            this.consultation.og_kerato_R1_Mm = parseFloat(variable1);
          } else if (index === 1) {
            let variable2 = value;
            this.consultation.og_kerato_R1_D = parseFloat(variable2);
          } else if (index === 2) {
            let variable3 = value;
            this.consultation.og_kerato_R1_Ax = parseFloat(variable3);
          }
        }
      });

      avgArray.forEach((value, index) => {
        if (type == 'R') {
          if (index === 0) {
            let variable1 = value;
            this.consultation.od_kerato_R1_Mm = parseFloat(variable1);
          } else if (index === 1) {
            let variable2 = value;

            this.consultation.od_kerato_R1_D = parseFloat(variable2);

          } else if (index === 2) {
            let variable3 = value;
            this.consultation.od_kerato_R1_Ax = parseFloat(variable3);


          }
        }
        else {
          if (index === 0) {
            let variable1 = value;
            this.consultation.og_kerato_R1_Mm = parseFloat(variable1);
          } else if (index === 1) {
            let variable2 = value;
            this.consultation.og_kerato_R1_D = parseFloat(variable2);
          } else if (index === 2) {
            let variable3 = value;
            this.consultation.og_kerato_R1_Ax = parseFloat(variable3);
          }
        }
      });
    }

    if (r2Value) {
      const avgArray = r2Value.split(' ');
      avgArray.forEach((value, index) => {
        if (type == 'R') {
          if (index === 0) {
            const variable1 = value;
            this.consultation.od_kerato_R2_Mm = parseFloat(variable1);
          } else if (index === 1) {
            let variable2 = value;
            this.consultation.od_kerato_R2_D = parseFloat(variable2);
          } else if (index === 2) {
            let variable3 = value;
            this.consultation.od_kerato_R2_Ax = parseFloat(variable3);
          }
        }
        else {
          if (index === 0) {
            let variable1 = value;
            this.consultation.og_kerato_R2_Mm = parseFloat(variable1);
          } else if (index === 1) {
            let variable2 = value;
            this.consultation.og_kerato_R2_D = parseFloat(variable2);
          } else if (index === 2) {
            let variable3 = value;
            this.consultation.og_kerato_R2_Ax = parseFloat(variable3);
          }
        }
      });
    }

    const avgValue = node.getElementsByTagName('AVG')[0]?.textContent;
    if (avgValue) {
      const avgArray = avgValue.split(' ');
      avgArray.forEach((value, index) => {
        if (type == 'R') {
          if (index === 0) {
            let variable1 = value;
            this.consultation.od_kerato_AVG_Mm = parseFloat(variable1);
          } else if (index === 1) {
            let variable2 = value;
            this.consultation.od_kerato_AVG_D = parseFloat(variable2);
          } else if (index === 2) {
            const variable3 = value;
            this.consultation.od_kerato_AVG_Ax = parseFloat(variable3);
          }
        }
        else {
          if (index === 0) {
            const variable1 = value;
            this.consultation.og_kerato_AVG_Mm = parseFloat(variable1);
          } else if (index === 1) {
            const variable2 = value;
            this.consultation.og_kerato_AVG_D = parseFloat(variable2);
          } else if (index === 2) {
            const variable3 = value;
            this.consultation.og_kerato_AVG_Ax = parseFloat(variable3);
          }
        }
      });
    }
  }

  importer() {
    this.readCSV()
  }

  readCSV(): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.setAttribute("style", 'visibility:hidden');
    input.accept = '.csv';
    input.addEventListener('change', (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const result = e.target?.result as string;
          const jsonData = this.convertCSVtoJSON(result);
        };

        reader.onerror = (error) => {
          console.error('File reading error:', error);
        };

        reader.onloadend = () => {
        };
        reader.readAsText(file);
      }
    });

    document.body.appendChild(input);
    input.click();
  }

  convertCSVtoJSON(csvData: string): any[] {
    const rows: string[] = csvData.split('\n');
    const jsonData: any[] = [];
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].trim() === '') continue;
      const values: string[] = rows[i].split(',');
      const cleanedValues = values.map(value => value.replace(/^"|"$/g, '').replace(/\"{2,}/g, ''));
      const rowData: { [key: string]: string } = {};
      cleanedValues.forEach((value, j) => {
        const fieldName = `Field${j + 1}`;
        const singleValue = value.split('+/-')[0].trim();
        if (singleValue !== '') {
          rowData[fieldName] = singleValue;
        }
        this.consultation.refractionVd = parseFloat(fieldName[0]);
      });
      if (Object.keys(rowData).length > 0) {
        jsonData.push(rowData);
      }
    }
    const firstRow = jsonData[0];
    this.consultation.refractionPd = parseFloat(firstRow.Field9);
    this.consultation.refractionVd = parseFloat(firstRow.Field11);
    this.consultation.refractionNpd = parseFloat(firstRow.Field10)
    this.consultation.od_refraction_Sphere = parseFloat(firstRow.Field33)
    this.consultation.od_refraction_Cylindre = parseFloat(firstRow.Field34)
    this.consultation.od_refraction_Axe = parseFloat(firstRow.Field35)
    this.consultation.og_refraction_Sphere = parseFloat(firstRow.Field36)
    this.consultation.og_refraction_Cylindre = parseFloat(firstRow.Field37)
    this.consultation.og_refraction_Axe = parseFloat(firstRow.Field38)
    this.consultation.od_refraction_Sphere_Subj = parseFloat(firstRow.Field88)
    this.consultation.od_refraction_Cylindre_Subj = parseFloat(firstRow.Field89)
    this.consultation.od_refraction_Axe_Subj = parseFloat(firstRow.Field90)
    this.consultation.og_refraction_Sphere_Subj = parseFloat(firstRow.Field93)
    this.consultation.og_refraction_Cylindre_Subj = parseFloat(firstRow.Field94)
    this.consultation.og_refraction_Axe_Subj = parseFloat(firstRow.Field95)
    this.consultation.od_kerato_R1_Mm = parseFloat(firstRow.Field166)
    this.consultation.od_kerato_R1_D = parseFloat(firstRow.Field167)
    this.consultation.od_kerato_R1_Ax = parseFloat(firstRow.Field168)
    this.consultation.og_kerato_R1_Mm = parseFloat(firstRow.Field169)
    this.consultation.og_kerato_R1_D = parseFloat(firstRow.Field170)
    this.consultation.og_kerato_R1_Ax = parseFloat(firstRow.Field171)

    this.consultation.od_kerato_R2_Mm = parseFloat(firstRow.Field172)
    this.consultation.od_kerato_R2_D = parseFloat(firstRow.Field173)
    this.consultation.od_kerato_R2_Ax = parseFloat(firstRow.Field174)
    this.consultation.og_kerato_R2_Mm = parseFloat(firstRow.Field175)
    this.consultation.og_kerato_R2_D = parseFloat(firstRow.Field176)
    this.consultation.og_kerato_R2_Ax = parseFloat(firstRow.Field177)

    this.consultation.od_vl_avac_Sphere = parseFloat(firstRow.Field70)
    this.consultation.od_vl_avac_Cylindre = parseFloat(firstRow.Field71)
    this.consultation.od_vl_avac_Axe = parseFloat(firstRow.Field72)
    this.consultation.og_vl_avac_Sphere = parseFloat(firstRow.Field76)
    this.consultation.og_vl_avac_Cylindre = parseFloat(firstRow.Field77)
    this.consultation.og_vl_avac_Axe = parseFloat(firstRow.Field78)
    return jsonData;
  }









}