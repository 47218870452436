<!-- <section>
    <div class="container">
        <form [formGroup]="formVar" target="_blank"  (ngSubmit)="onSubmit()"  method="POST">
  
     
        <div class="form-group">
          <label for="email">Destinataire</label>
          <input type="email" id="email"  formControlName="email" name="email" required>
        </div>
  
        <div class="form-group">
            <label for="objet">Objet</label>
            <input type="text" id="objet"  formControlName="objet" name="objet" [value]="object" required> 
          </div>    
        <div class="form-group">
          <label  for="message ">Message</label>
          <textarea  type="text"  id="massage" cols="30" rows="5" formControlName="message" name="message" [value]="subject">{{message}}</textarea>
       
        </div>
  
        <button type="submit" >Envoyer</button>
      </form>
    </div>
  
  </section> -->
  

  <div class="mt-2">
    <form [formGroup]="formVar" target="_blank"  (ngSubmit)="onSubmit()"  method="POST">

    <div class="row">
        <div class="col-lg-6 col-sm-12">
          <label for="email">Destinataire</label>
          <input type="email" id="email" type="text" class="form-control"  formControlName="email" name="email" required>
          <span *ngIf="isSubmitted && f.email.errors?.required" class="invalid-input">Email est obligatoire </span>

        </div>

        <div class="col-lg-6 col-sm-12 col-md-6">
          <label for="objet">Objet</label>
          <input type="text" id="objet" type="text" class="form-control"   formControlName="objet" name="objet" [value]="object" required> 
          

        </div>
       
    </div>
    <div>
      <label  for="message ">Message</label>
      <textarea  type="text"  id="massage" cols="28" rows="5" formControlName="message" class="form-control" name="message" [value]="subject">{{message}}</textarea>
    </div>
    <div class="button">
      <button class="btn btn-outline-info mb-3 mt-3" type="submit"  [disabled]="f.email.errors?.required" >Envoyer Mail</button>
    </div>
    </form>
</div>