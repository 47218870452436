import { Component, EventEmitter, ContentChildren, Input, OnInit, Output, QueryList } from '@angular/core';
import { PageDirective } from '../page.directive/page.directive';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  @ContentChildren('page', { read: PageDirective }) pages!: QueryList<PageDirective>;
  @Input() set currentPageIndex(value: number) {

  }

  get currentPageIndex(): number {
    return 0;
  }
  @Output() currentPageIndexChanged: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public next() {

  }

  public previous() {

  }

}
