
export interface IAntecedent {
    familiaux: string,
    medicaux: string,
    chirugicaux: string,
    rhumato: string,
    pharmacie: string,
    intorerance: string,
    allergie: string
    familiauxAlert: Boolean,
    medicauxAlert: Boolean,
    chirugicauxAlert: Boolean,
    rhumatoAlert: Boolean,
    pharmacieAlert: Boolean,
    intoreranceAlert: Boolean,
    allergieAlert: Boolean
}
export class Antecedent implements IAntecedent {
    familiaux: string
    medicaux: string
    chirugicaux: string
    rhumato: string
    pharmacie: string
    intorerance: string
    allergie: string
    familiauxAlert: Boolean
    medicauxAlert: Boolean
    chirugicauxAlert: Boolean
    rhumatoAlert: Boolean
    pharmacieAlert: Boolean
    intoreranceAlert: Boolean
    allergieAlert: Boolean
    constructor(other?: Partial<IAntecedent>) {
        this.familiaux = other?.familiaux || '';
        this.medicaux = other?.medicaux || '';
        this.chirugicaux = other?.chirugicaux || '';
        this.allergie = other?.allergie || '';
        this.rhumato = other?.rhumato || '';
        this.pharmacie = other?.pharmacie || '';
        this.intorerance = other?.intorerance || '';
        this.familiauxAlert = other?.familiauxAlert || false;
        this.pharmacieAlert = other?.pharmacieAlert || false;
        this.allergieAlert = other?.allergieAlert || false;
        this.intoreranceAlert = other?.intoreranceAlert || false;
        this.medicauxAlert = other?.medicauxAlert || false;
        this.chirugicauxAlert = other?.chirugicauxAlert || false;
        this.rhumatoAlert = other?.rhumatoAlert || false;
    }
    public static fromResponse(response: any): Antecedent {
        return new Antecedent(
            {
                familiaux: response.atcdFamiliaux,
                medicaux: response.atcdMedicaux,
                chirugicaux: response.atcdChirugicaux,
                allergie: response.atcdAllergie,
                pharmacie: response.atcdPharmacie,
                intorerance: response.atcdIntorerance,
                rhumato: response.atcdRhumato,
                allergieAlert: response.atcdAllergieAlert,
                rhumatoAlert: response.atcdRhumatoAlert,
                medicauxAlert: response.atcdMedicauxAlert,
                chirugicauxAlert: response.atcdChirugicauxAlert,
                intoreranceAlert: response.atcdIntoleranceAlert,
                pharmacieAlert: response.atcdPharmacieAlert,
                familiauxAlert: response.atcdFamiliauxAlert
            }
        );
    }
}
