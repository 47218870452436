import { Fillings } from "../fillings.entity/fillings";
import { Doctor } from "../user.entity/doctor.entity";


export class TextFillings {
     id:string;
     label:string;
     doctor:Doctor;
     fillings:Array<Fillings>
     constructor(other?:Partial<TextFillings>){
        this.id=other?.id ||'';
        this.label=other?.label||'';
        this.doctor=other?.doctor||new Doctor();
        this.fillings=other?.fillings||[]
     }

}
