<!--
    <span *ngIf="submitted && !validity.medics" style="color: red">Medicaments error</span>
<app-accordion-tab>
    <div class="prescription-item-header" header>
        <h3>Medicaments {{prescription.medics.length? ' (' + prescription.medics.length + ') items': ''}}</h3>
    </div>
    <app-medic-prescription-form (validation)="onValidityChange({ medics: $event })" [submitted]="submitted" [(medicPrescriptionItems)]="prescription.medics"
        (medicPrescriptionItemsChange)="_onChange()" body>
    </app-medic-prescription-form>
</app-accordion-tab>
<app-accordion-tab *ngIf="doctor.prescriptionBiology">
    <div class="prescription-item-header" header>
        <h3>Biologies {{prescription.biologies.length? ' (' + prescription.biologies.length + ') items': ''}}</h3>
    </div>
    <app-biology-prescription-form [submitted]="submitted" (validation)="validity.biologies= $event" [(biologyPrescriptionItems)]="prescription.biologies"
        (biologyPrescriptionItemsChange)="_onChange()" body>
    </app-biology-prescription-form>
</app-accordion-tab>
<app-accordion-tab *ngIf="doctor.prescriptionRadiology">
    <div class="prescription-item-header" header>
        <h3>Radiologies {{prescription.radiologies.length? ' (' + prescription.radiologies.length + ') items': ''}}</h3>
    </div>
    <app-radiology-prescription-form [submitted]="submitted" (validation)="validity.radiologies = $event" [(radiologyPrescriptionItems)]="prescription.radiologies"
        (radiologyPrescriptionItemsChange)="_onChange()" body>
    </app-radiology-prescription-form>
</app-accordion-tab>

-->
<div class="tabview">
    <div class="tabview-nav">
        <div [ngClass]="{active:tabIndex == 0}" (click)="tabIndex = 0">
            <span>
                <i class="fas fa-capsules"></i>                Médicaments
            </span>
            <span class="badge">{{prescription.medics.length}}</span>
        </div>
        <div [ngClass]="{active:tabIndex == 1}" (click)="tabIndex = 1">
            <span>
                <i class="fas fa-flask"></i>
                Biologies
            </span>
            <span class="badge">{{prescription.biologies.length}}</span>
        </div>
        <div *ngIf="doctor.consultationRadiology || prescription.radiologies" [ngClass]="{active:tabIndex == 2}" (click)="tabIndex = 2">
            <span>
                <i class="fas fa-x-ray"></i>
                Radiologies
            </span>
            <span class="badge">{{prescription.radiologies.length}}</span>
        </div>
    </div>
    <div class="tabview-content">
        <app-medic-prescription-form *ngIf="tabIndex == 0" (validation)="validity.medics=$event" [submitted]="submitted"
            [(medicPrescriptionItems)]="prescription.medics" (medicPrescriptionItemsChange)="_onChange()" body>
        </app-medic-prescription-form>
        <app-biology-prescription-form *ngIf="tabIndex == 1" [submitted]="submitted" (validation)="validity.biologies= $event" [(biologyPrescriptionItems)]="prescription.biologies"
            (biologyPrescriptionItemsChange)="_onChange()" body>
        </app-biology-prescription-form>
        <app-radiology-prescription-form *ngIf="tabIndex == 2" [submitted]="submitted" (validation)="validity.radiologies = $event"
            [(radiologyPrescriptionItems)]="prescription.radiologies" (radiologyPrescriptionItemsChange)="_onChange()" body>
        </app-radiology-prescription-form>
    </div>
</div>