import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { DoctorAppointmentBill } from 'src/app/entities/appointment-bill.entity';
import { EAppointmentState } from 'src/app/entities/appointment.entity';
import { DoctorAppointment } from 'src/app/entities/appointment.entity/doctor-appointment.entity/doctor-appointment';
import { DocumentAttachment } from 'src/app/entities/document-attachment.entity/document-attachment';
import { VideoCallRoom } from 'src/app/entities/video-call-room.entity/video-call-room';
import { MILLISECONDS_IN_SECOND } from 'src/app/utils/date.util';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DoctorAppointmentService extends ApiService {

  bill(appointemntId: string): Observable<DoctorAppointmentBill> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments/${appointemntId}/invoice`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  all(pageRequest?: PageRequest): Observable<Array<DoctorAppointment>> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments`, { responseType: 'json' }).pipe<Array<DoctorAppointment>>(this.responsePipe);
    }
    else return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments/all`, { responseType: 'json' }).pipe<Array<DoctorAppointment>>(this.responsePipe);
  }

  search(query: { date?: Date, query?: string }): Observable<Array<DoctorAppointment>> {
    let params = new HttpParams();
    if (query.date) params = params.append('date', query.date.getTime());
    if (query.query) params = params.append('query', query.query);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments/all/search`, { params: params, responseType: 'json' }).pipe<Array<DoctorAppointment>>(this.responsePipe);
  }

  timings(timings: { at?: number, before?: number, after?: number }, pageRequest: PageRequest, query?: { date?: Date, query?: string }): Observable<any> {
    let params = new HttpParams();
    if (timings.at != undefined) params = params.append('at', timings.at);
    if (timings.before != undefined) params = params.append('before', timings.before);
    if (timings.after != undefined) params = params.append('after', timings.after);

    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    if (query) {
      if (query.date) params = params.append('at', query.date.getTime());
      if (query.query) params = params.append('q', query.query);
    }
    if (!query)
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments/timings`, { params: params, responseType: 'json' }).pipe<Array<DoctorAppointment>>(this.responsePipe);
    else return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments/timings/search`, { params: params, responseType: 'json' }).pipe<Array<DoctorAppointment>>(this.responsePipe);
  }

  add(patientId: string, appointment: DoctorAppointment): Observable<DoctorAppointment> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/appointments?patientId=${patientId}`, appointment.requestPayload, { responseType: 'json' }).pipe(this.responsePipe);
  }
  allRdvCalendar(before: number, after: number): Observable<Array<DoctorAppointment>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments/timings/all?before=${after}&after=${before}`, { responseType: 'json' }).pipe<Array<DoctorAppointment>>(this.responsePipe);
  }
  update(id: string, appointment: DoctorAppointment): Observable<DoctorAppointment> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/appointments/${id}`, appointment.requestPayload, { responseType: 'json' }).pipe(this.responsePipe)
  }

  getMotifs(idSpecialty: string): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/consultations/tags/motif/${idSpecialty}`).pipe(this.responsePipe)
  }

  one(appointmentId: string): Observable<DoctorAppointment> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments/${appointmentId}`, { responseType: 'json' }).pipe<DoctorAppointment>(this.responsePipe);
  }

  updateStatus(appointmentId: string, status: EAppointmentState): Observable<DoctorAppointment> {
    return this.http.patch<ApiResponse>(`${environment.API_URL}/doctor/appointments/${appointmentId}`, {}, { params: new HttpParams().append('status', status), responseType: 'json' }).pipe<DoctorAppointment>(this.responsePipe);
  }

  attachments(appointmentId: string): Observable<Array<DocumentAttachment>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments/${appointmentId}/attachments`, { responseType: 'json' }).pipe<Array<DocumentAttachment>>(this.responsePipe);
  }

  uploadAttachment(data: FormData, appointmentId: string): Observable<DocumentAttachment> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/appointments/${appointmentId}/attachments`, data, { responseType: 'json' }).pipe<DocumentAttachment>(this.responsePipe);
  }

  teleconsultation(appointmentId: string): Observable<VideoCallRoom> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/appointments/${appointmentId}/teleconsultation`, { responseType: 'json' }).pipe(timeout(20 * MILLISECONDS_IN_SECOND)).pipe<VideoCallRoom>(this.responsePipe);
  }
}

