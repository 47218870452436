import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { DoctorAvailability } from 'src/app/entities/doctor-disponiblity.entity/doctor-availability';;
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DoctorAvailabilitiesService extends ApiService {

  all(): Observable<{ date: number, availabilities: Array<DoctorAvailability> }> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/calender/availabilities`, { responseType: 'json' }).pipe<{ date: number, availabilities: Array<DoctorAvailability> }>(this.responsePipe);
  }

  getAllByDate(date: Date): Observable<{ date: number, availabilities: Array<DoctorAvailability> }> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/calender/availabilities?date=${date.getTime()}`, { responseType: 'json' }).pipe<{ date: number, availabilities: Array<DoctorAvailability> }>(this.responsePipe);
  }

  getAllByDatePatient(doctorId: string, date: Date): Observable<{ date: number, availabilities: Array<DoctorAvailability> }> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/${doctorId}/calender/?date=${date.getTime()}`, { responseType: 'json' }).pipe<{ date: number, availabilities: Array<DoctorAvailability> }>(this.responsePipe);
  }
}
