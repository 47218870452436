<div>
  <div class="row">
    <div class="col-lg-6 col-sm-12">
      <label class="mt-1">Description*</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="doctorAct.actes.desc_Acte"
        (ngModel)="_change()"
      />
      <span
        *ngIf="isSubmitted && doctorAct.actes.desc_Acte.length==0"
        style="color: red;"
        >Description is required</span
      >
    </div>
    <div class="col-lg-6 col-sm-12">
      <label class="mt-1">Famille</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="doctorAct.actes.famille_Acte"
        (ngModel)="_change()"
      />
    
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-lg-6 col-sm-12">
      <label class="mt-1">Prix</label>
      <input
        type="number"
        class="form-control"
        [(ngModel)]="doctorAct.montant"
        (ngModel)="_change()"
        Number
      />
     
    </div>
    <div class="col-lg-6 col-sm-12">
      <label class="mt-1">Prise En Charge</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="doctorAct.actes.priseenCharge_Acte"
        (ngModel)="_change()"
      />
     
    </div>
  </div>
  <div class="row">
    <div class="form-group">
      <label
        style="margin-left: 15px !important;margin-top: 55px !important;"
        (click)="infoCnam = !infoCnam"
        style="cursor: pointer; color: #48b9b2;"
        >Info-CNAM:</label
      >
    </div>
  </div>
  <fieldset
    *ngIf="infoCnam"
    style="border: 1px solid gray; border-radius: 6px;"
  >
    <div class="container-fluid" style="padding: 5px;">
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">act_LIB_USE</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.act_LIB_USE"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">ACT_AHJ</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_ACT_AHJ"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">ACT_APR</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_ACT_APR"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">ACT_COD</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_ACT_COD"
            (ngModel)="_change()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">ACT_DES*</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_ACT_DES"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">ART_COD</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_ART_COD"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">ART_DES </label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_ART_DES
        "
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">CHP_COD</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_CHP_COD"
            (ngModel)="_change()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">CHP_DES</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_CHP_DES"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">LET_COD</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_LET_COD"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">NAT_COD</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_NAT_COD"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">NAT_DES</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_NAT_DES"
            (ngModel)="_change()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">REM_COD</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_REM_COD"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">REM_DES</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_REM_DES"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">ACT_PRIX_U</label>
          <input
            type="number"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_ACT_PRIX_U"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">COT_ACTM</label>
          <input
            type="number"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_COT_ACTM"
            (ngModel)="_change()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">SAR_COD</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_SAR_COD"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">SAR_DES</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_SAR_DES"
            (ngModel)="_change()"
          />
        </div>

        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">SRM_COD</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_SRM_COD"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-3 col-sm-12">
          <label class="mt-1">SRM_DES</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_SRM_DES"
            (ngModel)="_change()"
          />
        </div>
      </div>
      <div class="row" style="margin-bottom: 5px;">
        <div class="col-lg-6 col-sm-12">
          <label class="mt-1">TIT_COD </label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_TIT_COD"
            (ngModel)="_change()"
          />
        </div>
        <div class="col-lg-6 col-sm-12">
          <label class="mt-1">TIT_DES</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="doctorAct.actes.cnam_TIT_DES"
            (ngModel)="_change()"
          />
        </div>
      </div>
    </div>
  </fieldset>
</div>
