<div class="calendar">
  <div class="calendar-header">
    <button (click)="prevMonth()">Previous</button>
    <h2>{{ currentMonth | date: 'MMMM yyyy' }}</h2>
    <button (click)="nextMonth()">Next</button>
  </div>
  <table>
    <thead>
      <tr>
        <th *ngFor="let day of daysOfWeek">{{ day }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let week of weeks">
        <td *ngFor="let day of week" [ngClass]="{'today': isToday(day), 'other-month': !isCurrentMonth(day)}">
          {{ day | date: 'd' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>