import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActeConsultationItem, BiologyConsultationItem, ConsultationParametreSanteItem, RadiologyConsultationItem, VaccinConsultationItem } from 'src/app/entities/consultation.entity';
import { ConsultationOphtalmoParametreSanteItem } from 'src/app/entities/consultation.entity/consultation-ophtalmo.entity/consultation-ophtalmo';
import { BioPrescription } from 'src/app/entities/prescription.entity/bio-prescription.entity/bio-prescription';
import { MedicPrescription } from 'src/app/entities/prescription.entity/medic-prescription.entity/medic-prescription';
import { RadioPrescription } from 'src/app/entities/prescription.entity/radio-prescription.entity/radio-prescription';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { DoctorPatientsService } from 'src/app/services/doctor-patients.service/doctor-patients.service';

export class DoctorPatientHistoricItem {
  date: Date;
  motif: string;
  examenClinique: string;
  diagnostic: string;
  histoireMaladie: string;
  examenDemande: string;
  prescriptionMedics: Array<MedicPrescription>;
  prescriptionBiologies: Array<BioPrescription>;
  prescriptionRadiologies: Array<RadioPrescription>;

  consultationBiologies: Array<BiologyConsultationItem>;
  consultationRadiologies: Array<RadiologyConsultationItem>;
  consultationVaccinations: Array<VaccinConsultationItem>;
  consultationActes: Array<ActeConsultationItem>;
  consultationParamSanteValues: Array<ConsultationParametreSanteItem | ConsultationOphtalmoParametreSanteItem>
  constructor(other?: Partial<DoctorPatientHistoricItem>) {
    this.date = other?.date || new Date();
    this.motif = other?.motif || '';
    this.examenClinique = other?.examenClinique || '';
    this.diagnostic = other?.diagnostic || '';
    this.histoireMaladie = other?.histoireMaladie || '';
    this.examenDemande = other?.examenDemande || '';
    this.prescriptionMedics = other?.prescriptionMedics || [];
    this.prescriptionBiologies = other?.prescriptionBiologies || [];
    this.prescriptionRadiologies = other?.prescriptionRadiologies || [];
    this.consultationBiologies = other?.consultationBiologies || [];
    this.consultationRadiologies = other?.consultationRadiologies || [];
    this.consultationVaccinations = other?.consultationVaccinations || [];
    this.consultationActes = other?.consultationActes || [];
    this.consultationParamSanteValues = other?.consultationParamSanteValues || [];
  }

  get hasPrescription(): boolean { return this.prescriptionBiologies.length > 0 || this.prescriptionMedics.length > 0 || this.prescriptionRadiologies.length > 0; }
  get hasConsultation(): boolean { return this.consultationBiologies.length > 0 || this.consultationRadiologies.length > 0 || this.consultationActes.length > 0 || this.consultationVaccinations.length > 0 || this.consultationParamSanteValues.length > 0; }
  get isEmpty(): boolean {
    return !this.hasConsultation && !this.hasPrescription;
  }

  public static fromResponse(res: any): DoctorPatientHistoricItem {
    return new DoctorPatientHistoricItem({
      date: new Date(res.date),
      motif: res.motif,
      examenClinique: res.eexamenClinique,
      diagnostic: res.diagnostic,
      histoireMaladie: res.histoireMaladie,
      examenDemande: res.examenDemande,
      prescriptionMedics: Array.from(res.prescriptionMedics || [], e => MedicPrescription.fromResponse(e)),
      prescriptionBiologies: Array.from(res.prescriptionBiologies, e => BioPrescription.fromResponse(e)),
      prescriptionRadiologies: Array.from(res.prescriptionRadiologies || [], e => RadioPrescription.fromResponse(e)),
      consultationBiologies: Array.from(res.consultationBiologies, e => BiologyConsultationItem.fromResponse(e)),
      consultationRadiologies: Array.from(res.consultationRadiologies || [], e => RadiologyConsultationItem.fromResponse(e)),
      consultationVaccinations: res.consultationVaccinations,
      consultationActes: res.consultationActes,
      consultationParamSanteValues: Array.from(res.consultationParamSanteValues || [], e => ConsultationParametreSanteItem.fromResponse(e) || ConsultationOphtalmoParametreSanteItem.fromResponse(encodeURIComponent)),
    })
  }
}
export class DoctorPatientHistoricItemOpth {
  date: Date;
  motif: string;
  examenClinique: string;
  diagnostic: string;
  histoireMaladie: string;
  examenDemande: string;
  prescriptionMedics: Array<MedicPrescription>;
  prescriptionBiologies: Array<BioPrescription>;
  prescriptionRadiologies: Array<RadioPrescription>;

  consultationBiologies: Array<BiologyConsultationItem>;
  consultationRadiologies: Array<RadiologyConsultationItem>;
  consultationVaccinations: Array<VaccinConsultationItem>;
  consultationActes: Array<ActeConsultationItem>;
  consultationParamSanteValues: Array<ConsultationOphtalmoParametreSanteItem>;
  od_vl_avac_Sphere: number;
  od_vl_avac_Cylindre: number;
  od_vl_avac_Axe: number;
  od_vp_avac_Sphere: number;
  od_vp_avac_Cylindre: number;
  od_vp_avac_Axe: number;
  og_vl_avac_Sphere: number;
  og_vl_avac_Cylindre: number;
  og_vl_avac_Axe: number;
  og_vp_avac_Sphere: number;
  og_vp_avac_Cylindre: number;
  og_vp_avac_Axe: number;
  og_vp_avac_Addition: number;
  lentille_Type: string;
  od_lentille_RC: number;
  od_lentille_DC: number;
  od_lentille_Puissance: number;
  og_lentille_RC: number;
  og_lentille_DC: number;
  og_lentille_Puissance: number;
  lentille_remarque: string;
  lunette_remarque: string;
  constructor(other?: Partial<DoctorPatientHistoricItemOpth>) {
    this.date = other?.date || new Date();
    this.motif = other?.motif || "";
    this.examenClinique = other?.examenClinique || "";
    this.diagnostic = other?.diagnostic || "";
    this.histoireMaladie = other?.histoireMaladie || "";
    this.examenDemande = other?.examenDemande || "";
    this.prescriptionMedics = other?.prescriptionMedics || [];
    this.prescriptionBiologies = other?.prescriptionBiologies || [];
    this.prescriptionRadiologies = other?.prescriptionRadiologies || [];
    this.consultationBiologies = other?.consultationBiologies || [];
    this.consultationRadiologies = other?.consultationRadiologies || [];
    this.consultationVaccinations = other?.consultationVaccinations || [];
    this.consultationActes = other?.consultationActes || [];
    this.consultationParamSanteValues = other?.consultationParamSanteValues || [];
    this.od_vl_avac_Sphere = other?.od_vl_avac_Sphere || 0;
    this.od_vl_avac_Cylindre = other?.od_vl_avac_Cylindre || 0;
    this.od_vl_avac_Axe = other?.od_vl_avac_Axe || 0;
    this.od_vp_avac_Sphere = other?.od_vp_avac_Sphere || 0;
    this.od_vp_avac_Cylindre = other?.od_vp_avac_Cylindre || 0;
    this.od_vp_avac_Axe = other?.od_vp_avac_Axe || 0;
    this.og_vl_avac_Sphere = other?.og_vl_avac_Sphere || 0;
    this.og_vl_avac_Cylindre = other?.og_vl_avac_Cylindre || 0;
    this.og_vl_avac_Axe = other?.og_vl_avac_Axe || 0;
    this.og_vp_avac_Sphere = other?.og_vp_avac_Sphere || 0;
    this.og_vp_avac_Cylindre = other?.og_vp_avac_Cylindre || 0;
    this.og_vp_avac_Axe = other?.og_vp_avac_Axe || 0;
    this.og_vp_avac_Addition = other?.og_vp_avac_Addition || 0;
    this.lentille_Type = other?.lentille_Type || "Select type Lentille";
    this.od_lentille_RC = other?.od_lentille_RC || 0;
    this.od_lentille_DC = other?.od_lentille_DC || 0;
    this.od_lentille_Puissance = other?.od_lentille_Puissance || 0;
    this.og_lentille_RC = other?.og_lentille_RC || 0;
    this.og_lentille_DC = other?.og_lentille_DC || 0;
    this.og_lentille_Puissance = other?.og_lentille_Puissance || 0;
    this.lentille_remarque = other?.lentille_remarque || "";
    this.lunette_remarque = other?.lunette_remarque || "";
  }

  get hasPrescription(): boolean {
    return (
      this.prescriptionBiologies.length > 0 ||
      this.prescriptionMedics.length > 0 ||
      this.prescriptionRadiologies.length > 0
    );
  }
  get hasConsultation(): boolean {
    return (
      this.consultationBiologies.length > 0 ||
      this.consultationRadiologies.length > 0 ||
      this.consultationActes.length > 0 ||
      this.consultationVaccinations.length > 0 ||
      this.consultationParamSanteValues.length > 0
    );
  }
  get isEmpty(): boolean {
    return !this.hasConsultation && !this.hasPrescription;
  }

  public static fromResponse(res: any): DoctorPatientHistoricItemOpth {
    return new DoctorPatientHistoricItemOpth({
      date: new Date(res.date),
      motif: res.motif,
      examenClinique: res.eexamenClinique,
      diagnostic: res.diagnostic,
      histoireMaladie: res.histoireMaladie,
      examenDemande: res.examenDemande,
      prescriptionMedics: Array.from(res.prescriptionMedics || [], (e) =>
        MedicPrescription.fromResponse(e)
      ),
      prescriptionBiologies: Array.from(res.prescriptionBiologies, (e) =>
        BioPrescription.fromResponse(e)
      ),
      prescriptionRadiologies: Array.from(
        res.prescriptionRadiologies || [],
        (e) => RadioPrescription.fromResponse(e)
      ),
      consultationBiologies: Array.from(res.consultationBiologies, (e) =>
        BiologyConsultationItem.fromResponse(e)
      ),
      consultationRadiologies: Array.from(
        res.consultationRadiologies || [],
        (e) => RadiologyConsultationItem.fromResponse(e)
      ),
      consultationVaccinations: res.consultationVaccinations,
      consultationActes: res.consultationActes,
      consultationParamSanteValues: Array.from(
        res.consultationParamSanteValues || [],
        (e) => ConsultationOphtalmoParametreSanteItem.fromResponse(e)
      ),
      od_vl_avac_Sphere: res.od_vl_avac_Sphere,
      od_vl_avac_Cylindre: res.od_vl_avac_Cylindre,
      od_vl_avac_Axe: res.od_vl_avac_Axe,
      od_vp_avac_Sphere: res.od_vp_avac_Sphere,
      od_vp_avac_Cylindre: res.od_vp_avac_Cylindre,
      od_vp_avac_Axe: res.od_vp_avac_Axe,
      og_vl_avac_Sphere: res.og_vl_avac_Sphere,
      og_vl_avac_Cylindre: res.og_vl_avac_Cylindre,
      og_vl_avac_Axe: res.og_vl_avac_Axe,
      og_vp_avac_Sphere: res.og_vp_avac_Sphere,
      og_vp_avac_Cylindre: res.og_vp_avac_Cylindre,
      og_vp_avac_Axe: res.og_vp_avac_Axe,
      og_vp_avac_Addition: res.og_vp_avac_Addition,
      lentille_Type: res.lentille_Type || "Select type Lentille",
      od_lentille_RC: res.od_lentille_RC,
      od_lentille_DC: res.od_lentille_DC,
      od_lentille_Puissance: res.od_lentille_Puissance,
      og_lentille_RC: res.og_lentille_RC,
      og_lentille_DC: res.og_lentille_DC,
      og_lentille_Puissance: res.og_lentille_Puissance,
      lentille_remarque: res.lentille_remarque,
      lunette_remarque: res.lunette_remarque,
    });
  }
}

@Component({
  selector: 'app-doctor-patient-historics',
  templateUrl: './doctor-patient-historics.component.html',
  styleUrls: ['./doctor-patient-historics.component.css']
})
export class DoctorPatientHistoricsComponent implements OnInit {
  @Input() patientId: string = '';

  patientHistorics: Array<DoctorPatientHistoricItem> | Array<DoctorPatientHistoricItemOpth> | any = new Array();
  doctor: Doctor = JSON.parse(localStorage.getItem('current-user')!) as Doctor;

  constructor(private doctorPatientsService: DoctorPatientsService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.loadHistoriqueConsultation()
  }

  loadHistoriqueConsultation(): void {
    this.doctorPatientsService.historics(this.patientId).subscribe(
      res => {
        this.patientHistorics = this.doctor.speciality.label == "Ophtalmologue" ? Array.from(Object.values(res) || [], e => DoctorPatientHistoricItemOpth.fromResponse(e)).sort((a, b) => b.date.getTime() - a.date.getTime()) : Array.from(Object.values(res) || [], e => DoctorPatientHistoricItem.fromResponse(e)).sort((a, b) => b.date.getTime() - a.date.getTime())
      }, error => {
        this.toastrService.error('Enable to load patient historics')
      }
    )
  }
}
