import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/entities/user.entity';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(user: User): unknown {
    return ["Patient", 'Medecin', 'Secretaire', 'Administrateur'][user.role];
  }

}
