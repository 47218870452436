import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConsultationActFormComponent } from './consultation-act-form.component/consultation-act-form.component';
import { ConsultationBiologyFormComponent } from './consultation-biology-form.component/consultation-biology-form.component';
import { ParametreSanteConsultationFormsComponent } from './parametre-sante-consultation-form.component/parametre-sante-consultation-forms.component';
import { ConsultationRadiologyFormComponent } from './consultation-radiology-form.component/consultation-radiology-formcomponent';
import { ConsultationVaccinationFormComponent } from './consultation-vaccination-form.component/consultation-vaccination-form.component';
import { ConsultationLacerComponent } from './consultation-lacer.component/consultation-lacer.component';
import { AppCommonModule } from 'src/app/common/common.module';
import { VaccinationFormModule } from '../vaccination-from.module/vaccination-form.module';
import { RadiologyFormModule } from '../radiology-form.module/radiology-form.module';
import { BiologyFormModule } from '../biology-form.module/biology-form.module';
import { ActsFormModule } from '../acts-form.module/acts-form.module';
import { ConsultationsLentilleFormComponent } from './consultations-lentille-form.component/consultations-lentille-form.component';
import { ConsultationsLunetteFormComponent } from './consultations-lunette-form.component/consultations-lunette-form.component';
import { ParametreSanteOphtalmoComponent } from './parametre-sante-consultation-ophtalmo-form.component/parametre-sante-consultation-ophtalmo-form.component';
import { ConsultationActItemFormComponent } from './consultation-act-item-form/consultation-act-item-form.component';
import { ConsultationFormCnamComponent } from './consultation-form-cnam.component/consultation-form-cnam.component';
import { ListeQuestionSahredComponent } from './liste-question-sahred.component/liste-question-sahred.component';
import { TextFillingsFormModule } from '../text-fillings-form.module/text-fillings-form.module';
import { ConsultationFormCnamAp2Component } from './consultation-form-cnam-ap2/consultation-form-cnam-ap2.component';
import { ConsultationFormsCnamAp3Component } from './consultation-forms-cnam-ap3.component/consultation-forms-cnam-ap3.component';
import { InputLunetteComponent } from './input-lunette.component/input-lunette.component';
import { PediatricConsultationVaccinationsFormComponent } from './pediatric-consultation-vaccinations-form/pediatric-consultation-vaccinations-form.component';

@NgModule({
    declarations: [
        ConsultationActFormComponent,
        ConsultationBiologyFormComponent,
        ParametreSanteConsultationFormsComponent,
        ParametreSanteOphtalmoComponent,
        ConsultationRadiologyFormComponent,
        ConsultationVaccinationFormComponent,
        ConsultationLacerComponent,
        ConsultationsLentilleFormComponent,
        ConsultationsLunetteFormComponent,
        ConsultationActItemFormComponent,
        ConsultationFormCnamComponent,
        ListeQuestionSahredComponent,
        ConsultationFormCnamAp2Component,
        ConsultationFormsCnamAp3Component,
        InputLunetteComponent,
        PediatricConsultationVaccinationsFormComponent
    ],
    imports: [
        AppCommonModule,
        FormsModule,
        ReactiveFormsModule,
        VaccinationFormModule,
        RadiologyFormModule,
        BiologyFormModule,
        ActsFormModule,
        TextFillingsFormModule,
    ],
    exports: [
        ConsultationActFormComponent,
        ConsultationBiologyFormComponent,
        ParametreSanteConsultationFormsComponent,
        ParametreSanteOphtalmoComponent,
        ConsultationRadiologyFormComponent,
        ConsultationVaccinationFormComponent,
        PediatricConsultationVaccinationsFormComponent,
        ConsultationLacerComponent,
        ConsultationsLentilleFormComponent,
        ConsultationsLunetteFormComponent,
        ConsultationFormCnamComponent,
        ListeQuestionSahredComponent,
        ConsultationFormCnamAp2Component,
        ConsultationFormsCnamAp3Component
    ]
})
export class ConsultationFormsModule { }
