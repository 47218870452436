export const TN_CITIES = [
    {
        ville: "ARIANA",
        city: "ARIANA VILLE",
        id: "3"
    },
    {
        ville: "ARIANA",
        city: "AOUINA",
        id: "4"
    },
    {
        ville: "ARIANA",
        city: "ARIANA",
        id: "5"
    },
    {
        ville: "ARIANA",
        city: "ARIANA MEDINA",
        id: "6"
    },
    {
        ville: "ARIANA",
        city: "ARIANA NORD",
        id: "7"
    },
    {
        ville: "ARIANA",
        city: "BORJ LOUZIR",
        id: "8"
    },
    {
        ville: "ARIANA",
        city: "CITE EL KHADRA",
        id: "9"
    },
    {
        ville: "ARIANA",
        city: "CITE ENNASR",
        id: "10"
    },
    {
        ville: "ARIANA",
        city: "CITE ETTADHAMEN",
        id: "11"
    },
    {
        ville: "ARIANA",
        city: "DJERBA HOUMET ESSOUK",
        id: "12"
    },
    {
        ville: "ARIANA",
        city: "EL MANAR (I, II, III)",
        id: "13"
    },
    {
        ville: "ARIANA",
        city: "EL MENZAH",
        id: "14"
    },
    {
        ville: "ARIANA",
        city: "EL MENZAH ( I - IV )",
        id: "15"
    },
    {
        ville: "ARIANA",
        city: "EL MENZAH 6",
        id: "16"
    },
    {
        ville: "ARIANA",
        city: "EL MENZAH 7 - ENNASR II",
        id: "17"
    },
    {
        ville: "ARIANA",
        city: "ENNASR",
        id: "18"
    },
    {
        ville: "ARIANA",
        city: "KALAAT LANDLOUS",
        id: "19"
    },
    {
        ville: "ARIANA",
        city: "CITE GHAZALA",
        id: "21"
    },
    {
        ville: "ARIANA",
        city: "LA SOUKRA",
        id: "22"
    },
    {
        ville: "ARIANA",
        city: "MANOUBA",
        id: "23"
    },
    {
        ville: "ARIANA",
        city: "MENIHLA",
        id: "24"
    },
    {
        ville: "ARIANA",
        city: "MNIHLA",
        id: "25"
    },
    {
        ville: "ARIANA",
        city: "NABEUL",
        id: "26"
    },
    {
        ville: "ARIANA",
        city: "OMRANE SUPERIEUR",
        id: "27"
    },
    {
        ville: "ARIANA",
        city: "RAOUED",
        id: "28"
    },
    {
        ville: "ARIANA",
        city: "RIADH LANDALOUS",
        id: "29"
    },
    {
        ville: "ARIANA",
        city: "SIDI EL BECHIR",
        id: "30"
    },
    {
        ville: "ARIANA",
        city: "SIDI THABET",
        id: "31"
    },
    {
        ville: "ARIANA",
        city: "TEBOURSOUK",
        id: "32"
    },
    {
        ville: "ARIANA",
        city: "TUNIS CARTHAGE -CHARGUIA",
        id: "33"
    },
    {
        ville: "BARDO",
        city: "BARDO",
        id: "34"
    },
    {
        ville: "BEJA",
        city: "AMDOUN",
        id: "35"
    },
    {
        ville: "BEJA",
        city: "BEJA",
        id: "36"
    },
    {
        ville: "BEJA",
        city: "BEJA NORD",
        id: "37"
    },
    {
        ville: "BEJA",
        city: "BEJŔ VILLE",
        id: "38"
    },
    {
        ville: "BEJA",
        city: "ESSLOUGUIA",
        id: "39"
    },
    {
        ville: "BEJA",
        city: "GOUBELLAT",
        id: "40"
    },
    {
        ville: "BEJA",
        city: "JENDOUBA SUD",
        id: "41"
    },
    {
        ville: "BEJA",
        city: "MEDJEZ EL BAB",
        id: "42"
    },
    {
        ville: "BEJA",
        city: "NEFZA",
        id: "43"
    },
    {
        ville: "BEJA",
        city: "TEBOURSOUK",
        id: "44"
    },
    {
        ville: "BEJA",
        city: "TESTOUR",
        id: "45"
    },
    {
        ville: "BEJA",
        city: "THIBAR",
        id: "46"
    },
    {
        ville: "BEN AROUS",
        city: "BEN AROUS",
        id: "47"
    },
    {
        ville: "BEN AROUS",
        city: "BAB SOUIKA",
        id: "48"
    },
    {
        ville: "BEN AROUS",
        city: "BEN AROUS",
        id: "49"
    },
    {
        ville: "BEN AROUS",
        city: "BEN AROUS EST",
        id: "50"
    },
    {
        ville: "BEN AROUS",
        city: "BIR KASSAA",
        id: "52"
    },
    {
        ville: "BEN AROUS",
        city: "BORJ CEDRIA",
        id: "53"
    },
    {
        ville: "BEN AROUS",
        city: "BOU M'HEL",
        id: "54"
    },
    {
        ville: "BEN AROUS",
        city: "BOUMHEL BASSATINE",
        id: "55"
    },
    {
        ville: "BEN AROUS",
        city: "BOUSALEM",
        id: "56"
    },
    {
        ville: "BEN AROUS",
        city: "CITE EL KHADRA",
        id: "57"
    },
    {
        ville: "BEN AROUS",
        city: "EL MEDINA JADIDA",
        id: "58"
    },
    {
        ville: "BEN AROUS",
        city: "EL MENZAH",
        id: "59"
    },
    {
        ville: "BEN AROUS",
        city: "EL MOUROUJ",
        id: "60"
    },
    {
        ville: "BEN AROUS",
        city: "EL MOUROUJ I",
        id: "61"
    },
    {
        ville: "BEN AROUS",
        city: "EZZAHRA",
        id: "62"
    },
    {
        ville: "BEN AROUS",
        city: "FOUCHANA",
        id: "63"
    },
    {
        ville: "BEN AROUS",
        city: "HAMMAM CHATT",
        id: "64"
    },
    {
        ville: "BEN AROUS",
        city: "HAMMAM-LIF",
        id: "66"
    },
    {
        ville: "BEN AROUS",
        city: "M'HAMDIA",
        id: "69"
    },
    {
        ville: "BEN AROUS",
        city: "MEDINA JEDIDA",
        id: "70"
    },
    {
        ville: "BEN AROUS",
        city: "MEGRINE",
        id: "71"
    },
    {
        ville: "BEN AROUS",
        city: "MEGRINE COTEAU",
        id: "72"
    },
    {
        ville: "BEN AROUS",
        city: "MORNAG",
        id: "73"
    },
    {
        ville: "BEN AROUS",
        city: "RADES VILLE",
        id: "74"
    },
    {
        ville: "BEN AROUS",
        city: "RADES",
        id: "75"
    },
    {
        ville: "BIZERTE",
        city: "BIZERTE",
        id: "80"
    },
    {
        ville: "BIZERTE",
        city: "BIZERTE NORD",
        id: "81"
    },
    {
        ville: "BIZERTE",
        city: "BIZERTE SUD",
        id: "82"
    },
    {
        ville: "BIZERTE",
        city: "EL ALIA",
        id: "83"
    },
    {
        ville: "BIZERTE",
        city: "FERIANA",
        id: "84"
    },
    {
        ville: "BIZERTE",
        city: "GHAR EL MELH",
        id: "85"
    },
    {
        ville: "BIZERTE",
        city: "GHEZALA",
        id: "86"
    },
    {
        ville: "BIZERTE",
        city: "JARZOUNA",
        id: "87"
    },
    {
        ville: "BIZERTE",
        city: "JOUMINE",
        id: "88"
    },
    {
        ville: "BIZERTE",
        city: "KHETMINE",
        id: "89"
    },
    {
        ville: "BIZERTE",
        city: "MATEUR",
        id: "90"
    },
    {
        ville: "BIZERTE",
        city: "MENZEL ABDERRAHMEN",
        id: "91"
    },
    {
        ville: "BIZERTE",
        city: "MENZEL BOURGUIBA",
        id: "92"
    },
    {
        ville: "BIZERTE",
        city: "MENZEL JEMIL",
        id: "93"
    },
    {
        ville: "BIZERTE",
        city: "METLINE",
        id: "94"
    },
    {
        ville: "BIZERTE",
        city: "RAFRAF",
        id: "95"
    },
    {
        ville: "BIZERTE",
        city: "RAS JEBEL",
        id: "96"
    },
    {
        ville: "BIZERTE",
        city: "SEJNANE",
        id: "97"
    },
    {
        ville: "BIZERTE",
        city: "TINJA",
        id: "98"
    },
    {
        ville: "BIZERTE",
        city: "UTIQUE",
        id: "99"
    },
    {
        ville: "GABES",
        city: "ARRAM",
        id: "102"
    },
    {
        ville: "GABES",
        city: "BOUCHAMMA",
        id: "103"
    },
    {
        ville: "GABES",
        city: "CITE AMAL",
        id: "104"
    },
    {
        ville: "GABES",
        city: "EL HAMMA",
        id: "105"
    },
    {
        ville: "GABES",
        city: "EL HAMMA DE GABES",
        id: "106"
    },
    {
        ville: "GABES",
        city: "FOUSSANA",
        id: "107"
    },
    {
        ville: "GABES",
        city: "GABES",
        id: "108"
    },
    {
        ville: "GABES",
        city: "GABES EST",
        id: "109"
    },
    {
        ville: "GABES",
        city: "GABES HACHED",
        id: "110"
    },
    {
        ville: "GABES",
        city: "GABES OUEST",
        id: "111"
    },
    {
        ville: "GABES",
        city: "GABES SUD",
        id: "112"
    },
    {
        ville: "GABES",
        city: "GABES VILLE",
        id: "113"
    },
    {
        ville: "GABES",
        city: "GHANNOUCHE",
        id: "114"
    },
    {
        ville: "GABES",
        city: "KEBILI NORD",
        id: "115"
    },
    {
        ville: "GABES",
        city: "MARETH",
        id: "116"
    },
    {
        ville: "GABES",
        city: "MATMATA NOUVELLE",
        id: "117"
    },
    {
        ville: "GABES",
        city: "METOUIA",
        id: "118"
    },
    {
        ville: "GABES",
        city: "MTORRECH",
        id: "119"
    },
    {
        ville: "GABES",
        city: "NOUVELLE MATMATA",
        id: "120"
    },
    {
        ville: "GABES",
        city: "ZARRAT",
        id: "121"
    },
    {
        ville: "GAFSA",
        city: "DOUALY GAFSA",
        id: "122"
    },
    {
        ville: "GAFSA",
        city: "EL GUETAR",
        id: "123"
    },
    {
        ville: "GAFSA",
        city: "EL KSAR",
        id: "124"
    },
    {
        ville: "GAFSA",
        city: "GAFSA",
        id: "125"
    },
    {
        ville: "GAFSA",
        city: "GAFSA SUD",
        id: "126"
    },
    {
        ville: "GAFSA",
        city: "HAMMAM SOUSSE",
        id: "127"
    },
    {
        ville: "GAFSA",
        city: "M'DHILLA",
        id: "128"
    },
    {
        ville: "GAFSA",
        city: "METLAOUI",
        id: "129"
    },
    {
        ville: "GAFSA",
        city: "MOULARES",
        id: "130"
    },
    {
        ville: "GAFSA",
        city: "REDEYEF",
        id: "131"
    },
    {
        ville: "GAFSA",
        city: "SENED",
        id: "132"
    },
    {
        ville: "GAFSA",
        city: "TOZEUR",
        id: "133"
    },
    {
        ville: "JENDOUBA",
        city: "AIN DRAHAM",
        id: "134"
    },
    {
        ville: "JENDOUBA",
        city: "BALTA BOUAOUEN",
        id: "135"
    },
    {
        ville: "JENDOUBA",
        city: "BOUSALEM",
        id: "136"
    },
    {
        ville: "JENDOUBA",
        city: "FERNANA",
        id: "137"
    },
    {
        ville: "JENDOUBA",
        city: "GHARDIMAOU",
        id: "138"
    },
    {
        ville: "JENDOUBA",
        city: "JENDOUBA",
        id: "139"
    },
    {
        ville: "JENDOUBA",
        city: "JENDOUBA SUD",
        id: "140"
    },
    {
        ville: "JENDOUBA",
        city: "MANOUBA",
        id: "141"
    },
    {
        ville: "JENDOUBA",
        city: "OUED MELIZ",
        id: "142"
    },
    {
        ville: "JENDOUBA",
        city: "TABARKA",
        id: "143"
    },
    {
        ville: "KAIROUAN",
        city: "AIN JALLOULA",
        id: "144"
    },
    {
        ville: "KAIROUAN",
        city: "BOU HAJLA",
        id: "145"
    },
    {
        ville: "KAIROUAN",
        city: "BOUHAJLA",
        id: "146"
    },
    {
        ville: "KAIROUAN",
        city: "CHEBIKA",
        id: "147"
    },
    {
        ville: "KAIROUAN",
        city: "CHERARDA",
        id: "148"
    },
    {
        ville: "KAIROUAN",
        city: "EL ALA",
        id: "149"
    },
    {
        ville: "KAIROUAN",
        city: "HAFFOUZ",
        id: "150"
    },
    {
        ville: "KAIROUAN",
        city: "HAJEB EL AYOUN",
        id: "151"
    },
    {
        ville: "KAIROUAN",
        city: "KAIROUAN",
        id: "152"
    },
    {
        ville: "KAIROUAN",
        city: "KAIROUAN NORD",
        id: "153"
    },
    {
        ville: "KAIROUAN",
        city: "KAIROUAN SUD",
        id: "154"
    },
    {
        ville: "KAIROUAN",
        city: "MENZEL M'HIRI",
        id: "155"
    },
    {
        ville: "KAIROUAN",
        city: "NASRALLAH",
        id: "156"
    },
    {
        ville: "KAIROUAN",
        city: "OUED ELLIL",
        id: "157"
    },
    {
        ville: "KAIROUAN",
        city: "OUESLATIA",
        id: "158"
    },
    {
        ville: "KAIROUAN",
        city: "RAKKADA",
        id: "159"
    },
    {
        ville: "KAIROUAN",
        city: "SBIKHA",
        id: "160"
    },
    {
        ville: "KAIROUAN",
        city: "SFAX MEDINA",
        id: "161"
    },
    {
        ville: "KASSERINE",
        city: "EL KSOUR",
        id: "162"
    },
    {
        ville: "KASSERINE",
        city: "FERIANA",
        id: "163"
    },
    {
        ville: "KASSERINE",
        city: "FOUSSANA",
        id: "164"
    },
    {
        ville: "KASSERINE",
        city: "HASSI FERID",
        id: "165"
    },
    {
        ville: "KASSERINE",
        city: "KAIROUAN NORD",
        id: "166"
    },
    {
        ville: "KASSERINE",
        city: "KASSERINE",
        id: "167"
    },
    {
        ville: "KASSERINE",
        city: "KASSERINE EZZOUHOUR",
        id: "168"
    },
    {
        ville: "KASSERINE",
        city: "KHEMOUDA",
        id: "169"
    },
    {
        ville: "KASSERINE",
        city: "MAJEL BEL ABBES",
        id: "170"
    },
    {
        ville: "KASSERINE",
        city: "SBEITLA",
        id: "171"
    },
    {
        ville: "KASSERINE",
        city: "SBIBA",
        id: "172"
    },
    {
        ville: "KASSERINE",
        city: "THALA",
        id: "173"
    },
    {
        ville: "KEBILI",
        city: "DOUZ",
        id: "174"
    },
    {
        ville: "KEBILI",
        city: "KEBILI",
        id: "175"
    },
    {
        ville: "KEBILI",
        city: "KEBILI NORD",
        id: "176"
    },
    {
        ville: "KEBILI",
        city: "KEBILI SUD",
        id: "177"
    },
    {
        ville: "KEBILI",
        city: "OUESLATIA",
        id: "178"
    },
    {
        ville: "KEBILI",
        city: "SOUK EL AHAD",
        id: "179"
    },
    {
        ville: "KEBILI",
        city: "SOUK LAHAD",
        id: "180"
    },
    {
        ville: "LE KEF",
        city: "BAB BHAR",
        id: "184"
    },
    {
        ville: "LE KEF",
        city: "DAHMANI",
        id: "185"
    },
    {
        ville: "LE KEF",
        city: "EL KSOUR",
        id: "186"
    },
    {
        ville: "LE KEF",
        city: "KALAA EL KHESBA",
        id: "187"
    },
    {
        ville: "LE KEF",
        city: "KALAAT SENANE",
        id: "188"
    },
    {
        ville: "LE KEF",
        city: "LE BARDO",
        id: "189"
    },
    {
        ville: "LE KEF",
        city: "LE KEF",
        id: "190"
    },
    {
        ville: "LE KEF",
        city: "LE KEF EST",
        id: "191"
    },
    {
        ville: "LE KEF",
        city: "LE KEF OUEST",
        id: "192"
    },
    {
        ville: "LE KEF",
        city: "LE SERS",
        id: "193"
    },
    {
        ville: "LE KEF",
        city: "NEBEUR",
        id: "194"
    },
    {
        ville: "LE KEF",
        city: "RAS JEBEL",
        id: "195"
    },
    {
        ville: "LE KEF",
        city: "SAKIET SIDI YOUSSEF",
        id: "196"
    },
    {
        ville: "LE KEF",
        city: "TAJEROUINE",
        id: "197"
    },
    {
        ville: "MAHDIA",
        city: "BIR ALI BEN KHALIFA",
        id: "198"
    },
    {
        ville: "MAHDIA",
        city: "BOUMERDES",
        id: "199"
    },
    {
        ville: "MAHDIA",
        city: "CHORBANE",
        id: "200"
    },
    {
        ville: "MAHDIA",
        city: "CITE ETTADHAMEN",
        id: "201"
    },
    {
        ville: "MAHDIA",
        city: "EL BRADAA",
        id: "202"
    },
    {
        ville: "MAHDIA",
        city: "EL JEM",
        id: "203"
    },
    {
        ville: "MAHDIA",
        city: "H'BIRA",
        id: "204"
    },
    {
        ville: "MAHDIA",
        city: "HAMMAM SOUSSE",
        id: "205"
    },
    {
        ville: "MAHDIA",
        city: "KSOUR ESSAF",
        id: "206"
    },
    {
        ville: "MAHDIA",
        city: "LA CHEBBA",
        id: "207"
    },
    {
        ville: "MAHDIA",
        city: "MAHDIA",
        id: "208"
    },
    {
        ville: "MAHDIA",
        city: "MAHDIA HIBOUN",
        id: "209"
    },
    {
        ville: "MAHDIA",
        city: "MAHDIA REPUBLIQUE",
        id: "210"
    },
    {
        ville: "MAHDIA",
        city: "MELLOULECH",
        id: "211"
    },
    {
        ville: "MAHDIA",
        city: "MELLOULECHE",
        id: "212"
    },
    {
        ville: "MAHDIA",
        city: "OULED CHAMAKH",
        id: "213"
    },
    {
        ville: "MAHDIA",
        city: "REJICHE",
        id: "214"
    },
    {
        ville: "MAHDIA",
        city: "SFAX MEDINA",
        id: "215"
    },
    {
        ville: "MAHDIA",
        city: "SIDI ALOUANE",
        id: "216"
    },
    {
        ville: "MAHDIA",
        city: "SOUASSI",
        id: "217"
    },
    {
        ville: "MANOUBA",
        city: "BORJ EL AMRI",
        id: "220"
    },
    {
        ville: "MANOUBA",
        city: "CITE EL KHADRA",
        id: "221"
    },
    {
        ville: "MANOUBA",
        city: "DEN DEN",
        id: "222"
    },
    {
        ville: "MANOUBA",
        city: "DENDEN",
        id: "223"
    },
    {
        ville: "MANOUBA",
        city: "DOUAR HICHER",
        id: "224"
    },
    {
        ville: "MANOUBA",
        city: "EL BATTANE",
        id: "225"
    },
    {
        ville: "MANOUBA",
        city: "EL MENZAH",
        id: "226"
    },
    {
        ville: "MANOUBA",
        city: "HRAIRIA",
        id: "227"
    },
    {
        ville: "MANOUBA",
        city: "JEDAIDA",
        id: "228"
    },
    {
        ville: "MANOUBA",
        city: "LE BARDO",
        id: "229"
    },
    {
        ville: "MANOUBA",
        city: "MANOUBA",
        id: "230"
    },
    {
        ville: "MANOUBA",
        city: "MORNAGUIA",
        id: "231"
    },
    {
        ville: "MANOUBA",
        city: "OUED ELLIL",
        id: "232"
    },
    {
        ville: "MANOUBA",
        city: "TEBOURBA",
        id: "233"
    },
    {
        ville: "MEDENINE",
        city: "MEDENINE",
        id: "234"
    },
    {
        ville: "MEDENINE",
        city: "BEN GUERDANE",
        id: "236"
    },
    {
        ville: "MEDENINE",
        city: "BENI KHEDACHE",
        id: "237"
    },
    {
        ville: "MEDENINE",
        city: "DJERBA AJIM",
        id: "238"
    },
    {
        ville: "MEDENINE",
        city: "DJERBA HOUMET ESSOUK",
        id: "239"
    },
    {
        ville: "MEDENINE",
        city: "DJERBA HOUMET SOUK",
        id: "240"
    },
    {
        ville: "MEDENINE",
        city: "DJERBA MELLITA",
        id: "241"
    },
    {
        ville: "MEDENINE",
        city: "DJERBA MIDOUN",
        id: "242"
    },
    {
        ville: "MEDENINE",
        city: "EL MAY",
        id: "243"
    },
    {
        ville: "MEDENINE",
        city: "JERBA",
        id: "244"
    },
    {
        ville: "MEDENINE",
        city: "MAHDIA",
        id: "245"
    },
    {
        ville: "MEDENINE",
        city: "MANOUBA",
        id: "246"
    },
    {
        ville: "MEDENINE",
        city: "MEDENINE",
        id: "247"
    },
    {
        ville: "MEDENINE",
        city: "MEDENINE EL JEDIDA",
        id: "248"
    },
    {
        ville: "MEDENINE",
        city: "MEDENINE NORD",
        id: "249"
    },
    {
        ville: "MEDENINE",
        city: "TATAOUINE SUD",
        id: "250"
    },
    {
        ville: "MEDENINE",
        city: "TEBOULBA",
        id: "251"
    },
    {
        ville: "MEDENINE",
        city: "ZARZIS",
        id: "252"
    },
    {
        ville: "MONASTIR",
        city: "AMIRAT ELHOJJAJ",
        id: "253"
    },
    {
        ville: "MONASTIR",
        city: "ARIANA",
        id: "254"
    },
    {
        ville: "MONASTIR",
        city: "BEKALTA",
        id: "255"
    },
    {
        ville: "MONASTIR",
        city: "BEMBLA",
        id: "256"
    },
    {
        ville: "MONASTIR",
        city: "BENI HASSEN",
        id: "257"
    },
    {
        ville: "MONASTIR",
        city: "CHERAHIL",
        id: "258"
    },
    {
        ville: "MONASTIR",
        city: "EZZOUHOUR",
        id: "259"
    },
    {
        ville: "MONASTIR",
        city: "GABES EST",
        id: "260"
    },
    {
        ville: "MONASTIR",
        city: "JEMMAL",
        id: "261"
    },
    {
        ville: "MONASTIR",
        city: "KAIROUAN NORD",
        id: "262"
    },
    {
        ville: "MONASTIR",
        city: "KHENISS",
        id: "263"
    },
    {
        ville: "MONASTIR",
        city: "KSAR HELAL",
        id: "264"
    },
    {
        ville: "MONASTIR",
        city: "KSAR HELLAL",
        id: "265"
    },
    {
        ville: "MONASTIR",
        city: "KSIBET MEDIOUNI",
        id: "266"
    },
    {
        ville: "MONASTIR",
        city: "MENZEL EL HAYET",
        id: "267"
    },
    {
        ville: "MONASTIR",
        city: "MENZEL ENNOUR",
        id: "268"
    },
    {
        ville: "MONASTIR",
        city: "MENZEL KAMEL",
        id: "269"
    },
    {
        ville: "MONASTIR",
        city: "MOKNINE",
        id: "270"
    },
    {
        ville: "MONASTIR",
        city: "MONASTIR",
        id: "271"
    },
    {
        ville: "MONASTIR",
        city: "NABEUL",
        id: "272"
    },
    {
        ville: "MONASTIR",
        city: "OUARDANINE",
        id: "273"
    },
    {
        ville: "MONASTIR",
        city: "OUERDANINE",
        id: "274"
    },
    {
        ville: "MONASTIR",
        city: "SAHLINE",
        id: "275"
    },
    {
        ville: "MONASTIR",
        city: "SAYADA",
        id: "276"
    },
    {
        ville: "MONASTIR",
        city: "SAYADA LAMTA BOUHJAR",
        id: "277"
    },
    {
        ville: "MONASTIR",
        city: "SFAX SUD (THYNA)",
        id: "278"
    },
    {
        ville: "MONASTIR",
        city: "SIDI BANNOUR",
        id: "279"
    },
    {
        ville: "MONASTIR",
        city: "SIDI BOUZID EST",
        id: "280"
    },
    {
        ville: "MONASTIR",
        city: "SOUSSE MEDINA",
        id: "281"
    },
    {
        ville: "MONASTIR",
        city: "TEBOULBA",
        id: "282"
    },
    {
        ville: "MONASTIR",
        city: "ZERAMDINE",
        id: "283"
    },
    {
        ville: "NABEUL",
        city: "BARRAKET ESSAHEL",
        id: "286"
    },
    {
        ville: "NABEUL",
        city: "BENI KHALLED",
        id: "287"
    },
    {
        ville: "NABEUL",
        city: "BENI KHIAR",
        id: "288"
    },
    {
        ville: "NABEUL",
        city: "BIR BOUREGBA",
        id: "289"
    },
    {
        ville: "NABEUL",
        city: "BIR DRASSEN",
        id: "290"
    },
    {
        ville: "NABEUL",
        city: "BIR MROUA - TAKELSA",
        id: "291"
    },
    {
        ville: "NABEUL",
        city: "BOUARGOUB",
        id: "292"
    },
    {
        ville: "NABEUL",
        city: "DAR CHAABANE",
        id: "293"
    },
    {
        ville: "NABEUL",
        city: "DAR CHAABANE FEHRI",
        id: "294"
    },
    {
        ville: "NABEUL",
        city: "DJERBA MIDOUN",
        id: "295"
    },
    {
        ville: "NABEUL",
        city: "EL HAOUARIA",
        id: "296"
    },
    {
        ville: "NABEUL",
        city: "EL MIDA",
        id: "297"
    },
    {
        ville: "NABEUL",
        city: "FONDOUK JEDID",
        id: "298"
    },
    {
        ville: "NABEUL",
        city: "GROMBALIA",
        id: "299"
    },
    {
        ville: "NABEUL",
        city: "HAMMAM EL GHEZAZ",
        id: "300"
    },
    {
        ville: "NABEUL",
        city: "HAMMAMET",
        id: "301"
    },
    {
        ville: "NABEUL",
        city: "KAIROUAN NORD",
        id: "302"
    },
    {
        ville: "NABEUL",
        city: "KELIBIA",
        id: "303"
    },
    {
        ville: "NABEUL",
        city: "KELIBIA OUEST",
        id: "304"
    },
    {
        ville: "NABEUL",
        city: "KORBA",
        id: "305"
    },
    {
        ville: "NABEUL",
        city: "MEDENINE NORD",
        id: "306"
    },
    {
        ville: "NABEUL",
        city: "MENZEL BOUZELFA",
        id: "307"
    },
    {
        ville: "Nabeul",
        city: "Menzel Temim",
        id: "308"
    },
    {
        ville: "NABEUL",
        city: "MENZEL TEMIME",
        id: "309"
    },
    {
        ville: "NABEUL",
        city: "MORNAG",
        id: "310"
    },
    {
        ville: "NABEUL",
        city: "NABEUL",
        id: "311"
    },
    {
        ville: "NABEUL",
        city: "SIDI EL BECHIR",
        id: "312"
    },
    {
        ville: "NABEUL",
        city: "SOLIMAN",
        id: "313"
    },
    {
        ville: "NABEUL",
        city: "SOUSSE RIADH",
        id: "314"
    },
    {
        ville: "NABEUL",
        city: "TAKELSA",
        id: "315"
    },
    {
        ville: "NABEUL",
        city: "TAZARKA",
        id: "316"
    },
    {
        ville: "SFAX",
        city: "AGAREB",
        id: "321"
    },
    {
        ville: "SFAX",
        city: "BEN GUERDANE",
        id: "322"
    },
    {
        ville: "SFAX",
        city: "BIR ALI BEN KHALIFA",
        id: "323"
    },
    {
        ville: "SFAX",
        city: "CAID M'HAMED",
        id: "324"
    },
    {
        ville: "SFAX",
        city: "CEKHIRA",
        id: "325"
    },
    {
        ville: "SFAX",
        city: "CITE EL HABIB",
        id: "326"
    },
    {
        ville: "SFAX",
        city: "EL AIN",
        id: "327"
    },
    {
        ville: "SFAX",
        city: "EL AMRA",
        id: "328"
    },
    {
        ville: "SFAX",
        city: "EL BOUSTEN",
        id: "329"
    },
    {
        ville: "SFAX",
        city: "EL GHERABA",
        id: "330"
    },
    {
        ville: "SFAX",
        city: "EL HANCHA",
        id: "331"
    },
    {
        ville: "SFAX",
        city: "GABES EST",
        id: "332"
    },
    {
        ville: "SFAX",
        city: "GHRAIBA",
        id: "333"
    },
    {
        ville: "SFAX",
        city: "HAJEB",
        id: "334"
    },
    {
        ville: "SFAX",
        city: "JEBENIANA",
        id: "335"
    },
    {
        ville: "SFAX",
        city: "KERKENAH",
        id: "336"
    },
    {
        ville: "SFAX",
        city: "MAHARES",
        id: "337"
    },
    {
        ville: "SFAX",
        city: "MAHRES",
        id: "338"
    },
    {
        ville: "SFAX",
        city: "MENZEL CHAKER",
        id: "339"
    },
    {
        ville: "SFAX",
        city: "MENZEL HEDI CHAKER",
        id: "340"
    },
    {
        ville: "SFAX",
        city: "MERKEZ BOUASSIDA",
        id: "341"
    },
    {
        ville: "SFAX",
        city: "MERKEZ CHIHIA",
        id: "342"
    },
    {
        ville: "SFAX",
        city: "OUED RAMEL",
        id: "343"
    },
    {
        ville: "SFAX",
        city: "RAMLA - KERKENNAH",
        id: "344"
    },
    {
        ville: "SFAX",
        city: "SAKIET EDDAIER",
        id: "345"
    },
    {
        ville: "SFAX",
        city: "SAKIET EZZIT",
        id: "346"
    },
    {
        ville: "SFAX",
        city: "SFAX",
        id: "347"
    },
    {
        ville: "SFAX",
        city: "SFAX B DIWAN",
        id: "348"
    },
    {
        ville: "SFAX",
        city: "SFAX B JEBLI",
        id: "349"
    },
    {
        ville: "SFAX",
        city: "SFAX EL JADIDA",
        id: "350"
    },
    {
        ville: "SFAX",
        city: "SFAX G C",
        id: "351"
    },
    {
        ville: "SFAX",
        city: "SFAX JADIDA",
        id: "352"
    },
    {
        ville: "SFAX",
        city: "SFAX MEDINA",
        id: "353"
    },
    {
        ville: "SFAX",
        city: "SFAX OUEST",
        id: "354"
    },
    {
        ville: "SFAX",
        city: "SFAX P C",
        id: "355"
    },
    {
        ville: "SFAX",
        city: "SFAX SUD (THYNA)",
        id: "356"
    },
    {
        ville: "SFAX",
        city: "SIDI ABBČS",
        id: "357"
    },
    {
        ville: "SFAX",
        city: "SILIANA NORD",
        id: "358"
    },
    {
        ville: "SFAX",
        city: "TATAOUINE SUD",
        id: "359"
    },
    {
        ville: "SFAX",
        city: "THYNA",
        id: "360"
    },
    {
        ville: "SIDI BOUZID",
        city: "BIR EL HAFFEY",
        id: "361"
    },
    {
        ville: "SIDI BOUZID",
        city: "BIR EL HFAY",
        id: "362"
    },
    {
        ville: "SIDI BOUZID",
        city: "BIR LAHFAY",
        id: "363"
    },
    {
        ville: "SIDI BOUZID",
        city: "CEBALET OULED ASKER",
        id: "364"
    },
    {
        ville: "SIDI BOUZID",
        city: "CITE ETTADHAMEN",
        id: "365"
    },
    {
        ville: "SIDI BOUZID",
        city: "JELMA",
        id: "366"
    },
    {
        ville: "SIDI BOUZID",
        city: "JILMA",
        id: "367"
    },
    {
        ville: "SIDI BOUZID",
        city: "MANOUBA",
        id: "368"
    },
    {
        ville: "SIDI BOUZID",
        city: "MEKNASSY",
        id: "369"
    },
    {
        ville: "SIDI BOUZID",
        city: "MENZEL BOUZAIANE",
        id: "370"
    },
    {
        ville: "SIDI BOUZID",
        city: "MEZZOUNA",
        id: "371"
    },
    {
        ville: "SIDI BOUZID",
        city: "OULED HAFFOUZ",
        id: "372"
    },
    {
        ville: "SIDI BOUZID",
        city: "REGUEB",
        id: "373"
    },
    {
        ville: "SIDI BOUZID",
        city: "SAĎDA",
        id: "374"
    },
    {
        ville: "SIDI BOUZID",
        city: "SIDI ALI BEN AOUN",
        id: "375"
    },
    {
        ville: "SIDI BOUZID",
        city: "SIDI BOUZID",
        id: "376"
    },
    {
        ville: "SIDI BOUZID",
        city: "SIDI BOUZID EST",
        id: "377"
    },
    {
        ville: "SIDI BOUZID",
        city: "SOUK JEDID",
        id: "378"
    },
    {
        ville: "SILIANA",
        city: "BOU ROUIS",
        id: "380"
    },
    {
        ville: "SILIANA",
        city: "BOUARADA",
        id: "381"
    },
    {
        ville: "SILIANA",
        city: "GAAFOUR",
        id: "382"
    },
    {
        ville: "SILIANA",
        city: "LE KRIB",
        id: "383"
    },
    {
        ville: "SILIANA",
        city: "MAKTHAR",
        id: "384"
    },
    {
        ville: "SILIANA",
        city: "ROUHIA",
        id: "385"
    },
    {
        ville: "SILIANA",
        city: "SILIANA",
        id: "386"
    },
    {
        ville: "SILIANA",
        city: "SILIANA NORD",
        id: "387"
    },
    {
        ville: "SOUSSE",
        city: "AKOUDA",
        id: "390"
    },
    {
        ville: "SOUSSE",
        city: "ARIANA",
        id: "391"
    },
    {
        ville: "SOUSSE",
        city: "BOUFICHA",
        id: "392"
    },
    {
        ville: "SOUSSE",
        city: "CHATT MERIEM",
        id: "393"
    },
    {
        ville: "SOUSSE",
        city: "CITE JAOUHARA",
        id: "394"
    },
    {
        ville: "SOUSSE",
        city: "ENFIDHA",
        id: "395"
    },
    {
        ville: "SOUSSE",
        city: "FOUCHANA",
        id: "396"
    },
    {
        ville: "SOUSSE",
        city: "HAMMAM SOUSSE",
        id: "397"
    },
    {
        ville: "SOUSSE",
        city: "HAMMAM SOUSSE MEDINA",
        id: "398"
    },
    {
        ville: "SOUSSE",
        city: "HERGLA",
        id: "399"
    },
    {
        ville: "SOUSSE",
        city: "INCONNUE",
        id: "400"
    },
    {
        ville: "SOUSSE",
        city: "KALAA EL KEBIRA",
        id: "401"
    },
    {
        ville: "SOUSSE",
        city: "KALAA ESSEGHIRA",
        id: "402"
    },
    {
        ville: "SOUSSE",
        city: "KALAA KEBIRA",
        id: "403"
    },
    {
        ville: "SOUSSE",
        city: "KALAA SGHIRA",
        id: "404"
    },
    {
        ville: "SOUSSE",
        city: "KASSERINE EZZOUHOUR",
        id: "405"
    },
    {
        ville: "SOUSSE",
        city: "KHEZAMA OUEST",
        id: "406"
    },
    {
        ville: "SOUSSE",
        city: "KONDAR",
        id: "407"
    },
    {
        ville: "SOUSSE",
        city: "KSAR HELLAL",
        id: "408"
    },
    {
        ville: "SOUSSE",
        city: "M'SAKEN",
        id: "409"
    },
    {
        ville: "SOUSSE",
        city: "MAHDIA",
        id: "410"
    },
    {
        ville: "SOUSSE",
        city: "MESSADINE",
        id: "411"
    },
    {
        ville: "SOUSSE",
        city: "RAS JEBEL",
        id: "412"
    },
    {
        ville: "SOUSSE",
        city: "SEHLOUL",
        id: "413"
    },
    {
        ville: "SOUSSE",
        city: "SFAX MEDINA",
        id: "414"
    },
    {
        ville: "SOUSSE",
        city: "SIDI BOU ALI",
        id: "415"
    },
    {
        ville: "SOUSSE",
        city: "SIDI EL HANI",
        id: "416"
    },
    {
        ville: "SOUSSE",
        city: "SOUSSE",
        id: "417"
    },
    {
        ville: "SOUSSE",
        city: "SOUSSE EZZOUHOUR",
        id: "418"
    },
    {
        ville: "SOUSSE",
        city: "SOUSSE JAWHARA",
        id: "419"
    },
    {
        ville: "SOUSSE",
        city: "SOUSSE KHEZAMA",
        id: "420"
    },
    {
        ville: "SOUSSE",
        city: "SOUSSE MEDINA",
        id: "421"
    },
    {
        ville: "SOUSSE",
        city: "SOUSSE RIADH",
        id: "422"
    },
    {
        ville: "SOUSSE",
        city: "SOUSSE SIDI ABDELHAMID",
        id: "423"
    },
    {
        ville: "SOUSSE",
        city: "ZERAMDINE",
        id: "424"
    },
    {
        ville: "TATAOUINE",
        city: "DJERBA HOUMET ESSOUK",
        id: "425"
    },
    {
        ville: "TATAOUINE",
        city: "GHOMRASSEN",
        id: "426"
    },
    {
        ville: "TATAOUINE",
        city: "SMAR",
        id: "427"
    },
    {
        ville: "TATAOUINE",
        city: "TATAOUINE 7 NOV",
        id: "428"
    },
    {
        ville: "TATAOUINE",
        city: "TATAOUINE MEDINA",
        id: "429"
    },
    {
        ville: "TATAOUINE",
        city: "TATAOUINE SUD",
        id: "430"
    },
    {
        ville: "TOZEUR",
        city: "DEGACHE",
        id: "431"
    },
    {
        ville: "TOZEUR",
        city: "NEFTA",
        id: "432"
    },
    {
        ville: "TOZEUR",
        city: "SIDI BOUZID EST",
        id: "433"
    },
    {
        ville: "TOZEUR",
        city: "TOZEUR",
        id: "434"
    },
    {
        ville: "TUNIS",
        city: "AMILCAR",
        id: "437"
    },
    {
        ville: "TUNIS",
        city: "ARIANA",
        id: "438"
    },
    {
        ville: "TUNIS",
        city: "ARIANA NORD",
        id: "439"
    },
    {
        ville: "TUNIS",
        city: "BAB BHAR",
        id: "440"
    },
    {
        ville: "TUNIS",
        city: "BAB BNET",
        id: "441"
    },
    {
        ville: "TUNIS",
        city: "BAB SAADOUN GARE",
        id: "442"
    },
    {
        ville: "TUNIS",
        city: "BAB SOUIKA",
        id: "443"
    },
    {
        ville: "TUNIS",
        city: "BARDO",
        id: "444"
    },
    {
        ville: "TUNIS",
        city: "CARTHAGE",
        id: "445"
    },
    {
        ville: "TUNIS",
        city: "CITE EL KHADRA",
        id: "446"
    },
    {
        ville: "TUNIS",
        city: "CITE EL MAHRAJENE",
        id: "447"
    },
    {
        ville: "TUNIS",
        city: "CITE ETTADHAMEN",
        id: "448"
    },
    {
        ville: "TUNIS",
        city: "CITE ETTAHRIR",
        id: "449"
    },
    {
        ville: "TUNIS",
        city: "CITE EZZOUHOUR",
        id: "450"
    },
    {
        ville: "TUNIS",
        city: "CITE IBN KHALDOUN",
        id: "451"
    },
    {
        ville: "TUNIS",
        city: "CITE INTILAKA",
        id: "452"
    },
    {
        ville: "TUNIS",
        city: "CITE M'HIRI",
        id: "453"
    },
    {
        ville: "TUNIS",
        city: "CITE MAHRAJENE",
        id: "454"
    },
    {
        ville: "TUNIS",
        city: "EL BOUHAIRA - LE KRAM",
        id: "455"
    },
    {
        ville: "TUNIS",
        city: "EL MANAR (I, II, III)",
        id: "456"
    },
    {
        ville: "TUNIS",
        city: "EL MANAR 2",
        id: "457"
    },
    {
        ville: "TUNIS",
        city: "EL MENZAH",
        id: "458"
    },
    {
        ville: "TUNIS",
        city: "EL MENZAH ( I - IV )",
        id: "459"
    },
    {
        ville: "TUNIS",
        city: "EL MENZAH 1",
        id: "460"
    },
    {
        ville: "TUNIS",
        city: "EL MENZAH 6",
        id: "461"
    },
    {
        ville: "TUNIS",
        city: "EL MENZAH 9",
        id: "462"
    },
    {
        ville: "TUNIS",
        city: "EL MOUROUJ",
        id: "463"
    },
    {
        ville: "TUNIS",
        city: "EL OMRANE",
        id: "464"
    },
    {
        ville: "TUNIS",
        city: "EL OUARDIA",
        id: "465"
    },
    {
        ville: "TUNIS",
        city: "EL OUERDIA",
        id: "466"
    },
    {
        ville: "TUNIS",
        city: "ETTAHRIR",
        id: "467"
    },
    {
        ville: "TUNIS",
        city: "EZZAHROUNI",
        id: "468"
    },
    {
        ville: "TUNIS",
        city: "EZZOUHOUR",
        id: "469"
    },
    {
        ville: "TUNIS",
        city: "GAMMARTH",
        id: "470"
    },
    {
        ville: "TUNIS",
        city: "HAMMAM CHATT",
        id: "471"
    },
    {
        ville: "TUNIS",
        city: "HAMMAM-LIF",
        id: "472"
    },
    {
        ville: "TUNIS",
        city: "HRAIRIA",
        id: "473"
    },
    {
        ville: "TUNIS",
        city: "JEBEL JELLOUD",
        id: "474"
    },
    {
        ville: "TUNIS",
        city: "KABARIA",
        id: "475"
    },
    {
        ville: "TUNIS",
        city: "KELIBIA",
        id: "476"
    },
    {
        ville: "TUNIS",
        city: "KSAR SAID",
        id: "477"
    },
    {
        ville: "TUNIS",
        city: "LA CHARGUIA II - TUNIS CE",
        id: "478"
    },
    {
        ville: "TUNIS",
        city: "LA GOULETTE",
        id: "479"
    },
    {
        ville: "TUNIS",
        city: "LA MARSA",
        id: "480"
    },
    {
        ville: "TUNIS",
        city: "LA SOUKRA",
        id: "481"
    },
    {
        ville: "TUNIS",
        city: "LE BARDO",
        id: "482"
    },
    {
        ville: "TUNIS",
        city: "LE KRAM",
        id: "483"
    },
    {
        ville: "TUNIS",
        city: "LE KRAM OUEST",
        id: "484"
    },
    {
        ville: "TUNIS",
        city: "LES BERGES DU LAC",
        id: "485"
    },
    {
        ville: "TUNIS",
        city: "MARSA ERRIADH",
        id: "486"
    },
    {
        ville: "TUNIS",
        city: "MARSA SAF SAF",
        id: "487"
    },
    {
        ville: "TUNIS",
        city: "MARSA SAFSAF",
        id: "488"
    },
    {
        ville: "TUNIS",
        city: "MENIHLA",
        id: "489"
    },
    {
        ville: "TUNIS",
        city: "MONTPLAISIR",
        id: "490"
    },
    {
        ville: "TUNIS",
        city: "OMRANE SUPERIEUR",
        id: "491"
    },
    {
        ville: "TUNIS",
        city: "SALAMBO",
        id: "492"
    },
    {
        ville: "TUNIS",
        city: "SFAX MEDINA",
        id: "493"
    },
    {
        ville: "TUNIS",
        city: "SIDI BOU ALI",
        id: "494"
    },
    {
        ville: "TUNIS",
        city: "SIDI BOUSAID",
        id: "495"
    },
    {
        ville: "TUNIS",
        city: "SIDI DAOUD",
        id: "496"
    },
    {
        ville: "TUNIS",
        city: "SIDI EL BECHIR",
        id: "497"
    },
    {
        ville: "TUNIS",
        city: "SIDI HASSINE",
        id: "498"
    },
    {
        ville: "TUNIS",
        city: "SIDJOUMI",
        id: "499"
    },
    {
        ville: "TUNIS",
        city: "Tunis",
        id: "500"
    },
    {
        ville: "TUNIS",
        city: "TUNIS BAB BNET",
        id: "501"
    },
    {
        ville: "TUNIS",
        city: "TUNIS BAB EL KHADRA",
        id: "502"
    },
    {
        ville: "TUNIS",
        city: "TUNIS BAB MENARA",
        id: "503"
    },
    {
        ville: "TUNIS",
        city: "TUNIS BAB SOUIKA",
        id: "504"
    },
    {
        ville: "TUNIS",
        city: "TUNIS BELVEDERE",
        id: "505"
    },
    {
        ville: "TUNIS",
        city: "TUNIS EL OMRANE",
        id: "506"
    },
    {
        ville: "TUNIS",
        city: "TUNIS JEBBARI",
        id: "507"
    },
    {
        ville: "TUNIS",
        city: "TUNIS REPUBLIQUE",
        id: "508"
    },
    {
        ville: "TUNIS",
        city: "TUNIS RP (VILLE)",
        id: "509"
    },
    {
        ville: "TUNIS",
        city: "TUNIS THAMEUR",
        id: "510"
    },
    {
        ville: "ZAGHOUAN",
        city: "BAB BHAR",
        id: "511"
    },
    {
        ville: "ZAGHOUAN",
        city: "BIR M'CHARGUA",
        id: "512"
    },
    {
        ville: "ZAGHOUAN",
        city: "EL FAHS",
        id: "513"
    },
    {
        ville: "ZAGHOUAN",
        city: "JEBEL OUAST",
        id: "514"
    },
    {
        ville: "ZAGHOUAN",
        city: "NADHOUR",
        id: "515"
    },
    {
        ville: "ZAGHOUAN",
        city: "SAOUAF",
        id: "516"
    },
    {
        ville: "ZAGHOUAN",
        city: "ZAGHOUAN",
        id: "517"
    },
    {
        ville: "ZAGHOUAN",
        city: "ZRIBA",
        id: "518"
    }
]

export function citiesByGovernment(government: string): Array<{ name: string, id: number }> {
    return TN_CITIES.filter(c => c.ville.toLowerCase().trim() === government.trim().toLowerCase()).map(e => ({ name: e.city, id: Number(e.id || 0) }));
}