<div class="row" style="background-color: transparent;">
    <div class="col-lg-10">
        <select-drop-down [items]="doctorMedics" [disabled]="disabled" (filterPatternChange)="onFilterPatternChange($event)" [(ngModel)]="value"
            [options]="{ filter:{ byKey:'nom' } }" name="medic">
            <ng-template let-item="item">{{item.nom}}</ng-template>
        </select-drop-down>
    </div>
    <div class="col-lg-1">
        <button class="openDialoge" [title]="value?.co" (click)="urlMedic(value.details,'Détail')">
            <i class="feather-eye"></i>
        </button>
    </div>
    <div class="col-lg-1">
        <button class="openDialoge" (click)="openDialoge()">
            <i class="feather-plus-circle"></i>
        </button>
    </div>
</div>

<app-modal [title]="'Ajouter Nouveau Médicament'" #dialog>
    <app-medic-form #medicForm></app-medic-form>
    <button class="btn btn-outline-info mb-3 mt-3" (click)="onAddMedic()">Ajouter Médicament</button>
</app-modal>

<!-- <div class="drop-down-select">

    <div class="drop-down-input" (click)="toggleModal()">
        <input type="text" [placeholder]="placeHolder || ''" [value]="value.nom" readonly>
        <span>
            <i class="fa fa-plus-circle"></i>
        </span>
    </div>
    <div *ngIf="modalToggled" class="drop-down-modal">
        <div class="drop-down-modal-header">
            <span>Choose a Medic</span>
            <span (click)="toggleModal(false)">X</span>
        </div>
        <div class="drop-down-modal-body">
            <div class="tab-view">
                <ul class="nav-tabs">
                    <li class="nav-item" (click)="currentTabIndex = 0" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="{'active':currentTabIndex == 0}" data-bs-toggle="tab">All</a>
                    </li>
                    <li class="nav-item" (click)="currentTabIndex = 1" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="{'active':currentTabIndex == 1}" data-bs-toggle="tab">New</a>
                    </li>
                </ul>
                <div *ngIf="currentTabIndex == 0" class="tab-content">
                    <div class="input-control" style="height: 40px;">
                        <input type="text" placeholder="search" [(ngModel)]="searchPattern">
                        <span>
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                    <ng-container *ngIf="medics.length else empty">
                        <scrollbar>
                            <div *ngFor="let medic of medics" class="list-group-item" (click)="onValuesSelected(medic)">
                                <span
                                    style="color: #d86800;font-size:14px;text-overflow: ellipsis;">{{medic.nom}}</span>
                                <p style="color: #6e6e6e;font-size:10px;text-overflow: ellipsis;">{{medic.labo}}</p>
                            </div>
                        </scrollbar>
                    </ng-container>
                    <ng-template #empty>
                        <span>No item found</span>
                    </ng-template>
                </div>
                <div *ngIf="currentTabIndex == 1" class="tab-content">
                    <app-medic-form #medicForm></app-medic-form>
                    <button class="btn btn-outline-info mb-3" (click)="onAddMedic()">Add Medic</button>
                </div>
            </div>
        </div>
    </div>
</div> 
-->

<app-drawer *ngIf="drawerActive" [active]="drawerActive" [title]="title" class="drawer">
    <nav header class="style">
        <span *ngIf="nav?.deails==true" (click)="urlInter(value.details,'Détail')">Détail</span>
        <span *ngIf="nav?.Notice==true" (click)="urlInter(value.notice,'Notice')">Notice</span>
        <span *ngIf="nav?.RCP==true" (click)="urlInter(value.rcp,'Rcp')">RCP</span>
    </nav>
    <iframe body style="width: 100%; height: 58%;" [src]="src"></iframe>

</app-drawer>