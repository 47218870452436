import { Environment } from "./environment-entity";


export const environment: Environment = new Environment({
  VIDEOSDK_API_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJmMmI1ZWVjYy05ZjQyLTQxNzgtODNmOS00NTBhMGQ1YTZmN2IiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyNDQxODk1MywiZXhwIjoxODgyMjA2OTUzfQ.I8W5ujobutwW3t017fTKaqD8rfO8xpjScuyvD67RiIY',
  VIDEOSDK_API_KEY: 'f2b5eecc-9f42-4178-83f9-450a0d5a6f7b',
  VIDEO_CALL_MAX_DURATION: 90,
  PAYME_API_KEY: '4c0048c0cc9cf8e8eede71090069a209d98fa88a',
  PAYME_API_URL: "https://app.paymee.tn/api/v2/payments/create",
  PAYMENT_SERVICE: 'KONNECT',
  API_HOST_NAME: 'backend.altabib.ci',
  STORAGE_ENDPOINT: 'fileuploads',
  API_URL: 'https://backend.altabib.ci/altabib-0.3.5/api',
  ENV: 'development',
  DOMAINE_NAME: 'backend.altabib.ci',
  API_SSL: true,
  KONNECT_API_URL: "https://api.konnect.network/api/v2",
  KONNECT_API_KEY: "656ef9fd07bb824e74098b0f:JeSY8ywAcf4WducVsYXmuxXmq3",
  KONNECT_RECEIVER_WALLET_ID: '656ef9fd07bb824e74098b13',
  PORT: 0,
  addsPannels: false
});
