<div class="tags-input scroller" (click)="focus()">
  <div class="tags-list">
    <div *ngFor="let tag of items;let i = index" [ngStyle]="{'background-color':tag.color || _getColor()}" class="tag-list-item">
      <span>{{tag.text}}</span>
      <span class="close" (click)="removeTag(i)">x</span>
    </div>

    <div class="tag-input">
      <input type="text" (keypress)="_handleTagInputKeyEnter($event)" (input)="filter($event)" #tagInput />
    </div>
  </div>
  <ng-container *ngIf="filterdInputAutoCompleteTags.length">
    <div *ngIf="filterdInputAutoCompleteTags.length || options.strictMode" class="tags-source">
      <ng-container *ngIf="filterdInputAutoCompleteTags.length;else empty">
        <div *ngFor="let tag of filterdInputAutoCompleteTags" class="tag-source-item" (click)="pickFiltredTag(tag)">
          <span>{{tag.text}}</span>
        </div>
      </ng-container>
      <ng-template #empty>
        <span>No filter fits inputs .</span>
      </ng-template>
    </div>
  </ng-container>
</div>