import { NgModule } from '@angular/core';
import { ActDropdownSelectComponent } from './act-dropdown-select.component/act-dropdown-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DoctorActFormComponent } from './doctor-act-form.component/doctor-act-form.component';
import { AppCommonModule } from 'src/app/common/common.module';

@NgModule({
  declarations: [
    ActDropdownSelectComponent,
    DoctorActFormComponent
  ],
  imports: [
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
  ], exports: [
    ActDropdownSelectComponent,
    DoctorActFormComponent
  ]
})
export class ActsFormModule { }
