import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

export interface DatePickerOptions {
  format: string;
}
@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
})
export class DatepickerComponent implements OnInit {
  _options!: Partial<DatePickerOptions>;
  @Input() set options(options: Partial<DatePickerOptions>) {
    this._options = { ...options };
  }
  @Input() date: Date = new Date();
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  _min: string = '';
  _max: string = '';

  ngOnInit(): void {
   
    
   }

  @Input() set min(min: Date) {
    this._min = min.getFullYear() + '-' + (min.getMonth() + 1 <= 9 ? '0' + (min.getMonth() + 1) : min.getMonth() + 1) + '-' + (min.getDate() <= 9 ? '0' + min.getDate() : min.getDate());
  }

  @Input() set max(max: Date) {
    this._max = max.getFullYear() + '-' + (max.getMonth() + 1 <= 9 ? '0' + (max.getMonth() + 1) : max.getMonth() + 1) + '-' + (max.getDate() <= 9 ? '0' + max.getDate() : max.getDate());
  }

  constructor() { }

  _onDateInputChange(event: any): void {

    const strDate = event.target.value;
    
    this.date = new Date(Number(strDate.split('-')[0]), Number(strDate.split('-')[1]) - 1, Number(strDate.split('-')[2]));
    this.dateChange.emit(this.date);
  }

}
