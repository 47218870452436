import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component, AfterViewInit, ContentChild, ElementRef, Input, OnInit, Renderer2, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.css']
})
export class DropDownMenuComponent implements AfterViewInit {
  @ContentChild('trigger', { read: ElementRef, static: false }) trigger!: ElementRef;
  @ContentChild('content', { read: ElementRef, static: false }) content!: ElementRef;
  @Input() space: number = 12;
  isActive: boolean = false;
  coordinations: { x: number, y: number } = { x: 0, y: 0 };
  @Input() orientation: 'left' | 'right' | 'top' | 'bottom' | 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom' = 'left';

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.isActive) this.toggleMenu()
    }
  }

  constructor(private rendrer: Renderer2, private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    if (this.trigger) {
      this.rendrer.listen(this.trigger.nativeElement, 'click', () => {
        this.toggleMenu()
      })
    }
  }

  getPosition(): { x: number, y: number } {
    if (this.trigger) {
      const triggerCoordinations = { x: this.trigger.nativeElement.getBoundingClientRect().x, y: this.trigger.nativeElement.getBoundingClientRect().y, width: this.trigger.nativeElement.clientWidth, height: this.trigger.nativeElement.clientHeight }
      switch (this.orientation) {
        case 'left': {
          return { x: - (this.space + 200 + (this.content.nativeElement as Element).clientWidth), y: triggerCoordinations.height + this.space }
        } break;
        case 'right': {
          return { x: triggerCoordinations.x + this.space + (this.elementRef.nativeElement as Element).clientWidth, y: triggerCoordinations.y }
        } break;
        case 'top': {
          return { x: triggerCoordinations.x, y: triggerCoordinations.y - this.space - (this.elementRef.nativeElement as Element).clientHeight }
        } break;
        case 'bottom': {
          return { x: triggerCoordinations.x, y: triggerCoordinations.y + this.space + (this.elementRef.nativeElement as Element).clientHeight }
        } break;
        case 'left-top': {
          return { x: triggerCoordinations.x, y: triggerCoordinations.y + this.space + (this.elementRef.nativeElement as Element).clientHeight }
        } break;
        case 'left-bottom': { return { x: 0, y: 0 } } break;
        case 'right-top': { return { x: 0, y: 0 } } break;
        case 'right-bottom': { return { x: 0, y: 0 } } break;
      }
    } else return { x: 0, y: 0 }
  }

  toggleMenu(): void {
    this.isActive = !this.isActive;
    if (this.isActive) {
      this.coordinations = this.getPosition();
    } else this.coordinations = { x: 0, y: 0 }
  }
}
