import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends ApiService{
sendEmail(formatData):Observable<any>{
  return this.http.post<ApiResponse>(
    `${environment.API_URL}/mail`,formatData
  ).pipe(this.responsePipe)
}

}
