import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { PatientAppointmentBill } from 'src/app/entities/appointment-bill.entity';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { VideoCallRoom } from 'src/app/entities/video-call-room.entity/video-call-room';
import { MILLISECONDS_IN_SECOND } from 'src/app/utils/date.util';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientAppointmentsService extends ApiService {



  search(query: { date?: Date | undefined; query?: string | undefined; }, pageRequest?: PageRequest): Observable<Array<any>> {
    let params = new HttpParams();
    if (query.date) params = params.append('date', query.date.getTime());
    if (query.query) params = params.append('query', query.query);
    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    if (pageRequest)
      return this.http.get<ApiResponse>(`${environment.API_URL}/patient/appointments/search`, { params: params, responseType: 'json' }).pipe<Array<PatientAppointment>>(this.responsePipe);
    else
      return this.http.get<ApiResponse>(`${environment.API_URL}/patient/appointments/all/search`, { params: params, responseType: 'json' }).pipe<Array<PatientAppointment>>(this.responsePipe);
  }

  all(pageRequest?: PageRequest): Observable<Array<PatientAppointment>> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/patient/appointments`, { responseType: 'json', params: params }).pipe<Array<PatientAppointment>>(this.responsePipe);
    }
    else return this.http.get<ApiResponse>(`${environment.API_URL}/patient/appointments/all`, { responseType: 'json' }).pipe<Array<PatientAppointment>>(this.responsePipe);
  }

  add(doctorId: string, appointment: PatientAppointment): Observable<PatientAppointment> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/patient/appointments?doctorId=${doctorId}`, { date: appointment.date.getTime(), startTime: appointment.startTime, duration: appointment.duration, location: appointment.location, motif: appointment.motif }, { responseType: 'json' }).pipe(this.responsePipe);
  }

  oneByConfidentialCode(confidentialCode: String): Observable<PatientAppointment> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/patients/appointments/${confidentialCode}`, { responseType: 'json' }).pipe<PatientAppointment>(this.responsePipe);
  }

  one(appointmentId: String): Observable<PatientAppointment> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/patient/appointments/${appointmentId}`, { responseType: 'json' }).pipe<PatientAppointment>(this.responsePipe);
  }

  bill(appointmentId: String): Observable<PatientAppointmentBill> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/patient/appointments/${appointmentId}/billing`, { responseType: 'json' }).pipe<PatientAppointmentBill>(this.responsePipe);
  }

  billByConfidentialCode(confidentialCode: String): Observable<PatientAppointmentBill> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/patients/appointments/${confidentialCode}/billing`, { responseType: 'json' }).pipe<PatientAppointmentBill>(this.responsePipe);
  }

  update(appointmentId: String, appointment: PatientAppointment): Observable<PatientAppointment> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/patient/appointments/${appointmentId}`, { date: appointment.date.getTime(), startTime: appointment.startTime, duration: appointment.duration, location: appointment.location, motif: appointment.motif }, { responseType: 'json' }).pipe<PatientAppointment>(this.responsePipe);
  }

  cancel(appointmentId: String): Observable<PatientAppointment> {
    return this.http.patch<ApiResponse>(`${environment.API_URL}/patient/appointments/${appointmentId}`, { responseType: 'json' }).pipe<PatientAppointment>(this.responsePipe);
  }

  teleconsultation(appointmentId: string): Observable<VideoCallRoom> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/patient/appointments/${appointmentId}/teleconsultation`, { responseType: 'json' }).pipe(timeout(20 * MILLISECONDS_IN_SECOND)).pipe<VideoCallRoom>(this.responsePipe);
  }

}
