import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { PublicApisService } from 'src/app/services/public-apis.service/public-apis.service';

@Component({
  selector: 'app-appointment-patient-verification',
  templateUrl: './appointment-patient-verification.component.html',
  styleUrls: ['./appointment-patient-verification.component.css']
})
export class AppointmentPatientVerificationComponent implements OnInit {
  @Input() patientAppointment!: PatientAppointment;
  code: string = '';
  validating: boolean = false;
  @Input() dir: string = 'ar'
  get valid(): boolean {
    return this.code.length == 5;
  }

  constructor(private publicApiService: PublicApisService, private toastrService: ToastrService) {

  }

  validate(): boolean {
    this.validating = true;
    return this.valid;
  }

  ngOnInit(): void {

  }

  submit(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.valid) reject()
      this.publicApiService.verify(this.patientAppointment.id, this.code.trim()).subscribe(is => {
        if (!is) {
          this.toastrService.error('Enable to confirm appointment ,Invalid code !');
          reject(is)
        }
        else resolve(this.patientAppointment);
      }, error => {
        this.toastrService.error('Error! , Enable to confirm appointment')
        reject(error)
      })
    })
  }
}
