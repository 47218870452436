<div class="drop-down-select">
    <div class="drop-down-input">
        <form-input [type]="'text'" [placeholder]="placeHolder || 'Chercher '" style="width: 100%;"
            [value]="(options.filter) ? (options.filter!.byKey ? (value ? value[options.filter!.byKey] : '') : value.toString()) : ''" (valueChange)="onSearchInputChange($event)"
            (keyup.arrowdown)="onKeyDown($event)" [append]="'fa fa-search'" [disabled]="disabled" class="input"> </form-input>
    </div>
    <div *ngIf="toggeledModal" class="drop-down-modal">
        <div class="drop-down-modal-body">
            <ng-container *ngIf="filteredItems.length  else empty">
                <div *ngFor="let item of filteredItems.slice(0,10)" class="list-group-item" (click)="onSelectedItem(item)">
                    <template [ngTemplateOutlet]="templateVariable" [ngTemplateOutletContext]="{item: item}"></template>
                </div>
            </ng-container>
            <ng-template #empty>
                <span>No item found</span>
            </ng-template>
        </div>
    </div>
</div>