import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-tab',
  templateUrl: './accordion-tab.component.html',
  styleUrls: ['./accordion-tab.component.css']
})
export class AccordionTabComponent implements OnInit {

  @Input() title: string = '';
  @Input() collapsed: boolean = false;
  @Input() collapsable: boolean = true;

  constructor() { }

  ngOnInit(): void {

  }

}
