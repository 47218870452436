import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/common/modules/modal.module/modal/modal.component';
import { FilterPipe } from 'src/app/common/pipes/filter.pipe/filter.pipe';
import { Act, DoctorAct } from 'src/app/entities/act.entity/act';
import { DoctorActFormComponent } from '../doctor-act-form.component/doctor-act-form.component';
import { DoctorActsService } from 'src/app/services/doctor-acts.service/doctor-acts.service';

@Component({
  selector: 'app-act-dropdown-select',
  templateUrl: './act-dropdown-select.component.html',
  styleUrls: ['./act-dropdown-select.component.css']
})
export class ActDropdownSelectComponent implements OnInit {


  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleModal(false)
    }
  }

  @Input() values: Array<Act> = new Array();
  @Input() placeHolder: string = "";
  @ViewChild('doctorActForm', { read: DoctorActFormComponent, static: false }) doctorActForm!: DoctorActFormComponent;
  searchPattern: string = '';
  @ViewChild(ModalComponent, { read: ModalComponent, static: false })

  dialog!: ModalComponent;
  actsList: Array<Act> = new Array();
  @Input() set value(value: Act) {
    this._value = value;
    this.valueChange.emit(this._value);
  }
  modalToggled: boolean = false;
  currentTabIndex: number = 0;

  get value(): Act {
    return this._value;
  }

  private _value!: Act;
  @Output() valueChange: EventEmitter<Act> = new EventEmitter();

  constructor(private elementRef: ElementRef, private doctorActsService: DoctorActsService, private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.doctorActsService.all().subscribe(acts => {
      this.actsList = Array.from(acts, e => DoctorAct.fromResponse(e)).map(a => a.actes);
    })
  }

  toggleModal(toggled?: boolean, elementId?: string): void {
    if (toggled != undefined) this.modalToggled = toggled;
    else this.modalToggled = !this.modalToggled;
    if (toggled == true && elementId != undefined) {
      this.currentTabIndex = 0;
      this.viewportScroller.scrollToAnchor(elementId);
    }
  }

  onAddAct(): void {
    this.doctorActForm.isSubmitted = true;
    if (this.doctorActForm.isValid) {
      this.doctorActsService.add(this.doctorActForm.doctorAct).subscribe((doctorAct: any) => {
        this.value = Act.fromResponse(doctorAct.act);
        this.doctorActForm.isSubmitted = false;
        this.actsList = [doctorAct.act, ...this.acts];
        this.dialog.close()

        this.toggleModal(false);
      }, error => alert('Enable to add medic'));
    }
  }

  get acts(): Array<Act> {
    return new FilterPipe().transform(this.actsList, this.searchPattern, { keyword: 'famille_Acte' });
  }


  get groupedActs(): Array<{ keyName: string, items: Array<Act> }> {
    let groups: Array<string> = this.acts.map(item => item.famille_Acte).filter((item, index, self) => self.indexOf(item) === index)
    return Array.from(groups, group => {
      let items: Array<Act> = new Array();
      this.acts.forEach(act => {
        if (String(group).toUpperCase() == String(act.famille_Acte).toUpperCase()) items.push(act);
      })
      return {
        keyName: group, items: items
      };
    });
  }

  onValuesSelected(value: Act) {
    this.value = value;
    if (value) {
      this.value = value;
      this.toggleModal(false)
      this.searchPattern = ''
    }
  }

}
