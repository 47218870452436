import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppCommonModule } from 'src/app/common/common.module';
import { BiologyFormComponent } from './biology-form.component/biology-form.component';
import { BiologySelectDropdownComponent } from './biology-select-dropdown.component/biology-select-dropdown.component';
import { BiologySelectDrawerComponent } from './biology-select-drawer/biology-select-drawer.component';


@NgModule({
    declarations: [
        BiologyFormComponent,
        BiologySelectDropdownComponent,
        BiologySelectDrawerComponent,
        
    ],
    imports: [
        AppCommonModule,
        FormsModule,
        
    ],
    exports: [
        BiologyFormComponent,
        BiologySelectDropdownComponent,
        BiologySelectDrawerComponent
    ]
})
export class BiologyFormModule { }