import { IUser, User, UserRole } from "../";
import Speciality from "./speciality.entity";
import { DoctorSubSpeciality } from "./sub-speciality.entity";
export enum AppointmentTiming {
    CALANDER,
    DATEHEURE,
    DATE,
}
interface IDoctor extends IUser {
    //educations: { title: string; description: string; startYear: number; endYear?: number | undefined; }[];
    //experiences: { title: string; description: string; startYear: number; endYear?: number | undefined; }[];
    //awards: { date: number; title: string; description: string; }[];
    //services: string[];
    //businessHours: Map<Day, { start: Time; end: Time; }[]>;
    //locations: IDoctorLocalization[];
    //reviews: IDoctorReview[];

    fixPhone: string;

    arabicFirstName: string;
    arabicLastName: string;
    arabicAddress: string;
    professionalCivility: string;
    arabicProfessionalCivility: string;


    speciality: Speciality;
    subSpecialities: Array<DoctorSubSpeciality>;
    timeSlotLocalSetting: number;
    biography: string;

    consultationActs: boolean;
    consultationVaccinations: boolean;
    consultationBiology: boolean;
    consultationRadiology: boolean;
    consultationRefraction: boolean;
    consultationLaser: boolean;
    consultationLens: boolean;
    appointmentTiming: AppointmentTiming
    prescriptionActs: boolean;
    prescriptionBiology: boolean;
    prescriptionRadiology: boolean;
    prescriptionRefraction: boolean;
    prescriptionLaser: boolean;
    prescriptionLens: boolean;

    hasCalender: boolean;
    hasCalenderUpToDate: boolean;

    cnam: string;

    printSpeciality: string;
    printArabicSpeciality: string;
    printAddress: string;
    printArabicAddress: string;
    printFullName: string;
    printArabicFullName: string;

    dashboardPrintPayments: boolean;

    patientRefSetting: boolean;
    regularConsultationPriceSetting: number;
    secretaryPaymentsSetting: boolean;
    secretaryAPSetting: boolean;

    consultationCNAMAP1: boolean;
    consultationCNAMAP2: boolean;
    consultationCNAMAP3: boolean;
    consultationCNAMAP4: boolean;


    pediatricVaccinations: boolean;

    smsNotification: boolean;
    smsPhone: string;

    teleconsultationWaitTime: number;

    qualifications: Array<string>;
    spokenLanguages: Array<string>;
}

export class Doctor extends User implements IDoctor {

    arabicFirstName: string;
    arabicLastName: string;

    arabicAddress: string;

    professionalCivility: string;
    arabicProfessionalCivility: string;


    speciality: Speciality;
    subSpecialities: Array<DoctorSubSpeciality> = [];

    fixPhone: string;
    timeSlotLocalSetting: number;
    biography: string;
    //educations: { title: string; description: string; startYear: number; endYear?: number | undefined; }[];
    //experiences: { title: string; description: string; startYear: number; endYear?: number | undefined; }[];
    //awards: { date: number; title: string; description: string; }[];
    //services: string[];
    //businessHours: Map<Day, { start: Time; end: Time; }[]>;
    //locations: IDoctorLocalization[];
    //reviews: IDoctorReview[];

    password?: string | undefined;


    hasCalender: boolean;
    hasCalenderUpToDate: boolean;
    consultationActs: boolean;
    consultationVaccinations: boolean;
    consultationBiology: boolean;
    consultationRadiology: boolean;
    consultationRefraction: boolean;
    consultationLaser: boolean;
    consultationLens: boolean;
    prescriptionActs: boolean;
    prescriptionBiology: boolean;
    prescriptionRadiology: boolean;
    prescriptionRefraction: boolean;
    prescriptionLaser: boolean;
    prescriptionLens: boolean;
    cnam: string;
    printSpeciality: string;
    printArabicSpeciality: string;
    printAddress: string;
    printArabicAddress: string;
    printFullName: string;
    printArabicFullName: string;
    dashboardPrintPayments: boolean;
    patientRefSetting: boolean;
    regularConsultationPriceSetting: number;
    secretaryPaymentsSetting: boolean;
    secretaryAPSetting: boolean;
    appointmentTiming: AppointmentTiming;
    consultationCNAMAP1: boolean;
    consultationCNAMAP2: boolean;
    consultationCNAMAP3: boolean;
    consultationCNAMAP4: boolean;

    pediatricVaccinations: boolean;

    smsNotification: boolean;
    smsPhone: string;

    teleconsultationWaitTime: number;
    qualifications: Array<string> = new Array();
    spokenLanguages: Array<string> = new Array();
    override getFullName(inverted?: boolean | undefined): string {
        return inverted ? this.lastName + ' ' + this.firstName + '.' + this.professionalCivility : this.professionalCivility + '.' + this.firstName + ' ' + this.lastName;
    }
    getArabicFullName(inverted?: boolean): string {
        return inverted ? this.arabicLastName + ' ' + this.arabicFirstName + '.' + this.arabicProfessionalCivility : this.arabicProfessionalCivility + '.' + this.arabicFirstName + ' ' + this.arabicLastName;
    }

    constructor(other?: Partial<IDoctor>) {
        super(other as User);
        this.arabicFirstName = other?.arabicFirstName || "";
        this.arabicLastName = other?.arabicLastName || "";
        this.professionalCivility = other?.professionalCivility || '';
        this.arabicProfessionalCivility = other?.arabicProfessionalCivility || '';
        this.speciality = other?.speciality || new Speciality();
        this.subSpecialities = other?.subSpecialities || [];
        this.fixPhone = other?.fixPhone || '';
        this.biography = other?.biography || '';
        this.timeSlotLocalSetting = other?.timeSlotLocalSetting || 0;
        this.consultationBiology = other?.consultationBiology || false;
        this.consultationActs = other?.consultationActs || false;
        this.consultationLaser = other?.consultationLaser || false;
        this.consultationRadiology = other?.consultationRadiology || false;
        this.consultationRefraction = other?.consultationRefraction || false;
        this.consultationVaccinations = other?.consultationVaccinations || false;
        this.consultationLens = other?.consultationLens || false;
        this.prescriptionActs = other?.prescriptionActs || false;
        this.prescriptionBiology = other?.prescriptionBiology || false;
        this.prescriptionLaser = other?.prescriptionLaser || false;
        this.prescriptionRadiology = other?.prescriptionRadiology || false;
        this.prescriptionRefraction = other?.prescriptionRefraction || false;
        this.prescriptionLens = other?.prescriptionLens || false;

        this.hasCalender = other?.hasCalender || false;
        this.hasCalenderUpToDate = other?.hasCalenderUpToDate || false;
        this.arabicAddress = other?.arabicAddress || "";
        this.cnam = other?.cnam || '';


        this.printSpeciality = other?.printSpeciality || "";
        this.printArabicSpeciality = other?.printArabicSpeciality || "";
        this.printAddress = other?.printAddress || "";
        this.printArabicAddress = other?.printArabicAddress || "";
        this.printFullName = other?.printFullName || "";
        this.printArabicFullName = other?.printArabicFullName || "";

        this.dashboardPrintPayments = other?.dashboardPrintPayments || false;

        this.patientRefSetting = other?.patientRefSetting || false;
        this.regularConsultationPriceSetting = other?.regularConsultationPriceSetting || 0;
        this.secretaryPaymentsSetting = other?.secretaryPaymentsSetting || false;
        this.secretaryAPSetting = other?.secretaryAPSetting || false;

        this.consultationCNAMAP1 = other?.consultationCNAMAP1 || false;
        this.consultationCNAMAP2 = other?.consultationCNAMAP2 || false;
        this.consultationCNAMAP3 = other?.consultationCNAMAP3 || false;
        this.consultationCNAMAP4 = other?.consultationCNAMAP4 || false;

        this.role = UserRole.DOCTOR;
        this.appointmentTiming = other?.appointmentTiming || AppointmentTiming.CALANDER;

        this.pediatricVaccinations = other?.pediatricVaccinations || false;

        this.smsNotification = other?.smsNotification || false;
        this.smsPhone = other?.smsPhone || '';

        this.teleconsultationWaitTime = other?.teleconsultationWaitTime || 0;
        this.qualifications = other?.qualifications || [];
        this.spokenLanguages = other?.spokenLanguages || []
    }


    public static fromResponse(res: any): Doctor {
        return new Doctor({
            fixPhone: res.fixPhone,
            arabicLastName: res.arabicLastName,
            arabicFirstName: res.arabicFirstName,
            arabicAddress: res.arabicAddress,
            professionalCivility: res.professionalCivility,
            arabicProfessionalCivility: res.arabicProfessionalCivility,
            biography: res.biography,
            consultationActs: res.consultationActs,
            consultationBiology: res.consultationBiology,
            consultationLaser: res.consultationLaser,
            consultationVaccinations: res.consultationVaccinations,
            consultationLens: res.consultationLens,
            consultationRadiology: res.consultationRadiology,
            consultationRefraction: res.consultationRefraction,
            prescriptionActs: res.prescriptionActs,
            prescriptionBiology: res.prescriptionBiology,
            prescriptionLaser: res.prescriptionLaser,
            prescriptionLens: res.prescriptionLens,
            prescriptionRadiology: res.prescriptionRadiology,
            prescriptionRefraction: res.prescriptionRefraction,

            speciality: Speciality.fromResponse(res.speciality || res.specialite),
            subSpecialities: Array.from(res.subSpecialities || [], elm => DoctorSubSpeciality.fromResponse(elm)),
            hasCalender: res.hasCalender,
            hasCalenderUpToDate: res.hasCalenderUpToDate,
            cnam: res.cnam,
            timeSlotLocalSetting: res.timeSlotLocalSetting,
            printSpeciality: res.printSpeciality,
            printArabicSpeciality: res.printArabicSpeciality,
            printAddress: res.printAddress,
            printArabicAddress: res.printArabicAddress,
            printFullName: res.printFullName,
            printArabicFullName: res.printArabicFullName,
            dashboardPrintPayments: res.dashboardPrintPayments,
            patientRefSetting: res.patientRefSetting,
            regularConsultationPriceSetting: res.regularConsultationPriceSetting,
            secretaryPaymentsSetting: res.secretaryPaymentsSetting,
            secretaryAPSetting: res.secretaryAPSetting,

            consultationCNAMAP1: res.consultationCNAMAP1,
            consultationCNAMAP2: res.consultationCNAMAP2,
            consultationCNAMAP3: res.consultationCNAMAP3,
            consultationCNAMAP4: res.consultationCNAMAP4,
            appointmentTiming: res.appointmentTiming,
            pediatricVaccinations: res.pediatricVaccinations,
            smsNotification: res.smsNotification,
            smsPhone: res.smsPhone,
            teleconsultationWaitTime: res.teleconsultationWaitTime,
            qualifications: res.qualifications,
            spokenLanguages: res.spokenLanguages,
            ...User.fromResponse(res)
        })
    }
}