import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface PayMeRequestPayload {
  amount: number,
  note?: string,
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  return_url?: string,
  cancel_url?: string,
  webhook_url: string,
  order_id?: string
}
export interface PayMeResponsePayload {
  token: string,
  order_id: string,
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  note: string,
  amount: number,
  payment_url: string
}
@Injectable({
  providedIn: 'root'
})
export class PaymeService {

  constructor(private http: HttpClient) { }

  initTransaction(request: PayMeRequestPayload): Observable<PayMeResponsePayload> {
    return this.http.post(environment.PAYME_API_URL, request, { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${environment.PAYME_API_KEY}` } })
      .pipe(tap((res: any) => {
        if (!res.status) throw { kind: res.kind, message: res.message, code: res.code, data: res.data };
      }), map((res: any) => res.data));
  }
}
