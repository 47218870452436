import {
  MILLISECONDS_IN_DAY,
  getDayDate,
} from "src/app/utils/date.util";
import { EDalyTimeSlotLocation } from "../daly-time-slot.entity/daly-time-slot";
import { DoctorAvailability } from "../doctor-disponiblity.entity/doctor-availability";

export enum EAppointmentState {
  PENDING,
  ACCEPTED,
  REJECTED,
  DONE,
}

export enum EAppointmentHistoricalState {
  EXPIRED,
  ACTUAL,
  SNOOZED,
}

export interface IAppointement {
  id: string;
  date: Date;
  startTime: number;
  duration: number;
  location: EDalyTimeSlotLocation;
  status: EAppointmentState;
  motif: string;
  isEmergency: boolean;
  hasPrescription: boolean;
  hasConsultation: boolean;
  regularAmount: number;
  paidAmount: number;
  requested: boolean;
  confirmed: boolean;
  patientFirstName: string;
  patientLastName: string;
  patientMobilePhone: string;
  patientBirthDate: Date;
  requestDuration: number
  isPaid: boolean;
}

export class Appointment implements IAppointement {
  id: string;
  date: Date;
  startTime: number;
  duration: number;
  location: EDalyTimeSlotLocation;
  status: EAppointmentState;
  motif: string;
  isEmergency: boolean;
  hasPrescription: boolean;
  hasConsultation: boolean;
  historicalStatus: EAppointmentHistoricalState;
  regularAmount: number;
  paidAmount: number;
  requested: boolean;
  confirmed: boolean;
  patientFirstName: string;
  patientLastName: string;
  patientMobilePhone: string;
  patientBirthDate: Date;
  requestDuration: number;
  isPaid: boolean;
  language: string;

  patientEmail: any;
  patientCountry: any;

  get startDateTime(): Date {
    return new Date(getDayDate(this.date).getTime() + this.startTime);
  }

  get doctorAvailability(): DoctorAvailability {
    return new DoctorAvailability({
      location: this.location,
      startTime: this.startTime,
      date: this.date,
      duration: this.duration,
    })
  }
  constructor(other?: Partial<Appointment>) {
    this.id = other?.id || "";
    this.date = other?.date || new Date(0);
    this.startTime = other?.startTime || 0;
    this.duration = other?.duration || 0;
    this.location = other?.location || EDalyTimeSlotLocation.ATSITE;
    this.motif = other?.motif || "";
    this.status = other?.status || EAppointmentState.PENDING;
    this.isEmergency = other?.isEmergency || false;
    this.hasConsultation = other?.hasConsultation || false;
    this.hasPrescription = other?.hasPrescription || false;
    this.historicalStatus =
      other?.historicalStatus || EAppointmentHistoricalState.EXPIRED;
    this.regularAmount = other?.regularAmount || 0;
    this.paidAmount = other?.paidAmount || 0;
    this.requested = other?.requested || false;
    this.confirmed = other?.confirmed || false;
    this.patientBirthDate = other?.patientBirthDate || new Date();
    this.patientLastName = other?.patientLastName || '';
    this.patientFirstName = other?.patientFirstName || '';
    this.patientMobilePhone = other?.patientMobilePhone || "";
    this.requestDuration = other?.requestDuration || 0;
    this.isPaid = other?.isPaid || false;
    this.language = other?.language || 'fr';
    this.patientEmail = other?.patientEmail || '';
    this.patientCountry = other?.patientCountry || '';
  }


  get isOld(): boolean {
    return (
      this.date.getTime() + MILLISECONDS_IN_DAY - 1 <=
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ).getTime()
    );
  }

  public static fromResponse(res: any): Appointment {
    return new Appointment({
      id: res.id,
      startTime: res.startTime,
      duration: res.duration,
      location: res.location,
      status: res.status,
      motif: res.motif,
      date: new Date(res.date),
      isEmergency: res.isEmergency,
      hasConsultation: res.hasConsultation,
      hasPrescription: res.hasPrescription,
      historicalStatus: res.historicalStatus,
      regularAmount: res.regularAmount,
      paidAmount: res.paidAmount,
      requested: res.requested,
      confirmed: res.confirmed,
      isPaid: res.paid,
      patientBirthDate: new Date(res.patientBirthDate),
      patientFirstName: res.patientFirstName,
      patientLastName: res.patientLastName,
      patientMobilePhone: res.patientMobilePhone,
      patientEmail: res.patientEmail,
      patientCountry: res.patientCountry,
      requestDuration: res.requestDuration,
      language: res.language
    });
  }
}
