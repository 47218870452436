import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PageRequest, PaginationPage } from 'src/app/common/modules/data-table.module/interfaces';
import { ListFilter } from 'src/app/common/pipes/list-filter.pipe/list-filter.pipe';
import { DoctorPrescription } from 'src/app/entities/prescription.entity/doctor-prescription.entity/doctor-prescription';
import { DoctorPrescriptionService } from 'src/app/services/prescription.service/prescription.service';

@Component({
  selector: 'app-doctor-prescriptions-table',
  templateUrl: './doctor-prescriptions-table.component.html',
  styleUrls: ['./doctor-prescriptions-table.component.css']
})
export class DoctorPrescriptionsTableComponent implements OnInit {
  @Input() currentPage: number = 0;
  @Input() perPage: number = 10;
  @Input() pageRequest!: (pageRequest: PageRequest) => Promise<PaginationPage<DoctorPrescription>>;
  @Input() filters: Array<ListFilter> = new Array();
  @Input() emptyMessage?: string;
  doctorPrescriptions: Array<DoctorPrescription> = new Array();


  constructor(private doctorPrescriptionService: DoctorPrescriptionService, private toasterService: ToastrService) { }
  ngOnInit(): void {
  }
  deletePrescription(index: number): void {
    if (confirm('Are you sure you will delete prescription'))
      this.doctorPrescriptionService
        .deleteOne(this.doctorPrescriptions[index].id)
        .subscribe(
          (res) => {
            this.toasterService.success('Prescription has deleted');
            this.doctorPrescriptions.splice(index, 1);
          },
          (error) => this.toasterService.error('Enable to delete prescription')
        );
  }

}
