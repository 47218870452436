import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ConsultationOphtalmoParametreSanteItem, DoctorParametereSante } from "src/app/entities/consultation.entity/consultation-ophtalmo.entity/consultation-ophtalmo";
import { ParametereSante } from "src/app/entities/parametre-sante.entity/paramtere-sante";
import { Doctor } from "src/app/entities/user.entity/doctor.entity";
import { AuthService } from "src/app/services/auth.service/auth.service";
import { DoctorParametersSanteService } from "src/app/services/doctor-parametres-sante.service/doctor-parameters-sante.service";

export interface IParametreSanteFormControler {
  id: string;
  target: ParametereSante;
  value: any;
  value_OG: any;
  value_OD: any
}
export class ParametreSanteFormControler implements IParametreSanteFormControler {
  id: string;
  target: ParametereSante;
  value: any;
  value_OG: any;
  value_OD: any
  constructor(other?: Partial<IParametreSanteFormControler>) {
    this.id = other?.id || "";
    this.target = other?.target || new ParametereSante({});
    this.value = [
      Number(isNaN(other?.value) ? 0 : other?.value),
      String(other?.value || ""),
      String(other?.value || ""),
      Boolean(other?.value || false),
    ][this.target.type];

    this.value_OD = [
      Number(isNaN(other?.value_OD) ? 0 : other?.value_OD),
      String(other?.value_OD || ""),
      String(other?.value_OD || ""),
      Boolean(other?.value_OD || false),
    ][this.target.type];

    this.value_OG = [
      Number(isNaN(other?.value_OG) ? 0 : other?.value_OG),
      String(other?.value_OG || ""),
      String(other?.value_OG || ""),
      Boolean(other?.value_OG || false),
    ][this.target.type];
  }
}

export interface IParametreSanteFormGroupControler {
  label: string;
  controlers: Array<ParametreSanteFormControler>;
}
export class ParametreSanteFormGroupControler implements IParametreSanteFormGroupControler {
  label: string;
  controlers: Array<ParametreSanteFormControler>;
  constructor(other?: Partial<IParametreSanteFormGroupControler>) {
    this.label = other?.label || "";
    this.controlers = other?.controlers || [];
  }
}

export interface IParametreSanteForm {
  controlers: Array<ParametreSanteFormGroupControler>;
}
export class ParametreSanteForm implements IParametreSanteForm {
  controlers: Array<ParametreSanteFormGroupControler>;

  constructor(other?: Partial<IParametreSanteForm>) {
    this.controlers = other?.controlers || new Array();
  }

  get value(): Array<ConsultationOphtalmoParametreSanteItem> {
    let value: Array<ConsultationOphtalmoParametreSanteItem> = new Array();

    this.controlers.forEach(
      (group) =>
      (value = value.concat(
        Array.from(
          group.controlers,
          (controler) =>
            new ConsultationOphtalmoParametreSanteItem({
              id: controler.id,
              parametre: controler.target,
              value: controler.value,
              value_OD: controler.value_OD,
              value_OG: controler.value_OG
            })
        )
      ))
    );

    return value;
  }

  set value(value: Array<ConsultationOphtalmoParametreSanteItem>) {

    this.controlers = this.controlers.map((group) => {
      group.controlers = group.controlers.map((controler) => {
        let controlerValue = value.find((item) => item.parametre.id == controler.target.id);
        return controlerValue ? new ParametreSanteFormControler({
          id: controlerValue.id,
          value: controlerValue!.value,
          value_OD: controlerValue.value_OD,
          value_OG: controlerValue.value_OG,
          target: controlerValue.parametre
        }
        ) : controler;
      });

      return group;
    }).sort((a: any, b: any) => a.order - b.order);

  }

  public static fromListParametreSante(
    patametresSante: Array<DoctorParametereSante>
  ): ParametreSanteForm {
    let parametreSanteForm: ParametreSanteForm = new ParametreSanteForm();
    let groupNames: Array<string> = patametresSante
      .map((item: DoctorParametereSante) => item.target.group)
      .filter((item, index, self) => self.indexOf(item) === index);

    groupNames.forEach((groupName) => {
      let items: Array<ParametreSanteFormControler> = new Array();

      patametresSante.forEach((parametereSante) => {
        if (groupName.toUpperCase() == parametereSante.target.group.toUpperCase())
          items.push(new ParametreSanteFormControler({ target: parametereSante.target }));
      });

      parametreSanteForm.controlers.push(
        new ParametreSanteFormGroupControler({
          label: groupName,
          controlers: items,
        })
      );
    });
    return parametreSanteForm;
  }
}
@Component({
  selector: 'app-parametre-sante-ophtalmo',
  templateUrl: './parametre-sante-consultation-ophtalmo-form.component.html',
  styleUrls: ['./parametre-sante-consultation-ophtalmo-form.component.css']
})
export class ParametreSanteOphtalmoComponent implements OnInit, OnChanges {
  parametreSanteForm: ParametreSanteForm = new ParametreSanteForm();
  get doctor(): Doctor {
    return this.authService.getCurrentUser() as Doctor;
  }
  @Input() parameteresSante: Array<ConsultationOphtalmoParametreSanteItem> = new Array();
  @Input() consultationParametreSanteItems!: Array<ConsultationOphtalmoParametreSanteItem>;
  @Output() consultationParametreSanteItemsChange: EventEmitter<Array<ConsultationOphtalmoParametreSanteItem>> = new EventEmitter();

  constructor(
    private doctorParametersSanteService: DoctorParametersSanteService,
    private authService: AuthService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.consultationParametreSanteItems.length)
      this.parametreSanteForm.value = this.consultationParametreSanteItems;
  }
  ngOnInit(): void {
    this.doctorParametersSanteService
      .getActivatedParametresSante()
      .subscribe(
        (res) => {
          this.parametreSanteForm = ParametreSanteForm.fromListParametreSante(Array.from(res, (elm: any) => DoctorParametereSante.fromResponse(elm)).sort((a, b) => a.order - b.order));
          if (this.consultationParametreSanteItems.length)
            this.parametreSanteForm.value = this.consultationParametreSanteItems;
        },
        (error) => alert("Enable to load parametres de sante !")
      );
  }

  _onChange(): void {
    this.consultationParametreSanteItems = [];
    this.parametreSanteForm.controlers.forEach((group) => (this.consultationParametreSanteItems = this.consultationParametreSanteItems.concat(
      Array.from(
        group.controlers,
        (controler) =>
          new ConsultationOphtalmoParametreSanteItem({
            value: controler?.value,
            value_OD: controler?.value_OD,
            value_OG: controler?.value_OG,
            parametre: controler.target,
            id: controler.id,
          })
      )
    ))
    );

    this.consultationParametreSanteItemsChange.emit(
      this.consultationParametreSanteItems
    );
  }
}