
export interface DataViewHeadOptions {
    label: string,
    index?: number,
    sortbal?: boolean,
    data?: any
}

export interface DataViewHead {
    label: string,
    index?: string | number,
    sortOrder?: "asc" | "desc",
    data?: any
}

export interface Action {
    label: string,
    callback: (items: Array<Row>) => void
}

export interface Sort {
    sorter: DataViewHeadOptions,
    order?: 'asc' | 'desc'
}



export interface Row {
    selected: boolean,
    data: any,
    cells: Array<Cell>
}

export interface Cell {
    header: DataViewHeadOptions,
    data: any
}

export interface Headers {
    items: Array<DataViewHeadOptions>,
    sort?: Sort,
    path?: Array<string>
    cellResolver?: (cell: { header: DataViewHeadOptions, row: Row }) => any
}

export interface PaginationOptions {
    perPage: number,
    maxPages: number,
    step: number,
    currentPage: number,
    pages?: Array<Array<Row>>
}

export interface DataSet {
    headers: Headers,
    rows: Array<Row>
}

export interface DataTableChanges {
    selections: Array<any>,
    view: Array<any>,
    dataset: Array<any>,
    source: Array<any>,
    pagination: PaginationOptions
}

/********  */


export class PaginationPage<T> {
    hasNext: boolean;
    hasPrevious: boolean;
    totalPages: number;
    currentPage: number;
    items: Array<T>;
    constructor(other?: Partial<PaginationPage<T>>) {
        this.hasNext = other?.hasNext || false;
        this.hasPrevious = other?.hasPrevious || false;
        this.totalPages = other?.totalPages || 0;
        this.currentPage = other?.currentPage || 0;
        this.items = other?.items || [];
    }

    public static fromResponse<T>(responsePage: any, transformer?: (e: any) => T): PaginationPage<T> {
        return new PaginationPage({
            hasNext: responsePage.last,
            hasPrevious: responsePage.first,
            items: transformer ? Array.from(responsePage.content, transformer) : responsePage.content,
            currentPage: responsePage.number,
            totalPages: responsePage.totalPages
        })
    }
}

export interface PageRequest {
    pageOffset: number;
    pageSize: number;
    sort?: {
        key: string;
        order?: string
    }
}

export type PageRequestFn<T> = (pageRequest: PageRequest) => Promise<PaginationPage<T>>;



export declare type SortOrder = 'asc' | 'desc' | 'none';
export interface HeaderOptions { sortbal: boolean, label: string, sortKey: string, index: string }