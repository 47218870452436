import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperProgressBarComponent } from './stepper-progress-bar.component/stepper-progress-bar.component';



@NgModule({
  declarations: [
    StepperProgressBarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StepperProgressBarComponent
  ]
})
export class StepperModule { }
