import { Component, Input, OnInit } from '@angular/core';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';

@Component({
  selector: 'app-appointment-success-booking',
  templateUrl: './appointment-success-booking.component.html',
  styleUrls: ['./appointment-success-booking.component.css']
})
export class AppointmentSuccessBookingComponent implements OnInit {
  @Input() patientAppointment!: PatientAppointment;
  @Input() doctor!: Doctor;
  @Input() dir:string='fr'
  constructor() { }

  ngOnInit(): void {
  }

}
