<div class="content success-page-cont" [dir]="dir=='ar'?'rtl':'lrt'">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="success-cont">
                    <img src="assets/img/icons/check-circle.png" alt="logo">
                    <h3>{{dir=='ar'?'تم حجز الموعد بنجاح':'RDV réservé avec succés'}}</h3>
                    <p>
                        {{dir=='fr'?'RDV réservé avec ':'تم حجز موعد مع '}}
                        <span class="text-info">
                            <strong>{{patientAppointment.doctor.getFullName()}}</strong></span>
                        <br>
                        {{dir=='fr'?'le':'في'}}
                        <span>{{ patientAppointment.date | date:'dd/MM/yyyy'}}<strong class="text-info apt-time">{{patientAppointment.startTime |time}}</strong></span>
                    </p>
                    <p *ngIf="patientAppointment.status == 0"> {{dir=='fr'?'Votre rdv est en attente de confirmation par le médecin' :'موعدك في انتضار القبول من الطبيب'}} </p>
                    <p *ngIf="patientAppointment.status == 1 && patientAppointment.location== 1 && patientAppointment.isPaid"> <a
                            [routerLink]="['/','apps','meetings',patientAppointment.id]">
                            {{dir=='fr'?'Commencez votre consultation video' :'ابدأ حصتك عن بعد الان'}}
                        </a> </p>
                </div>
            </div>
        </div>
    </div>
</div>