<div class="input-control" style="height: 40px;"  [ngClass]="{'invalid':!isValid}">
    <div [innerHTML]="prependHtml" *ngIf="prependHtml">
    </div>
    <span *ngIf="prepend" (click)="onPrependClick($event)">
        <!-- fa fa-search -->
        <i [ngClass]="prepend"></i>
    </span>
    <input [type]="_type" [placeholder]="placeholder" (input)="onInputChange($event)" (keydown)="onKeyDown($event)" [disabled]="disabled" [value]="value" [style.background]="background">

    <span *ngIf="append" (click)="onAppendClick($event)">
        <!-- fa fa-search -->
        <i [ngClass]="append"></i>
    </span>

    <div [innerHTML]="appendHtml" *ngIf="appendHtml">
    </div>
</div>