import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NotesFillings } from "src/app/entities/notes.entity/notes.fillings";
import { IStyleInput } from "../consultation-forms.module/liste-question-sahred.component/liste-question-sahred.component";
import { TextFillingsService } from "src/app/services/fillings.service/text-fillings.service";

@Component({
  selector: "app-liste-notes-shared",
  templateUrl: "./liste-notes-shared.component.html",
  styleUrls: ["./liste-notes-shared.component.css"],
})
export class ListeNotesSharedComponent implements OnInit {
  @Input() Notes: Array<NotesFillings> = new Array();
  notesFilter: Array<NotesFillings> = new Array();
  @Output() NotesChange: EventEmitter<
    Array<NotesFillings>
  > = new EventEmitter();
  @Input() filter: String = "";
  @Input() text: string = "";
  @Input() value: string = "";
  @Input() labelText: any = "";
  @Input() labelfilter: string = "";
  @Output() labelfilterChange: EventEmitter<string> = new EventEmitter();
  @Input() options: Partial<IStyleInput> = {
    right: "0",
    top: "0",
  };
  @Output() valueChange: EventEmitter<string> = new EventEmitter();
  @Output() textChange: EventEmitter<string> = new EventEmitter();
  @Input() open: boolean = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Input() doctorName: string = "";
  @Input() PatientName: string = "";
  @Input() paidAmount: number = 0;
  @Input() repos: string = "";
  @Input() header: string = "";
  @Input() date: any;
  @Input() Age: number = 0;

  constructor(private textFillingsService: TextFillingsService) {}

  ngOnInit(): void {
    this.textFillingsService.getAllTextFillingsDoctor().subscribe((res) => {
      this.Notes = res;
      this.NotesChange.emit(this.Notes);
      this.notesFilter = this.Notes.filter((elm) => elm.label == this.filter);
    });
  }
  toggleDropdown(): void {
    this.open = !this.open;
  }
  label(event: any) {
    let note = event.target.value;

    var indexSoussigne = false;
    var indexCertifie = false;
    var prix = false;
    var repos = false;
    var age = false;
    var date = false;

    if (note.includes("[nom docteur]")) {
      indexSoussigne = true;
      note = note.replace("[nom docteur]", this.doctorName + " ");
    }

    if (note.includes("[nom patient]")) {
      indexCertifie = true;
      note = note.replace("[nom patient]", this.PatientName + " ");
    }

    if (note.includes("[montant]")) {
      prix = true;
      note = note.replace("[montant]", String(this.paidAmount) + " ");
    }

    if (note.includes("[date]")) {
      date = true;
      note = note.replace("[date]", this.date + " ");
    }
    if (note.includes("[repos]")) {
      repos = true;
      note = note.replace("[repos]", this.repos + " ");
      this.header = "Attestation";
    }
    if (note.includes("[age]")) {
      age = true;
      note = note.replace("[age]", this.Age + " ");
      this.header = "Attestation";
    }

    var modifiedContent = note;

    if (indexSoussigne || indexCertifie || prix || repos || date) {
      note = modifiedContent;
    }

    let labels!: string;
    for (let i = 0; i < this.notesFilter.length; i++) {
      this.labelText = this.notesFilter[i].fillings;
      for (let j = 0; j < this.labelText.length; j++) {
        if (this.labelText[j].textContent === event.target.value) {
          this.labelfilter = labels = this.labelText[j].label;
          this.labelfilterChange.emit(this.labelfilter);

          break;
        } else {
          j++;
        }
      }
    }

    this.textChange.emit(note);

    this.valueChange.emit(
      this.value.length == 0
        ? this.value.concat(note)
        : (this.value = note)
    );

    this.open = false;
    this.openChange.emit(this.open);
  }
}
