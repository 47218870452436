import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PageRequest, PaginationPage } from 'src/app/common/modules/data-table.module';
import { CabinetOrganisation } from 'src/app/entities/cabinet-organisation.entity/cabinet-organisation';
import { DocumentAttachment } from 'src/app/entities/document-attachment.entity/document-attachment';
import { DoctorDocumentsService } from 'src/app/services/doctor-documents.service/doctor-documents.service';

@Component({
  selector: 'app-liste-doctor',
  templateUrl: './liste-doctor.component.html',
  styleUrls: ['./liste-doctor.component.css']
})
export class ListeDoctorComponent implements OnInit {
  @Input() currentPage: number = 0;
  @Input() perPage: number = 10;
  @Input() pageRequest!: (pageRequest: PageRequest) => Promise<PaginationPage<CabinetOrganisation>>;
  @Output() onFinish: EventEmitter<void> = new EventEmitter();
  doctors: Array<CabinetOrganisation> = new Array();
  @Input() documentAttachment!: DocumentAttachment;


  constructor(private doctorDocumentsService: DoctorDocumentsService, private toastrService: ToastrService) { }


  ngOnInit(): void {
  }

  onShareRequested(event): void {
    this.doctorDocumentsService.share(this.documentAttachment.id, event).subscribe(res => {
      this.toastrService.success('Succés to partager ce document')

      this.onFinish.emit()
    }, error => {
      this.toastrService.error('Enable to partager ce document')
    })
  }
}
