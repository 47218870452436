import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionDirective } from './accordion.directive';
import { AccordionTabComponent } from './accordion-tab.component/accordion-tab.component';



@NgModule({
  declarations: [
    AccordionDirective,
    AccordionTabComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [AccordionTabComponent, AccordionDirective]
})
export class AccordionModule { }
