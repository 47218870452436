import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { Vaccin } from 'src/app/entities/vaccin.entity/vaccin';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DoctorVaccinationsService extends ApiService {

  owned(pageRequest: PageRequest) {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/vaccinations/owned`, { params: params }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/vaccinations/owned/all`).pipe(this.responsePipe)
  }
  ownedSearch(searchQuery: { query: string; }, pageRequest: PageRequest) {
    let params = new HttpParams();
    if (searchQuery.query.length != 0) params = params.set('q', searchQuery.query);
    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/vaccinations/owned/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }



  add(data: Vaccin): Observable<Vaccin> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/vaccinations`, {
      nom_Vaccin: data.nom_Vaccin,
      nom_commercial_Vaccin: data.nom_commercial_Vaccin,
      nom_Labo_Vaccin: data.nom_Labo_Vaccin,
      note_Vaccin: data.note_Vaccin
    }, { responseType: 'json' }).pipe(this.responsePipe)
  }
  all(pageRequest?: PageRequest): Observable<any> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/vaccinations`, { params: params }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/vaccinations/all`).pipe(this.responsePipe)
  }
  search(searchQuery: { query: string; }, pageRequest?: PageRequest) {
    let params = new HttpParams();
    if (searchQuery.query.length != 0) params = params.set('q', searchQuery.query);
    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/vaccinations/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }

  one(VaccinId: string): Observable<Vaccin> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/vaccinations/${VaccinId}`, { responseType: 'json' }).pipe(this.responsePipe)
  }
  delete(VaccinId: string): Observable<Vaccin> {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/vaccinations/${VaccinId}`, { responseType: 'json' }).pipe(this.responsePipe)
  }


  update(VaccinId: string, data: Vaccin): Observable<Vaccin> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/vaccinations/${VaccinId}`, {
      nom_Vaccin: data.nom_Vaccin,
      nom_commercial_Vaccin: data.nom_commercial_Vaccin,
      nom_Labo_Vaccin: data.nom_Labo_Vaccin,
      note_Vaccin: data.note_Vaccin
    }, { responseType: 'json' }).pipe(this.responsePipe)

  }
}
