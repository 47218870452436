<div class="card card-table">
    <div style="padding: 12px;">
        <h2>Pédiatrie: Tableau de vaccination </h2>
    </div>
    <table class="table table-hover table-center">
        <thead>
            <tr>
                <th>Age ( mois )</th>
                <th>Label</th>
                <th style="min-width: 200px;">Vaccin</th>
                <th style="min-width: 140px;">Date</th>
                <th>Numéro de Lot</th>
                <th style="min-width: 80px;">Fait</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let consultationPediatricVaccinationItem of patientPediatricVaccinationItems;let i = index" style="height: 48px;font-size: 22px;border: 2px solid green;">
                <td *ngIf="consultationPediatricVaccinationItem.setting.minOld>0;else atBirthDate">
                    {{ getOldFormat(consultationPediatricVaccinationItem.setting).years}} années
                    {{ getOldFormat(consultationPediatricVaccinationItem.setting).months }} mois
                </td>
                <ng-template #atBirthDate>
                    <td>A la naissance</td>
                </ng-template>
                <td>
                    {{consultationPediatricVaccinationItem.setting.label}}
                </td>
                <td style="width:41%">
                    {{consultationPediatricVaccinationItem.vaccination?.nom_commercial_Vaccin}}
                </td>
                <td>
                    {{consultationPediatricVaccinationItem.date | date:'dd/MM/YYYY'}}
                </td>
                <td>
                    {{consultationPediatricVaccinationItem.lotNumber}}
                </td>
                <td>&#x2705;</td>
            </tr>
            <tr *ngFor="let consultationPediatricVaccinationItem of consultationPediatricVaccinationFormItems;let i = index"
                [ngStyle]="{'background-color':['#ddede0','#fbdbdd'][ getItemColorIndex(consultationPediatricVaccinationItem)]}">
                <td *ngIf="consultationPediatricVaccinationItem.setting.minOld>0;else atBirthDate">
                    {{ getOldFormat(consultationPediatricVaccinationItem.setting).years}} années
                    {{ getOldFormat(consultationPediatricVaccinationItem.setting).months }} mois
                </td>
                <ng-template #atBirthDate>
                    <td>A la naissance</td>
                </ng-template>
                <td>
                    {{consultationPediatricVaccinationItem.setting.label}}
                </td>
                <td style="width:41%">
                    <app-vaccinaion-drop-down-select [(value)]="consultationPediatricVaccinationItem.vaccination" [placeHolder]="'Vaccin is here'"
                        (valueChange)="_change()"></app-vaccinaion-drop-down-select>
                    <div *ngIf="validateConsultationItem(consultationPediatricVaccinationItem).controlsValidation['vaccin'] == 'required'  && submitted">
                        <span style="color: red;">Vaccin est obligatoire </span>
                    </div>
                </td>
                <td>
                    <date-picker [(ngModel)]="consultationPediatricVaccinationItem.date"></date-picker>
                </td>
                <td>
                    <textarea style="height: 45px;" [(ngModel)]="consultationPediatricVaccinationItem.lotNumber" (ngModelChange)="_change()" class="form-control"></textarea>
                </td>
                <td>
                    <input type="checkbox" style="width: 26px;height: 26px;cursor: pointer;" name="done" [(ngModel)]="consultationPediatricVaccinationItem.vaccinated"
                        (ngModelChange)="_change()">
                </td>
            </tr>
        </tbody>
    </table>
</div>