
<button class="btn button-more"(click)="open=!open">
  <i class="feather-more-horizontal"></i>
</button>


<div class="body"  [ngStyle]="{'position': 'relative',width:'100%',left:'315px',top:'465px'}" *ngIf="open==true">
  <ng-container *ngIf="notesFilter.length; else empty">
     
      <select class="select" name="notes" multiple (change)="label($event)" id="notes-select">{{labelText}}
        <option *ngFor="let fillings of notesFilter" [value]="fillings.fillings[0]?.textContent" >
          {{ fillings.fillings[0]?.label }}
        </option>
      </select>
  </ng-container>
  <ng-template #empty>
      <select class="select-vide">
          <option [value]="">Ajouter nouvelle note - {{ filter }}</option>
      </select>
  </ng-template>
</div>