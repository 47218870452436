import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'progress-splash-screen',
  templateUrl: './progress-splash-screen.component.html',
  styleUrls: ['./progress-splash-screen.component.css']
})
export class ProgressSplashScreenComponent implements OnInit {
  @Input() id!: string;
  isActive: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.isActive = !this.isActive;
  }

}
