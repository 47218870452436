import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownMenuComponent } from "./drop-down-menu.component/drop-down-menu.component"
import { DropDownModalComponent } from "./drop-down-modal.component/drop-down-modal.component"



@NgModule({
    declarations: [
        DropDownMenuComponent,
        DropDownModalComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        DropDownMenuComponent,
        DropDownModalComponent
    ]
})
export class DropDownMenuModule { }
