<ng-container *ngIf="isValid;else invalid">
    <ng-container *ngIf="old <= 36">
        <h3>Taille</h3>
        <div class="chart">
            <img [src]="gender == 0?'assets/img/pediatric/pediatric-height-chart-boy.PNG':'assets/img/pediatric/pediatric-height-chart-girl.PNG'">
            <span class="pointer" [ngStyle]="{'left': (old * XSTEP) + '%','top':(93.33333333333333 - ((height - 40>0?(height-40)/5:0) * 6.666666666666667)) +'%','color':'green'}">●
                (Taille)</span>
        </div>
        <h3>Poids</h3>
        <div class="chart">
            <img [src]="gender == 0?'assets/img/pediatric/pediatric-weight-chart-boy.PNG':'assets/img/pediatric/pediatric-weight-chart-girl.PNG'">
            <span class="pointer" [ngStyle]="{'left': (old * XSTEP) + '%','top':95.65217391304348 - (weight * 4.3478260869565215) +'%','color':'green'}">● (Poids)</span>
        </div>
    </ng-container>
    <ng-container *ngIf="old > 36">
        <div class="chart">
            <img [src]="gender == 0?'assets/img/pediatric/pediatric-growth-chart-boy-18.PNG':'assets/img/pediatric/pediatric-growth-chart-girl-18.PNG'">
            <span class="pointer" [ngStyle]="{'left': ((old / 12) * 5.2631578947368425) + '%','top':__calculateHeightY() +'%','color':'green'}">● (Taille)</span>
            <span class="pointer" [ngStyle]="{'left': ((old / 12) * 5.2631578947368425) + '%','top':__calculateWeightY() +'%','color':'magenta'}">● (Poids)</span>
        </div>
    </ng-container>
</ng-container>

<ng-template #invalid>
    <p *ngIf="!isFitOld">Age est supperieur a 18 ans </p>
    <p *ngIf="!(this.height > 0 && this.weight > 0)">Taille ou poids invalid ! </p>
</ng-template>