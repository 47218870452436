import { Component, ContentChild, DoCheck, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ISelectDropDownOptions {
  filter?: {
    camelCase?: boolean,
    byKey?: string,
    firstCaracters?: number,
    with?: 'containe' | 'start' | 'end' | 'start-or-end' | 'start-and-end'
  },
}

@Component({
  selector: 'select-drop-down',
  templateUrl: './select-drop-down.component.html',
  styleUrls: ['./select-drop-down.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => SelectDropDownComponent)
  }]
})
export class SelectDropDownComponent implements OnInit, ControlValueAccessor, DoCheck {
  @Input() disabled: boolean = false;
  @ContentChild(TemplateRef) templateVariable!: TemplateRef<any>;
  @Input() options: ISelectDropDownOptions = { filter: { firstCaracters: 1, camelCase: false } };
  @Input() placeHolder: string = "";
  @Input() set value(value: any) {
    if (!!value) {
      this.toggeledModal = false;
    }
    this._value = value;
  }
  @Input() required: boolean = false;
  @Output() filterPatternChange: EventEmitter<string> = new EventEmitter()
  @Input() items: Array<any> = new Array();

  get value(): any {
    return this._value;
  }
  get isValid(): boolean { return this.required && this.value != null; }

  _value?: any;

  errors: Array<any> = new Array();
  toggeledModal: boolean = false;
  filteredItems: Array<any> = [];
  inputValue: string = '';
  arrowSelectIndex: number = -1;
  arrowValue: any;
  searchPattern: string = "";

  _onChange!: (value: any) => void;

  constructor() { }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (this.toggeledModal) {
      if (event.keyCode == 40  /** Down key code */) {
        this.selectNext();
        event.preventDefault();
      }
      else if (event.keyCode == 38 /** Up key code */) {
        this.selectPrevious();
        event.preventDefault();
      }
      else if (event.keyCode == 13 && this.arrowSelectIndex >= 0 && this.arrowSelectIndex < this.filteredItems.length /** Enter key code */) {
        this.selectInput();
        this.toggeledModal = false;
        event.preventDefault();
      }
    }
  }

  ngDoCheck(): void {
    this.errors = this.isValid ? [] : ['required'];
  }

  filter(pattern: string): Array<any> {
    return this.items.filter(e => {
      let value = '';

      if (typeof e === "object" && this.options.filter?.byKey && pattern.length >= (this.options.filter?.firstCaracters || 1)) {
        value = e[this.options.filter?.byKey];
      }
      if (value) return this.__matchPattern(value, pattern)
      else return false;
    })
  }

  __matchPattern(source: string, pattern: string): boolean {
    if (this.options.filter?.with === 'containe')
      return source.toString().trim().toLocaleLowerCase().includes(this.options.filter?.camelCase ? pattern : pattern.toLocaleLowerCase());
    else if (this.options.filter?.with === 'end')
      return source.toString().trim().toLocaleLowerCase().endsWith(this.options.filter?.camelCase ? pattern : pattern.toLocaleLowerCase());
    else if (this.options.filter?.with === 'start-and-end')
      return source.toString().trim().toLocaleLowerCase().endsWith(this.options.filter?.camelCase ? pattern : pattern.toLocaleLowerCase()) && source.toString().trim().toLocaleLowerCase().startsWith(this.options.filter?.camelCase ? pattern : pattern.toLocaleLowerCase());
    else if (this.options.filter?.with === 'start-or-end')
      return source.toString().trim().toLocaleLowerCase().endsWith(this.options.filter?.camelCase ? pattern : pattern.toLocaleLowerCase()) || source.toString().trim().toLocaleLowerCase().startsWith(this.options.filter?.camelCase ? pattern : pattern.toLocaleLowerCase());
    else
      return source.toString().trim().toLocaleLowerCase().startsWith(this.options.filter?.camelCase ? pattern : pattern.toLocaleLowerCase());
  }


  selectNext(): void {
    if (this.toggeledModal) {
      this.arrowSelectIndex = this.arrowSelectIndex + 1;
    }
  }
  selectPrevious(): void {
    if (this.toggeledModal) {
      this.arrowSelectIndex = this.arrowSelectIndex - 1;
    }


  }
  selectInput() {
    if (this.toggeledModal) {
      this.value = this.filteredItems[this.arrowSelectIndex];

    }
  }
  ngOnInit(): void { }

  onSelectedItem(item: any): void {
    this.value = null;
    this.toggeledModal = false;
    this.value = new Object(item);
    if (this._onChange) this._onChange(this.value)
  }

  onSearchInputChange(value: string): void {
    if (this.value === value) return;
    this.searchPattern = value;
    this.filterPatternChange.emit(value)
    if (value.length == 0 || value.length >= (this.options.filter?.firstCaracters || 1)) {
      this.value = null;
      if (this._onChange) this._onChange(this.value)
    }
    if (value.length >= (this.options.filter?.firstCaracters || 1)) {
      this.toggeledModal = true;
      this.arrowSelectIndex = -1;
      this.filteredItems = this.filter(value);

    } else {
      this.toggeledModal = false;
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
