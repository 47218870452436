<div class="mt-2">
    <div class="row">
        <div class="col-lg-6 col-sm-12">
            <label for="">Name</label>
            <input type="text" class="form-control" [(ngModel)]="vaccination.nom_Vaccin" (ngModel)="_change()">
            <span *ngIf="isSubmitted && vaccination.nom_Vaccin.length == 0" style="color:red">Vaccination name is
                required </span>
        </div>

        <div class="col-lg-6 col-sm-12 col-md-6">
            <label for="">Commercial name</label>
            <input type="text" class="form-control" [(ngModel)]="vaccination.nom_commercial_Vaccin"
                (ngModel)="_change()">
          

        </div>
        <div class="col-lg-6 col-sm-12 col-md-6">
            <label for="">Labo</label>
            <input type="text" class="form-control" [(ngModel)]="vaccination.nom_Labo_Vaccin" (ngModel)="_change()">
           
        </div>
        <div class="col-lg-6 col-sm-12 col-md-6">
            <label for="">Note</label>
            <input type="text" class="form-control" [(ngModel)]="vaccination.note_Vaccin" (ngModel)="_change()">
            
        </div>
    </div>
</div>