import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActeConsultationItem } from 'src/app/entities/consultation.entity';
import { ActeConsultationnItemValidation } from '../consultation-act-form.component/consultation-act-form.component';

@Component({
  selector: 'app-consultation-act-item-form',
  templateUrl: './consultation-act-item-form.component.html',
  styleUrls: ['./consultation-act-item-form.component.css']
})
export class ConsultationActItemFormComponent implements OnInit {

  @Input() submitted: boolean = false;
  @Input() actConsultationItem: ActeConsultationItem = new ActeConsultationItem();
  @Output() actConsultationItemChange: EventEmitter<ActeConsultationItem> = new EventEmitter()
  @Output() onDelete: EventEmitter<void> = new EventEmitter()
  @Output() validationChange: EventEmitter<ActeConsultationnItemValidation> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  get validation(): ActeConsultationnItemValidation {
    return new ActeConsultationnItemValidation({
      act: !this.actConsultationItem.act?.id_Acte ? 'required' : null,
      note: null,
      montant: !this.actConsultationItem.montant_consultation_acte ? 'required' : null,
      duration: this.actConsultationItem.date_consultation_acte ? (!isNaN(Number(this.actConsultationItem.date_consultation_acte)) ? (Number(this.actConsultationItem.date_consultation_acte) < 1 ? 'min' : null) : 'number') : 'required'
    });
  }

  onChange(): void {
    this.actConsultationItemChange.emit(this.actConsultationItem);
  }
}
