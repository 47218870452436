<div class="content success-page-cont">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="success-cont">
          <img src="assets/img/icons/check-circle.png" alt="logo">
          <h3>RDV réservé avec succés!</h3>
          <p>RDV réservé avec <span class="text-info"><strong>{{doctorAppointment.patient.getFullName()}}</strong></span><br>le
            <span>{{ doctorAppointment.date | date:'dd/MM/yyyy'}}<strong class="text-info apt-time">{{doctorAppointment.startTime |time}}</strong></span>
          </p>
          <button class="btn align-content-lg-center btn-sm bg-success" [routerLink]="['/doctor/patients/' + doctorAppointment.patient.id + '/appointments']"
            routerLinkActive="router-link-active">Retourner aux rendez vous avec
            {{doctorAppointment.patient.getFullName()}}</button>
        </div>
      </div>
    </div>
  </div>
</div>