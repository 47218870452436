import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ModalComponent } from 'src/app/common/modules/modal.module/modal/modal.component';
import { FilterPipe } from 'src/app/common/pipes/filter.pipe/filter.pipe';
import { Biology } from 'src/app/entities/biology.entity/biology';
import { DoctorBiologieService } from 'src/app/services/doctor-biologies.services/doctor-biologie.service';
import { BiologyFormComponent } from '../biology-form.component/biology-form.component';

@Component({
  selector: 'app-biology-select-dropdown',
  templateUrl: './biology-select-dropdown.component.html',
  styleUrls: ['./biology-select-dropdown.component.css'],
})
export class BiologySelectDropdownComponent implements OnInit {
  @Input() values: Array<Biology> = new Array();
  @Input() placeHolder: string = '';
  @Input() set value(value: Biology) {
    if (value) {
      this._value = value;
      this.valueChange.emit(this._value);
    }
  }
  @Output() valueChange: EventEmitter<Biology> = new EventEmitter();

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleModal(false);
    }
  }

  @ViewChild('biologyForm', { read: BiologyFormComponent, static: false })
  biologyForm!: BiologyFormComponent;
  @ViewChild(ModalComponent, { read: ModalComponent, static: false })
  modalToggled: boolean = false;
  currentTabIndex: number = 0;
  searchPattern: string = '';

  @ViewChild('dialog', { static: false, read: ModalComponent })
  dialog!: ModalComponent;

  get value(): Biology {
    return this._value || { element_Bio: '' };
  }

  private _value!: Biology;

  constructor(
    private elementRef: ElementRef,
    private toasterService: ToastrService,
    private doctorBiologiesServices: DoctorBiologieService
  ) { }

  ngOnInit(): void {

  }

  openDialoge(): void {
    this.dialog.open();
  }

  toggleModal(toggled?: boolean, elementId?: string): void {
    if (toggled != undefined) {
      this.modalToggled = toggled;
    } else this.modalToggled = !this.modalToggled;
    if (toggled == true && elementId != undefined) {
      this.currentTabIndex = 0;
    }
  }

  onAddBiology(): void {
    this.biologyForm.isSubmitted = true;
    if (this.biologyForm.isValid) {
      this.doctorBiologiesServices.add(this.biologyForm.biology).subscribe(
        (biology) => {
          this.value = Biology.fromResponse(biology);
          this.biologyForm.isSubmitted = false;
          this.values = [biology, ...this.values];
          this.dialog.close();
        },
        (error) => this.toasterService.error('Enable to add medic')
      );
    }
  }

  get filtredBiologies(): Array<Biology> {
    return new FilterPipe().transform(this.values, this.searchPattern, {
      keyword: 'element_Bio',
    });
  }

  get groupedBiologies(): Array<{ keyName: string; items: Array<Biology> }> {
    let groups: Array<string> = this.filtredBiologies
      .map((item) => item.param_Bio)
      .filter((item, index, self) => self.indexOf(item) === index);
    return Array.from(groups, (group) => {
      let items: Array<Biology> = new Array();
      this.filtredBiologies.forEach((biology) => {
        if (
          String(group).toUpperCase() == String(biology.param_Bio).toUpperCase()
        )
          items.push(biology);
      });
      return {
        keyName: group,
        items: items,
      };
    });
  }

  onValuesSelected(value: Biology) {
    if (value) {
      this.value = value;
      this.toggleModal(false);
      this.searchPattern = '';
    }
  }
}
