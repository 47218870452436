<div class="card card-table">
    <div class="card-body">
        <div class="prescription-items-container">
            <table class="table table-hover table-center">
                <thead>
                    <tr>
                        <th style="width: min-content;">Radiologie (Saisir la première lettre)  <button class="openDialoge" [title]="'Ajouter Radio'" (click)="drawerActive = !drawerActive">
                            <i class="fas fa-x-ray"></i>
                        </button> </th>
                        <th style="width: 60%;">Notes </th>
                        <th style="max-width: 80px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let radiologyPrescriptionItem of radiologyPrescriptionItems;let i = index"
                        [ngStyle]="{'border':!validatePrescriptionItem(radiologyPrescriptionItem).isValid && submitted?'1px solid red':'None'}">
                        <td style="width:41%">
                            <app-radiology-select-dropdown [(value)]="radiologyPrescriptionItem.radio" [placeHolder]="'Radiology is here'" (valueChange)="_change()">
                            </app-radiology-select-dropdown>

                            <!-- <div
                                *ngIf="validatePrescriptionItem(radiologyPrescriptionItem).controlsValidation['radiology'] && submitted">
                                <span
                                    *ngIf="validatePrescriptionItem(radiologyPrescriptionItem).controlsValidation['radiology'] == 'required'"
                                    style="color: red"> Radio est obligatoire </span>
                            </div> -->
                        </td>
                        <td>
                            <textarea style="height: 45px" [(ngModel)]="radiologyPrescriptionItem.note" (change)="onEditPrescriptionItem(i)" class="form-control"
                                [(ngModel)]="radiologyPrescriptionItem.note" (ngModelChange)="_change()"></textarea>
                            <!-- <div
                                *ngIf="validatePrescriptionItem(radiologyPrescriptionItem).controlsValidation['notes'] && submitted">
                                <span
                                    *ngIf="validatePrescriptionItem(radiologyPrescriptionItem).controlsValidation['notes'] == 'required'"
                                    style="color: red"> Note est obligatoire </span>
                            </div> -->
                        </td>
                        <td>
                            <a href="javascript:void(0)" class="btn bg-danger-light trash" (click)="deletePrescriptionItem(i)"><i class="feather-trash-2"></i></a>
                        </td>
                    <tr>
                        <td colspan="5">
                            <div class="add-more-item text-center">
                                <a href="javascript:void(0);" (click)="addPrescriptionItem()"><i class="feather-plus-circle"></i> Ajouter
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-drawer [active]="drawerActive" [title]="'Ajouter Radiologie'">
    <div body style="height: 80%;">
        <app-radio-select-drawer  [(values)]="radiologies"
        (valuesChange)="_change()" (closed)="closed()" [(radioSelected)]="radiosFilter"></app-radio-select-drawer>
      <!-- <app-doctor-patient-historics *ngIf="consultation.appointment.patient.id.length" [patientId]="consultation.appointment.patient.id"></app-doctor-patient-historics> -->
    </div>
  </app-drawer>