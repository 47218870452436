import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/common/modules/modal.module/modal/modal.component';
import { FilterPipe } from 'src/app/common/pipes/filter.pipe/filter.pipe';
import { Vaccin } from 'src/app/entities/vaccin.entity/vaccin';
import { DoctorVaccinationsService } from 'src/app/services/doctor-vaccinations.service/doctor-vaccination.service';
import { VaccinationFormComponent } from '../vaccination-form.component/vaccination-form.component';

@Component({
  selector: 'app-vaccinaion-drop-down-select',
  templateUrl: './vaccinaion-drop-down-select.component.html',
  styleUrls: ['./vaccinaion-drop-down-select.component.css']
})
export class VaccinaionDropDownSelectComponent implements OnInit {

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleModal(false)
    }
  }

  @Input() values: Array<Vaccin> = new Array();
  @Input() placeHolder: string = "";
  @Input() vaccine: Vaccin = new Vaccin()
  @Output() vaccineChange: EventEmitter<Vaccin> = new EventEmitter
  @ViewChild('doctorVaccinationForm', { read: VaccinationFormComponent, static: false }) doctorVaccinationForm!: VaccinationFormComponent;
  searchPattern: string = '';
  @ViewChild(ModalComponent, { read: ModalComponent, static: false })

  dialog!: ModalComponent;

  vaccinationsList: Array<Vaccin> = new Array();
  @Input() set value(value: Vaccin) {
    this._value = value;
    this.valueChange.emit(this._value);
  }
  modalToggled: boolean = false;
  currentTabIndex: number = 0;

  get value(): Vaccin {
    return this._value;
  }

  private _value!: Vaccin;
  @Output() valueChange: EventEmitter<Vaccin> = new EventEmitter();

  constructor(private elementRef: ElementRef, private vaccinationsService: DoctorVaccinationsService, private viewportScroller: ViewportScroller,) { }

  ngOnInit(): void {
    this.vaccinationsService.all().subscribe(vaccinations => {
      this.vaccinationsList = vaccinations;
    })
  }

  toggleModal(toggled?: boolean, elementId?: string): void {
    if (toggled != undefined) this.modalToggled = toggled;
    else this.modalToggled = !this.modalToggled;
    if (toggled == true && elementId != undefined) {
      this.currentTabIndex = 0;
      this.viewportScroller.scrollToAnchor(elementId);
    }
  }
  openDialoge(): void {
    this.dialog.open()
  }
  onAddVaccination(): void {
    this.doctorVaccinationForm.isSubmitted = true;
    if (this.doctorVaccinationForm.isValid) {
      this.vaccinationsService.add(this.doctorVaccinationForm.vaccination).subscribe(vaccination => {
        this.value = vaccination;
        this.doctorVaccinationForm.isSubmitted = false;
        this.vaccinationsList = [vaccination, ...this.vaccinationsList];
        this.toggleModal(false);
        this.dialog.close()
      }, error => alert('Enable to add medic'));
    }
  }

  get vaccinations(): Array<Vaccin> {
    return new FilterPipe().transform(this.vaccinationsList, this.searchPattern, { keyword: 'nom_commercial_Vaccin' });
  }

  onValuesSelected(value: Vaccin) {
    this.value = value;
    if (value) {
      this.value = value;
      this.toggleModal(false)
      this.searchPattern = ''
    }
  }

}
