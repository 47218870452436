import { Injectable } from '@angular/core';
import { DBConfig, NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { Prescription } from 'src/app/entities/prescription.entity';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  getPrescription(id: string): Observable<any> {
    return this.indexedDBService.getByKey('prescriptions', id)
  }

  constructor(private indexedDBService: NgxIndexedDBService) { }

  updatePrescription(id: string, prescription: Prescription): Observable<any> {
    return this.indexedDBService.update('prescriptions', {
      'id': id,
      'date': prescription.date.getTime(),
      'medics': prescription.medics,
      'radiologies': prescription.radiologies,
      'biologies': prescription.biologies
    });
  }

  deletePrescription(id: string): Observable<any> {
    return this.indexedDBService.deleteByKey('prescriptions', id);
  }

  static getConfig(): DBConfig {
    return {
      name: 'local-db',
      version: 1,
      objectStoresMeta: [
        {
          store: 'prescriptions',
          storeConfig: { keyPath: 'id', autoIncrement: false, options: { unique: true } },
          storeSchema: [
            { name: 'date', keypath: 'date', options: { unique: false } },
            { name: 'medics', keypath: 'medics', options: { unique: false } },
            { name: 'radiologies', keypath: 'radiologies', options: { unique: false } },
            { name: 'biologies', keypath: 'biologies', options: { unique: false } }
          ]
        },
        {
          store: 'consultations',
          storeConfig: { keyPath: 'appointmentId', autoIncrement: false },
          storeSchema: [

            { name: 'date', keypath: 'date', options: { unique: false } },
            { name: 'assurance', keypath: 'assurance', options: { unique: false } },
            { name: 'motifTags', keypath: 'motifTags', options: { unique: false } },
            { name: 'assuranceMatricule', keypath: 'assuranceMatricule', options: { unique: false } },


            { name: 'consultationHeureDebut', keypath: 'consultationHeureDebut', options: { unique: false } },
            { name: 'consultationHistoireMaladie', keypath: 'consultationHistoireMaladie', options: { unique: false } },
            { name: 'consultationExamenClinique', keypath: 'consultationExamenClinique', options: { unique: false } },
            { name: 'diagnosticTags', keypath: 'diagnosticTags', options: { unique: false } },


            { name: 'montant', keypath: 'montant', options: { unique: false } },
            { name: 'consultationExamenDemande', keypath: 'consultationExamenDemande', options: { unique: false } },
            { name: 'montantRegle', keypath: 'montantRegle', options: { unique: false } },
            { name: 'note', keypath: 'note', options: { unique: false } },

            { name: 'radiologies', keypath: 'radiologies', options: { unique: false } },
            { name: 'biologies', keypath: 'biologies', options: { unique: false } },
            { name: 'vaccines', keypath: 'vaccines', options: { unique: false } },
            { name: 'actes', keypath: 'actes', options: { unique: false } },
            { name: 'parametresSante', keypath: 'parametresSante', options: { unique: false } }

          ]
        }
      ]
    };
  }

  addPrescription(prescription: Prescription): Observable<any> {
    return this.indexedDBService.add('prescriptions', {
      'id': prescription.id,
      'date': prescription.date.getTime(),
      'medics': [],
      'radiologies': [],
      'biologies': []
    });
  }

  clear(): Observable<boolean> {
    return this.indexedDBService.clear('local-db');
  }
}
