import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../../modules/modal.module/modal/modal.component';
import { Observable, Subscriber } from 'rxjs';

export enum ConfirmDialogResult {
  REJECTED,
  ACCEPTED
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements AfterViewInit {

  @Input() title: string = '';
  @Input() subject: string = '';
  @Output() onAccept: EventEmitter<void> = new EventEmitter();
  @Output() onReject: EventEmitter<void> = new EventEmitter();

  @ViewChild(ModalComponent, { read: ModalComponent, static: false }) modal!: ModalComponent;

  private _subscriber!: Subscriber<ConfirmDialogResult>;
  constructor() { }

  ngAfterViewInit(): void {

  }

  accept(): void {
    this._subscriber.next(ConfirmDialogResult.ACCEPTED);
    this.onAccept.emit();
    this._subscriber.complete();
    this.modal.close();
  }

  reject(): void {
    this._subscriber.next(ConfirmDialogResult.REJECTED);
    this.onReject.emit();
    this._subscriber.complete();
    this.modal.close();
  }

  open(options?: { title?: string, subject?: string }): Observable<ConfirmDialogResult> {
    if (options && options.title) this.title = options.title;
    if (options && options.subject) this.subject = options.subject;

    return new Observable<ConfirmDialogResult>(subscriber => {
      this._subscriber = subscriber;
      this.modal.open();
    })
  }

}
