<div class="list-doctors-wrapper" [dir]="language=='fr'?'ltr':'rtl'">
  <div class="row" *ngFor="let doctor of doctors | paginate: { itemsPerPage: perPage, currentPage: currentPage }">
    <div class="col-md-10 col-sm-6 col-lg-10 mb-sm-12 mb-12" style="margin-bottom: 8px;">
      <div class="attribute-user d-flex flex-column h-100 favorite">
        <div class="row list">
          <div class="col-lg-4">
            <div class="contact-user">
              <div class="avatar-sm">
                <img class="avatar-img rounded-circle avatar avatar-sm" [src]="doctor?.owner?.avatar | avatarSrc" alt="User Image" />
              </div>
              <div class="user">
                <div class="name-user">
                  {{language=="fr"? doctor.owner?.getFullName():doctor?.owner?.getArabicFullName()}}
                </div>
                <div class="specialtiy-name">
                  {{language=="fr"?doctor.speciality?.label:doctor?.owner?.speciality?.arabicName}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="contact">
              <div class="contact-patient">
                {{language=='fr'?'Addresse : ':'العنوان'}}
                <span>{{language=="fr"?doctor.address:doctor.arabicAddress}}</span>
              </div>
              <div class="contact-patient">
                {{language == 'fr'?'Telephone fixe :':':الهاتف'}}
                <span>{{doctor.fixPhone}}</span>
                <br>
                {{language == 'fr'?'Telephone(s) mobile(s) :':':الجوال'}}<br>
                <span>{{doctor.mobilePhone1}} <br>{{doctor.mobilePhone2}}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <a class="btn button-rdv small btn-navbar" [routerLink]="['/booking',doctor?.owner?.id]" [queryParams]="{dir: language}">
              {{language=='fr'?'Prendre rendez-vous':'إحجز موعد'}}</a>
            <a class="btn small btm" [href]="getProfileLink(doctor)" target="_blank">{{language=='fr'?'Voir profile':'عرض الملف'}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-pagination [pageRequest]="pageRequest" (content)="doctors = $event" class="paginate" [language]="language"></app-pagination>