import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service/api.service';
import { Observable } from 'rxjs';
import { Antecedent } from '../../entities/atced.entity/antecedent';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DoctorPatientAntecedentsService extends ApiService {


  one(patientId: string): Observable<Array<Antecedent>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/patients/${patientId}/antecedents`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  updateAntecedent(patientId: string, data: Antecedent): Observable<Antecedent> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/patients/${patientId}/antecedents`, {
      atcdFamiliaux: data.familiaux,
      atcdMedicaux: data.medicaux,
      atcdChirugicaux: data.chirugicaux,
      atcdRhumato: data.rhumato,
      atcdPharmacie: data.pharmacie,
      atcdIntorerance: data.intorerance,
      atcdAllergie: data.allergie,
      atcdFamiliauxAlert: data.familiauxAlert,
      atcdMedicauxAlert: data.medicauxAlert,
      atcdChirugicauxAlert: data.chirugicauxAlert,
      atcdRhumatoAlert: data.rhumatoAlert,
      atcdPharmacieAlert: data.pharmacieAlert,
      atcdIntoleranceAlert: data.intoreranceAlert,
      atcdAllergieAlert: data.allergieAlert
    }, { responseType: 'json' }).pipe(this.responsePipe)

  }
}
