import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DoctorAppointment } from 'src/app/entities/appointment.entity/doctor-appointment.entity/doctor-appointment';
import { Prescription } from 'src/app/entities/prescription.entity';
import { DoctorPrescription } from 'src/app/entities/prescription.entity/doctor-prescription.entity/doctor-prescription';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';


export interface PrescriptionFormValidity {
  medics: boolean;
  radiologies: boolean;
  biologies: boolean;
}
@Component({
  selector: 'app-prescription-form',
  templateUrl: './prescription-form.component.html',
  styleUrls: ['./prescription-form.component.css']
})
export class PrescriptionFormComponent implements OnInit {
  @Input() prescription: DoctorPrescription = new DoctorPrescription();
  @Output() prescriptionChange: EventEmitter<DoctorPrescription> = new EventEmitter();
  @Input() appointment: DoctorAppointment = new DoctorAppointment();
  @Input() submitted: boolean = false;
  tabIndex: number = 0;
  get doctor(): Doctor {
    return this.authService.getCurrentUser() as Doctor;
  }
  get isValid() {
    return this.validity.biologies && this.validity.radiologies && this.validity.medics;
  }

  validity: PrescriptionFormValidity = { medics: true, radiologies: true, biologies: true }

  constructor(private authService: AuthService) { }

  onValidityChange(validity: Partial<PrescriptionFormValidity>): void {
    this.validity = { medics: validity.medics === undefined ? this.validity.medics : validity.medics, biologies: validity.biologies == undefined ? this.validity.biologies : validity.biologies, radiologies: validity.radiologies == undefined ? this.validity.radiologies : validity.radiologies }
  }

  ngOnInit(): void { }


  _onChange(): void {
    this.prescriptionChange.emit(this.prescription)
  }
}
