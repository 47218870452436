import { AfterViewInit, Component, ContentChild, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { DataTableItemDirective } from '../../directives/data-table-item.directive/data-table-item.directive';
import { DataTableHeadDirective } from '../../directives/data-table-head.directive/data-table-head.directive';
import { PageRequestFn, PaginationPage, PageRequest } from '../../interfaces';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
})
export class DataTableComponent<T> implements AfterViewInit {

  @Input() perPage: number = 10;
  @Input() pageRequest!: PageRequestFn<T>;
  @Output() pageChange: EventEmitter<PaginationPage<T>> = new EventEmitter();


  @ContentChildren(DataTableHeadDirective, { descendants: true }) headers!: QueryList<DataTableHeadDirective>;
  @ContentChild(DataTableItemDirective, { static: false }) dataTableItem!: DataTableItemDirective<any>;

  @Input() onRefresh: EventEmitter<PageRequest> = new EventEmitter();
  @Input() set page(page: PageRequest) {
    this.onRefresh.emit(page);
    /**
     * @Tody update sort headers
     */
  }
  /*@ContentChild('item', { static: true }) itemTemplate!: TemplateRef<any>;
  @ContentChild('empty', { static: true }) emptyTemplate!: TemplateRef<any>;
  */

  constructor() { }

  ngAfterViewInit(): void {
    this.headers.forEach(head => {
      head.onSort.subscribe(target => {
        const previousOrder = target.sortOrder;
        this.headers.forEach(e => e.sort('none'))
        target.sort(previousOrder == 'none' ? 'asc' : (previousOrder == 'asc' ? 'desc' : 'none'))
        this.onRefresh.emit({ pageOffset: 0, pageSize: this.perPage, sort: { key: target.options.sortKey!, order: target.sortOrder } })
      })
    })
  }

  ngOnInit(): void {

  }

  onPageChange(page: PaginationPage<T>): void {
    this.dataTableItem.items = page.items;
  }
}
