import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSplashScreenService } from './progress-splash-screen.service/progress-splash-screen.service';
import { ProgressSplashScreenComponent } from './progress-splash-screen.component/progress-splash-screen.component';



@NgModule({
  declarations: [
    ProgressSplashScreenComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProgressSplashScreenComponent,
  ],
  providers: [
    ProgressSplashScreenService
  ]
})
export class ProgressSplashScreenModule { }
