<app-appointment-patient-login *ngIf="!patientAppointment.requested" [dir]="'ar'" [patientAppointment]="patientAppointment"></app-appointment-patient-login>
<ng-container *ngIf="patientAppointment.requested && dir=='fr'">
    <form [formGroup]="userForm">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label>Prénom</label>
                    <form-input [placeholder]="'Prénom'" [append]="'fi feather-user'" [required]="true" name="firstName" formControlName="firstName"></form-input>
                    <span *ngIf="isSubmited && f.firstName.errors?.required" class="invalid-input">Prénom est obligatoire</span>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label>Nom</label>
                    <form-input [placeholder]="'Nom'" [append]="'fi feather-user'" name="lastName" formControlName="lastName"></form-input>
                    <span *ngIf="isSubmited && f.lastName.errors?.required" class="invalid-input">Nom est obligatoire</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12">
                <div class="form-group">
                    <label>Date Naissance </label>
                    <date-picker [append]="'fi feather-calendar'" name="birthDate" formControlName="birthDate"></date-picker>
                    <span *ngIf="isSubmited && f.birthDate.errors?.required" class="invalid-input">Date Naissance est
                        obligatoire</span>
                </div>
            </div>
            <div class="col-lg-8 col-sm-12">
                <div class="form-group">
                    <label> Téléphone </label>
                    <form-input [placeholder]="'Téléphone'" [append]="'fi feather-phone'" name="mobilePhone" formControlName="mobilePhone"></form-input>
                    <span *ngIf="isSubmited && !f.mobilePhone.errors?.required" class="invalid-input">
                        Téléphone est obligatoire</span>
                </div>
            </div>
        </div>
    </form>
</ng-container>
<ng-container *ngIf="patientAppointment.requested && dir=='ar'">
    <form [formGroup]="userForm">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label>اللقب</label>
                    <form-input [placeholder]="'اللقب'" [append]="'fi feather-user'" [required]="true" name="firstName" formControlName="firstName"></form-input>
                    <span *ngIf="isSubmited && f.firstName.errors?.required" class="invalid-input">اللقب مطلوب</span>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label>الاسم</label>
                    <form-input [placeholder]="'الاسم'" [append]="'fi feather-user'" name="lastName" formControlName="lastName"></form-input>
                    <span *ngIf="isSubmited && f.lastName.errors?.required" class="invalid-input">الإسم مطلوب</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12">
                <div class="form-group">
                    <label>تاريخ الولادة </label>
                    <date-picker [append]="'fi feather-calendar'" name="birthDate" formControlName="birthDate"></date-picker>
                    <span *ngIf="isSubmited && f.birthDate.errors?.required" class="invalid-input">تاريخ الميلاد مطلوب</span>
                </div>
            </div>
            <div class="col-lg-8 col-sm-12">
                <div class="form-group">
                    <label> هاتف </label>
                    <form-input [placeholder]="'هاتف'" [append]="'fi feather-phone'" name="mobilePhone" formControlName="mobilePhone"></form-input>
                    <span *ngIf="isSubmited && !f.mobilePhone.errors?.required" class="invalid-input">
                        الهاتف مطلوب</span>
                </div>
            </div>
        </div>
    </form>
</ng-container>
<a *ngIf="!patientAppointment.requested && dir=='ar'" class="btn small btm" (click)="patientAppointment.requested = true;" style="text-decoration: underline;">لا املك حساب ؟</a>
<a *ngIf="patientAppointment.requested && dir=='ar'" class="btn small btm" (click)="patientAppointment.requested = false;" style="text-decoration: underline;">لدي حساب الطبيب؟</a>
<a *ngIf="!patientAppointment.requested && dir=='fr'" class="btn small btm" (click)="patientAppointment.requested = true;" style="text-decoration: underline;">Je n'ai pas de compte
    ?</a>
<a *ngIf="patientAppointment.requested && dir=='fr'" class="btn small btm" (click)="patientAppointment.requested = false;" style="text-decoration: underline;">J'ai un compte
    SilaDoc ?</a>