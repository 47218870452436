import { Component, Input, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  ActeConsultationItem,
  BiologyConsultationItem,
  ConsultationParametreSanteItem,
  RadiologyConsultationItem,
  VaccinConsultationItem,
} from "src/app/entities/consultation.entity";
import { DoctorPatient } from "src/app/entities/doctor-patient.entity/doctor-patient";
import { BioPrescription } from "src/app/entities/prescription.entity/bio-prescription.entity/bio-prescription";
import { MedicPrescription } from "src/app/entities/prescription.entity/medic-prescription.entity/medic-prescription";
import { RadioPrescription } from "src/app/entities/prescription.entity/radio-prescription.entity/radio-prescription";
import { Doctor } from "src/app/entities/user.entity/doctor.entity";
import { DoctorPatientsService } from "src/app/services/doctor-patients.service/doctor-patients.service";
import { ConsultationOphtalmoParametreSanteItem } from "src/app/entities/consultation.entity/consultation-ophtalmo.entity/consultation-ophtalmo";
import * as printJS from "print-js";
import jsPDF from "jspdf";
import { DoctorPatientAntecedentsService } from "src/app/services/doctor-patient-antecedents.services/doctor-patient-antecedent.service";
import { Antecedent } from "src/app/entities/atced.entity/antecedent";
export class DoctorPatientHistoricItem {
  date: Date;
  motif: string;
  examenClinique: string;
  diagnostic: string;
  histoireMaladie: string;
  examenDemande: string;
  prescriptionMedics: Array<MedicPrescription>;
  prescriptionBiologies: Array<BioPrescription>;
  prescriptionRadiologies: Array<RadioPrescription>;

  consultationBiologies: Array<BiologyConsultationItem>;
  consultationRadiologies: Array<RadiologyConsultationItem>;
  consultationVaccinations: Array<VaccinConsultationItem>;
  consultationActes: Array<ActeConsultationItem>;
  consultationParamSanteValues: Array<
    ConsultationParametreSanteItem | ConsultationOphtalmoParametreSanteItem
  >;
  constructor(other?: Partial<DoctorPatientHistoricItem>) {
    this.date = other?.date || new Date();
    this.motif = other?.motif || "";
    this.examenClinique = other?.examenClinique || "";
    this.diagnostic = other?.diagnostic || "";
    this.histoireMaladie = other?.histoireMaladie || "";
    this.examenDemande = other?.examenDemande || "";
    this.prescriptionMedics = other?.prescriptionMedics || [];
    this.prescriptionBiologies = other?.prescriptionBiologies || [];
    this.prescriptionRadiologies = other?.prescriptionRadiologies || [];
    this.consultationBiologies = other?.consultationBiologies || [];
    this.consultationRadiologies = other?.consultationRadiologies || [];
    this.consultationVaccinations = other?.consultationVaccinations || [];
    this.consultationActes = other?.consultationActes || [];
    this.consultationParamSanteValues =
      other?.consultationParamSanteValues || [];
  }

  get hasPrescription(): boolean {
    return (
      this.prescriptionBiologies.length > 0 ||
      this.prescriptionMedics.length > 0 ||
      this.prescriptionRadiologies.length > 0
    );
  }
  get hasConsultation(): boolean {
    return (
      this.consultationBiologies.length > 0 ||
      this.consultationRadiologies.length > 0 ||
      this.consultationActes.length > 0 ||
      this.consultationVaccinations.length > 0 ||
      this.consultationParamSanteValues.length > 0
    );
  }
  get isEmpty(): boolean {
    return !this.hasConsultation && !this.hasPrescription;
  }

  public static fromResponse(res: any): DoctorPatientHistoricItem {
    return new DoctorPatientHistoricItem({
      date: new Date(res.date),
      motif: res.motif,
      examenClinique: res.eexamenClinique,
      diagnostic: res.diagnostic,
      histoireMaladie: res.histoireMaladie,
      examenDemande: res.examenDemande,
      prescriptionMedics: Array.from(res.prescriptionMedics || [], (e) =>
        MedicPrescription.fromResponse(e)
      ),
      prescriptionBiologies: Array.from(res.prescriptionBiologies, (e) =>
        BioPrescription.fromResponse(e)
      ),
      prescriptionRadiologies: Array.from(
        res.prescriptionRadiologies || [],
        (e) => RadioPrescription.fromResponse(e)
      ),
      consultationBiologies: Array.from(res.consultationBiologies, (e) =>
        BiologyConsultationItem.fromResponse(e)
      ),
      consultationRadiologies: Array.from(
        res.consultationRadiologies || [],
        (e) => RadiologyConsultationItem.fromResponse(e)
      ),
      consultationVaccinations: res.consultationVaccinations,
      consultationActes: res.consultationActes,
      consultationParamSanteValues: Array.from(
        res.consultationParamSanteValues || [],
        (e) =>
          ConsultationParametreSanteItem.fromResponse(e) ||
          ConsultationOphtalmoParametreSanteItem.fromResponse(
            encodeURIComponent
          )
      ),
    });
  }
}
export class DoctorPatientHistoricItemOpth {
  date: Date;
  motif: string;
  examenClinique: string;
  diagnostic: string;
  histoireMaladie: string;
  examenDemande: string;
  prescriptionMedics: Array<MedicPrescription>;
  prescriptionBiologies: Array<BioPrescription>;
  prescriptionRadiologies: Array<RadioPrescription>;

  consultationBiologies: Array<BiologyConsultationItem>;
  consultationRadiologies: Array<RadiologyConsultationItem>;
  consultationVaccinations: Array<VaccinConsultationItem>;
  consultationActes: Array<ActeConsultationItem>;
  consultationParamSanteValues: Array<ConsultationOphtalmoParametreSanteItem>;
  od_vl_avac_Sphere: number;
  od_vl_avac_Cylindre: number;
  od_vl_avac_Axe: number;
  od_vp_avac_Sphere: number;
  od_vp_avac_Cylindre: number;
  od_vp_avac_Axe: number;
  og_vl_avac_Sphere: number;
  og_vl_avac_Cylindre: number;
  og_vl_avac_Axe: number;
  og_vp_avac_Sphere: number;
  og_vp_avac_Cylindre: number;
  og_vp_avac_Axe: number;
  og_vp_avac_Addition: number;
  lentille_Type: string;
  od_lentille_RC: number;
  od_lentille_DC: number;
  od_lentille_Puissance: number;
  og_lentille_RC: number;
  og_lentille_DC: number;
  og_lentille_Puissance: number;
  lentille_remarque: string;
  lunette_remarque: string;
  constructor(other?: Partial<DoctorPatientHistoricItemOpth>) {
    this.date = other?.date || new Date();
    this.motif = other?.motif || "";
    this.examenClinique = other?.examenClinique || "";
    this.diagnostic = other?.diagnostic || "";
    this.histoireMaladie = other?.histoireMaladie || "";
    this.examenDemande = other?.examenDemande || "";
    this.prescriptionMedics = other?.prescriptionMedics || [];
    this.prescriptionBiologies = other?.prescriptionBiologies || [];
    this.prescriptionRadiologies = other?.prescriptionRadiologies || [];
    this.consultationBiologies = other?.consultationBiologies || [];
    this.consultationRadiologies = other?.consultationRadiologies || [];
    this.consultationVaccinations = other?.consultationVaccinations || [];
    this.consultationActes = other?.consultationActes || [];
    this.consultationParamSanteValues = other?.consultationParamSanteValues || [];
    this.od_vl_avac_Sphere = other?.od_vl_avac_Sphere || 0;
    this.od_vl_avac_Cylindre = other?.od_vl_avac_Cylindre || 0;
    this.od_vl_avac_Axe = other?.od_vl_avac_Axe || 0;
    this.od_vp_avac_Sphere = other?.od_vp_avac_Sphere || 0;
    this.od_vp_avac_Cylindre = other?.od_vp_avac_Cylindre || 0;
    this.od_vp_avac_Axe = other?.od_vp_avac_Axe || 0;
    this.og_vl_avac_Sphere = other?.og_vl_avac_Sphere || 0;
    this.og_vl_avac_Cylindre = other?.og_vl_avac_Cylindre || 0;
    this.og_vl_avac_Axe = other?.og_vl_avac_Axe || 0;
    this.og_vp_avac_Sphere = other?.og_vp_avac_Sphere || 0;
    this.og_vp_avac_Cylindre = other?.og_vp_avac_Cylindre || 0;
    this.og_vp_avac_Axe = other?.og_vp_avac_Axe || 0;
    this.og_vp_avac_Addition = other?.og_vp_avac_Addition || 0;
    this.lentille_Type = other?.lentille_Type || "Select type Lentille";
    this.od_lentille_RC = other?.od_lentille_RC || 0;
    this.od_lentille_DC = other?.od_lentille_DC || 0;
    this.od_lentille_Puissance = other?.od_lentille_Puissance || 0;
    this.og_lentille_RC = other?.og_lentille_RC || 0;
    this.og_lentille_DC = other?.og_lentille_DC || 0;
    this.og_lentille_Puissance = other?.og_lentille_Puissance || 0;
    this.lentille_remarque = other?.lentille_remarque || "";
    this.lunette_remarque = other?.lunette_remarque || "";
  }

  get hasPrescription(): boolean {
    return (
      this.prescriptionBiologies.length > 0 ||
      this.prescriptionMedics.length > 0 ||
      this.prescriptionRadiologies.length > 0
    );
  }
  get hasConsultation(): boolean {
    return (
      this.consultationBiologies.length > 0 ||
      this.consultationRadiologies.length > 0 ||
      this.consultationActes.length > 0 ||
      this.consultationVaccinations.length > 0 ||
      this.consultationParamSanteValues.length > 0
    );
  }
  get isEmpty(): boolean {
    return !this.hasConsultation && !this.hasPrescription;
  }

  public static fromResponse(res: any): DoctorPatientHistoricItemOpth {
    return new DoctorPatientHistoricItemOpth({
      date: new Date(res.date),
      motif: res.motif,
      examenClinique: res.eexamenClinique,
      diagnostic: res.diagnostic,
      histoireMaladie: res.histoireMaladie,
      examenDemande: res.examenDemande,
      prescriptionMedics: Array.from(res.prescriptionMedics || [], (e) =>
        MedicPrescription.fromResponse(e)
      ),
      prescriptionBiologies: Array.from(res.prescriptionBiologies, (e) =>
        BioPrescription.fromResponse(e)
      ),
      prescriptionRadiologies: Array.from(
        res.prescriptionRadiologies || [],
        (e) => RadioPrescription.fromResponse(e)
      ),
      consultationBiologies: Array.from(res.consultationBiologies, (e) =>
        BiologyConsultationItem.fromResponse(e)
      ),
      consultationRadiologies: Array.from(
        res.consultationRadiologies || [],
        (e) => RadiologyConsultationItem.fromResponse(e)
      ),
      consultationVaccinations: res.consultationVaccinations,
      consultationActes: res.consultationActes,
      consultationParamSanteValues: Array.from(
        res.consultationParamSanteValues || [],
        (e) => ConsultationOphtalmoParametreSanteItem.fromResponse(e)
      ),
      od_vl_avac_Sphere: res.od_vl_avac_Sphere,
      od_vl_avac_Cylindre: res.od_vl_avac_Cylindre,
      od_vl_avac_Axe: res.od_vl_avac_Axe,
      od_vp_avac_Sphere: res.od_vp_avac_Sphere,
      od_vp_avac_Cylindre: res.od_vp_avac_Cylindre,
      od_vp_avac_Axe: res.od_vp_avac_Axe,
      og_vl_avac_Sphere: res.og_vl_avac_Sphere,
      og_vl_avac_Cylindre: res.og_vl_avac_Cylindre,
      og_vl_avac_Axe: res.og_vl_avac_Axe,
      og_vp_avac_Sphere: res.og_vp_avac_Sphere,
      og_vp_avac_Cylindre: res.og_vp_avac_Cylindre,
      og_vp_avac_Axe: res.og_vp_avac_Axe,
      og_vp_avac_Addition: res.og_vp_avac_Addition,
      lentille_Type: res.lentille_Type || "Select type Lentille",
      od_lentille_RC: res.od_lentille_RC,
      od_lentille_DC: res.od_lentille_DC,
      od_lentille_Puissance: res.od_lentille_Puissance,
      og_lentille_RC: res.og_lentille_RC,
      og_lentille_DC: res.og_lentille_DC,
      og_lentille_Puissance: res.og_lentille_Puissance,
      lentille_remarque: res.lentille_remarque,
      lunette_remarque: res.lunette_remarque,
    });
  }
}
@Component({
  selector: "app-doctor-historique",
  templateUrl: "./doctor-historique.component.html",
  styleUrls: ["./doctor-historique.component.css"],
})
export class DoctorHistoriqueComponent implements OnInit {
  @Input() patientId: string = "";
  pdfViewSettings: {
    medics: Array<boolean>;
    parametreSante: Array<boolean>;
    biologieConsultation: Array<boolean>;
    biologiePresc: Array<boolean>;
    radioPres: Array<boolean>;
    prescription: Array<boolean>;
    radioConsultation: Array<boolean>;
    actes: Array<boolean>;
    vaccin: Array<boolean>;
    Rdv: Array<boolean>;
    motif: Array<boolean>;
    examenClinique: Array<boolean>;
    examenDemande: Array<boolean>;
    diagnostic: Array<boolean>;
    antecedant: boolean;
    lunette: Array<boolean>;
    lentille: Array<boolean>
  } = {
      medics: new Array(),
      parametreSante: new Array(),
      biologieConsultation: new Array(),
      biologiePresc: new Array(),
      prescription: new Array(),
      radioConsultation: new Array(),
      actes: new Array(),
      vaccin: new Array(),
      Rdv: new Array(),
      motif: new Array(),
      radioPres: new Array(),
      examenClinique: new Array(),
      examenDemande: new Array(),
      diagnostic: new Array(),
      lunette: new Array(),
      lentille: new Array(),
      antecedant: true,
    };
  doctorPatientAntecedents!: Antecedent;

  patientHistorics:
    | Array<DoctorPatientHistoricItemOpth>
    | Array<DoctorPatientHistoricItem> | any = new Array();
  doctor: Doctor = JSON.parse(localStorage.getItem("current-user")!) as Doctor;

  header: boolean = false;
  constructor(
    private renderer2: Renderer2,
    private doctorPatientAntecedentsService: DoctorPatientAntecedentsService,
    private router: Router,
    private doctorPatientsService: DoctorPatientsService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.patientId = this.router.url.split("/")[
      this.router.url.split("/").indexOf("patients") + 1
    ];
    this.loadHistoriqueConsultation();
    this.antecedentPatient();

  }

  loadHistoriqueConsultation(): void {
    this.doctorPatientsService.historics(this.patientId).subscribe(
      (res) => {
        this.patientHistorics =
          this.doctor.speciality.label == "Ophtalmologue"
            ? Array.from(Object.values(res) || [], (e) =>
              DoctorPatientHistoricItemOpth.fromResponse(e)
            ).sort((a, b) => b.date.getTime() - a.date.getTime())
            : Array.from(Object.values(res) || [], (e) =>
              DoctorPatientHistoricItem.fromResponse(e)
            ).sort((a, b) => b.date.getTime() - a.date.getTime());
        for (let i = 0; i < this.patientHistorics.length; i++) {
          this.pdfViewSettings.Rdv[i] = true;
          this.pdfViewSettings.examenClinique[i] = true;
          this.pdfViewSettings.motif[i] = true;
          this.pdfViewSettings.medics[i] = true;
          this.pdfViewSettings.biologiePresc[i] = true;
          this.pdfViewSettings.biologieConsultation[i] = true;
          this.pdfViewSettings.radioConsultation[i] = true;
          this.pdfViewSettings.radioPres[i] = true;
          this.pdfViewSettings.actes[i] = true;
          this.pdfViewSettings.vaccin[i] = true;
          this.pdfViewSettings.parametreSante[i] = true;
          this.pdfViewSettings.diagnostic[i] = true;
          this.pdfViewSettings.lunette[i] = true;
          this.pdfViewSettings.lentille[i] = true;
          this.pdfViewSettings.examenDemande[i] = true
        }
      },
      (error) => {
        this.toastrService.error("Enable to load patient historics");
      }
    );
  }
  antecedentPatient(): void {
    if (this.patientId && !this.doctorPatientAntecedents)
      this.doctorPatientAntecedentsService
        .one(this.patientId)
        .subscribe((res) => {
          this.doctorPatientAntecedents = Antecedent.fromResponse(res);
        });
  }

  get antcedents(): Array<{
    label: string;
    isActivated: boolean;
    text: string;
  }> {
    let antcedents: Array<{
      label: string;
      isActivated: boolean;
      text: string;
    }> = new Array();
    if (this.doctorPatientAntecedents) {
      if (this.doctorPatientAntecedents.allergieAlert)
        antcedents.push({
          isActivated: true,
          label: "Allergie",
          text: this.doctorPatientAntecedents.allergie,
        });
      if (this.doctorPatientAntecedents.familiauxAlert)
        antcedents.push({
          isActivated: true,
          label: "Familiaux",
          text: this.doctorPatientAntecedents.familiaux,
        });
      if (this.doctorPatientAntecedents.medicauxAlert)
        antcedents.push({
          isActivated: true,
          label: "Medicaux",
          text: this.doctorPatientAntecedents.medicaux,
        });
      if (this.doctorPatientAntecedents.pharmacieAlert)
        antcedents.push({
          isActivated: true,
          label: "Pharmacy",
          text: this.doctorPatientAntecedents.pharmacie,
        });
      if (this.doctorPatientAntecedents.chirugicauxAlert)
        antcedents.push({
          isActivated: true,
          label: "Chirugicaux",
          text: this.doctorPatientAntecedents.chirugicaux,
        });
      if (this.doctorPatientAntecedents.intoreranceAlert)
        antcedents.push({
          isActivated: true,
          label: "Intorerance",
          text: this.doctorPatientAntecedents.intorerance,
        });
      if (this.doctorPatientAntecedents.rhumatoAlert)
        antcedents.push({
          isActivated: true,
          label: "Rhumato",
          text: this.doctorPatientAntecedents.rhumato,
        });
    }
    return antcedents;
  }
  async print() {
    let target = window.document.getElementById("pdf");

    await printJS({
      printable: target,
      type: "html",
      targetStyle: ["*"],
      targetStyles: ["*"],
      font: "Cairo",
      scanStyles: false,
      style: `
      @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;800;900;1000&display=swap');
   
      .tableheader{width:100%;background-color:#E0FFFF;position:fixed;bottom:0;}
      .docStyle{text-align:right;padding-right:0%;width:50%!important;}

      .name{font-family:"Cairo";font-weight:800;}
      .name-arbic{font-family:"Cairo";font-weight:800;}
      .noborder{border:3px solid transparent;border-spacing:0;border-collapse: collapse;}
      .text-mutedd{position:absolute;bottom:26%;right:5%;}
      .adresseFrFooter{text-align:center;border-left:none;border-top:none}
      .h-list{
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
        justify-content: flex-start;
        border: 1px solid #FFFFFF;
        border-radius: 0.5rem;
        width:100%!important
     }
     .label {
        font-family: 'Gotham Rounded Bold';
     }
     .textarea{
        border-color: #ebebeb;
        border-radius: 8px;
        color: #000;
        font-size: 15px;
        width:250%;
        height:60px
  
     }
        .d-block {font-weight: bold}
      .page-header, .page-header-space {
        height: 150px;
      }
      .espace-label{
       margin-right:15px
      }
      .page-footer, .page-footer-space {
        height: 120px;
        margin-bottom:25px
      }
      
      .page-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
      }
      
      .page-header {
        position: fixed;
        top: 0mm;
        width: 98%;
        border-bottom: 1px solid rgb(22, 7, 7); /* for demo */
        margin-bottom:25px
      }
      

      
      table {
        width: 100%
      }
      
      .content {
        width: 100%;
        break-inside: avoid;
        align-items: center;
        justify-content: center;
      }
      
      .flex{
        display:flex;
        gap: 25px;
      }
      .notes{
        font-family: 'Gotham Rounded Bold';
        text-align: center;
        justify-content: center;
        margin-top:15px
      }
     
      
      @media print {
         thead {display: table-header-group;} 
         tfoot {display: table-footer-group;}
         
      }
    
`,
    });
  }
}
