import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Act } from "../../../entities/act.entity/act"
@Pipe({
  name: 'imageActe'
})
export class ImageActePipe implements PipeTransform {

  transform(acte: Act): string {
    return null ? `http${environment.API_SSL ? ':' : ''}://${environment.API_HOST_NAME}${environment.PORT ? ':' + environment.PORT : ''}/${environment.STORAGE_ENDPOINT}/` : 'assets/img/user-avatar.png';
  }

}
