import { Pipe, PipeTransform } from '@angular/core';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import Speciality from 'src/app/entities/user.entity/doctor.entity/speciality.entity';

@Pipe({
  name: 'specialtyFilter'
})
export class SpecialtyFilterPipe implements PipeTransform {

  transform(doctors: Array<Doctor>, speciality: Speciality | null): Array<Doctor> {
    return speciality ? (doctors || []).filter(doctor => doctor.speciality.id === speciality.id) : doctors;
  }

}
