import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthentificatedGuard } from './guards/auth.guards/authentificated.guard/authentificated.guard';
import { UnauthentificatedGuard } from './guards/auth.guards/unauthentificated.guard/unauthentificated.guard';
import { DoctorSecretarySpaceGuard } from './guards/doctor-secretary-space.guard/doctor-secretary-space.guard';
import { DoctorSpaceGuard } from './guards/doctor-space.guard/doctor-space.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'backoffice',
        loadChildren: () => import('./modules/backoffice.module/backoffice.module').then(m => m.BackOfficeModule)
      },
      {
        path: 'doctor',
        canActivate: [AuthentificatedGuard, DoctorSpaceGuard],
        loadChildren: () =>
          import('./modules/doctor.module/doctor.module').then(
            (m) => m.DoctorModule
          ),
      },
      {
        path: 'secretary',
        canActivate: [AuthentificatedGuard, DoctorSecretarySpaceGuard],
        loadChildren: () =>
          import('./modules/secretary.module/doctor-secretary.module').then(
            (m) => m.DoctorSecretaryModule
          ),
      },
      {
        path: 'patient',
        canActivate: [AuthentificatedGuard],
        loadChildren: () =>
          import('./modules/patient.module/patient.module').then(
            (m) => m.PatientModule
          ),
      },
      {
        path: 'pharmacist',
        canActivate: [AuthentificatedGuard],
        loadChildren: () =>
          import('./modules/pharmacist.module/pharmacist.module').then(
            (m) => m.PharmacistModule
          ),
      },
      {
        path: 'auth',
        canActivate: [UnauthentificatedGuard],
        loadChildren: () =>
          import('./modules/auth.module/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./modules/apps.module/apps.module').then((m) => m.AppsModule),
      },
      {
        path: 'booking',
        loadChildren: () => import('./modules/appointment-booking/appointment-booking.module').then(m => m.AppointmentBookingModule)
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
