<div class="mt-2">
  <form method="POST">
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <label for="email">Date</label>
        <datepicker [(date)]="calenderDate"></datepicker>
      </div>

      <div class="col-lg-6 col-sm-12 col-md-6">
        <label for="objet">Heure</label>
        <input
          type="text"
          id="objet"
          type="text"
          class="form-control"
          name="objet"
          placeholder="08:00"
          required
          [(ngModel)]="Heure"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-sm-12">
        <label for="message ">Motif</label>
        <select [(ngModel)]="patientAppointment.motif" name="patientPays" class="form-control">
            <option value="" selected>Choix Motif</option>
            <option *ngFor="let motif of consultationMotifs" [value]="motif.text" selected [ngStyle]="{'color':motif.color || '#820040'}"><span
                    [ngStyle]="{'color':motif.color || '#820040'}">{{motif.text}}</span></option>
        </select>
      </div>

      <div class="col-lg-4 col-sm-12">
        <label for="message ">location</label>

        <select [(ngModel)]="patientAppointment.location" name="motif" class="form-control">
          <option [value]="0">En Cabinet</option>
          <option [value]="1">A Distance</option>
        </select>
      </div>
    </div>
    <div class="button">
      <button class="btn btn-outline-info mb-3 mt-3" (click)="onSubmit()" type="submit">
        Ajoute Rendez-vous
      </button>
    </div>
  </form>
</div>
