import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { environment } from 'src/environments/environment';

const API_BASE_URL = "https://api.videosdk.live";

export interface VSDKMeeting {
  userId: string,
  meetingId: string,
  apiKey: string,
  userMeetingId: string,
  disabled: boolean,
  createdAt: string,
  updatedAt: string,
  id: string
}
@Injectable({
  providedIn: 'root'
})
export class MeetingsService extends ApiService {

  createMeeting(meeting: { id: string, duration: number }): Observable<
    {
      apiKey: string,
      webhook: {
        events: []
      },
      disabled: boolean,
      autoCloseConfig: {
        type: string,
        duration: number
      }
      createdAt: string,
      updatedAt: string,
      roomId: string,
      customRoomId: string,
      links: {
        get_room: string,
        get_session: string
      },
      id: string
    }> {

    let payload = {
      roomId: meeting.id,
      customRoomId: meeting.id,
      id: meeting.id,
      autoCloseConfig: {
        type: 'session-end-and-deactivate',
        duration: meeting.duration
      }
    }

    return this.http
      .post<any>(`https://api.videosdk.live/v2/rooms`, payload, { headers: new HttpHeaders().append("Content-Type", "application/json").append('authorization', environment.VIDEOSDK_API_TOKEN) });
  }

  validateMeeting(meetingId: string): Observable<{
    apiKey: string,
    webhook: {
      events: []
    },
    disabled: boolean,
    autoCloseConfig: {
      type: string,
      duration: number
    }
    createdAt: string,
    updatedAt: string,
    roomId: string,
    customRoomId: string,
    links: {
      get_room: string,
      get_session: string
    },
    id: string
  }> {
    return this.http.get<any>(`https://api.videosdk.live/v2/rooms/validate/${meetingId}`, { headers: new HttpHeaders().append("Content-Type", "application/json").append('authorization', environment.VIDEOSDK_API_TOKEN) })
  }

}
