import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Biology } from 'src/app/entities/biology.entity/biology';
import { BiologyConsultationItem } from 'src/app/entities/consultation.entity';
import { DoctorBiologiesService } from 'src/app/services/doctor-biologies.service/doctor-biologies.service';
import { BiologyFormComponent } from '../../biology-form.module/biology-form.component/biology-form.component';

export class ConsultationItemValidation {
  controlsValidation: Record<symbol, symbol | null> = {}
  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every(elm => elm === null)
  }
}

export class BiologyConsultationnItemValidation extends ConsultationItemValidation {
  controlsValidation: { biology: 'required' | null, note: 'required' | null, date: 'required' | 'number' | 'min' | null } = {
    biology: null,
    note: null,
    date: null
  };

  constructor(controlsValidation: { biology: 'required' | null, value: 'required' | null, note: 'required' | null, date: 'required' | 'number' | 'min' | null }) {
    super()
    this.controlsValidation = controlsValidation
  }

  static validate(biologyConsultationItem: BiologyConsultationItem): BiologyConsultationnItemValidation {
    return biologyConsultationItem.biology != undefined && biologyConsultationItem.biology.id_BIO != undefined && biologyConsultationItem.biology.id_BIO != 0 ? new BiologyConsultationnItemValidation({
      biology: null,//!biologyConsultationItem.biology?.id_BIO ? 'required' : null,
      note: null,
      value: !biologyConsultationItem.value ? "required" : null,
      date: biologyConsultationItem.date ? (!isNaN(Number(biologyConsultationItem.date)) ? (Number(biologyConsultationItem.date) < 1 ? 'min' : null) : 'number') : 'required'
    }) : new BiologyConsultationnItemValidation({ biology: null, note: null, value: null, date: null });
  }
}

@Component({
  selector: 'app-biologie-consultation-forms',
  templateUrl: './consultation-biology-form.component.html',
  styleUrls: ['./consultation-biology-form.component.css']
})
export class ConsultationBiologyFormComponent implements OnInit {

  @Input() biologyConsultationItems: Array<BiologyConsultationItem> = new Array();
  @Output() biologyConsultationItemsChange: EventEmitter<Array<BiologyConsultationItem>> = new EventEmitter();
  @Input() submitted: boolean = false;
  @Output() validation: EventEmitter<boolean> = new EventEmitter()
  @Input() defaultDate: Date = new Date()
  @ViewChild('biologyForm', { read: BiologyFormComponent, static: true }) biologyForm!: BiologyFormComponent;
  biologies: Array<any> = new Array();
  isValid(): boolean {
    return this.biologyConsultationItems.every(item => this.validateConsultationItem(item).isValid)
  }
  get groupedBiologies(): Array<{ keyName: string, items: Array<{ label: string, id: string }> }> {
    let groups: Array<string> = Array.from(this.biologies, r => r.param_Bio)
    return Array.from(groups, group => {
      let items: Array<{ label: string, id: string }> = new Array();
      this.biologies.forEach(biology => {
        if (group == biology.param_Bio) items.push({ label: biology.element_Bio, id: biology.id_BIO });
      })
      return {
        keyName: group, items: items
      };
    });
  }
  constructor(private doctorBiologiesService: DoctorBiologiesService, private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.doctorBiologiesService.all().subscribe(res => { this.biologies = Array.from(res,e=>Biology.fromResponse(e)) }, error => { this.toasterService.error('Enable to laoàd biologies') })
  }

  addPrescriptionItem(): void {
    let biologyConsultationItem: BiologyConsultationItem = new BiologyConsultationItem({})
    // biologyConsultationItem.biology = null as any;
    this.biologyConsultationItems.push(biologyConsultationItem);
    this._change();
  }

  deletePrescriptionItem(index: number): void {
    this.biologyConsultationItems.splice(index, 1);
    this._change();
  }

  validateConsultationItem(biologyConsultationItem: BiologyConsultationItem): BiologyConsultationnItemValidation {
    return BiologyConsultationnItemValidation.validate(biologyConsultationItem)
  }

  onEditPrescriptionItem(index: number): void {
    this._change();
  }

  addBiologySubmit(): void {
    this.biologyForm.isSubmitted = true;
    if (this.biologyForm.isValid) {
      this.doctorBiologiesService.add(this.biologyForm.biology).subscribe(bio => {
        this.biologies = [bio, ...this.biologies];
        this.biologyForm.biology = new Biology();
        this.biologyForm.isSubmitted = false;
        this._change();

      }, error => this.toasterService.error('Enable to add Biologie'));
    }
  }

  _change(): void {
    this.biologyConsultationItemsChange.emit(this.biologyConsultationItems);
    this.validation.emit(this.isValid())

  }
}
