<div class="row row-grid" *ngIf="doctorPrescriptions.length; else empty">
  <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="
    let patientPrescription of doctorPrescriptions | paginate: { itemsPerPage: perPage, currentPage: currentPage } ;  let i = index " style="cursor: pointer;">
    <div class="card widget-profile pat-widget-profile">
      <div class="card-body">
        <div class="pro-widget-content">
          <div class="profile-info-widget">
            <a class="booking-doc-img">
              <img [src]="patientPrescription.patient | avatarSrc" [alt]="patientPrescription.patient.getFullName()" [alt]="patientPrescription.patient.getFullName()" image />
            </a>
            <div class="profile-det-info">
              <h3><a class="label">{{patientPrescription.patient.getFullName()}}</a></h3>
              <div class="patient-details">
                <h5><b>Date Naissance:</b> {{patientPrescription.patient.birthDate|
                  date:"dd/MM/yyyy"}}</h5>
                <h5 class="mb-0">
                  <i class="feather-map-pin"></i> {{patientPrescription.patient.address}}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="patient-info">
          <ul>
            <li>Mobile <span>{{ patientPrescription.patient.mobilePhone1 || "---" }}</span></li>
            <li>Date Prescription<span>{{patientPrescription.date| date: "dd/MM/YYYY"}}</span></li>
            <!-- <li>Blood Group <span>AB+</span></li> -->
          </ul>
        </div>
        <div class="table-action">
          <a title="View prescription" [routerLink]="['patients',patientPrescription.patient.id,'prescriptions',patientPrescription.id]" class="btn btn-sm bg-info-light">
            <i class="feather-eye"> </i>
          </a>
          <a title="Modifier Prescription" [routerLink]="['patients',patientPrescription.patient.id,'prescriptions',patientPrescription.id,'edit']"
            class="btn btn-sm bg-success-light">
            <i class="feather-edit"> </i>
          </a>
          <a title="Supprimer  prescription" (click)="deletePrescription(i)" class="btn btn-sm bg-danger-light">
            <i class="feather-trash"> </i>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>
<ng-template #empty>
  <tr>
    <td colspan="8" style="text-align: center;">
      <span style="text-align: center; width: 100%;">
        Pas de Prescription !
      </span>
    </td>
  </tr>
</ng-template>
<app-pagination [pageRequest]="pageRequest" (content)="doctorPrescriptions = $event"></app-pagination>