import * as moment from "moment";

export class ConsultationCnam {
  public ap4Reeducation: string;
  public ap4Reeducationfonctionnelle: boolean;
  public ap4Reeducationphysiologique: boolean;
  public ap4Orthophonie: string;
  public ap4Orthoptie: string;
  public ap4Duree: number;
  public ap4DebutReeducation: number;
  public ap4NbSeanceSemaine: number;
  public ap4NbSeanceTotal: number;
  public ap4DemandeInitial: boolean;
  public ap4DemandeRenouvlable: boolean;
  public ap4Question1: string;
  public ap4APCI: boolean;
  public ap4AccidentTravail: boolean;
  public ap4AccidentCirculation: boolean;
  public ap4Autres: boolean;
  public ap4Question2: string;
  public ap4Question3: string;
  public ap4Question4: string;
  public ap4Question5: string;
  public ap4AutersInfo:string;
  constructor(other?: Partial<ConsultationCnam>) {
    this.ap4APCI = other?.ap4APCI || false;
    this.ap4Reeducation = other?.ap4Reeducation || "";
    this.ap4Reeducationfonctionnelle = other?.ap4Reeducationfonctionnelle || false;
    this.ap4Reeducationphysiologique = other?.ap4Reeducationphysiologique || false;
    this.ap4Orthophonie = other?.ap4Orthophonie || "";
    this.ap4Orthoptie = other?.ap4Orthoptie || "";
    this.ap4Duree = other?.ap4Duree || 0;
    this.ap4DebutReeducation = other?.ap4DebutReeducation || new Date().getTime();
    this.ap4NbSeanceSemaine = other?.ap4NbSeanceSemaine || 0;
    this.ap4NbSeanceTotal = other?.ap4NbSeanceTotal || 0;
    this.ap4DemandeInitial = other?.ap4DemandeInitial || false;
    this.ap4DemandeRenouvlable = other?.ap4DemandeRenouvlable || false;
    this.ap4AccidentTravail = other?.ap4AccidentTravail || false;
    this.ap4AccidentCirculation = other?.ap4AccidentCirculation || false;
    this.ap4Autres = other?.ap4Autres || false;
    this.ap4NbSeanceTotal = other?.ap4NbSeanceTotal || 0;
    this.ap4Question1 = other?.ap4Question1 || "";
    this.ap4Question2 = other?.ap4Question2 || "";
    this.ap4Question3 = other?.ap4Question3 || "";
    this.ap4Question4 = other?.ap4Question4 || "";
    this.ap4Question5 = other?.ap4Question5 || "";
    this.ap4AutersInfo=other?.ap4AutersInfo||""
  }
}
