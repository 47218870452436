<div class="table-responsive">
    <table class="table table-hover table-center mb-0">
        <thead>
            <tr>
                <th>Patient</th>
                <th>Date Prescription</th>
                <th>Date RDV</th>
                <th>Description RDV</th>
                <th>Medics</th>
                <th>Radiologies</th>
                <th>Biologies</th>
                <th class="text-center">Actions</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="doctorPrescriptions.length; else empty">
                <tr *ngFor="
              let patientPrescription of doctorPrescriptions | paginate: { itemsPerPage: perPage, currentPage: currentPage } ;  let i = index " style="cursor: pointer;">

                    <td style="text-align: flex-start;width: 20%;">
                        <a class="avatar avatar-sm " style="float: left;">
                            <img class="avatar-img rounded-circle" [src]="patientPrescription.patient | avatarSrc" [alt]="patientPrescription.patient.getFullName()" image />
                        </a>
                        <div style="float: right; margin: 0px;width:80%;">
                            <div>
                                <span style="font-family: 'Gotham Rounded Bold';font-weight: Bold;">{{patientPrescription.patient.getFullName()}}</span>
                            </div>
                            <div>
                                <span style="font-size: 12px;">{{patientPrescription.patient.birthDate|
                                    date:"dd/MM/yyyy"}}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        {{patientPrescription.date| date: "dd/MM/YYYY"}}
                    </td>
                    <td>
                        <span class="d-block"> {{patientPrescription.appointment.date| date:
                            "dd/MM/YYYY"}}</span>
                        <span class="apt-time"> {{patientPrescription.appointment.startTime | time }}</span>
                    </td>
                    <td>
                        {{ patientPrescription.appointment.motif }}
                    </td>

                    <td>
                        {{patientPrescription.medics.length? "Oui": "Non"}}
                    </td>
                    <td>
                        {{patientPrescription.radiologies.length? "Oui":"Non"}}
                    </td>
                    <td>
                        {{patientPrescription.biologies.length? "Oui":"Non"}}
                    </td>
                    <td>
                        <div class="table-action">
                            <a title="View prescription" [routerLink]="['patients',patientPrescription.patient.id,'prescriptions',patientPrescription.id]"
                                class="btn btn-sm bg-info-light">
                                <i class="feather-eye"> </i>
                            </a>
                            <a title="Modifier Prescription" [routerLink]="['patients',patientPrescription.patient.id,'prescriptions',patientPrescription.id,'edit']"
                                class="btn btn-sm bg-success-light">
                                <i class="feather-edit"> </i>
                            </a>
                            <a title="Supprimer  prescription" (click)="deletePrescription(i)" class="btn btn-sm bg-danger-light">
                                <i class="feather-trash"> </i>
                            </a>
                        </div>
                    </td>
                </tr>
            </ng-container>
            <ng-template #empty>
                <tr>
                    <td colspan="8" style="text-align: center;">
                        <span style="text-align: center; width: 100%;">
                            {{emptyMessage || 'Pas de Préscription !'}}
                        </span>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <app-pagination [pageRequest]="pageRequest" (content)="doctorPrescriptions = $event"></app-pagination>
</div>