import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Radiology } from 'src/app/entities/radiology.entity/radiology';

@Component({
  selector: 'app-radiology-form',
  templateUrl: './radiology-form.component.html',
  styleUrls: ['./radiology-form.component.css']
})
export class RadiologyFormComponent implements OnInit {

  @Input() radiology: Radiology = new Radiology();
  @Output() radiologyChanged: EventEmitter<Radiology> = new EventEmitter();
  @Input() isSubmitted: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  get isValid(): boolean {
    return this.radiology.nom_Radio.length > 0 && this.radiology.type_Radio != null;
  }

  _change(): void {
    this.radiologyChanged.emit(this.radiology);
  }
}
