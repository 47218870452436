import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service/api.service';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../core/entities/api-response.entity/api-response';
import { environment } from 'src/environments/environment';
import { Biology } from 'src/app/entities/biology.entity/biology';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DoctorBiologieService extends ApiService {
  ownedSearch(searchQuery: { query: string; }, pageRequest: PageRequest) {
    if (pageRequest) {
      let params = new HttpParams();
      if (searchQuery.query.length != 0) params = params.set('q', searchQuery.query);
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/biologies/owned/search`, { params: params }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/biologies/owned/all/search`).pipe(this.responsePipe)
  }

  owned(pageRequest: PageRequest) {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/biologies/owned`, { params: params }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/biologies/owned/all`).pipe(this.responsePipe)
  }

  add(data: Biology): Observable<Biology> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/biologies`, {
      element_Bio: data.element_Bio,
      indication_Bio: data.indication_Bio,
      norme_Bio: data.norme_Bio,
      param_Bio: data.param_Bio
    }, { responseType: 'json' }).pipe(this.responsePipe)
  }

  all(pageRequest?: PageRequest): Observable<any> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/biologies`, { params: params }).pipe(this.responsePipe)
    } else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/biologies/all`).pipe(this.responsePipe)
  }
  search(searchQuery: { query: string; }, pageRequest?: PageRequest) {
    let params = new HttpParams();
    if (searchQuery.query.length != 0) params = params.set('q', searchQuery.query);
    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/biologies/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }
  getOneActeDoctor(BiologieId: string): Observable<Biology> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/biologies/${BiologieId}`, { responseType: 'json' }).pipe(this.responsePipe)
  }
  deleteBiologie(BiologieId: number): Observable<Biology> {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/biologies/${BiologieId}`, { responseType: 'json' }).pipe(this.responsePipe)
  }
  updateBiologie(BiologieId: string, data: Biology): Observable<Biology> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/biologies/${BiologieId}`, {
      element_Bio: data.element_Bio,
      indication_Bio: data.indication_Bio,
      norme_Bio: data.norme_Bio,
      param_Bio: data.param_Bio
    }, { responseType: 'json' }).pipe(this.responsePipe)

  }
}
