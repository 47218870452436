<div class="calender-container">
    <div class="calender-head">
        <div *ngFor="let dayInWeek of DAYS_IN_WEEK;let i = index" class="calender-day-head" [ngClass]="{'active':currentDayIndex == (i+1)%7}" (click)="currentDayIndex = (i+1)%7">
            <span class="day font-weight-bold">{{dayInWeek}}</span>
        </div>
    </div>
    <scrollbar>
        <div class="calender-content">
            <ng-container *ngIf="dayTimeSlots.length>0;else emptyDay">
                <div *ngFor="let slot of dayTimeSlots" class="slot">
                    <div class="calender-day-item" [ngClass]="{'selected':selectedTimeSlot?.id == slot.id}" (click)="onSelectedTimeSlot(slot)">
                        <a class="timing" style="cursor: pointer;">
                            {{slot.startTime | date:'HH:MM' }} vers {{slot.endTime | date:'HH:MM'}}
                        </a>
                        <span class="badge"></span>
                    </div>
                </div>
            </ng-container>
            <ng-template #emptyDay>
                <div class="calender-day-content">
                    <div class="calender-day-item" style="border:none">
                        <a class="timing" style="cursor: pointer;">---</a>
                    </div>
                </div>
            </ng-template>
        </div>
    </scrollbar>
</div>