import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform<T>(list: Iterable<T>, options: { key?: keyof T, direction?: 'asc' | 'dsc' }): Iterable<T> {
    return Array.from(list).sort((a, b) => {
      if (options.key) return a[options.key] > b[options.key] ? options.direction == 'asc' ? -1 : 1 : options.direction == 'asc' ? 1 : -1;
      else return a > b ? options.direction == 'asc' ? -1 : 1 : options.direction == 'asc' ? 1 : -1;
    })
  }
}
