import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-lunette',
  templateUrl: './input-lunette.component.html',
  styleUrls: ['./input-lunette.component.css']
})
export class InputLunetteComponent implements OnInit {
  @Input() value: number | any = 0
  @Output() valueChange: EventEmitter<number> = new EventEmitter()
  constructor() { }

  ngOnInit(): void { }

  _change(event: any) {
    this.value = event.replace(',', '.')
    this.valueChange.emit(this.value)
  }
}
