import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsultationOphtalmo } from 'src/app/entities/consultation.entity/consultation-ophtalmo.entity/consultation-ophtalmo';

@Component({
  selector: 'app-consultation-lacer',
  templateUrl: './consultation-lacer.component.html',
  styleUrls: ['./consultation-lacer.component.css']
})
export class ConsultationLacerComponent implements OnInit {
  @Input() consultation: ConsultationOphtalmo = new ConsultationOphtalmo
  @Output() consultationChange: EventEmitter<ConsultationOphtalmo> = new EventEmitter
  constructor() { }

  ngOnInit(): void {
  }

}
