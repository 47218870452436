import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './paginator.component/paginator.component';
import { PageDirective } from './page.directive/page.directive';



@NgModule({
  declarations: [
    PaginatorComponent,
    PageDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PaginatorComponent,
    PageDirective
  ]
})
export class PaginatorModule { }
