import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MedicPrescription } from 'src/app/entities/prescription.entity/medic-prescription.entity/medic-prescription';

@Component({
  selector: 'app-prescription-dropdown',
  templateUrl: './prescription-dropdown.component.html',
  styleUrls: ['./prescription-dropdown.component.css']
})
export class PrescriptionDropdownComponent implements OnInit {
  @Input() medicaments: Array<MedicPrescription> = new Array()
  @Output() medicamentsChange: EventEmitter<Array<MedicPrescription>> = new EventEmitter
  constructor() { }

  ngOnInit(): void {

  }
  _change(): void {

    this.medicamentsChange.emit(this.medicaments);
  }


}
