import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VideoSDK } from '@videosdk.live/js-sdk';
import { Meeting } from '@videosdk.live/js-sdk/meeting';
import { MeetingsService } from 'src/app/services/meeting.service/meetings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.css']
})
export class VideoCallComponent implements AfterViewInit {

  @Input() webcamEnabled: boolean = true;
  @Input() micEnabled: boolean = true;
  @Input() title: string = 'Video call';
  @Input() participant: { name: string, id: string } = { id: "", name: "" };
  @ViewChild('localVideo', { read: ElementRef, static: false }) localVideo!: ElementRef;
  @ViewChild('remoteVideo', { read: ElementRef, static: false }) remoteVideo!: ElementRef;
  @ViewChild('remoteAudio', { read: ElementRef, static: false }) remoteAudio!: ElementRef;
  @Output() end: EventEmitter<void> = new EventEmitter();
  @Output() leave: EventEmitter<void> = new EventEmitter();
  @Input() meetingId!: string;
  meeting!: Meeting | null;

  currentSelectedTool: number = 0;
  toggledVideoTools: boolean = false;

  private localMediaStream?: MediaStream;
  constructor(private meetingsSerivce: MeetingsService) { }
  async initMeeting() {
    VideoSDK.config(environment.VIDEOSDK_API_TOKEN);
    this.meeting = VideoSDK.initMeeting({
      token: environment.VIDEOSDK_API_TOKEN,
      meetingId: this.meetingId, // required
      name: this.participant.name, // required
      micEnabled: true, // optional, default: true
      webcamEnabled: true, // optional, default: true
      maxResolution: "hd", // optional, default: "hd",
      participantId: this.participant.id,
      chatEnabled: true
    });
    this.registerParticipantEvents();
    this.join();
  }

  ngAfterViewInit(): void {
    //this.initMeeting()
  }

  join() {
    this.meeting!.join();
  }

  onEnd() {
    this.meeting?.end();
    this.end.emit();
  }

  registerParticipantEvents() {
    this.meeting!.on("meeting-joined", () => {
      this.meeting!.localParticipant.on("stream-enabled", (stream: any) => {
        if (stream.kind == "video") this.createVideoElement(stream, this.meeting!.localParticipant, this.localVideo);
      })
    })
    this.meeting!.on("participant-joined", (participant) => {
      participant.on("stream-enabled", (stream: any) => {
        if (stream.kind == "audio") this.createAudioElement(stream, participant, this.remoteAudio);
        if (stream.kind == "video") this.createVideoElement(stream, participant, this.remoteVideo);
      })
    })
  }

  createAudioElement(stream: any, participant: any, remoteAudio: ElementRef) {
    if (participant.id == this.meeting!.localParticipant.id) return;
    const mediaStream = new MediaStream();
    mediaStream.addTrack(stream.track);
    remoteAudio.nativeElement.srcObject = mediaStream;
  }

  createVideoElement(stream: any, participant: any, remoteVideo: ElementRef) {
    const mediaStream = new MediaStream();
    mediaStream.addTrack(stream.track);
    remoteVideo.nativeElement.srcObject = mediaStream;
  }

  getKeys(map: any) {
    return Array.from(map.keys());
  }

  toggleAudioEnable() {
    this.micEnabled ? this.meeting!.localParticipant.disableMic() : this.meeting!.localParticipant.enableMic();
    this.micEnabled = !this.micEnabled;
  }

  toggleVideoEnable() {
    this.webcamEnabled ? this.meeting!.localParticipant.disableWebcam() : this.meeting!.localParticipant.enableWebcam();
    this.webcamEnabled = !this.webcamEnabled;
  }

  onSelectedTool(currentSelectedToolIndex: number) {
    this.toggledVideoTools = this.toggledVideoTools ? (this.currentSelectedTool != currentSelectedToolIndex) : true;
    this.currentSelectedTool = currentSelectedToolIndex;
  }

  toggleVideoTools() {
    this.toggledVideoTools = !this.toggledVideoTools;
    if (this.toggledVideoTools) this.currentSelectedTool = 0;
  }
}
