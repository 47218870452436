import { Component, Input, OnInit } from '@angular/core';
import { DoctorPatient } from 'src/app/entities/doctor-patient.entity/doctor-patient';
import { EUserGender, User } from 'src/app/entities/user.entity';
import { BMIUtils } from 'src/app/utils/bmi.utils';

@Component({
  selector: 'app-bmi-chart',
  templateUrl: './bmi-chart.component.html',
  styleUrls: ['./bmi-chart.component.css']
})
export class BMIChartComponent implements OnInit {

  @Input() height: number = 0;
  @Input() weight: number = 0;
  @Input() old: number = 0;
  @Input() gender: EUserGender = EUserGender.HOMME;
  get coordinations(): { x: number, y: number } {
    return { x: this.old, y: (this.bmi - 10) }
  }

  get bmi(): number {
    return BMIUtils.calculateBMI(this.height, this.weight);
  }

  xStep: number = 5.555555555555556;
  yStep: number = 3.333333333333333;

  constructor() { }

  ngOnInit(): void {
  }

  get yearsOld(): number {
    return Math.floor((this.old * 12) / 12)
  }

  get monthsOld(): number {
    return Math.floor((this.old * 12) / 12)
  }
}
