import { Component, Input, OnInit, Output, EventEmitter, ViewChild, forwardRef, DoCheck } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => DatePickerComponent)
  }]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {

  @Input() prepend!: string;
  @Input() append!: string;

  @Input() separator: string = '/';
  @Input() prependHtml!: string;
  @Input() appendHtml!: string;

  @Input() required: boolean = false;
  @Input() disabled: boolean = false;


  @Input() value: Date | null = null;
  @Input() min: Date | null = null;
  @Input() max: Date | null = null;
  @Input() submitted: boolean = false;
  /**@deprecated */
  @Input() format!: string;

  /**@deprecated */
  private get _dateformat(): string {
    return this.format || `DD${this.separator}MM${this.separator}YYYY`;
  }
  @Output() change: EventEmitter<Date | null> = new EventEmitter();
  _strDate = '';

  onChange!: (date: Date | null) => void;

  constructor() { }

  writeValue(date: Date | null): void {
    if (date && moment(date).isValid()) {
      this.value = (!isNaN(new Date(date).getTime())) ? new Date(date) : null;
      this._strDate = moment(new Date(date)).format(`DD${this.separator}MM${this.separator}YYYY`);
    } else {
      this.value = null;
      this._strDate = '';
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.writeValue(this.value)
  }

  _onInputChange(str: string): void {
    if ((str.length == 2 && str.split(this.separator).length <= 1) || (str.length == 5 && str.split(this.separator).length <= 2))
      this._strDate += this.separator;

    const newValue = this.isValid ? new Date(Number(this._strDate.split(this.separator)[2]), Number(this._strDate.split(this.separator)[1]) - 1, Number(this._strDate.split(this.separator)[0]), 0, 0) : null;

    if (newValue?.getTime() != this.value?.getTime()) {
      this.value = newValue;
      this.onChange && this.onChange(this.value);
      this.change.emit(this.value)
    }
  }

  _onInputKeyDown($event: any): void {
    const key = $event.key;
    const position = $event.target.selectionStart;
    let str: string = $event.target.value;
    if ((isNaN(key) || str.length + 1 > 10) && ![39, 37, 8].includes($event.keyCode)) $event?.preventDefault();
  }



  private __validate(pattern: string): boolean {
    if (pattern.split(this.separator).length != 3) return false;
    const days: any = pattern.split(this.separator)[0];
    const months: any = pattern.split(this.separator)[1];
    const years: any = pattern.split(this.separator)[2];
    return ((!isNaN(years) && !isNaN(days) && !isNaN(months)) && (Number(years) >= 1000 && (Number(days) > 0 && Number(days) <= 31) && (Number(months) > 0 && Number(months) <= 12)))
    // return moment(pattern, this._dateformat).isValid()
  }

  get isValid(): boolean {
    return this.__validate(this._strDate)
  }
  /*_onInputChange(event: any): void {
    this.value = this.isValid ? new Date(Number(this._strDate.split(this.separator)[2]), Number(this._strDate.split(this.separator)[1]) - 1, Number(this._strDate.split(this.separator)[0])) : null;
    this.onChange(this.value);
    this.change.emit(this.value)
  }*/

  _onModalRequest(): void {

  }

  clear() {
    this.writeValue(null);
    this.onChange(null)
  }
}