import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RadioPrescription } from 'src/app/entities/prescription.entity/radio-prescription.entity/radio-prescription';
import { Radiology } from 'src/app/entities/radiology.entity/radiology';
import { RadiologiesService } from 'src/app/services/radiologies.service/radiologies.service';
import { RadiologyFormComponent } from '../../radiology-form.module/radiology-form.component/radiology-form.component';
import { RadioFilter } from '../../radiology-form.module/radio-select-drawer/radio-select-drawer.component';

export class PrescriptionItemValidation {
  controlsValidation: Record<symbol, symbol | null> = {}
  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every(elm => elm === null)
  }
}

export class RadioPrescriptionValidation extends PrescriptionItemValidation {
  controlsValidation: { radiology: 'required' | null, notes: 'required' | null } = {
    radiology: null,
    notes: null
  };

  constructor(controlsValidation: { radiology: 'required' | null, notes: 'required' | null }) {
    super()
    this.controlsValidation = controlsValidation
  }

  static validate(radioPrescription: RadioPrescription): any {
    return radioPrescription.radio != undefined && radioPrescription.radio.id_RADIO != undefined && radioPrescription.radio.id_RADIO != '' ? new RadioPrescriptionValidation({
      radiology: null,//!(String(radioPrescription.radio.id_RADIO)).length ? 'required' : null,
      notes: null//!radioPrescription.note ? 'required' : null,
    }) : new RadioPrescriptionValidation({ radiology: null, notes: null });
  }
}


@Component({
  selector: 'app-radiology-prescription-form',
  templateUrl: './radiology-prescription-form.component.html',
  styleUrls: ['./radiology-prescription-form.component.css']
})
export class RadiologyPrescriptionFormComponent implements OnInit {
  @Input() submitted: Boolean = false;
  @Input() radiologyPrescriptionItems: Array<RadioPrescription> = new Array();
  @Output() radiologyPrescriptionItemsChange: EventEmitter<Array<RadioPrescription>> = new EventEmitter();
  @Output() validation: EventEmitter<boolean> = new EventEmitter()
  @ViewChild('radiologyForm', { read: RadiologyFormComponent, static: true }) radiologyForm!: RadiologyFormComponent;
  Radiologies: Array<Radiology> = new Array();

  radiosFilter: Array<RadioFilter> = new Array({radioPrescription:new RadioPrescription(),selected:false});

  radiologie: string = "";

  drawerActive: boolean = false;

  isValid(): boolean {
    return this.radiologyPrescriptionItems.every(item => this.validatePrescriptionItem(item).isValid)
  }
  @Input() radiologies: Array<any> = new Array();

  constructor(private radiologiesService: RadiologiesService, private toasterService: ToastrService) { }

  get groupedRadiologies(): Array<{ keyName: string, items: Array<{ label: string, id: string }> }> {
    let groups: Array<string> = this.radiologies.map(item => item.type_Radio).filter((item, index, self) => self.indexOf(item) === index)

    return Array.from(groups, group => {
      let items: Array<{ label: string, id: string }> = new Array();
      this.radiologies.forEach(radio => {
        if (group == radio.type_Radio) items.push({ label: radio.nom_Radio, id: radio.id_RADIO });
      })
      return {
        keyName: group, items: items
      };
    });
  }
  ngOnInit(): void {
    this.radiologiesService.all().subscribe(radiologies => this.radiologies = radiologies, error => this.toasterService.error('Enable to load radiologies'))
  }

  addPrescriptionItem(): void {
    this.radiologyPrescriptionItems.push(new RadioPrescription({}));
    this.radiologyPrescriptionItemsChange.emit(this.radiologyPrescriptionItems);
    this.validation.emit(this.isValid())

  }

  deletePrescriptionItem(index: number): void {
    this.radiologyPrescriptionItems.splice(index, 1);
    this.radiologyPrescriptionItemsChange.emit(this.radiologyPrescriptionItems);
    this.validation.emit(this.isValid())

  }
  onEditPrescriptionItem(index: number): void {
    this.validation.emit(this.isValid())
  }
  validatePrescriptionItem(radiologyPrescriptionItem: RadioPrescription): RadioPrescriptionValidation {
    return RadioPrescriptionValidation.validate(radiologyPrescriptionItem)
  }

  addRadiologySubmit(): void {
    this.radiologyForm.isSubmitted = true;
    if (this.radiologyForm.isValid) this.radiologiesService.add(this.radiologyForm.radiology).subscribe(radio => {
      this.radiologies = [radio, ...this.radiologies];
      this.radiologyForm.radiology = new Radiology();
      this.radiologyForm.isSubmitted = false;
    }, error => this.toasterService.error('Enable to add radiologie'))
  }
  closed(): void {
    this.radiosFilter.map((elm) => {
      
      return this.radiologyPrescriptionItems.push(elm.radioPrescription);
    });
    // Étape 1: Utiliser reduce pour compter les occurrences des objets biologie
    const countRadio = this.radiosFilter.reduce((acc, obj) => {
      const radioKey = JSON.stringify(obj.radioPrescription.radio);
      acc[radioKey] = (acc[radioKey] || 0) + 1;
      return acc;
    }, {});

    // Étape 2: Utiliser filter pour sélectionner les objets dont biologie apparaît plus d'une fois
    const biologies = this.radiosFilter.filter((obj, index, self) => {
      const radioKey = JSON.stringify(obj.radioPrescription.radio);
      return (
        countRadio[radioKey] > 1 &&
        self.findIndex((o) => this.deepEqual(o.radioPrescription.radio, obj.radioPrescription.radio)) === index
      );
    });
  

    this.radiosFilter = biologies;
    const countRadioItem = this.radiologyPrescriptionItems.reduce((acc, obj) => {
      const radioKey = JSON.stringify(obj.radio);
      acc[radioKey] = (acc[radioKey] || 0) + 1;
      return acc;
    }, {});

    // Étape 2: Utiliser filter pour sélectionner les objets dont biologie apparaît plus d'une fois
    const biologiesitem = this.radiologyPrescriptionItems.filter((obj, index, self) => {
      const radioKey = JSON.stringify(obj.radio);
      return (
        countRadioItem[radioKey] > 1 &&
        self.findIndex((o) => this.deepEqual(o.radio, obj.radio)) === index
      );
    });
    this.radiologyPrescriptionItems=this.radiologyPrescriptionItems.filter(elm=>elm.radio.nom_Radio!=="")   
    this.drawerActive = false;
  }
  deepEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  _change(): void {
    this.radiologyPrescriptionItemsChange.emit(this.radiologyPrescriptionItems)
  }
}
