import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DoctorAct } from 'src/app/entities/act.entity/act';

@Component({
  selector: 'app-act-form',
  templateUrl: './doctor-act-form.component.html',
  styleUrls: ['./doctor-act-form.component.css']
})
export class DoctorActFormComponent implements OnInit {
  @Input() doctorAct: DoctorAct = new DoctorAct();
  @Output() doctorActChange: EventEmitter<DoctorAct> = new EventEmitter();
  isSubmitted: boolean = false;
  infoCnam:boolean=false;
  get isValid(): boolean {
    return !isNaN(this.doctorAct.montant) && this.doctorAct.actes.desc_Acte.length > 0 ;
  }

  constructor() { }

  ngOnInit(): void {

  }
  _change(): void {
    this.doctorActChange.emit(this.doctorAct)
  }

}
