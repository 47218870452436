<div class="duration-input">
    <ng-container *ngIf="inputs.years">
        <input [(ngModel)]="_duration.years" (ngModelChange)="emitChange()" type="number" min="0">
        <span>Annees</span>
    </ng-container>
    <ng-container *ngIf="inputs.months">
        <input [(ngModel)]="_duration.months" (ngModelChange)="emitChange()" type="number" min="0" max="12">
        <span>Mois</span>
    </ng-container>
    <ng-container *ngIf="inputs.days">
        <input [(ngModel)]="_duration.days" (ngModelChange)="emitChange()" type="number" min="0" max="365">
        <span>Jours</span>
    </ng-container>
    <ng-container *ngIf="inputs.hours">
        <input [(ngModel)]="_duration.hours" (ngModelChange)="emitChange()" type="number" min="0" max="23">
        <span>Heures</span>
    </ng-container>
    <ng-container *ngIf="inputs.minutes">
        <input [(ngModel)]="_duration.minutes" (ngModelChange)="emitChange()" type="number" min="0" max="59">
        <span>Minutes</span>
    </ng-container>
    <ng-container *ngIf="inputs.seconds">
        <input [(ngModel)]="_duration.seconds" (ngModelChange)="emitChange()" type="number" min="0" max="59">
        <span>Seconds</span>
    </ng-container>
    <ng-container *ngIf="inputs.millseconds">
        <input [(ngModel)]="_duration.millseconds" (ngModelChange)="emitChange()" type="number" min="0" max="9999">
        <span>Milli seconds</span>
    </ng-container>
</div>