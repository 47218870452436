<div class="h-list">
    <ng-container *ngIf="doctorPatientAntecedents && antcedents.length;else empty">
        <div *ngFor="let patientAntecedent of antcedents" class="antecedent-card">
            <h4>{{patientAntecedent.label}}</h4>
            <textarea [value]="patientAntecedent.text" disabled></textarea>
        </div>
    </ng-container>
    <ng-template #empty>
        <span>Aucun antecedant</span>
    </ng-template>
</div>