<!-- Prescription Item -->
<div class="card card-table">
    <div class="card-body">
        <div class="prescription-items-container">
            <table class="table table-hover table-center">
                <thead>
                    <tr>
                        <th>Médicament (Saisir les 2 premières lettres)<button class="openDialoge" [title]="'Ajouter Medics'" (click)="drawerActive = !drawerActive">
                            <i class="fas fa-capsules"></i>    
                        </button></th>
                        <th>Posologie </th>
                        <th>Durée</th>
                        <th class="text-center">Unité</th>
                        <th>Note</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let medicPrescriptionItem of medicPrescriptionItems;let i = index"
                        [ngStyle]="{'border':!validatePrescriptionItem(medicPrescriptionItem).isValid && submitted?'1px solid red':'None'}">
                        <td style="min-width: 150px">
                            <!--<app-autocomplete-input [data]="medics" [keyword]="'nom'"
                                    (valueChange)="onEditPrescriptionItem(i)" [(value)]="medicPrescriptionItem.medic">
                                </app-autocomplete-input>-->
                            <app-medic-select-dropdown [(value)]="medicPrescriptionItem.medic" (valueChange)="_change()" [placeHolder]="'Chercher Médicament'">
                            </app-medic-select-dropdown>

                            <!-- <div
                                *ngIf="validatePrescriptionItem(medicPrescriptionItem).controlsValidation['medic'] && submitted">
                                <span
                                    *ngIf="validatePrescriptionItem(medicPrescriptionItem).controlsValidation['medic'] == 'required'"
                                    style="color: red"> Medicament est obligatoire </span>
                                <span
                                    *ngIf="validatePrescriptionItem(medicPrescriptionItem).controlsValidation['medic'] == 'notExisting'"
                                    style="color: red"> Nom Médicament Invalide </span>
                            </div> -->
                        </td>

                        <td style="max-width: 150px;">
                            <input class="form-control" type="text" min="1" value="1"  (ngModelChange)="_change()" (input)="onEditPrescriptionItem(i)"
                                [(ngModel)]="medicPrescriptionItem.posology">
                            <div *ngIf="validatePrescriptionItem(medicPrescriptionItem).controlsValidation['posology'] == 'required'  && submitted">
                                <span style="color: red"> Posologie est obligatoire </span>
                            </div>
                        </td>
                        <td style="max-width: 90px;">
                            <input class="form-control" type="number" min="1" value="1" (ngModelChange)="_change()" [(ngModel)]="medicPrescriptionItem.duration"
                                (input)="onEditPrescriptionItem(i)">
                            <div *ngIf="validatePrescriptionItem(medicPrescriptionItem).controlsValidation.duration  && submitted">
                                <span *ngIf="validatePrescriptionItem(medicPrescriptionItem).controlsValidation.duration == 'required'" style="color: red"> Durée est obligatoire
                                </span>
                                <span *ngIf="validatePrescriptionItem(medicPrescriptionItem).controlsValidation.duration == 'number'" style="color: red"> Durée doit etre Numérique
                                </span>
                                <span *ngIf="validatePrescriptionItem(medicPrescriptionItem).controlsValidation.duration == 'min'" style="color: red"> Durée doit etre > 0 </span>
                            </div>
                        </td>
                        <td>
                            <div class="row" style="width: 100%;">
                                <div class="col-lg-3 col-sm-12 col-md-4">
                                  <input type="radio" [(ngModel)]="medicPrescriptionItem.unit" (ngModelChange)="_change()"  [name]="'unit'[i]" [value]="0" class="mx-1" />
                                  <label for="jours">Jours</label>
                                </div>
                                <div class="col-lg-3 col-sm-12 col-md-4">
                                  <input  type="radio"  [(ngModel)]="medicPrescriptionItem.unit" [name]="'unit'[i]" [value]="1" (ngModelChange)="_change()" class="mx-1" />
                                  <label for="unit">Semaines</label>
                                </div>
                                <div class="col-lg-3 col-sm-12 col-md-4">
                                  <input  type="radio"  [(ngModel)]="medicPrescriptionItem.unit" [name]="'unit'[i]" [value]="2" (ngModelChange)="_change()" class="mx-1" />
                                  <label for="unit">Mois</label>
                                </div>
                              </div>
                        </td>
                        <td>
                            <textarea class="form-control" style="height: 45px;" [(ngModel)]="medicPrescriptionItem.note" (ngModelChange)="_change()"></textarea>
                        </td>
                        <td>
                            <a href="javascript:void(0)" class="btn bg-danger-light trash" (click)="deletePrescriptionItem(i)"><i class="feather-trash-2"></i></a>
                        </td>
                    <tr>
                        <td colspan="5">
                            <div class="add-more-item text-center">
                                <a href="javascript:void(0);" (click)="addPrescriptionItem()"><i class="feather-plus-circle"></i>Ajouter</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- /Prescription Item -->

<app-modal [title]="'Ajouter Nouveau Médicament'" #dialog>
    <app-medic-form #medicForm (ngModelChange)="_change()"></app-medic-form>
    <button class="btn btn-outline-info mb-3 mt-3" (click)="onAddMedic()">Ajouter Médicament</button>

</app-modal>

<app-drawer [active]="drawerActive" [title]="'Ajouter Medics'">
    <div body style="height: 80%;">
        <app-medic-select-drawer  [(values)]="medics"
        (valuesChange)="_change()" (closed)="closed()" [(medicsSelected)]="medicsFilter"></app-medic-select-drawer>
      <!-- <app-doctor-patient-historics *ngIf="consultation.appointment.patient.id.length" [patientId]="consultation.appointment.patient.id"></app-doctor-patient-historics> -->
    </div>
  </app-drawer>