<div class="card card-table">
    <div class="card-body">
        <div class="prescription-items-container">
            <table class="table table-hover table-center">
                <thead>
                    <tr>
                        <th style="width: min-content;">label </th>
                        <th style="width: 60%;">Text </th>
                        <th style="max-width: 80px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fillingsItme of fillingsItmes;let i = index">
                        <td style="width:41%">
                            <textarea class="form-control" style="height: 45px" 
                         [(ngModel)]="fillingsItme.label"   ></textarea>
                        </td>
                        <td>
                            <textarea class="form-control" style="height: 45px" 
                          [(ngModel)]="fillingsItme.textContent"      ></textarea>
                             

                        </td>
                        <td>
                            <a href="javascript:void(0)" class="btn bg-danger-light trash"
                             (click)="deleteFillingsItem(i)"  ><i class="feather-trash-2">

                                </i>
                            </a>
                              
                        </td>
                        
                    <tr>
                        <td colspan="5">
                            <div class="add-more-item text-center">
                                <a href="javascript:void(0);" (click)="addFillingsItem()"><i
                                        class="feather-plus-circle" ></i> Ajouter</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>