import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { EAppointmentState } from 'src/app/entities/appointment.entity';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { ConsultationMotif } from 'src/app/entities/consultation.entity/consultation-motif.entity/consultation-motif';
import { EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { Time } from 'src/app/utils/date.util';

@Component({
  selector: 'app-rdv-urgence-forms',
  templateUrl: './rdv-urgence-forms.component.html',
  styleUrls: ['./rdv-urgence-forms.component.css']
})
export class RdvUrgenceFormsComponent implements OnInit {
  calenderDate: Date = new Date();
  Heure:string="08:00"
  @Input()  consultationMotifs: Array<ConsultationMotif> = new Array();
 @Input() patientAppointment: PatientAppointment = new PatientAppointment({ status: EAppointmentState.PENDING });
 @Output() patientAppointmentChange:EventEmitter<PatientAppointment>=new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(){
this.patientAppointment.duration=new Time(parseInt(this.Heure)).getTime();
this.patientAppointment.motif;

    
  }
}
