import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from 'src/app/common/common.module';
import { AppointmentBookingComponent } from './appointment-booking.component/appointment-booking.component';

import { RouterModule } from '@angular/router';
import { AppointmentFormModule } from '../appointment-form.module/appointment-form.module';
import { AppointmentBookingCalenderComponent } from './appointment-booking-calender.component/appointment-booking-calender.component';
import { AppointmentBookingPaymentComponent } from './appointment-booking-payment.component/appointment-booking-payment.component';
import { AppointmentBookingVerificationComponent } from './appointment-booking-verification/appointment-booking-verification.component';
import { AppointmentPatientLoginComponent } from './appointment-patient-login.component/appointment-patient-login.component';
import { AppointmentPatientVerificationComponent } from './appointment-patient-verification/appointment-patient-verification.component';
import { AppointmentSuccessBookingComponent } from './appointment-success-booking.component/appointment-success-booking.component';

@NgModule({
  declarations: [
    AppointmentBookingComponent,
    AppointmentPatientVerificationComponent,
    AppointmentPatientVerificationComponent,
    AppointmentPatientLoginComponent,
    AppointmentSuccessBookingComponent,
    AppointmentBookingCalenderComponent,
    AppointmentBookingPaymentComponent,
    AppointmentBookingVerificationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppCommonModule,
    AppointmentFormModule,
  ],
  exports: [
    AppointmentBookingComponent,
    AppointmentPatientVerificationComponent,
    AppointmentPatientVerificationComponent,
    AppointmentPatientLoginComponent,
    AppointmentSuccessBookingComponent,
    AppointmentBookingCalenderComponent,
    AppointmentBookingPaymentComponent,
    AppointmentBookingVerificationComponent,
  ]
})
export class AppointmentBookingModule { }
