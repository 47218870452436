import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from 'src/app/common/common.module';
import { RadiologyFormComponent } from './radiology-form.component/radiology-form.component';
import { RadiologySelectDropdownComponent } from './radiology-select-dropdown.component/radiology-select-dropdown.component';
import { RadioSelectDrawerComponent } from './radio-select-drawer/radio-select-drawer.component';

@NgModule({
    declarations: [
        RadiologyFormComponent,
        RadiologySelectDropdownComponent,
        RadioSelectDrawerComponent
    ],
    imports: [
        AppCommonModule,
        FormsModule,
        ReactiveFormsModule
    ], exports: [
        RadiologyFormComponent,
        RadiologySelectDropdownComponent,
        RadioSelectDrawerComponent
    ]
})
export class RadiologyFormModule { }
