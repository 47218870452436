import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-word-viewer',
  templateUrl: './word-viewer.component.html',
  styleUrls: ['./word-viewer.component.css']
})
export class WordViewerComponent implements OnInit {

  @Input() src: string = '';

  ngOnInit(): void {
  }
}