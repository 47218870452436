import { Component, OnInit } from "@angular/core";
import { DoctorDashboardService } from "src/app/services/doctor-dashboard.service/doctor-dashboard.service";

@Component({
  selector: "app-rounder-patient",
  templateUrl: "./rounder-patient.component.html",
  styleUrls: ["./rounder-patient.component.css"],
})
export class RounderPatientComponent implements OnInit {
  // round-progress-bar
  numberCurrentPatient: number = 100;
  numberMoisPrePatient: number = 100;
  stroke: number = 15;
  radius: number = 70;
  semicircle: boolean = false;
  rounded: boolean = false;
  responsive: boolean = false;
  clockwise: boolean = true;
  color: string = "#820040"; //'#45ccce';
  background: string = "#eaeaea";
  duration: number = 800;
  animation: string = "easeOutCubic";
  animationDelay: number = 0;
  animations: Array<string> = [
    "linearEase",
    "easeInQuad",
    "easeOutQuad",
    "easeInOutQuad",
    "easeInCubic",
    "easeOutCubic",
    "easeInOutCubic",
    "easeInQuart",
    "easeOutQuart",
    "easeInOutQuart",
    "easeInQuint",
    "easeOutQuint",
    "easeInOutQuint",
    "easeInSine",
    "easeOutSine",
    "easeInOutSine",
    "easeInExpo",
    "easeOutExpo",
    "easeInOutExpo",
    "easeInCirc",
    "easeOutCirc",
    "easeInOutCirc",
    "easeInElastic",
    "easeOutElastic",
    "easeInOutElastic",
    "easeInBack",
    "easeOutBack",
    "easeInOutBack",
    "easeInBounce",
    "easeOutBounce",
    "easeInOutBounce",
  ];
  gradient: boolean = false;
  realCurrent: number = 0;

  getOverlayStyle() {
    const isSemi = this.semicircle;
    const transform = (isSemi ? "" : "translateY(-50%) ") + "translateX(-50%)";

    return {
      top: isSemi ? "auto" : "50%",
      bottom: isSemi ? "5%" : "auto",
      left: "50%",
      transform,
      "font-size": this.radius / 3.5 + "px",
    };
  }
  constructor(private doctorDashboardService: DoctorDashboardService) {}

  ngOnInit(): void {
    this.getProgressPatients();
  }
  dateMoisPrescrend(): number {
    const dateActuelle = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    const dateFinSemaine = new Date(dateActuelle);
    const startDate = dateFinSemaine.setTime(dateFinSemaine.setMonth(dateActuelle.getMonth() - 1));
    const debutMois = new Date(startDate);
    const deboutMoisPresc = new Date(debutMois.getFullYear(), debutMois.getMonth(), 1).getTime();
    return deboutMoisPresc;
  }
  dateMoisActuel(): number {
    const endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime();

    return endDate;
  }
  getProgressPatients() {
    const curentMois = this.dateMoisActuel();
    const moisPres = this.dateMoisPrescrend();
    this.doctorDashboardService.projectionPatients(moisPres, curentMois).subscribe((res) => {
      let patients = res;
      this.numberCurrentPatient = patients[0].pCount;
      patients[1]==undefined?this.numberMoisPrePatient=0:this.numberMoisPrePatient = patients[1].pCount;
    });
  }
}
