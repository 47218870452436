import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from 'src/app/common/pipes/filter.pipe/filter.pipe';
import { Medic } from 'src/app/entities/medic.entity/medic';
import { MedicsService } from 'src/app/services/medics.service/medics.service';
import { MedicFormComponent } from '../../medic-form.component/medic-form.component';

@Component({
  selector: 'app-medic-checkbox-component',
  templateUrl: './medic-checkbox-component.component.html',
  styleUrls: ['./medic-checkbox-component.component.css']
})
export class MedicCheckboxComponentComponent implements OnInit {

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.toggleModal(false)
    }
  }
  @Input() MedicSelected: Array<{
    "medic": Medic,
    "selectd": boolean
  }> = new Array()
  @Input() values: Array<Medic> = new Array();
  @Input() placeHolder: string = "";
  @ViewChild('medicForm', { read: MedicFormComponent, static: false }) medicForm!: MedicFormComponent;
  searchPattern: string = '';
  form: FormGroup;

  medicsList: Array<Medic> = new Array();
  @Input() set value(value: Medic) {
    if (value) {
      this._value = value;
      this.valueChange.emit(this._value);
    }
  }
  modalToggled: boolean = false;
  currentTabIndex: number = 0;

  get value(): Medic {
    return this._value || { name: '' };
  }

  private _value!: Medic;
  @Output() valueChange: EventEmitter<Medic> = new EventEmitter();

  constructor(private elementRef: ElementRef, private viewportScroller: ViewportScroller, private fb: FormBuilder, private medicsService: MedicsService, private toasterService: ToastrService) {
    this.form = this.fb.group({
      checkArray: this.fb.array([])
    })

  }

  ngOnInit(): void {
    this.medicsService.all().subscribe(medics => {
      this.medicsList = medics;
    })

  }

  toggleModal(toggled?: boolean, elementId?: string): void {
    if (toggled != undefined) this.modalToggled = toggled;
    else this.modalToggled = !this.modalToggled;
    if (toggled == true && elementId != undefined) {
      this.currentTabIndex = 0;
      this.viewportScroller.scrollToAnchor(elementId);
    }
  }

  onValuesSelected(value: Medic, event: any) {

    if (event.target.checked) {

      this.MedicSelected.push({
        medic: value,
        selectd: event.target.checked
      })



    }
  }
  onCheckboxChange(e: any) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

  }
  onAddMedic(): void {
    this.medicForm.isSubmitted = true;
    if (this.medicForm.isValid) {
      this.medicsService.add(this.medicForm.medic).subscribe(medic => {
        this.value = medic;
        this.medicForm.isSubmitted = false;
        this.medicsList = [medic, ...this.medicsList];
        this.medicForm.medic = new Medic();
        this.toggleModal(false);
      }, error => this.toasterService.error('Enable to add medic'));
    }
  }

  get medics(): Array<Medic> {
    return new FilterPipe().transform(this.medicsList, this.searchPattern, { keyword: 'nom' });
  }


}
