import { Pipe, PipeTransform } from '@angular/core';
import { Row } from '../../interfaces';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(rows: Array<Row>, ...args: any[]): Array<Row> {

    /* let newRows = new Array<any>();
     rows.map(row => {
       let match = true;
       for (let cell of row.cells) {
         let query = cell.header.searchQuery ? cell.header.searchQuery.toLowerCase() : '';
         match = String(cell.data.toString()).toLowerCase().trim().search(new RegExp(String(query).trim())) >= 0;
         if (!match) break;
       }
       if (!match) {
         row.selected || (row.selected = false);
       } else newRows.push(row);
 
       return row;
     })*/

    return rows;
  }

}
