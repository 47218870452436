import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(duration: number, ...args: unknown[]): string {
    const years = Math.floor(duration / (365 * 24 * 60 * 60));
    duration %= 365 * 24 * 60 * 60;

    const months = Math.floor(duration / (30 * 24 * 60 * 60));
    duration %= 30 * 24 * 60 * 60;

    const days = Math.floor(duration / (24 * 60 * 60));
    duration %= 24 * 60 * 60;

    const hours = Math.floor(duration / (60 * 60));
    duration %= 60 * 60;

    const minutes = Math.floor(duration / 60);
    duration %= 60;

    const parts = new Array<string>();

    if (years > 0) {
      parts.push(`${years} year${years > 1 ? 's' : ''}`);
    }

    if (months > 0) {
      parts.push(`${months} month${months > 1 ? 's' : ''}`);
    }

    if (days > 0) {
      parts.push(`${days} day${days > 1 ? 's' : ''}`);
    }

    if (hours > 0) {
      parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    }

    if (minutes > 0) {
      parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    }

    return parts.join(', ');
  }

}
