import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Appointment, EAppointmentState, PatientRequestedAppointment } from 'src/app/entities/appointment.entity';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { User } from 'src/app/entities/user.entity';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { DoctorsService } from 'src/app/services/doctors.service/doctors.service';
import { PatientAppointmentsService } from 'src/app/services/patient-appointments.service/patient-appointments.service';
import { PublicApisService } from 'src/app/services/public-apis.service/public-apis.service';
import { AppointmentBookingCalenderComponent } from '../appointment-booking-calender.component/appointment-booking-calender.component';
import { AppointmentBookingVerificationComponent } from '../appointment-booking-verification/appointment-booking-verification.component';
import { AppointmentPatientVerificationComponent } from '../appointment-patient-verification/appointment-patient-verification.component';
import { AppointmentBookingPaymentComponent } from '../appointment-booking-payment.component/appointment-booking-payment.component';
import { environment } from 'src/environments/environment';
import { StepperProgressBarItem } from 'src/app/common/modules/stepper.module/stepper-progress-bar.component/stepper-progress-bar.component';
import { EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';

const LATIN_STEPS = [
  {
    label: 'Rendez-vous',
    icon: '<i class="feather-calendar"><i/>',
  },
  {
    label: 'Vérification',
    icon: '<i class="feather-user"><i/>'
  },
  {
    label: 'Confirmation',
    icon: '<i class="feather-check"><i/>'
  },
  {
    label: 'Validation',
    icon: '<i class="feather-bookmark"><i/>'
  }
];

const ARABIC_STEPS = [
  {
    label: 'موعد طبي',
    icon: '<i class="feather-calendar"><i/>',
  },
  {
    label: 'تَحَقّق',
    icon: '<i class="feather-user"><i/>'
  },
  {
    label: 'تأكيد',
    icon: '<i class="feather-check"><i/>'
  },
  {
    label: 'تأكيد الطلب',
    icon: '<i class="feather-bookmark"><i/>'
  }
]

@Component({
  selector: 'app-appointment-booking',
  templateUrl: './appointment-booking.component.html',
  styleUrls: ['./appointment-booking.component.css']
})
export class AppointmentBookingComponent implements OnInit {
  @ViewChild(AppointmentBookingCalenderComponent, { read: AppointmentBookingCalenderComponent, static: false }) appointmentBookingCalenderComponent!: AppointmentBookingCalenderComponent;
  @ViewChild(AppointmentBookingVerificationComponent, { read: AppointmentBookingVerificationComponent, static: false }) appointmentBookingVerificationComponent!: AppointmentBookingVerificationComponent;
  @ViewChild(AppointmentPatientVerificationComponent, { read: AppointmentPatientVerificationComponent, static: false }) appointmentPatientVerificationComponent!: AppointmentPatientVerificationComponent;
  @ViewChild(AppointmentBookingPaymentComponent, { read: AppointmentBookingPaymentComponent, static: false }) appointmentBookingPaymentComponent!: AppointmentBookingPaymentComponent;
  steps: Array<StepperProgressBarItem<Appointment>> = new Array()
  @Input() collapsedSideBar: boolean = true;
  @Input() language: string = "fr"
  @Input() doctor: Doctor | null = null;

  @Input() appointmentId: string | undefined = undefined;

  patientAppointment: PatientAppointment = new PatientAppointment({
    status: EAppointmentState.PENDING,
    motif: 'Consultation Régulière',
    language: this.language
  });

  confirmAppointmentSubmit: boolean = false;

  isLoading: boolean = false;
  isSubmited: boolean = false;

  activeIndex: number = 0;

  get isLoggedIn(): boolean {
    return !!this.patient;
  }

  get patient(): User {
    return this.authService.getCurrentUser() as User;
  }

  get isValidAppointmentConfirmation(): boolean {
    return this.patientAppointment.requested ? (
      this.patientAppointment.patientFirstName.length > 0 && this.patientAppointment.patientLastName.length > 0 && this.patientAppointment.patientBirthDate != null && this.patientAppointment.patientMobilePhone.length > 0
    ) : this.isLoggedIn;
  }

  get textDir(): 'rtl' | 'ltr' {
    return this.language === 'ar' ? 'rtl' : 'ltr';
  }

  get domaineName(): string {
    return environment.DOMAINE_NAME
  }

  constructor(
    private publicApiService: PublicApisService,
    private toasterService: ToastrService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private patientAppointmentsService: PatientAppointmentsService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.has('appointmentId')) {
        this.appointmentId = params.get('appointmentId')!;
        this.patientAppointmentsService.oneByConfidentialCode(this.appointmentId).subscribe(appointment => {
          this.patientAppointment = PatientAppointment.fromResponse(appointment);
          if (this.patientAppointment.requested && !this.patientAppointment.confirmed) this.activeIndex = 2;
          else if (this.patientAppointment.location == EDalyTimeSlotLocation.ONLINE && !this.patientAppointment.isPaid) {
            this.steps.splice(3, 0, this.language == "fr" ? { label: 'Paiement', icon: '<i class="feather-credit-card"><i/>' } : { label: 'استخلاص', icon: '<i class="feather-credit-card"><i/>' });
            this.activeIndex = 3;
          }
          else this.activeIndex = 4;
        }, error => {
          this.toasterService.error(`404 appoitnment not found`)
        });
      } else {
        this.patientAppointment = new PatientAppointment({
          status: EAppointmentState.PENDING,
          motif: this.language == 'ar' ? 'استشارة منتظمة' : 'Consultation Régulière',
          language: this.language
        });
        this.activeIndex = 0;
      }
      if (params.has('location') && params.has('date') && params.has('startTime')) {
        //**TODO link FOR appointment requesting continuation */
      }
    })

    this.steps = this.language == 'fr' ? LATIN_STEPS : ARABIC_STEPS;
  }

  logout(): void {
    this.authService.logOut();
    this.router.navigate(['/', 'auth', 'login'])
  }

  onAppointmentVerificationRequested(): void {
    if (this.appointmentBookingCalenderComponent.validate()) {
      //  if (this.patientAppointment.location == 1)
      //  this.steps.splice(3, 0, this.language == "fr" ? { label: 'Paiement', icon: '<i class="feather-credit-card"><i/>' } : { label: 'استخلاص', icon: '<i class="feather-credit-card"><i/>' });
      if (this.patient) {
        this.submitAppointment().then(response => {
          this.patientAppointment = PatientAppointment.fromResponse(response);
          this.activeIndex = 4;
        }, error => this.toasterService.error('Enable to create appointment'));
      }
      this.activeIndex = 1;
    }
  }

  onAppointmentConfirmationRequested(): void {
    if (!this.appointmentBookingVerificationComponent.valid) return
    this.appointmentBookingVerificationComponent.submit().then(appointment => {
      this.patientAppointment = appointment;
      this.activeIndex = appointment.requested ? 2 : 4;
    })
  }

  onAppointmentConfirmed(): void {
    if (!this.appointmentPatientVerificationComponent.validate()) return
    this.appointmentPatientVerificationComponent.submit().then(appointment => {
      //this.steps.splice(3, 0, this.language == "fr" ? { label: 'Paiement', icon: '<i class="feather-credit-card"><i/>' } : { label: 'استخلاص', icon: '<i class="feather-credit-card"><i/>' });
      this.activeIndex = 4;
    })
  }

  submitAppointment(): Promise<PatientAppointment> {
    return new Promise((resolve, reject) => {
      this.patientAppointment.language = this.language;
      (this.patient ? this.patientAppointmentsService.add(this.doctor?.id!, this.patientAppointment) : this.publicApiService.requestAppointment(this.doctor?.id!, new PatientRequestedAppointment(this.patientAppointment))).subscribe(response => {
        resolve(PatientAppointment.fromResponse(response))
      }, error => reject(error))
    })
  }

  onAppointmentPaymentRequest(): Promise<PatientAppointment> {
    return new Promise((resolve, reject) => {

    })
  }
}
