import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsultationOphtalmo } from 'src/app/entities/consultation.entity/consultation-ophtalmo.entity/consultation-ophtalmo';

@Component({
  selector: 'app-consultations-lentille-form',
  templateUrl: './consultations-lentille-form.component.html',
  styleUrls: ['./consultations-lentille-form.component.css']
})
export class ConsultationsLentilleFormComponent implements OnInit {
  @Input() consultation: ConsultationOphtalmo = new ConsultationOphtalmo()
  @Output() consultationChange: EventEmitter<ConsultationOphtalmo> = new EventEmitter
  constructor() { }

  ngOnInit(): void {
   
  }

}
