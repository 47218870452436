import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CodeInputComponent),
    multi: true
  }]
})
export class CodeInputComponent implements ControlValueAccessor {

  @Input() disabled: boolean = false;
  @Input() length: number = 2;
  @Input() value: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter();
  @Output() complete: EventEmitter<string> = new EventEmitter();

  @ViewChildren('input', { read: ElementRef }) inputElements: QueryList<ElementRef> = new QueryList();

  private onChange: (val: string) => void = (value: string) => { };
  private onTouched: () => void = () => { };

  constructor() { }

  onCaracterInput($event, index: number) {
    const value: string = $event.target.value;
    if (value.length > 1)
      $event.target.value = value.split('').pop() || '';

    let split = this.value.split('');
    split[index] = $event.target.value;
    this.value = split.join('')

    this.onChange(value);
    this.valueChange.emit(this.value)

    if (index + 1 >= this.inputElements.length) {
      this.complete.emit(this.value)
    } else
      this.inputElements.get(index + 1)?.nativeElement.focus();
  }

  writeValue(value: string): void {
    this.value = value;
    this.inputElements.forEach((item, i) => {
      item.nativeElement.value = value.charAt(i) || ''
    })
    this.onChange(value);
    this.valueChange.emit(this.value)
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFocus(): void {
    this.inputElements.get(this.value.length)?.nativeElement.focus()
  }
}
