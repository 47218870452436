import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DoctorParameterSante, ParametereSante } from 'src/app/entities/parametre-sante.entity/paramtere-sante';

@Component({
  selector: 'app-parametre-sante-form',
  templateUrl: './parametre-sante-form.component.html',
  styleUrls: ['./parametre-sante-form.component.css']
})
export class ParametreSanteFormComponent implements OnInit {
  @Input() submitName: string = 'Submit';
  @Input() doctorParameterSante: DoctorParameterSante = new DoctorParameterSante()
  @Output() parametreSanteChange: EventEmitter<DoctorParameterSante> = new EventEmitter();

  @Output() submit: EventEmitter<DoctorParameterSante> = new EventEmitter();
  validate(): boolean {
    return this.doctorParameterSante.group.length > 0 && this.doctorParameterSante.parametreSante.label.length > 0 && this.doctorParameterSante.parametreSante.unit.length > 0;
  }

  constructor() { }

  ngOnInit(): void {
  }

  _change() {
    this.parametreSanteChange.emit(this.doctorParameterSante)
  }
  _submit(): void {
    // if (!this.validate())
    //alert('Merci de remlpir les champs obligatoire');
    //else
    this.submit.emit(this.doctorParameterSante)
  }

}
