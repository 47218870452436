import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

export interface IAutoCompleteOptions {
  caseSensive: boolean,
  startAfterCase: number,
  alwayseShow: boolean,
}
@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.css']
})
export class AutocompleteInputComponent implements OnInit {

  @Input() keyword: string = 'name';
  @Input() options: IAutoCompleteOptions = { caseSensive: true, startAfterCase: 1, alwayseShow: false };
  @Input() data: Array<any> = new Array();
  @Input() value: any = null;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  control: FormControl = new FormControl();
  _completes?: Observable<Array<any>> = new Observable();
  edition = false;
  constructor() { }

  ngOnInit(): void {
    this.init()
  }

  init(): void {
    this.control = new FormControl(this.value ? this.value[this.keyword] : '');
    this._completes = this.control.valueChanges.pipe(
      startWith(''),
      map(value => {
        this.edition = true;
        return value.length > 1 ? this._filter(value).copyWithin(0, 10) : []
      })
    );
  }

  pickAutoCompleteItem(item: any): void {
    this.value = item;
    this.control = new FormControl(this.value ? this.value[this.keyword] : '');
    this.valueChange.emit(this.value);
    this.edition = false;
  }

  private _filter(pattern: string): Array<any> {
    const filterPattern = this._normalizeValue(pattern);
    return this.data.filter(item => this._normalizeValue(item[this.keyword]).includes(filterPattern));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}
