<div class="card p-3">
    <div class="row">
        <div class="col-lg-6 ">
            <label class="mt-1">Element Bio*</label>
            <input type="text" class="form-control" [(ngModel)]="biology.element_Bio" (ngModel)="_change()" />
            <span *ngIf="isSubmitted && biology.element_Bio.length== 0" style="color:red">Element bio est obligatoire</span>
        </div>
        <div class="col-lg-6 ">
            <label class="mt-1">Indication Bio</label>
            <ckeditor [(ngModel)]="biology.indication_Bio" [editor]="Editor" (ngModel)="_change()"></ckeditor>

        </div>
        <div class="col-lg-6 ">
            <label class="mt-1">Norme Bio</label>
            <input type="text" class="form-control" [(ngModel)]="biology.norme_Bio" (ngModel)="_change()" />

        </div>
        <div class="col-lg-6 ">
            <label class="mt-1">Param Bio</label>
            <input type="text" class="form-control" [(ngModel)]="biology.param_Bio" (ngModel)="_change()" />
        </div>
    </div>
</div>