import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker.component/date-picker.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DatePickerComponent
  ],
  imports: [
    FormsModule,
    CommonModule
  ],
  exports: [
    DatePickerComponent
  ]
})
export class DatePickerModule { }
