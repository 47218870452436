<div class="calender-container" *ngIf="displayMode==1">
    <ng-container *ngIf="!isLoading;else loading">
        <div class="calender-head" *ngIf="dir=='fr'">
            <div *ngIf="__canMoveBack()" class="calander-navigator left" (click)="__moveToBack()">
                <i class="feather-chevron-left"></i>
            </div>
            <ng-container *ngFor="let item of calender" class="calender-day">
                <div class="calender-day-head" (click)="selectDay($event,item)" [ngClass]="{selected:selectedDay.getTime() === item.day.getTime()}">
                    <!--<span class="day" class="font-weight-bold">{{ item.day | date:'EEEE' }}</span>-->
                    <span>{{ __getLiteralDay(item.day.getDay()) }}</span>
                    <span class="day">{{ item.day | date: "dd" }}/{{MONTHS[item.day.getMonth()].substr(0,3)}}</span>
                </div>
            </ng-container>
            <div class="calander-navigator right" (click)="__moveToNext()">
                <i class="feather-chevron-right"></i>
            </div>
        </div>
        <div class="calender-head" *ngIf="dir=='ar'">
            <div class="calander-navigator right" *ngIf="__canMoveBack() " (click)="__moveToBack()">
                <i class="feather-chevron-right"></i>
            </div>
            <ng-container *ngFor="let item of calender" class="calender-day">
                <div class="calender-day-head" (click)="selectDay($event,item)" [ngClass]="{selected:selectedDay.getTime() === item.day.getTime()}">
                    <!--<span class="day" class="font-weight-bold">{{ item.day | date:'EEEE' }}</span>-->
                    <span>{{ __getLiteralDay(item.day.getDay()) }}</span>
                    <span class="day">{{ item.day | date: "dd" }}/{{MONTHS[item.day.getMonth()]}}</span>
                </div>
            </ng-container>
            <div class="calander-navigator left" (click)="__moveToNext()">
                <i class="feather-chevron-left"></i>
            </div>

        </div>
        <scrollbar>
            <div class="calender-content ">
                <ng-container *ngFor="let item of calender">
                    <div *ngIf="item.availabilities.length;else emptyDay" class="calender-day-content">
                        <div *ngFor="let availability of item.availabilities | doctorAvailabilitiesFilter:location ;let availabilityIndex = index" class="calender-day-item"
                            (click)="onDisponibilitySelected(availability)" [ngClass]="{disabled: isDisabled(availability)  ,selected: isSelected(availability),deleted: false}">
                            <a class="timing" style="cursor: pointer;">
                                {{ availability.startTime | time }}
                            </a>
                            <span class="badge"></span>
                        </div>
                    </div>
                    <ng-template #emptyDay>
                        <div class="calender-day-content">
                            <div class="calender-day-item" style="border:none">
                                <a class="timing" style="cursor: pointer;">---</a>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </scrollbar>
    </ng-container>
    <ng-template #loading>
        <app-loader></app-loader>
    </ng-template>
</div>
<div class="calender-container" *ngIf="displayMode==2" style=" display: grid;
    grid-template-rows: repeat(9, 1fr);
    grid-gap: 5px;
    overflow: auto;
    height:260px;
   ">
    <div class="calender-head" style=" position: sticky;
            top: 0;background-color: #ffff;">
        <ng-container *ngFor="let item of calender" class="calender-day">
            <div class="calender-day-head" (click)="selectDay($event,item)" [ngClass]="{selected:selectedDay.getTime() === item.day.getTime()}">
                <!--<span class="day" class="font-weight-bold">{{ item.day | date:'EEEE' }}</span>-->
                <span class="day font-weight-bold">{{ __getLiteralDay(item.day.getDay()) }}</span>
                <span class="time">{{ item.day | date: "dd-LLL" }}</span>
            </div>
        </ng-container>
    </div>

    <div class="calender-content">
        <ng-container *ngFor="let item of calender">
            <div *ngIf="item.availabilities.length;else emptyDay" class="calender-day-content">
                <div *ngFor="let disponibility of item.availabilities | doctorAvailabilitiesFilter:location ;let disponibilityIndex = index" class="calender-day-item"
                    (click)="onDisponibilitySelected(disponibility)" [ngClass]="{disabled: false,selected: isSelected(disponibility),deleted: false}">
                    <a class="timing" style="cursor: pointer;">
                        {{ disponibility.startTime | time }}
                    </a>
                    <span class="badge">
                        <i class="feather-check"></i>
                    </span>
                </div>
            </div>
            <ng-template #emptyDay>
                <div class="calender-day-content">
                    <div class="calender-day-item" style="border:none">
                        <a class="timing" style="cursor: pointer;">
                            ---
                        </a>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>