import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RadioPrescription } from 'src/app/entities/prescription.entity/radio-prescription.entity/radio-prescription';
export class RadioFilter{
  radioPrescription:RadioPrescription;
  selected:boolean
  constructor(other?:Partial<RadioFilter>){
    this.radioPrescription=other?.radioPrescription|| new RadioPrescription;
    this.selected=other?.selected||false
  }
}
@Component({
  selector: 'app-radio-select-drawer',
  templateUrl: './radio-select-drawer.component.html',
  styleUrls: ['./radio-select-drawer.component.css']
})
export class RadioSelectDrawerComponent implements OnInit {
  @Input() values: Array<any> = new Array();

  @Output() valuesChange: EventEmitter<any> = new EventEmitter();
  isChecked: boolean = false;
  radiologie: string = "";
  select:Array<RadioFilter>=[]
  @Input() radioSelected: Array<RadioFilter> = new Array();
  @Output() radioSelectedChange: EventEmitter<Array<RadioFilter>> = new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {


    
  }
  changed(biologie: any, event): void {
    if (event.target.checked == true) {
      this.radioSelected.push({radioPrescription:new RadioPrescription({ radio: biologie }),selected:event.target.checked});

      this.radioSelectedChange.emit(this.radioSelected);
    } else {
      this.radioSelected = this.radioSelected.filter((elm) => elm.radioPrescription.radio !== biologie && elm.selected!=false);

      this.radioSelectedChange.emit(this.radioSelected);
    }
    
  }
  close(): void {
    this.radioSelectedChange.emit(this.radioSelected);

    this.closed.emit();
  }
}
