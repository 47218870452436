import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { EUserGender } from 'src/app/entities/user.entity';

@Component({
  selector: 'app-patient-growth-chart',
  templateUrl: './patient-growth-chart.component.html',
  styleUrls: ['./patient-growth-chart.component.css']
})
export class PatientGrowthChartComponent implements OnInit {

  @Input() gender: EUserGender = EUserGender.HOMME;
  @Input() height: number = 0;
  @Input() weight: number = 0;
  @Input() old: number = 0;

  chartDisplayFor: 'height' | 'weight' = 'height';

  get isValid(): boolean { return this.isValidHeight && this.isValidWeight && this.isFitOld }

  get isValidHeight(): boolean { return this.height > 0 };

  get isValidWeight(): boolean { return this.weight > 0; }

  get isFitOld(): boolean { return this.old <= 216; /* 216 is 18 years old in months*/ }

  get XSTEP(): number {
    return this.old > 36 ? 5.2631578947368425 : 2.7027027027027026;
  }

  get YSTEP(): number {
    return this.old > 36 ? 5 : 6.666666666666667;
  }

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
  }

  __calculateHeightY() {
    if (this.height <= 120)
      return (100 - (((this.height / 10) + 1) * 5))
    else if (this.height == 130)
      return (100 - (((this.height / 10) + 0.5) * 5))
    else return (100 - ((this.height / 10) * 5))
  }

  __calculateWeightY() {
    if (this.weight <= 120)
      return (100 - (((this.weight / 10) + 1) * 5))
    else if (this.weight == 130)
      return (100 - (((this.weight / 10) + 0.5) * 5))
    else return (100 - ((this.weight / 10) * 5))
  }
}
