import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.css']
})
export class DurationInputComponent implements OnInit {

  @Input() inputs: {
    years?: boolean,
    months?: boolean,
    days?: boolean,
    hours?: boolean,
    minutes?: boolean,
    seconds?: boolean,
    millseconds?: boolean
  } = {
      years: false,
      months: false,
      days: false,
      hours: false,
      minutes: false,
      seconds: false,
      millseconds: false,
    };

  @Input() set duration(duration: {
    years?: number, months?: number, days?: number, hours?: number, minutes?: number, seconds?: number, millseconds?: number
  }) {
    this._duration = { years: duration.years || 0, months: duration.months || 0, days: duration.days || 0, hours: duration.hours || 0, minutes: duration.minutes || 0, seconds: duration.seconds || 0, millseconds: duration.millseconds || 0 };
    this.durationChanged.emit(this._duration)
  }

  @Output() durationChanged: EventEmitter<{
    years?: number, months?: number, days?: number, hours?: number, minutes?: number, seconds?: number, millseconds?: number
  }> = new EventEmitter();

  _duration: {
    years: number,
    months: number,
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
    millseconds: number
  } = {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      millseconds: 0,
    }

  constructor() { }

  ngOnInit(): void {
  }

  emitChange(): void {
    if (this._duration.millseconds > 999)
      this._duration.millseconds = 999;
    if (this._duration.minutes > 59)
      this._duration.minutes = 59
    if (this._duration.hours > 23)
      this._duration.hours = 23;
    this.durationChanged.emit(this._duration)
  }

}
