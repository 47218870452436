import { Pipe, PipeTransform } from '@angular/core';
import { Consultation } from 'src/app/entities/consultation.entity';

@Pipe({
  name: 'searchConsultation'
})
export class FilterConsultationPipe implements PipeTransform {

  transform(Consultations: Array<Consultation>, motif: string): Array<Consultation> {
    return Consultations.filter((elm: any) => {
      return elm.motifTags.text === motif
    })
  }

}
