import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ModalComponent } from 'src/app/common/modules/modal.module/modal/modal.component';
import { Medic } from 'src/app/entities/medic.entity/medic';
import { MedicPrescription } from 'src/app/entities/prescription.entity/medic-prescription.entity/medic-prescription';
import { MedicsService } from 'src/app/services/medics.service/medics.service';
import { MedicFormComponent } from '../../medic-form.module/medic-form.component/medic-form.component';
import { MedicFilter } from '../../medic-form.module/medic-select-drawer.component/medic-select-drawer.component';

export class PrescriptionItemValidation {
  controlsValidation: Record<symbol, symbol | null> = {}
  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every(elm => elm === null)
  }
}

export class MedicPrescriptionItemValidation extends PrescriptionItemValidation {
  controlsValidation: { medic: 'required' | null, posology: 'required' | null, duration: 'required' | 'number' | 'min' | null, notes: null } = {
    medic: null,
    posology: null,
    duration: null,
    notes: null
  };

  constructor(controlsValidation: { medic: 'required' | null, posology: 'required' | null, duration: 'required' | 'number' | 'min' | null, notes: null }) {
    super()
    this.controlsValidation = controlsValidation
  }

  static validate(medicPrescriptionItem: MedicPrescription): MedicPrescriptionItemValidation {
    return (medicPrescriptionItem.medic != undefined && medicPrescriptionItem.medic?.idMedicament != undefined) ? new MedicPrescriptionItemValidation({
      medic: null,// medicPrescriptionItem.medic.idMedicament ? 'required' : null,
      duration: medicPrescriptionItem.duration ? (!isNaN(Number(medicPrescriptionItem.duration)) ? (Number(medicPrescriptionItem.duration) < 1 ? 'min' : null) : 'number') : 'required',
      posology: !medicPrescriptionItem.posology.length ? 'required' : null,
      notes: null
    }) : new MedicPrescriptionItemValidation({ medic: null, posology: null, duration: null, notes: null });
  }
}

@Component({
  selector: 'app-medic-prescription-form',
  templateUrl: './medic-prescription-form.component.html',
  styleUrls: ['./medic-prescription-form.component.css']
})
export class MedicPrescriptionFormComponent implements OnInit {

  @Input() medics: Array<Medic> = new Array();
  @Input() medicPrescriptionItems: Array<MedicPrescription> = new Array();
  @Output() medicPrescriptionItemsChange: EventEmitter<Array<MedicPrescription>> = new EventEmitter();
  _isSubmited: boolean = false;
  drawerActive:boolean=false
  medicsFilter: Array<MedicFilter> = new Array({medicPrescription:new MedicPrescription(),selected:false,index:0});

  @Input() set submitted(v: boolean) {
    if (v) this.medicPrescriptionItems = this.medicPrescriptionItems.filter(e => {
      return (e.medic != undefined && e.medic.idMedicament != undefined)
    })
    this._isSubmited = v;
  }
  @Output() validation: EventEmitter<boolean> = new EventEmitter()
  @ViewChild(ModalComponent, { read: ModalComponent, static: false })
  dialog!: ModalComponent;
  get submitted(): boolean {
    return this._isSubmited;
  }
  @ViewChild('medicForm', { read: MedicFormComponent, static: true }) medicForm!: MedicFormComponent;
  get isValid(): boolean {
    return this.medicPrescriptionItems.every(item => {
      return MedicPrescriptionItemValidation.validate(item).isValid
    })
  }

  constructor(private medicsService: MedicsService, private toasterService: ToastrService) { }

  validatePrescriptionItem(item: MedicPrescription) {
    return MedicPrescriptionItemValidation.validate(item)
  }

  ngOnInit(): void {
    this.medicsService.all().subscribe(medics => {this.medics = Array.from(medics, m => Medic.fromResponse(m))
    }, error => { this.toasterService.error('Enable to load medics') })
  }
  closed(): void {
    this.medicsFilter.map((elm) => {
      
      return this.medicPrescriptionItems.push(elm.medicPrescription);
    });
    // Étape 1: Utiliser reduce pour compter les occurrences des objets biologie
    const countMedics = this.medicsFilter.reduce((acc, obj) => {
      const medickey = JSON.stringify(obj.medicPrescription.medic);
      acc[medickey] = (acc[medickey] || 0) + 1;
      return acc;
    }, {});

    // Étape 2: Utiliser filter pour sélectionner les objets dont biologie apparaît plus d'une fois
    const medics = this.medicsFilter.filter((obj, index, self) => {
      const medickey = JSON.stringify(obj.medicPrescription.medic);
      return (
        countMedics[medickey] > 1 &&
        self.findIndex((o) => this.deepEqual(o.medicPrescription.medic, obj.medicPrescription.medic)) === index
      );
    });
  

    this.medicsFilter = medics;
    const countMedicsItem = this.medicPrescriptionItems.reduce((acc, obj) => {
      const medickey = JSON.stringify(obj.medic);
      acc[medickey] = (acc[medickey] || 0) + 1;
      return acc;
    }, {});

    // Étape 2: Utiliser filter pour sélectionner les objets dont biologie apparaît plus d'une fois
    const medicitem = this.medicPrescriptionItems.filter((obj, index, self) => {
      const medickey = JSON.stringify(obj.medic);
      return (
        countMedicsItem[medickey] > 1 &&
        self.findIndex((o) => this.deepEqual(o.medic, obj.medic)) === index
      );
    });
    
    this.drawerActive = false;
  }
  deepEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  addPrescriptionItem(): void {
    let medicPrescription: MedicPrescription = new MedicPrescription({ medic: null as any })
    medicPrescription.medic = null as any;
    this.medicPrescriptionItems.push(medicPrescription);
    this._change()
  }

  deletePrescriptionItem(index: number): void {
    this.medicPrescriptionItems.splice(index, 1);
    this._change()
  }

  onEditPrescriptionItem(index: number): void {
    this._change()
  }

  addMedicSubmit(): void {
    this.medicForm.isSubmitted = true;
    if (this.medicForm.isValid) {
      this.medicsService.add(this.medicForm.medic).subscribe(newMedic => {
        this.medics = [newMedic, ...this.medics];
        this.medicForm.isSubmitted = false;
        this.medicForm.medic = new Medic();
      }, error => { this.toasterService.error('Enable to add medic') });
    }
  }

  onAddMedic(): void {
    this.medicForm.isSubmitted = true;
    if (this.medicForm.isValid) {
      this.medicsService.add(this.medicForm.medic).subscribe(medic => {
        this.medicForm.isSubmitted = false;
        this.medics = [medic, ...this.medics];
        this.medicForm.medic = Medic.fromResponse(medic);
        this.medicPrescriptionItems.map(elm => { return elm.medic = medic })
        this.medicPrescriptionItemsChange.emit(this.medicPrescriptionItems);
      }, error => this.toasterService.error('Enable to add medic'));
    }
  }

  _change(): void {
    
    this.medicPrescriptionItemsChange.emit(this.medicPrescriptionItems);
    this.validation.emit(this.isValid)
  }
}
