<table class="table table-hover table-center">
  <thead>
    <tr>
      <th *ngFor="let header of headers;let i = index">
        {{header.label}}
        <!--<i class="sorter feather" [ngClass]="{'feather-chevron-up':true,'feather-chevron-down':false}"></i> -->
      </th>
      <th *ngIf="hasActions" class="text-center">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="doctorPatients.length;else empty">
      <tr *ngFor="let doctorPatient of doctorPatients;let i = index">
        <td>{{doctorPatient.ref || '..'}}</td>
        <td>
          <h2 class="table-avatar">
            <a *ngIf="actions.badge " class="btn btn-sm  " style="margin-right: 5px;" [ngStyle]="{'color':doctorPatient.createdAt==date?'#ffbc34':'#badfdc'}">
              <i class="feather-star" style="font-weight: bold; font-size: 15px;"></i>
            </a>
            <a (click)="onUpdateRequest.emit(doctorPatient)" class="avatar avatar-sm me-2">
              <img class="avatar-img rounded-circle" [src]="doctorPatient | avatarSrc" [alt]="doctorPatient.getFullName()" image />
            </a>
            <a (click)="onUpdateRequest.emit(doctorPatient)" style="font-family: 'Gotham Rounded Bold';font-weight: Bold;">
              {{ doctorPatient.getFullName() }}
            </a>
          </h2>
        </td>
        <td style="width: 15px;">
          {{ doctorPatient.mobilePhone1 || "---" }}
        </td>
        <td style="font-weight: 600;color:#000">
          {{ doctorPatient.birthDate? (doctorPatient.birthDate | date: "dd/MM/yyyy"):'---' }}
        </td>
        <td>
          {{(doctorPatient.country || '').length>0?doctorPatient.country + (doctorPatient.government?' /':''):''}}
          {{ doctorPatient.government }}
        </td>
        <td>
          <a>{{ doctorPatient.accompanist?doctorPatient.accompanist.getFullName():'---' }}</a>
        </td>
        <td class="text-center">
          {{
          doctorPatient.last_appointment?(doctorPatient.last_appointment
          | date: "dd/MM/yyyy"):'---' }}
        </td>
        <td *ngIf="hasActions">
          <div class="table-action">

            <a *ngIf="actions.edit" title="Modifier Profil" (click)="onUpdateRequest.emit(doctorPatient)" class="btn btn-sm bg-info-light">
              <i class="feather-edit"> </i>
            </a>
            <a *ngIf="actions.assignAppointment" title="Ajouter Rdv" (click)="onAssignAppointmentRequest.emit(doctorPatient)" routerLinkActive="router-link-active"
              class="btn btn-sm bg-success-light">
              <i class="feather-plus"></i>
            </a>
            <a *ngIf="actions.delete" title="Supprimer Patient" (click)="onDelete(i)" routerLinkActive="router-link-active" class="btn btn-sm bg-danger-light">
              <i class="feather-trash"></i>
            </a>
            <app-drop-down-menu *ngIf="actions.antecentendsView || actions.prescriptionsView || actions.consultationsView">
              <a class="btn btn-sm bg-success-light" #trigger trigger>
                <i class="feather feather-menu"></i>
              </a>
              <ul class="menu" #content content>
                <li *ngIf="actions.antecentendsView">
                  <a (click)="onAntecedentsRequest.emit(doctorPatient)" routerLinkActive="router-link-active"><i class="feather feather-book-open"></i> <span>Ajout
                      Antecedent</span></a>
                </li>

                <li *ngIf="actions.prescriptionsView">
                  <a (click)="onPrescriptionsRequest.emit(doctorPatient)"><i class="feather feather-user-plus"></i>
                    <span>Patient prescriptions</span></a>
                </li>
                <li *ngIf="actions.consultationsView">
                  <a (click)="onConsultationsRequest.emit(doctorPatient)"><i class="feather feather-activity"></i>
                    <span>Patient consultations</span></a>
                </li>
              </ul>
            </app-drop-down-menu>
          </div>
        </td>
      </tr>
    </ng-container>
    <ng-template #empty>
      <tr>
        <td colspan="8" style="text-align: center;">{{emptyMessage || 'Aucun patient trouvé !'}}</td>
      </tr>
    </ng-template>
  </tbody>
</table>
<app-pagination [pageRequest]="pageRequest" [refresh]="onRefresh" (content)="doctorPatients = $event"></app-pagination>