import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRole } from 'src/app/entities/user.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DoctorSecretarySpaceGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getCurrentUser()?.role == UserRole.DOCTOR_SECRETARY ? true : this.router.navigate([['patient', 'doctor', 'secretary', 'pharmacist', 'admin'][this.authService.getCurrentUser()?.role || 0]], { queryParams: route.queryParams });
  }

}
