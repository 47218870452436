import { Component, Input, OnInit } from '@angular/core';
import { DoctorAppointment } from 'src/app/entities/appointment.entity/doctor-appointment.entity/doctor-appointment';

@Component({
  selector: 'app-doctor-secretray-appointment-success',
  templateUrl: './doctor-secretary-appointment-success.component.html',
  styleUrls: ['./doctor-secretary-appointment-success.component.css']
})

export class DoctorSecretaryAppointmentSuccessComponent implements OnInit {
  @Input() doctorAppointment!: DoctorAppointment;

  constructor() { }

  ngOnInit(): void {
  }

}
