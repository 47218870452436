<div class="card rounded">
    <div class="card-header rounded accordion-header" (click)="collapsable?collapsed = !collapsed:null">
        <div class="header-container">
            <h3 class="page-title mb-0" style="margin-right: 12px">
                <i [ngClass]="collapsable?{'feather-chevron-right':collapsed,'feather-chevron-down':!collapsed}:{}"></i>
            </h3>
            <ng-content select="[header]"></ng-content>
        </div>
    </div>
    <div *ngIf="!collapsed" class=" rounded card-body" style="background-color: rgb(255, 255, 255);">
        <ng-content select="[body]"></ng-content>
    </div>
</div>