<div class="card" style="width: 98%;background-color: #FFFFFF;">
  <div class="row  card-header lacerinput">
    <div class="col-lg-4 col-md-5 col-sm-12">
      <label class="font-weight-bold" >Renseignements Cliniques</label>
    </div>
    <div class="col-lg-8 col-md-7 col-sm-12">
      <div class="user-box">
        <input [(ngModel)]="consultation.ophtalmo_RC">
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row p-3">
      <div class="col-lg-4">
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_1"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">Angiographie à la fluoresceine</span><br />
        <input type="checkbox" name="" id="" [(ngModel)]="consultation.exp_laser_2" class="mr-5"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">Angiographie ICG</span><br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_3"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">OCT maculaire</span>
        <br />
        <input type="checkbox" name="" id="" [(ngModel)]="consultation.exp_laser_4" class="mr-5"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">OCT-A</span>
        <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_5"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">Photo FO</span>
      </div>
      <div class="col-lg-4">
        <input type="checkbox" name="" id="" [(ngModel)]="consultation.exp_laser_6" class="mr-5"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">Echo B</label> <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_7"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">Echo A</label> <br />
        <input type="checkbox" name="" id="" [(ngModel)]="consultation.exp_laser_8" class="mr-5"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">Calcul d'implant</label>
        <br />
        <input type="checkbox" name="" id="" [(ngModel)]="consultation.exp_laser_9" class="mr-5"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">LOL Master</label> <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_10"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">UBM</label> <br />
      </div>
      <div class="col-lg-4">
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_11"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">PEV</label> <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_12"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">ERG</label>
      </div>
    </div>
    <div class="row p-3">
      <div class="col-lg-4">
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_13"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">OCT de la papille</span><br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_14"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">Complexe ganglionnaire </span><br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_15"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">Champ visuel</span>
        <br />
        <input type="checkbox" name="" id="" [(ngModel)]="consultation.exp_laser_16" class="mr-5"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">Bilan Glaucome</span>
        <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_17"
          style="margin-right: 5px;" />
        <span for="" class="font-weight-bold">Laser Multispot</span>
      </div>
      <div class="col-lg-4">
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_18"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">Topographique cornéenne</label>
        <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_19"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">Microscopie spéculaire</label>
        <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_20"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">Mapping épithélial par OCT</label>
        <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_21"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">Bilan pré LASIK</label>
        <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_22"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">Laser argon</label> <br />
      </div>
      <div class="col-lg-4">
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_23"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">Capsulotomie</label> <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_24"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold">IP</label> <br />
        <input type="checkbox" name="" id="" class="mr-5" [(ngModel)]="consultation.exp_laser_25"
          style="margin-right: 5px;" />
        <label for="" class="font-weight-bold" style="margin-top: 45px;">ttt focal au laser Micropulse jaune</label>
      </div>

    </div>

  </div>
  <div class="card-footer">
    <div class="row lacerinput">
      <div class="col-lg-2">
        <label for="" class="font-weight-bold">Note Laser</label>
      </div>
      <div class="col-lg-10">
        <div class="user-box">
          <input type="text" style="margin-left: 6px;" [(ngModel)]="consultation.ophtalmo_Remarque">
        </div>
      </div>


    </div>
  </div>
</div>