import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})
export class TreeViewComponent implements OnInit {
  @Input() groups: Array<{ groupName: string, items: Array<any> }> = new Array()
  constructor() { }

  ngOnInit(): void { }

}
