import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../core/services/api.service/api.service';
import { ApiResponse } from '../../core/entities/api-response.entity/api-response';
import { Biology } from 'src/app/entities/biology.entity/biology';

@Injectable({
  providedIn: 'root'
})
export class DoctorBiologiesService extends ApiService {

  add(data: Biology): Observable<Biology> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/biologies/all`, {
      element_Bio: data.element_Bio,
      indication_Bio: data.indication_Bio,
      norme_Bio: data.norme_Bio,
      param_Bio: data.param_Bio
    }, { responseType: 'json' }).pipe(this.responsePipe)
  }

  all(): Observable<Array<Biology>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/biologies/all`, { responseType: 'json' }).pipe(this.responsePipe)
  }

}
