import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TreeViewItemComponent } from './tree-view-item/tree-view-item.component';
import { TreeViewComponent } from './tree-view.component/tree-view.component';

@NgModule({
    declarations: [
        TreeViewComponent,
        TreeViewItemComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        TreeViewComponent,
        TreeViewItemComponent
    ]
})
export class TreeViewModule { }