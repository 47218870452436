<div *ngIf="page.totalPages>0" class="row">
    <div class="col-sm-12 col-md-5">
        <div class="dataTables_info" role="status" aria-live="polite">

        </div>
    </div>
    <div class="col-sm-12 col-md-7">
        <div class="dataTables_paginate paging_simple_numbers">
            <ul class="pagination">
                <li [ngClass]="{'disabled':page.hasPrevious}" class="paginate_button page-item previous"><a class="page-link" (click)="onPreviousRequest()">{{
                        language == 'fr'?'Précédent':'سابقة'}}</a>
                </li>
                <li *ngFor="let step of steps" (click)="gotTo(step)" [ngClass]="{'active':page.currentPage == step}" class="paginate_button page-item"><a
                        class="page-link">{{step+1}}</a></li>
                <li [ngClass]="{'disabled':page.hasNext}" class="paginate_button page-item next disabled"><a class="page-link"
                        (click)="onNextRequest()">{{language=='fr'?'Suivant':'التالي'}}</a></li>
            </ul>
        </div>
    </div>
</div>