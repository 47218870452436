import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Act, DoctorAct } from 'src/app/entities/act.entity/act';
import { ActeConsultationItem } from 'src/app/entities/consultation.entity';
import { DoctorActFormComponent } from '../../acts-form.module/doctor-act-form.component/doctor-act-form.component';
import { DoctorActsService } from 'src/app/services/doctor-acts.service/doctor-acts.service';


export class ConsultationItemValidation {
  controlsValidation: Record<symbol, symbol | null> = {}
  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every(elm => elm === null)
  }
}

export class ActeConsultationnItemValidation extends ConsultationItemValidation {
  controlsValidation: { act: 'required' | null, note: null, montant: 'required' | 'number' | null, duration: 'required' | 'number' | 'min' | null } = {
    act: null,
    note: null,
    montant: null,
    duration: null
  };

  constructor(controlsValidation: { act: 'required' | null, note: null, montant: 'required' | 'number' | null, duration: 'required' | 'number' | 'min' | null }) {
    super()
    this.controlsValidation = controlsValidation
  }
}
@Component({
  selector: 'app-actes-consultation',
  templateUrl: './consultation-act-form.component.html',
  styleUrls: ['./consultation-act-form.component.css']
})
export class ConsultationActFormComponent implements OnInit {
  @Input() actesConsultationItems: Array<ActeConsultationItem> = new Array();
  @Output() actesConsultationItemsChange: EventEmitter<Array<ActeConsultationItem>> = new EventEmitter();
  actes: Array<DoctorAct> = new Array();
  _submitted: boolean = false;
  @Input() set submitted(v: boolean) {
    this._submitted = v;
    if (this._submitted) {
      this.actesConsultationItems = this.actesConsultationItems.filter(e => e.act && e.act.id_Acte != undefined && e.act.id_Acte.length > 0)
      this._change()
    }
  }
  get submitted(): boolean {
    return this._submitted;
  }
  @Output() validation: EventEmitter<boolean> = new EventEmitter()
  @ViewChild('actForm', { read: DoctorActFormComponent, static: true }) actForm!: DoctorActFormComponent;

  constructor(private doctorActsService: DoctorActsService, private toasterService: ToastrService) { }
  get isValid(): boolean {
    return this.actesConsultationItems.every(item => this.validateConsultationItem(item).isValid)
  }
  get groupedactes(): Array<{ keyName: string, items: Array<{ label: string, id: string }> }> {
    let groups: Array<string> = Array.from(this.actes, r => r.actes.famille_Acte)
    return Array.from(groups, group => {
      let items: Array<{ label: string, id: string }> = new Array();
      this.actes.forEach(acte => {
        if (group == acte.actes.famille_Acte) items.push({
          label: acte.actes.desc_Acte
          , id: acte.actes.id_Acte
        });
      })
      return {
        keyName: group, items: items
      };
    });
  }
  ngOnInit(): void {
    this.doctorActsService.all().subscribe(res => {
      this.actes = Array.from(res || [], elm => new DoctorAct(
        DoctorAct.fromResponse(elm)
      ));
    }, error => this.toasterService.error('Problème de Chargements des Actes'))
  }

  addPrescriptionItem(): void {
    this.actesConsultationItems.push(new ActeConsultationItem());
    this._change();
  }

  validateConsultationItem(acteConsultationItem: ActeConsultationItem): ActeConsultationnItemValidation {
    return acteConsultationItem.act && acteConsultationItem.act.id_Acte != undefined && acteConsultationItem.act.id_Acte.length > 0 ? new ActeConsultationnItemValidation({
      act: null,
      note: null,
      montant: null,//!acteConsultationItem.montant_consultation_acte ? 'required' : (isNaN(acteConsultationItem.montant_consultation_acte as any) ? 'number' : null),
      duration: acteConsultationItem.date_consultation_acte ? (!isNaN(Number(acteConsultationItem.date_consultation_acte)) ? (Number(acteConsultationItem.date_consultation_acte) < 1 ? 'min' : null) : 'number') : 'required'
    }) : new ActeConsultationnItemValidation({ act: null, note: null, montant: null, duration: null });
  }

  onDoctorActSelected(actesConsultationItem: ActeConsultationItem, act: Act): void {
    if (!act) return;
    if (actesConsultationItem.act?.id_Acte != act.id_Acte) {
      actesConsultationItem.act = act;
      let find: any | null = this.actes.find(a => a.actes.id_Acte === actesConsultationItem.act.id_Acte)!;
      actesConsultationItem.montant_consultation_acte = find?.montant || 0;
      this._change()
    }
  }

  deleteConsultationActItem(index: number): void {
    this.actesConsultationItems.splice(index, 1);
    this._change()
  }

  addActSubmit(): void {
    this.actForm.isSubmitted = true;
    if (this.actForm.isValid) {
      this.doctorActsService.add(this.actForm.doctorAct).subscribe(acte => {
        this.actes = [acte, ...this.actes];
        this.actForm.doctorAct = new DoctorAct();
        this.actForm.isSubmitted = false;
        this._change();
      }, error => this.toasterService.error('Enable to add act'));
    }
  }

  _change(): void {
    this.actesConsultationItemsChange.emit(this.actesConsultationItems);
    this.validation.emit(this.isValid)
  }
}
