import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DoctorParameterSante, ParametereSante } from 'src/app/entities/parametre-sante.entity/paramtere-sante';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DoctorParametersSanteService extends ApiService {
  updateOrder(id: string, order: number) {
    return this.http.patch<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante/${id}/order?order=${order}`, null).pipe(this.responsePipe)
  }

  all(pageRequest?: PageRequest): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante/all`).pipe(this.responsePipe)
  }

  search(query: string, pageRequest?: PageRequest): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('q', query)
    if (pageRequest) {
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
    }
    else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante/all/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }

  create(data: ParametereSante): Observable<any> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante`, {
      paramFamille: data.group,
      paramLibelle: data.label,
      paramType: data.type,
      paramUnite: data.unit,
      splitValues: data.splitValues,
      paramDescription: data.description
    }, { responseType: 'json' }).pipe(this.responsePipe)
  }

  getActivatedParametresSante(): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante/activated/all`).pipe(this.responsePipe)
  }

  switchStatus(id: string): Observable<any> {
    return this.http.patch<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante/${id}`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  add(globalParametresSanteId: string): Observable<any> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante/${globalParametresSanteId}`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  delete(parametreId: string): Observable<any> {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante/${parametreId}`).pipe(this.responsePipe)
  }

  getDeactivatedParametresSante(): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  update(doctorParametreSanteId: string, updates: DoctorParameterSante): Observable<any> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/parametres-sante/${doctorParametreSanteId}`, {
      param: {
        paramFamille: updates.parametreSante.group,
        paramLibelle: updates.parametreSante.label,
        paramType: updates.parametreSante.type,
        paramUnite: updates.parametreSante.unit,
        splitValues: updates.parametreSante.splitValues,
        paramDescription: updates.parametreSante.description

      },
      rangeIndex: updates.order
    }, { responseType: 'json' }).pipe(this.responsePipe)
  }

  global(pageRequest?: PageRequest): Observable<any> {
    if (pageRequest) {
      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/global/parametres-sante`, { params: params, responseType: 'json' }).pipe(this.responsePipe);
    }
    else return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/global/parametres-sante/all`, { responseType: 'json' }).pipe(this.responsePipe);
  }

  globalSearch(query: string, pageRequest?: PageRequest): Observable<any> {
    let params = new HttpParams();
    params = params.append('q', query);
    if (pageRequest) {
      if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
      if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/global/parametres-sante/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
    }
    else
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/global/parametres-sante/all/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe)
  }
}
