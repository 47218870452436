  <nav class="tree-nav scroll">
    <ng-container *ngIf="doctors.length;else empty">
        <div *ngFor="let doctor of doctors | paginate: { itemsPerPage: perPage, currentPage: currentPage }">
          <div *ngIf="doctor.owner!=null;else owner" class="flex">
            <span class="label">{{doctor.owner?.firstName}} {{doctor.owner?.lastName}}</span>
            <button name="" id="" class="btn btn-primary" (click)="onShareRequested(doctor.owner.id)">Partger</button>
          </div>
          <ng-template #owner >
            <div class="flex">
                <span class="label">{{doctor?.name}} </span>
                <button name="" id="" class="btn btn-primary" [disabled]="doctor.owner==null">Partger</button>
            </div>
         
          </ng-template>
      </div>
    </ng-container>

    <ng-template #empty>
      <tr>
        <td colspan="8" style="text-align: center;">
          <span style="text-align: center; width: 100%;">
            Pas de rendez-vous !
          </span>
        </td>
      </tr>
    </ng-template>
    <app-pagination [pageRequest]="pageRequest" (content)="doctors = $event"></app-pagination>

  </nav>
