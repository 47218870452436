import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/entities/api-response.entity/api-response';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { TextFillings } from 'src/app/entities/text-fillings.entity/text-fillings';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TextFillingsService extends ApiService {

  addTextFillings(data: TextFillings): Observable<TextFillings> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/doctor/text-fillings`, ({
      label: data.label,
      fillings: data.fillings.map(elm => ({
        label: elm.label,
        textContent: elm.textContent
      }))
    }), { responseType: 'json' }).pipe(this.responsePipe)
  }
  getAllTextFillingsDoctor(): Observable<Array<TextFillings>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/text-fillings`).pipe(this.responsePipe)
  }
  updateTextFillingsDoctor(id: string, data: TextFillings): Observable<TextFillings> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor/text-fillings/${id}`, ({
      label: data.label,
      fillings: data.fillings.map(elm => ({
        label: elm.label,
        textContent: elm.textContent
      }))
    }), { responseType: 'json' }).pipe(this.responsePipe)
  }
  deleteTextFillingsDoctor(id: string): Observable<void> {
    return this.http.delete<ApiResponse>(`${environment.API_URL}/doctor/text-fillings/${id}`).pipe(this.responsePipe)
  }
}
