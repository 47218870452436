import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-appointments-table-rest',
  templateUrl: './doctor-appointments-table-rest.component.html',
  styleUrls: ['./doctor-appointments-table-rest.component.css']
})
export class DoctorAppointmentsTableRestComponent implements OnInit {

  constructor() { }
  @Input() appointmentsData: any[] = [];

  ngOnInit(): void {
    this.loadData();
  }

  loadData() { }
}
