<div class="row row-grid" *ngIf="doctorConsultations.length;else empty">
  <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let consultation of doctorConsultations  | paginate: { itemsPerPage: perPage, currentPage: currentPage } ;let i = index">
    <div class="card widget-profile pat-widget-profile">
      <div class="card-body">
        <div class="pro-widget-content">
          <div class="profile-info-widget">
            <a class="booking-doc-img">
              <img [src]="consultation.appointment.patient| avatarSrc" [alt]="consultation.appointment.patient.getFullName()" image />
            </a>
            <div class="profile-det-info">
              <h3><a class="label">{{ consultation.appointment.patient.getFullName()}}</a></h3>
              <div class="patient-details">
                <h5><b>Date Consultation:</b> {{consultation.date | date:'dd/MM/YYYY'}}</h5>
                <h5 class="mb-0">
                  {{consultation.startTime | date:'hh:mm' }}
                </h5>
                <span *ngFor="let tag of consultation.motifTags" style="display: block;" class="tag-td mx-1 my-1"
                  style="float: left; margin-right: 5px;border-radius: .3rem;color:white" [ngStyle]="{'background-color':tag.color || 'gray'}">{{tag.text}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="table-action">
          <a *ngIf="doctor.speciality.label != 'Ophtalmologue' && doctor.consultationCNAMAP4" title="View ordonnance Consultation"
            [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'ap4']" class="btn btn-sm bg-info-light mx-2">
            <span>AP4</span>
          </a>
          <a *ngIf="doctor.speciality.label != 'Ophtalmologue' && doctor.consultationCNAMAP4" title="View ordonnance Consultation"
            [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'ap3']" class="btn btn-sm bg-danger-light mx-2">
            <span>AP3</span>
          </a>
          <a *ngIf="doctor.speciality.label != 'Ophtalmologue' && doctor.consultationCNAMAP4" title="View ordonnance Consultation"
            [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'ap2']" class="btn btn-sm bg-success-light mx-2">
            <span>AP2</span>
          </a>
          <a *ngIf="doctor.speciality.label=='Ophtalmologue'" title="View ordonnance Consultation"
            [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'ordonnance']" class="btn btn-sm bg-info-light mx-2">
            <i class="feather-printer"></i>
          </a>
          <a title="Update Consultation" [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'edit']"
            class="btn btn-sm bg-success-light ">
            <i class="feather-edit-3"></i>
          </a>
          <a title="Delete Consultation" (click)="onDeleteConsultation(i)" class="btn btn-sm bg-danger-light">
            <i class="feather-trash"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #empty>
  <tr>
    <td colspan="8" style="text-align: center;">
      <span style="text-align: center; width: 100%;">
        Pas de Consultation !
      </span>
    </td>
  </tr>
</ng-template>

<app-pagination [pageRequest]="pageRequest" (content)="doctorConsultations = $event"></app-pagination>