import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[dataTableItem]'
})
export class DataTableItemDirective<T> implements OnInit {

  @Input() set dataTableItem(v: any) {

  }

  @Input() set items(items: Array<T>) {
    this._clearItemsView();
    this._loadItemsView(items);
  }

  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }

  ngOnInit(): void {

  }

  private _loadItemsView(source: Array<any>): void {
    for (let item of source) {
      this.viewContainer.createEmbeddedView(this.templateRef, { x: item }, source.indexOf(item));
    }
  }

  private _clearItemsView(): void {
    this.viewContainer.clear();
  }

}
