<app-modal [title]="title" (closed)="reject()" #modal>
    <div class="submit-section text-center row mt-5" [innerHTML]="subject">

    </div>
    <div class="submit-section text-center row mt-5" style="width: 25%;margin-left: 35%;">
        <div class="col-lg-6">
            <button class="btn btn-primary submit-btn" (click)="accept()">Oui</button>
        </div>
        <div class="col-lg-6">
            <button class="btn btn-danger submit-btn" (click)="reject()">NON</button>
        </div>
    </div>
</app-modal>