import { Injectable, QueryList, ViewChildren } from '@angular/core';
import { ProgressSplashScreenComponent } from '../progress-splash-screen.component/progress-splash-screen.component';

@Injectable({
  providedIn: 'root'
})
export class ProgressSplashScreenService {
  @ViewChildren(ProgressSplashScreenComponent, { read: ProgressSplashScreenComponent }) views!: QueryList<ProgressSplashScreenComponent>;
  constructor() { }

  show(id?: string): void {
    let target: ProgressSplashScreenComponent | undefined = this.getTargetView();
    if (!target) console.warn(`No target view found ${id ? 'with id ' + id : ''}`)
    else target.isActive = true;
  }

  dismiss(id?: string): void {
    let target: ProgressSplashScreenComponent | undefined = this.getTargetView();
    if (!target) console.warn(`No target view found ${id ? 'with id ' + id : ''}`)
    else target.isActive = false;
  }

  toggle(id?: string): void {
    let target: ProgressSplashScreenComponent | undefined = this.getTargetView();
    if (!target) console.warn(`No target view found ${id ? 'with id ' + id : ''}`)
    else target.toggle()
  }

  private getTargetView(id?: string): ProgressSplashScreenComponent | undefined {
    return id ? this.views.find(view => view.id == id) : this.views.get(0);
  }
}
