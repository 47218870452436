import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RadiologyConsultationItem } from 'src/app/entities/consultation.entity';
import { RadiologiesService } from 'src/app/services/radiologies.service/radiologies.service';
export class ConsultationItemValidation {
  controlsValidation: Record<symbol, symbol | null> = {}
  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every(elm => elm === null)
  }
}

export class RadioConsultationnItemValidation extends ConsultationItemValidation {
  controlsValidation: { radio: 'required' | null, note: null } = {
    radio: null,
    note: null,
  };

  constructor(controlsValidation: { radio: 'required' | null, note: | null }) {
    super()
    this.controlsValidation = controlsValidation
  }
}
@Component({
  selector: 'app-radiology-consultation-forms',
  templateUrl: './consultation-radiology-formcomponent.html',
  styleUrls: ['./consultation-radiology-formcomponent.css']
})
export class ConsultationRadiologyFormComponent implements OnInit {

  @Input() radiologyConsultationItems: Array<RadiologyConsultationItem> = new Array();
  @Output() radiologyConsultationItemsChange: EventEmitter<Array<RadiologyConsultationItem>> = new EventEmitter();
  @Input() submitted: boolean = false;
  @Output() validation: EventEmitter<boolean> = new EventEmitter()
  radiologies: Array<any> = new Array();
  constructor(private radiologiesService: RadiologiesService, private toasterService: ToastrService) { }
  isValid(): boolean {
    return this.radiologyConsultationItems.every(item => this.validateConsultationItem(item).isValid)
  }
  get groupedRadiologies(): Array<{ keyName: string, items: Array<{ label: string, id: string }> }> {
    let groups: Array<string> = Array.from(this.radiologies, r => r.type_Radio)
    return Array.from(groups, group => {
      let items: Array<{ label: string, id: string }> = new Array();
      this.radiologies.forEach(radio => {
        if (group == radio.type_Radio) items.push({ label: radio.nom_Radio, id: radio.id_RADIO });
      })
      return {
        keyName: group, items: items
      };
    });
  }

  ngOnInit(): void {
    this.radiologiesService.allDoctor().subscribe(res => { this.radiologies = res; }, error => this.toasterService.error('Enable to load radiologies'))
  }

  addPrescriptionItem(): void {
    this.radiologyConsultationItems.push(new RadiologyConsultationItem({}));
    this._change();

  }

  deletePrescriptionItem(index: number): void {
    this.radiologyConsultationItems.splice(index, 1);
    this._change();

  }
  validateConsultationItem(radioConsultationItem: RadiologyConsultationItem): RadioConsultationnItemValidation {
    return new RadioConsultationnItemValidation({
      radio: null,//!radioConsultationItem.radio.id_RADIO ? "required" : null,
      note: null,
    });
  }
  onEditPrescriptionItem(index: number): void {
    this._change();

  }
  _change() {
    this.radiologyConsultationItemsChange.emit(this.radiologyConsultationItems)
    this.validation.emit(this.isValid())
  }
}
