<div class="flex-rounder">
  <div class="progress-wrapper">
    <div class="current" [ngStyle]="getOverlayStyle()">
      {{ numberCurrentPatient }}/{{ numberMoisPrePatient }}
    </div>
    <app-progress-bar
      [current]="numberCurrentPatient"
      [max]="numberMoisPrePatient"
      [color]="gradient ? 'url(#gradient)' : color"
      [background]="background"
      [radius]="radius"
      [stroke]="stroke"
      [semicircle]="semicircle"
      [rounded]="rounded"
      [clockwise]="clockwise"
      [responsive]="responsive"
      [duration]="duration"
      [animation]="animation"
      [animationDelay]="animationDelay"
    ></app-progress-bar>
  </div>
  <span class="projection-patient">Projection Patient</span>
</div>