<ng-container *ngIf="old<=18;else notAvailable">
    <span style="font-size: 22px;text-align: center;">Courbe de croissance : Age : <mark>{{yearsOld}}</mark> ans Résultat IMC: <mark>{{bmi}}</mark> Poids :<mark>{{weight}}</mark>
        Kg
        Taille :<mark>{{height}}</mark> cm</span>
    <div class="bmi-container">
        <div class="repair-x-top">
            <span *ngFor="let _ of [].constructor(18) ; let i = index" style="text-align: right;font-weight: bold;width: 100%;">{{i+1}}</span>
        </div>
        <div class="repair-y-left">
            <span *ngFor="let _ of [].constructor(30) ; let i = index" style="text-align: right;font-weight: bold;height: 100%;">{{(30-i)+10}}</span>
        </div>
        <div class="bmi">
            <span class="bmi-marker" [ngStyle]="{'bottom':((coordinations.y * yStep)-1)+'%','left':((coordinations.x * xStep)) + '%'}">&bull;</span>
            <img width="420px" height="660px" [src]="gender == 0? 'assets/img/bmi-boy.png':'assets/img/bmi-girl.png'">
        </div>
        <div class="repair-x-bottom">
            <span *ngFor="let _ of [].constructor(18) ; let i = index" style="text-align: right;font-weight: bold;width: 100%;">{{i+1}}</span>
        </div>
        <div class="repair-y-right">
            <span *ngFor="let _ of [].constructor(30) ; let i = index" style="text-align: left;font-weight: bold;height: 100%;">{{(30-i)+10}}</span>
        </div>
    </div>
</ng-container>
<ng-template #notAvailable>
    <div class="empty">IMC est invalid pour l'age > 18</div>
</ng-template>