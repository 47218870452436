import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelFormPaymentComponent } from './model-form-payment.component/model-form-payment.component';
import { ListeModelPaymentComponent } from './liste-model-payment.component/liste-model-payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from 'src/app/common/common.module';
import { AngularEditorModule } from '@kolkov/angular-editor';



@NgModule({
  declarations: [
    ModelFormPaymentComponent,
    ListeModelPaymentComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AngularEditorModule,
    AppCommonModule
  ],
  exports:[
    ModelFormPaymentComponent,
    ListeModelPaymentComponent
  ]
})
export class ModelPaymentsModule { }
