import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appImagesCarrousel]'
})
export class ImagesCarrouselDirective implements OnInit {

  @Input() sources: Array<string> = new Array();
  @Input() timing: number = 0;
  @Input() current: number = 0;
  constructor(private element: ElementRef, private rendrer: Renderer2) {

  }
  ngOnInit(): void {
    this.setCurrent(this.current);
    if (this.timing > 0) this.start()
  }

  start(): void {
    setTimeout(() => {
      this.setCurrent();
      this.start()
    }, this.timing);
  }

  setCurrent(index?: number): void {
    this.current = !!index ? index : (this.current + 1) % this.sources.length;
    this.rendrer.setAttribute(this.element.nativeElement, 'src', this.sources[index != undefined ? index : this.current])
  }

}
