<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-lg-12 col-sm-12">
            <h4 class="appointment-form-title" *ngIf="dir=='fr'">Merci de saisir le code envoyé par SMS </h4>
            <h4 class="appointment-form-title" *ngIf="dir=='ar'"> الرجاء إدخال الرمز لإرساله عبر الرسائل القصيرة</h4>
        </div>
        <div class="col-lg-12 col-sm-12">
            <div class="form-group">
                <app-code-input [length]="5" [(value)]="code" dir="ltr"></app-code-input>
            </div>
            <span *ngIf="validating && !valid" class="invalid-feedback"></span>
        </div>
    </div>
</div>