import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-datepickeropen',
  templateUrl: './datepickeropen.component.html',
  styleUrls: ['./datepickeropen.component.css']
})
export class DatepickeropenComponent implements OnInit {
  currentMonth: Date = new Date();
  weeks: Date[][] = [];
  daysOfWeek: string[] = ['DIM', 'LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM'];

  constructor() { }

  ngOnInit(): void {
    this.generateCalendar();
  }

  generateCalendar(): void {
    const firstDayOfMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), 1);
    const lastDayOfMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 0);
    const firstDayOfWeek = firstDayOfMonth.getDay();
    const daysInMonth = lastDayOfMonth.getDate();

    const weeks: Date[][] = [];
    let currentWeek: Date[] = [];

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), i);
      if (currentDate.getDay() === 0 && currentWeek.length > 0) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
      currentWeek.push(currentDate);
    }

    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }

    this.weeks = weeks;
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
  }

  isCurrentMonth(date: Date): boolean {
    return date.getMonth() === this.currentMonth.getMonth() && date.getFullYear() === this.currentMonth.getFullYear();
  }

  prevMonth(): void {
    this.currentMonth.setMonth(this.currentMonth.getMonth() - 1);
    this.generateCalendar();
  }

  nextMonth(): void {
    this.currentMonth.setMonth(this.currentMonth.getMonth() + 1);
    this.generateCalendar();
  }
}
