import { Pipe, PipeTransform } from '@angular/core';
import { Consultation } from '../../../entities/consultation.entity';

@Pipe({
  name: 'searchDate'
})
export class SearchConsultationDatePipe implements PipeTransform {

  transform(items: Array<Consultation>, filter: Date): Consultation[] {
    filter = filter || new Date();
    let res = items.filter((elemnt) => {
      let resultat = new Date(elemnt.date).getTime() == filter.getTime()
      return resultat

    })
    return res
  }

}
