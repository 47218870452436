<table class="table table-hover">
    <thead>
        <tr>
            <th>{{language == 'fr'?'Nom du fichier':'اسم الملف'}}</th>
            <th>{{language == 'fr'?'Propriétaire':'صاحب الملف'}}</th>
            <th>{{language == 'fr'?'Date d`insertion':'تاريخ رفع الملف'}}</th>
            <th>{{language == 'fr'?'Description':'وصف'}}</th>
            <th>{{language == 'fr'?'Type':'نوع الملف'}}</th>
            <th>{{language == 'fr'?'Actions':'خيارات'}}</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="documents.length;else empty">
            <tr *ngFor="let document of documents | paginate: { itemsPerPage: perPage, currentPage: currentPage }">
                <td>
                    <img [src]="getIconUrl(document)" style="width: 42px;height: 42px;border-radius: 4px;" (click)="onViewRequested($event,document)">
                    {{document.fileName}}
                </td>
                <td>
                    {{document.owner?.getFullName()}}
                </td>
                <td>
                    {{ document.date | date:'dd/MM/yyyy HH:mm'}}
                </td>
                <td>{{ document.description || '---' }}</td>
                <td>{{['Autre','Radiologie','Biologie','Rapport'][document.subject]}}</td>
                <td>
                    <a style="margin-left: 6px;" (click)="onViewRequested($event,document)" class="btn btn-sm bg-success-light">
                        <i class="feather-play-circle"></i>
                    </a>
                    <a *ngIf="actions.canEdit" style="margin-left: 6px;" title="Mise à Jour" class="btn btn-sm bg-warning-light" (click)="onEditRequest.emit(document)">
                        <i class="feather-edit"></i>
                    </a>
                    <a *ngIf="actions.canShare" style="margin-left: 6px;" title="Paiement" class="btn btn-sm bg-primary-light" (click)="onShareRequest.emit(document)">
                        <i class="feather feather-share-2"></i>
                    </a>
                    <a *ngIf="actions.canDelete" style="margin-left: 6px;" title="Annuler" class="btn btn-sm bg-danger-light" (click)="onDeleteRequest.emit(document)">
                        <i class="feather-x"></i>
                    </a>
                </td>
            </tr>
        </ng-container>
        <ng-template #empty>
            <tr>
                <td colspan="8" style="text-align: center;">
                    <span style="text-align: center; width: 100%;">
                        {{emptyMessage?emptyMessage:'Pas des documents !'}}
                    </span>
                </td>
            </tr>
        </ng-template>
    </tbody>
</table>
<app-pagination [pageRequest]="pageRequest" (content)="documents = $event" [refresh]="this.refresh?.asObservable()"></app-pagination>
<app-file-viewer *ngIf="documentPreview" [src]="_getDocumentAttachmentPreviewSource(documentPreview)" (close)="documentPreview = null"></app-file-viewer>