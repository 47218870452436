<!-- Prescription Item -->
<div class="card card-table">
    <div class="card-body">
        <div class="prescription-items-container">
            <table class="table table-hover table-center">
                <thead>
                    <tr>
                        <th style="width: min-content;">Biologie (Saisir la première lettre)  <button class="openDialoge" [title]="'Ajouter Biologie'" (click)="drawerActive = !drawerActive">
                            <i class="fas fa-flask"></i>
                        </button> </th> 
                        <th style="width: 60%;">Notes </th>
                        <th style="max-width: 80px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let biologyPrescriptionItem of biologyPrescriptionItems;let i = index"
                        [ngStyle]="{'border':!validatePrescriptionItem(biologyPrescriptionItem).isValid && submitted?'1px solid red':'None'}">
                        <td style="width:41%">
                            <app-biology-select-dropdown [values]="biologies" [placeHolder]="'Biology is here'" [(value)]="biologyPrescriptionItem.biology"
                                (valueChange)="_change()">
                            </app-biology-select-dropdown>


                            <!-- <div
                                *ngIf="validatePrescriptionItem(biologyPrescriptionItem).controlsValidation['biology'] && submitted">
                                <span
                                    *ngIf="validatePrescriptionItem(biologyPrescriptionItem).controlsValidation['biology'] == 'required'"
                                    style="color: red"> Biologie est obligatoire </span>
                            </div> -->
                        </td>
                        <td>
                            <textarea class="form-control" style="height: 45px" (input)="onEditPrescriptionItem(i)" [(ngModel)]="biologyPrescriptionItem.note"
                                (ngModelChange)="_change()"></textarea>
                            <!-- <div
                                *ngIf="validatePrescriptionItem(biologyPrescriptionItem).controlsValidation['notes'] && submitted">
                                <span
                                    *ngIf="validatePrescriptionItem(biologyPrescriptionItem).controlsValidation['notes'] == 'required'"
                                    style="color: red"> Note est obligatoire </span>
                                </div> -->

                        </td>
                        <td>
                            <a href="javascript:void(0)" class="btn bg-danger-light trash" (click)="deletePrescriptionItem(i)"><i class="feather-trash-2">

                                </i>
                            </a>

                        </td>

                    <tr>
                        <td colspan="5">
                            <div class="add-more-item text-center">
                                <a href="javascript:void(0);" (click)="addPrescriptionItem()"><i class="feather-plus-circle"></i> Ajouter</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-drawer [active]="drawerActive" [title]="'Ajouter Biologie'">
    <div body style="height: 80%;">
        <app-biology-select-drawer  [(values)]="biologies"
        (valuesChange)="_change()" (closed)="closed()" [(biologesSelected)]="biologiesFilter"></app-biology-select-drawer>
      <!-- <app-doctor-patient-historics *ngIf="consultation.appointment.patient.id.length" [patientId]="consultation.appointment.patient.id"></app-doctor-patient-historics> -->
    </div>
  </app-drawer>
<!-- /Prescription Item -->