import { MILLISECONDS_IN_DAY, MILLISECONDS_IN_MINUTE } from "src/app/utils/date.util";
import { Orgianization } from "../orgianization.entity/orgianization";
export enum EAccountStatus {
  ENABLED
}
export enum EGroupSanguin {
  A_PLUS,
  A_MOINS,
  B_PLUS,
  B_MOINS,
  AB_PLUS,
  AB_MOINS,
  O_PLUS,
  O_MOINS,

}

export enum UserRole {
  PATIENT,
  DOCTOR,
  DOCTOR_SECRETARY,
  PHARMACIST,
  ADMIN,
}
export enum EUserGender {
  HOMME,
  FEMME,
}
export enum UserCivility {
  UNKNOWN,
  MD,
  M,
  DR,
  PR,
}
export enum EUserSocialCivility {
  SIGNLE,
  MARIED,
  DIVORCED,
}
export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  country: string;
  address: string;
  government: string,
  city: string;
  mobilePhone1: string;
  mobilePhone2: string;
  birthDate: Date;
  gender: EUserGender;
  socialCivility: EUserSocialCivility;
  sanguineGroup: EGroupSanguin;
  assuranceType: string;
  assuranceName: string;
  assuranceReference: string;
  avatar: string;
  role: UserRole;
  password?: string;
  accessToken?: string;
  orgianization: Orgianization | null;
  accountStatus: EAccountStatus;
  isAccompaniment: boolean;
  enabled: boolean;
  uniqueCode: string;
  cnamCode: string;
  language: string;
}

export class User implements IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  country: string;
  address: string;
  government: string;
  city: string;
  mobilePhone1: string;
  mobilePhone2: string;
  birthDate: Date;
  gender: EUserGender;
  socialCivility: EUserSocialCivility;
  sanguineGroup: EGroupSanguin;
  assuranceType: string;
  assuranceName: string;
  assuranceReference: string;
  avatar: string;
  role: UserRole;
  accessToken?: string | undefined;
  orgianization: Orgianization | null;
  accountStatus: EAccountStatus;
  isAccompaniment: boolean;
  enabled: boolean;
  cnamCode: string;

  uniqueCode: string;
  language: string;

  get old(): number {
    let d = new Date(this.birthDate);
    let age = new Date().getFullYear() - d.getFullYear();
    var m = new Date().getMonth() - d.getMonth();
    if (m < 0 || (m === 0 && new Date().getDate() < d.getDate())) {
      age = age - 1;
    }
    return age;
  }

  get calculateAge(): { years: number, months: number, days: number } {

    let ageInMilliseconds = Date.now() - this.birthDate.getTime();

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const millisecondsPerMonth = 30.44 * millisecondsPerDay;
    const millisecondsPerYear = 365.25 * millisecondsPerDay;

    // Calculate the age in years, months, and days
    let years = Math.floor(ageInMilliseconds / millisecondsPerYear);
    let remainingMilliseconds = ageInMilliseconds % millisecondsPerYear;
    let months = Math.floor(remainingMilliseconds / millisecondsPerMonth);
    remainingMilliseconds %= millisecondsPerMonth;
    let days = Math.floor(remainingMilliseconds / millisecondsPerDay);

    return { years: years, months: months + (years * 12), days: days + (years * 360) + (months * 30) };
  }

  get yearsOld(): number {
    let birthDate = new Date(this.birthDate);
    let currentDate = new Date();
    return currentDate.getFullYear() - birthDate.getFullYear();
  }

  public getFullName(inverted?: boolean): string {
    return inverted ? (this.lastName + ' ' + this.firstName) : (this.firstName + ' ' + this.lastName);
  }


  constructor(other?: Partial<IUser>) {
    this.id = other?.id || "";
    this.firstName = other?.firstName || "";
    this.lastName = other?.lastName || "";
    this.email = other?.email || "";
    this.country = other?.country || "";
    this.address = other?.address || "";
    this.government = other?.government || "";
    this.city = other?.city || "";
    this.mobilePhone1 = other?.mobilePhone1 || "";
    this.mobilePhone2 = other?.mobilePhone2 || "";
    this.birthDate = other?.birthDate || new Date(0);
    this.gender = Number(other?.gender) || EUserGender.HOMME;
    this.socialCivility = other?.socialCivility || EUserSocialCivility.SIGNLE;
    this.sanguineGroup = other?.sanguineGroup || EGroupSanguin.A_PLUS;
    this.assuranceType = other?.assuranceType || "";
    this.assuranceName = other?.assuranceName || "";
    this.assuranceReference = other?.assuranceReference || "";
    this.cnamCode = other?.cnamCode || ''
    this.avatar = other?.avatar || "";
    this.role = other?.role || UserRole.PATIENT;

    this.accessToken = other?.accessToken;
    this.orgianization = other?.orgianization || null;
    this.accountStatus = other?.accountStatus || EAccountStatus.ENABLED;
    this.isAccompaniment = other?.isAccompaniment || false;
    this.enabled = other?.enabled || false;
    this.uniqueCode = other?.uniqueCode || '';
    this.language = other?.language || '';
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  static fromResponse(res: any): User {
    return new User({
      id: res.id,
      firstName: res.firstName,
      lastName: res.lastName,
      email: res.email,
      country: res.country,
      address: res.address,
      government: res.government,
      city: res.city,
      mobilePhone1: res.mobilePhone1,
      mobilePhone2: res.mobilePhone2,
      birthDate: res.birthDate ? new Date(res.birthDate) : new Date(),
      gender: typeof (res.gender) === 'string' ? ['MALE', 'Feminine'].indexOf(res.gender) : Number(res.gender),
      socialCivility: res.socialCivility,
      sanguineGroup: res.sanguineGroup,
      avatar: res.avatar || res.photo,
      role: ["User", "Medecin", "Secretary", 'Pharmacist', "Admin"].indexOf(res.role),
      assuranceType: res.assuranceType,
      assuranceName: res.assuranceName,
      assuranceReference: res.assuranceReference,
      orgianization: res.orgianization || null,
      accountStatus: res.accountStatus,
      isAccompaniment: res.isAccompaniment,
      enabled: res.enabled,
      uniqueCode: res.uniqueCoded,
      cnamCode: res.cnamCode,
      language: res.language,
    })
  }
  classifyBMI(imc: number): { result: string, color: string } {
    return this.gender == EUserGender.HOMME ? this.classifyBMIMale(imc) : this.classifyBMIFemale(imc);
  }


  classifyBMIMale(imc: number): { result: string, color: string } {
    if (this.old == 0) {
      return this.classifyBMImale0mois(imc);
    }


    if (this.old >= 1 && this.old <= 2) {
      return this.classifyBMImale5moisto2(imc)
    }


    if (this.old > 2 && this.old <= 4) {
      return this.classifyBMImale2demito4demi(imc);
    }

    if (this.old >= 5 && this.old < 6) {
      return this.classifyBMImale5to6(imc);
    }
    if (this.old == 6) {
      return this.classifyBMImale6demi(imc);
    }
    if (this.old == 7) {
      return this.classifyBMImale7demi7demi(imc);
    }

    if (this.old == 8) {
      return this.classifyBMImale8demi8(imc);
    }
    if (this.old == 9) {
      return this.classifyBMImale9demi9(imc);
    }
    if (this.old == 10) {
      return this.classifyBMImale10demi10ans(imc);
    }
    if (this.old == 11) {
      return this.classifyBMImale11demi11ans(imc);
    }
    if (this.old == 12) {
      return this.classifyBMIMaleIntervalle12(imc);
    }


    if (this.old >= 13 && this.old <= 14) {
      return this.classifyBMIMaleIntervalle13to14ans(imc);
    }



    if (this.old >= 14 && this.old <= 16) {
      return this.classifyBMIMaleIntervalle14ansdemi16ans(imc);
    }

    if (this.old >= 16 && this.old <= 18) {
      return this.classifyBMIFemale16demians18ans(imc);
    }


    return this.classifyBMIMaleAdult(imc);


  }
  classifyBMIMaleUnder18(imc: number): { result: string, color: string } {
    if (imc < 18.5) {
      return { result: "Insuffisance pondérale", color: "blue" };
    } else if (imc < 23) {

      return { result: "Normal", color: "green" };
    } else {
      return { result: "Surpoids", color: "orange" };
    }
  }

  classifyBMIMaleAdult(imc: number): { result: string, color: string } {
    if (imc < 18.5) {

      return { result: "Insuffisance pondérale", color: "blue" };
    } else if (imc < 24.9) {
      return { result: "Normal", color: "green" };
    } else if (imc < 29.9) {
      return { result: "Surpoids", color: "orange" };
    } else {
      return { result: "Obèse", color: "red" };
    }
  }

  classifyBMIUnder1Year(imc: number): { result: string, color: string } {
    if (imc <= 16) {
      return { result: "Faible IMC pour un enfant  moins 1 an ", color: "blue" };

    }
    else if (imc <= 18) {

      return { result: "IMC normal pour un enfant  moins 1 an ", color: "green" };

    } else {

      return { result: "IMC élevé  pour un enfant  moins 1 an  ", color: "orange" };

    }
  }



  //from 16.5 to 18 years
  classifyBMIMaleIntervalle116demians18ans(imc: number): { result: string, color: string } {
    if (imc >= 25.18) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 28.20) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }



  classifyBMIMaleIntervalle14ansdemi16ans(imc: number): { result: string, color: string } {
    if (imc >= 24.05) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 27.26) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMIMaleIntervalle13to14ans(imc: number): { result: string, color: string } {
    if (imc >= 23.01) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 26.28) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMIMaleIntervalle12(imc: number): { result: string, color: string } {
    if (imc >= 22.25) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 25.44) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }



  //male 
  classifyBMImale11demi11ans(imc: number): { result: string, color: string } {
    if (imc >= 21.43) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 24.45) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMImale10demi10ans(imc: number): { result: string, color: string } {
    if (imc >= 20.60) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 23.35) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMImale9demi9(imc: number): { result: string, color: string } {
    if (imc >= 19.78) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 22.21) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMImale8demi8(imc: number): { result: string, color: string } {
    if (imc >= 19.01) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 21.11) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMImale7demi7demi(imc: number): { result: string, color: string } {
    if (imc >= 18.34) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 20.15) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }

  classifyBMImale6demi(imc: number): { result: string, color: string } {
    if (imc >= 18.07) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 19.75) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMImale5to6(imc: number): { result: string, color: string } {
    if (imc >= 17.61) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 19.02) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMImale2demito4demi(imc: number): { result: string, color: string } {
    if (imc >= 17.76) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 18.92) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }

  classifyBMImale5moisto2(imc: number): { result: string, color: string } {
    if (imc >= 18.66) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 19.72) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMImale0mois(imc: number): { result: string, color: string } {
    if (imc >= 14.28) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 15.01) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMImale1ans1demi(imc: number): { result: string, color: string } {
    if (imc >= 18.25) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 19.22) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMImale5mois(imc: number): { result: string, color: string } {
    if (imc >= 17.95) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 18.85) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }

  //male

  classifyBMIFemale(imc: number): { result: string, color: string } {
    if (this.old == 0) {
      return this.classifyBMIFemale0(imc);
    }

    if (this.old == 1) {
      return this.classifyBMIFemale1ans1demi(imc);
    }

    if (this.old == 2) {
      return this.classifyBMIFemale2(imc);
    }

    if (this.old >= 2 && this.old < 4) {
      return this.classifyBMIFemale2demi4demi(imc);
    }

    if (this.old >= 5 && this.old < 7) {
      return this.classifyBMIFemale5demi6demi(imc);
    }

    if (this.old >= 6 && this.old <= 7) {
      return this.classifyBMIFemale6demi7demi(imc);
    }

    if (this.old == 8) {
      return this.classifyBMIFemale8(imc);
    }

    if (this.old > 8 && this.old < 10) {
      return this.classifyBMIFemale9demi10ans(imc);
    }

    if (this.old > 9 && this.old < 11) {
      return this.classifyBMIFemale10demi11ans(imc);
    }

    if (this.old <= 12 && this.old >= 11) {
      return this.classifyBMIfemaleIntervalle11ans(imc);
    }

    if (this.old <= 13) {
      return this.classifyBMIfemaleIntervalle13ans(imc);
    }

    if (this.old >= 14 && this.old <= 16) {
      return this.classifyBMIFemale14ans16ans(imc);
    }

    if (this.old >= 16 && this.old <= 18) {
      return this.classifyBMIFemale16demians18ans(imc);
    }


    return this.classifyBMIFemaleAdult(imc);
  }




  classifyBMIFemaleUnder18(imc: number): { result: string, color: string } {
    if (imc < 18.5) {
      return { result: "Insuffisance pondérale", color: "blue" };
    } else if (imc < 23) {
      return { result: "Normal", color: "green" };
    } else {
      return { result: "Surpoids", color: "orange" };
    }

  }

  classifyBMIFemaleAdult(imc: number): { result: string, color: string } {
    if (imc < 18.5) {

      return { result: "Insuffisance pondérale", color: "blue" };
    } else if (imc < 24.9) {
      return { result: "Normal", color: "green" };
    } else if (imc < 29.9) {
      return { result: "Surpoids", color: "orange" };
    } else {
      return { result: "Obèse", color: "red" };
    }

  }
  classifyBMIFemale16demians18ans(imc: number): { result: string, color: string } {
    if (imc >= 25.02) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 27.69) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }
  classifyBMIFemale14ans16ans(imc: number): { result: string, color: string } {
    if (imc >= 24.05) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 27.01) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }

  //from 13 to 13.5 years
  classifyBMIfemaleIntervalle13ans(imc: number): { result: string, color: string } {
    if (imc >= 23.33) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 26.33) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "vert" };
    }
  }



  //from 11.5 to 12.5 years
  classifyBMIfemaleIntervalle11ans(imc: number): { result: string, color: string } {
    if (imc >= 22.04 && imc <= 26) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 25) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }

  classifyBMIFemale10demi11ans(imc: number): { result: string, color: string } {
    if (imc >= 21.20 && imc < 25) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 24.03) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
  classifyBMIFemale9demi10ans(imc: number): { result: string, color: string } {
    if (imc >= 20.42 && imc < 23.04) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 23.04) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
  classifyBMIFemale8demi9demi(imc: number): { result: string, color: string } {
    if (imc >= 19.65 && imc < 22.01) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 22.01) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
  classifyBMIFemale8(imc: number): { result: string, color: string } {
    if (imc >= 19.25 && imc < 21.47) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 21.47) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
  classifyBMIFemale6demi7demi(imc: number): { result: string, color: string } {
    if (imc >= 18.21 && imc <= 20.01) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 20.01) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }

  classifyBMIFemale5demi6demi(imc: number): { result: string, color: string } {
    if (imc >= 17.69 && imc < 19.16) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 19.16) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
  classifyBMIFemale2demi4demi(imc: number): { result: string, color: string } {
    if (imc >= 17.76 && imc <= 18.92) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 18.92) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
  classifyBMIFemale2(imc: number): { result: string, color: string } {
    if (imc >= 17.92) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 19.03) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
  classifyBMIFemale1ans1demi(imc: number): { result: string, color: string } {
    if (imc >= 18.25) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 19.22) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
  classifyBMIFemale5mois(imc: number): { result: string, color: string } {
    if (imc >= 17.95) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 18.85) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
  classifyBMIFemale0(imc: number): { result: string, color: string } {
    if (imc >= 14.12) {
      return { result: "Surpoids", color: "orange" };

    } else if (imc >= 14.81) {

      return { result: "Obése", color: "red" };
    } else {
      return { result: "Normal", color: "green" };
    }
  }
}


