import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PageRequest, PaginationPage } from 'src/app/common/modules/data-table.module/interfaces';
import { DoctorPatient } from 'src/app/entities/doctor-patient.entity/doctor-patient';
import { User, UserRole } from 'src/app/entities/user.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { DoctorPatientsService } from 'src/app/services/doctor-patients.service/doctor-patients.service';
import { DoctorSecretaryPatientsService } from 'src/app/services/doctor-secretary-appointments.service/doctor-secretary-patients.service/doctor-secretary-patients.service';



@Component({
  selector: 'app-doctor-patients-table',
  templateUrl: './doctor-patients-table.component.html',
  styleUrls: ['./doctor-patients-table.component.css']
})
export class DoctorPatientsTableComponent implements OnInit {
  @Input() headers: Array<{ label: string, sorted?: { key: string, order: number } }> = [{ label: 'Dossier N° ' }, { label: 'Patient' }, { label: 'Téléphone' }, { label: 'Date Naissance' }, { label: 'Ville' }, { label: 'Accompagnant' }, { label: 'Rdv' }]

  @Input() currentPage: number = 0;
  @Input() perPage: number = 10;
  @Input() pageRequest!: (pageRequest: PageRequest) => Promise<PaginationPage<DoctorPatient>>;
  @Output() onAssignAppointmentRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onAntecedentsRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onUpdateRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onDeleteRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onPrescriptionsRequest: EventEmitter<DoctorPatient> = new EventEmitter();
  @Output() onConsultationsRequest: EventEmitter<DoctorPatient> = new EventEmitter();

  @Output() onRefresh: EventEmitter<PageRequest> = new EventEmitter();

  @Input() actions: Partial<{ edit: boolean, delete: boolean, assignAppointment: boolean, prescriptionsView: boolean, consultationsView: boolean, antecentendsView: boolean, payementsView: boolean, badge: boolean }> = {}
  @Input() emptyMessage?: string;

  date: number = (new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).getTime();
  doctorPatients: Array<DoctorPatient> = new Array();


  sortedheadIndex: number = -1;


  get hasActions(): boolean { return Object.values(this.actions).some(e => e == true) }
  constructor(private doctorPatientsService: DoctorPatientsService, private doctorSecretaryPatientsService: DoctorSecretaryPatientsService, private authService: AuthService, private toasterService: ToastrService) { }

  get currentUser(): User {
    return this.authService.getCurrentUser()!;
  }

  ngOnInit(): void {
    this.currentPage = 0;
  }

  onSortRequested(headIndex: number, key: string): void {
    if (headIndex == this.sortedheadIndex) {
      this.sortedheadIndex = -1;
      this.onRefresh.emit()
    } else {
      this.sortedheadIndex = headIndex;

    }
  }

  onDelete(index: number): void {
    if (confirm(`Etez vous sure de vouloir supprimer ${this.doctorPatients[index].getFullName()} de votre liste des patients ?`))
      (this.currentUser.role == UserRole.DOCTOR_SECRETARY ? this.doctorSecretaryPatientsService : this.doctorPatientsService).delete(this.doctorPatients[index].id).subscribe(deletetion => {
        const deleted = this.doctorPatients[index];
        this.doctorPatients = this.doctorPatients.filter((e, i) => i != index)

        this.toasterService.success(`patient ${deleted.getFullName()} Supprimé`);
      }, error => {
        if (error.status === 501)
          this.toasterService.warning('Patient à des consultations ou bien des prescriptions')
        else
          this.toasterService.error(`Problème de suppression patient`);
      })

  }
}
