<div class="param-sante-form">
  <div class="param-sante-control-group" *ngFor="let parametreSanteFormGroupControler of parametreSanteForm.controlers;let i = index">
    <div *ngFor="let parametreSanteFormControler of parametreSanteForm.controlers[i].controlers;let j = index">
      <div class="param-sante-control-form">
        <label>{{ parametreSanteFormControler.target.label }} : </label>
        <input *ngIf="parametreSanteFormControler.target.type < 3" [type]="[ 'number', 'date','text','checkbox'][parametreSanteFormControler.target.type]"
          [(ngModel)]="parametreSanteForm.controlers[i].controlers[j].value" name="{{parametreSanteFormControler.target.label}}" (change)="_onChange()">
        <input *ngIf="parametreSanteFormControler.target.type == 3" type="checkbox" style="cursor: pointer;" [(ngModel)]="parametreSanteForm.controlers[i].controlers[j].value"
          (change)="_onChange()">
        <select [name]="parametreSanteFormControler.target.label" (change)="_onChange()" *ngIf="parametreSanteFormControler.target.type === 4"
          [(ngModel)]="parametreSanteForm.controlers[i].controlers[j].value">
          <option [value]="''" selected>Selectionner</option>
          <option *ngFor="let option of parametreSanteFormControler.target.splitValues.split(',')" [value]="option">
            {{option}}</option>
        </select>
        <a [title]="parametreSanteFormControler.target.description"><i  class="feather feather-help-circle c-pointer"></i></a>
       
        <!--<i class="feather feather-file c-pointer"></i>-->
      </div>
      <div class="param-sante-control-note">
        <textarea [(ngModel)]="parametreSanteForm.controlers[i].controlers[j].note" placeholder="note" rows="1" name="note" (change)="_onChange()"></textarea>
      </div>
    </div>
  </div>
</div>