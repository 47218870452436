import { Component, Input, OnInit } from '@angular/core';
import { DalyTimeSlot } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { TimeBoarding } from 'src/app/entities/time-boarding.entity/time-boarding';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { PublicApisService } from 'src/app/services/public-apis.service/public-apis.service';

@Component({
  selector: 'app-doctor-date-hours-calander',
  templateUrl: './doctor-date-hours-calander.component.html',
  styleUrls: ['./doctor-date-hours-calander.component.css']
})
export class DoctorDateHoursCalanderComponent implements OnInit {
  DAYS_IN_WEEK = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

  @Input() doctor: Doctor = new Doctor();
  timeBoarding!: TimeBoarding;
  currentDayIndex: number = new Date().getDay();
  selectedTimeSlot!: DalyTimeSlot;

  get dayTimeSlots(): Array<DalyTimeSlot> {
    return this.timeBoarding.dailyTimeSlots.filter(e => e.dayIndex == this.currentDayIndex);
  }

  constructor(private publicApiService: PublicApisService) { }

  ngOnInit(): void {
    this.publicApiService.getDoctorActualTimeBoarding(this.doctor.id).subscribe(timeBoarding => {
      this.timeBoarding = TimeBoarding.fromResponse(timeBoarding);
    }, error => {
      console.error(error)
    })
  }

  onSelectedTimeSlot(selectedTimeSlot: DalyTimeSlot): void {
    this.selectedTimeSlot = selectedTimeSlot;
  }

}
