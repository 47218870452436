import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
import { CkeditorComponent } from './components/ckeditor.component/ckeditor.component';
import { DatepickerComponent } from './components/datepicker.component/datepicker.component';
import { DrawerComponent } from './components/drawer.component/drawer.component';
import { ScrollbarModule } from './modules/scrollbar.module/scrollbar.module';
import { TagsInputComponent } from './components/tags-input.component/tags-input.component';
import { TimeInputComponent } from './components/time-input.component/time-input.component';
import { ToggleSwitchComponent } from './components/toggle-switch.component/toggle-switch.component';
import { ImgDirective } from './directives/img.directive/img.directive';
import { NumberInputDirective } from './directives/number-input.directive/number-input.directive';
import { AccordionModule } from './modules/accordion.module/accordion.module';
import { AutocompleteModule } from './modules/autocomplete.module/autocomplete.module';
import { DatePickerModule } from './modules/date-picker.module/date-picker.module';
import { ModalModule } from './modules/modal.module/modal.module';
import { TreeViewModule } from './modules/tree-view.module/tree-view.module';
import { AvatarSrcPipe } from './pipes/avatar-src/avatar-src.pipe';
import { FilterPipe } from './pipes/filter.pipe/filter.pipe';
import { FrenchDatePipe } from './pipes/french-date.pipe/french-date.pipe';
import { GroupPipe } from './pipes/group.pipe/group.pipe';
import { TimePipe } from './pipes/time.pipe/time.pipe';
import { SelectDropDownModule } from './modules/select-drop-down.module/select-drop-down.module';
import { PaginatorModule } from './modules/paginator.module/paginator.module';
import { ProgressSplashScreenModule } from './modules/progress-splash-screen.module/progress-splash-screen.module';
import { ListFilterPipe } from './pipes/list-filter.pipe/list-filter.pipe';
import { BreadcampComponent } from './components/breadcamp.component/breadcamp.component';
import { RouterModule } from '@angular/router';
import { DropDownMenuModule } from './modules/drop-down-menu.module/drop-down-menu.module';
import { FileViewerModule } from './modules/file-viewer.module/file-viewer.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DatepickeropenComponent } from './components/datepickeropen.component/datepickeropen.component';

import { SortPipe } from './pipes/sort.pipe/sort.pipe';
import { DurationInputComponent } from './components/duration-input/duration-input.component';
import { CodeInputComponent } from './components/code-input.component/code-input.component';
import { LoaderComponent } from './components/loader.component/loader.component';
import { PromptDialogComponent } from './components/prompt-dialog.component/prompt-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component/confirm-dialog.component';
import { DataTableModule } from './modules/data-table.module/data-table.module';
import { FilterBiologiePipe } from './pipes/biologie.pipe/filter-biologie.pipe';
import { RadioFilterPipe } from './pipes/radio-filter.pipe/radio-filter.pipe';
import { ImagesCarrouselDirective } from './directives/images-carrousel.directive/images-carrousel.directive';
import { MedicFilterPipe } from './pipes/medic.pipe/medic-filter.pipe';
import { StepperModule } from './modules/stepper.module/stepper.module';
import { InputModule } from './modules/input.module/input.module';
import { DurationPipe } from './pipes/duration.pipe/duration.pipe';
import { TimeCounterComponent } from './components/time-counter.component/time-counter.component';


@NgModule({
  declarations: [
    FrenchDatePipe,
    TimePipe,
    AvatarSrcPipe,
    FilterPipe,
    GroupPipe,
    ListFilterPipe,
    FilterBiologiePipe,
    RadioFilterPipe,
    SortPipe,
    TagsInputComponent,
    DatepickerComponent,
    ToggleSwitchComponent,
    TimeInputComponent,
    CkeditorComponent,
    DrawerComponent,
    BreadcampComponent,
    DatepickeropenComponent,
    BreadcampComponent,
    DurationInputComponent,
    CodeInputComponent,
    LoaderComponent,
    PromptDialogComponent,
    ConfirmDialogComponent,
    NumberInputDirective,
    ImagesCarrouselDirective,
    ImgDirective,
    MedicFilterPipe,
    DurationPipe,
    TimeCounterComponent,
  ],
  imports: [
    CommonModule,
    StepperModule,
    DataTableModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteModule,
    AccordionModule,
    NgxPaginationModule,
    CKEditorModule,
    ScrollbarModule,
    TreeViewModule,
    NgxExtendedPdfViewerModule,
    PdfJsViewerModule,
    DatePickerModule,
    SelectDropDownModule,
    PaginatorModule,
    ProgressSplashScreenModule,
    DropDownMenuModule,
    FileViewerModule,
    AngularEditorModule,
    RouterModule,
    InputModule
  ],
  exports: [
    CommonModule,
    InputModule,
    StepperModule,
    DataTableModule,
    ModalModule,
    SelectDropDownModule,
    AutocompleteModule,
    AccordionModule,
    TreeViewModule,
    NgxPaginationModule,
    CKEditorModule,
    NgxExtendedPdfViewerModule,
    ScrollbarModule,
    PdfJsViewerModule,
    DatePickerModule,
    PaginatorModule,
    ProgressSplashScreenModule,
    DropDownMenuModule,
    FileViewerModule,
    AngularEditorModule,
    FrenchDatePipe,
    AvatarSrcPipe,
    TimePipe,
    FilterPipe,
    GroupPipe,
    SortPipe,
    ListFilterPipe,
    ImgDirective,
    NumberInputDirective,
    ImagesCarrouselDirective,
    DatepickerComponent,
    ToggleSwitchComponent,
    TimeInputComponent,
    TagsInputComponent,
    CkeditorComponent,
    DrawerComponent,
    BreadcampComponent,
    DatepickeropenComponent,
    DurationInputComponent,
    CodeInputComponent,
    LoaderComponent,
    PromptDialogComponent,
    ConfirmDialogComponent,
    TimeCounterComponent,
    FilterBiologiePipe,
    RadioFilterPipe,
    MedicFilterPipe,
    DurationPipe
  ],
  providers: [
  ]
})
export class AppCommonModule { }

