import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Observer, Subscriber } from 'rxjs';
export interface DialogResult {
  data: any,
  accepted: boolean
}
@Component({
  selector: 'prompt-dialog',
  templateUrl: './web/prompt-dialog.html',
  styleUrls: ['./web/prompt-dialog.scss']
})
export class PromptDialogComponent implements OnInit {
  @Input() title: string = '';
  @Input() type: 'file' | 'text' | 'number' = 'text';
  @Output() onDismiss: EventEmitter<DialogResult> = new EventEmitter<DialogResult>();
  subscriber: Subscriber<DialogResult> | null = null;
  data: any;
  constructor() { }

  ngOnInit() {
  }

  fileChanged($event: any) {
    let reader = new FileReader();
    reader.addEventListener("loadend", $event => {
      let result = (<any>($event.target)).result.toString();
      this.data = result;
    })
    reader.addEventListener("error", $event => {
      alert("Could not read this file ! may be not har file");
      this.dismiss(false);

    })
    reader.readAsText($event.target.files[0]);
  }

  open(type: any): Observable<DialogResult> {
    if (type) this.type = type;
    this.data = null;
    return new Observable<DialogResult>(subscriber => {
      this.subscriber = subscriber;
    });
  }

  dismiss(accpet: boolean) {
    if (this.subscriber) {
      let result = { accepted: accpet, data: this.type == 'number' ? Number.parseInt(this.data) : this.data };
      this.onDismiss.emit(result);
      this.subscriber.next(result);
      this.subscriber.complete();
      this.subscriber = null;
    }
  }
}
