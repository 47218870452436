import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientAppointmentBill } from 'src/app/entities/appointment-bill.entity';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { User } from 'src/app/entities/user.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { KonnectPaymentService } from 'src/app/services/konnect-payment.service/konnect-payment.service';
import { PatientAppointmentsService } from 'src/app/services/patient-appointments.service/patient-appointments.service';
import { PaymeService } from 'src/app/services/payme.service/payme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-booking-payment',
  templateUrl: './appointment-booking-payment.component.html',
  styleUrls: ['./appointment-booking-payment.component.css']
})
export class AppointmentBookingPaymentComponent implements OnInit {
  @Input() patientAppointment!: PatientAppointment;
  @Output() complete: EventEmitter<PatientAppointment> = new EventEmitter();
  patientAppointmentBill?: PatientAppointmentBill;
  payMeUrl!: SafeResourceUrl;
  get patient(): User | null {
    return this.authService.getCurrentUser();
  }

  constructor(private router: Router, private patientAppointmentsService: PatientAppointmentsService, private payMeService: PaymeService, private konnectPaymentService: KonnectPaymentService, private authService: AuthService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    const observableBill: Observable<PatientAppointmentBill> = this.patient ? this.patientAppointmentsService.bill(this.patientAppointment.id) : this.patientAppointmentsService.billByConfidentialCode(this.patientAppointment.id)
    observableBill.subscribe(patientAppointmentBill => {
      this.patientAppointmentBill = PatientAppointmentBill.fromResponse(patientAppointmentBill);

      if (environment.PAYMENT_SERVICE == 'PAYME') {
        this.payMeService.initTransaction({
          order_id: this.patientAppointment.id,
          amount: patientAppointmentBill.totalAmount,
          first_name: this.patient?.firstName || this.patientAppointment.patientFirstName,
          last_name: this.patient?.lastName || this.patientAppointment.patientLastName,
          email: this.patient?.email! || '',
          phone: this.patient?.mobilePhone1 || this.patientAppointment.patientMobilePhone,
          webhook_url: `${environment.API_URL}/api/webhooks/payments/${this.patientAppointmentBill.id}`,
          //return_url: `https://dashboard.${domaineName}/patient/appointments`,
          // cancel_url: `https://dashboard.${domaineName}/patient/appointments`,
        }).subscribe(response => {
          this.payMeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.payment_url);
          const callBack = () => this.complete.emit(this.patientAppointment)
          window.addEventListener('message', function (event) {
            if (event.data.event_id === 'paymee.complete') callBack();
          }, false);
        });
      }
      else if (environment.PAYMENT_SERVICE == 'KONNECT') {
        this.konnectPaymentService.initTransaction({
          receiverWalletId: this.patientAppointment.id,
          token: 'TND',
          amount: patientAppointmentBill.totalAmount,
          type: 'immediate',
          description: `Invoice Id ${this.patientAppointmentBill.id}`,
          acceptedPaymentMethods: [
            "bank_card",
            "e-DINAR",
            "wallet",
            "flouci"
          ],
          lifespan: 10,
          checkoutForm: false,
          addPaymentFeesToAmount: true,
          firstName: this.patientAppointment.requested ? this.patientAppointment.patientFirstName : this.patient?.firstName || '',
          lastName: this.patientAppointment.requested ? this.patientAppointment.patientLastName : this.patient?.lastName || '',
          phoneNumber: this.patientAppointment.requested ? this.patientAppointment.patientMobilePhone : this.patient?.mobilePhone1 || '',
          email: this.patient?.email || '',
          orderId: this.patientAppointmentBill.id,
          webhook: `${environment.API_URL}/webhooks/konnect/${this.patientAppointmentBill.id}`,
          silentWebhook: true,
          theme: 'light'
        }).subscribe(response => {
          this.payMeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.payUrl);
          const callBack = () => this.complete.emit(this.patientAppointment)
          window.addEventListener('message', function (event) {
            if (event.data === 'paymee.complete') callBack();
          }, false);
        });
      }
    });
  }

}
