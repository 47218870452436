import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { VaccinConsultationItem } from 'src/app/entities/consultation.entity';
import { DoctorVaccinationsService } from 'src/app/services/doctor-vaccinations.service/doctor-vaccination.service';


export class ConsultationItemValidation {
  controlsValidation: Record<symbol, symbol | null> = {}
  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every(elm => elm === null)
  }
}

export class VaccinConsultationnItemValidation extends ConsultationItemValidation {
  controlsValidation: { numLot: null | 'required', vaccin: 'required' | null, date: 'required' | 'number' | 'min' | null } = {
    vaccin: null,
    numLot: null,
    date: null
  };
  constructor(controlsValidation: { numLot: null | 'required', vaccin: 'required' | null, date: 'required' | 'number' | 'min' | null }) {
    super()
    this.controlsValidation = controlsValidation
  }

  get isValid(): boolean {
    return Array.from(Object.values(this.controlsValidation)).every(elm => elm === null)
  }
}
@Component({
  selector: 'app-vaccin-consultation-forms',
  templateUrl: './consultation-vaccination-form.component.html',
  styleUrls: ['./consultation-vaccination-form.component.css']
})
export class ConsultationVaccinationFormComponent implements OnInit {

  @Input() vaccinesConsultationItems: Array<VaccinConsultationItem> = new Array();
  @Output() vaccinesConsultationItemsChange: EventEmitter<Array<VaccinConsultationItem>> = new EventEmitter();
  @Output() validation: EventEmitter<boolean> = new EventEmitter()


  @Input() set submitted(v: boolean) {
    this._submitted = v;
    this.vaccinesConsultationItems = this.vaccinesConsultationItems.filter(e => e.vaccin != null && e.vaccin.id_Vaccin != null && e.vaccin.id_Vaccin.length > 0)
    this._change()
  }
  vaccines: Array<any> = new Array();
  private _submitted: boolean = false;
  get submitted(): boolean {
    return this._submitted;
  }

  get isValid(): boolean {
    return this.vaccinesConsultationItems.every(item => this.validateConsultationItem(item).isValid)
  }
  constructor(private doctorVaccinationsService: DoctorVaccinationsService, private toasterService: ToastrService) { }

  get groupedvaccines(): Array<{ keyName: string, items: Array<{ label: string, id: string }> }> {
    let groups: Array<string> = Array.from(this.vaccines, r => r.nom_Labo_Vaccin)
    return Array.from(groups, group => {
      let items: Array<{ label: string, id: string }> = new Array();
      this.vaccines.forEach(vaccin => {
        if (group == vaccin.nom_Labo_Vaccin) items.push({ label: vaccin.nom_Vaccin, id: vaccin.id_Vaccin });
      })
      return {
        keyName: group, items: items
      };
    });
  }

  ngOnInit(): void {
    this.doctorVaccinationsService.all().subscribe(res => { this.vaccines = res; }, error => this.toasterService.error('Enable to load vaccines'))
  }

  addConsultationVaccinationItem(): void {
    let vaccinConsultationItem: VaccinConsultationItem = new VaccinConsultationItem({});
    //vaccinConsultationItem.vaccin = null as any;
    this.vaccinesConsultationItems.push(vaccinConsultationItem);
    this._change()
  }

  deletePrescriptionItem(index: number): void {
    this.vaccinesConsultationItems.splice(index, 1);
  }

  validateConsultationItem(vaccinConsultationItem: VaccinConsultationItem): VaccinConsultationnItemValidation {
    return vaccinConsultationItem.vaccin && vaccinConsultationItem.vaccin.id_Vaccin && vaccinConsultationItem.vaccin.id_Vaccin.length > 0 ? new VaccinConsultationnItemValidation({
      vaccin: null,
      numLot: null,
      date: vaccinConsultationItem.date_consultation_vaccin ? (!isNaN(Number(vaccinConsultationItem.date_consultation_vaccin)) ? (Number(vaccinConsultationItem.date_consultation_vaccin) < 1 ? 'min' : null) : 'number') : 'required'
    }) : new VaccinConsultationnItemValidation({ vaccin: null, numLot: null, date: null });
  }

  onEditPrescriptionItem(index: number): void {
    this._change()
  }

  _change() {
    this.vaccinesConsultationItemsChange.emit(this.vaccinesConsultationItems)
    this.validation.emit(this.isValid)
  }
}
