import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcamp',
  templateUrl: './breadcamp.component.html',
  styleUrls: ['./breadcamp.component.css']
})
export class BreadcampComponent implements OnInit {
  @Input() navigations: Array<{ label: string, link?: string }> = new Array();
  constructor() { }

  ngOnInit(): void {
  }

}
