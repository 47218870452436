import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { EUserGender } from 'src/app/entities/user.entity';
import { GROWTH_CHART_PROVIDERS_TOKEN, GrowthChartProviderValue } from '../growth-chart.module';

export enum GrowthChartMode {
  HEIGHT,
  WEIGHT
}


@Component({
  selector: 'app-growth-chart',
  templateUrl: './growth-chart.component.html',
  styleUrls: ['./growth-chart.component.css']
})
export class GrowthChartComponent implements AfterViewInit, OnChanges {

  @Input() gender: EUserGender = EUserGender.HOMME;
  @Input() height: number = 0;
  @Input() weight: number = 0;
  @Input() old: number = 0;

  @Input() mode: GrowthChartMode = GrowthChartMode.HEIGHT;

  @ViewChild('canvas') canvas!: ElementRef;

  private chart!: Chart;


  get canvasContext(): any {
    return this.canvas.nativeElement.getContext('2d')
  }

  get endLabelPlugin(): any {
    return {
      id: 'endLabelPlugin',
      afterDatasetsDraw(chart) {
        const { ctx, chartArea: { top, bottom, left, right }, scales: { x, y } } = chart;

        chart.data.datasets.forEach((dataset, index) => {
          const meta = chart.getDatasetMeta(index);
          if (meta.hidden) return;

          const label = dataset.label;
          const dataLength = dataset.data.length;
          const lastPoint = dataset.data[dataLength - 1];
          const xPos = x.getPixelForValue(dataLength - 1);
          const yPos = y.getPixelForValue(lastPoint);

          ctx.save();
          ctx.fillStyle = dataset.borderColor;
          ctx.font = '12px Arial';
          ctx.fillText(label, xPos + 5, yPos);
          ctx.restore();
        });
      }
    };
  }

  get providerToken(): GROWTH_CHART_PROVIDERS_TOKEN {
    if (this.mode == GrowthChartMode.HEIGHT) {
      if (this.gender == EUserGender.FEMME)
        return this.old > 36 ? GROWTH_CHART_PROVIDERS_TOKEN.HEIGHT_20Y_OLD_FEMAL : GROWTH_CHART_PROVIDERS_TOKEN.HEIGHT_3Y_OLD_FEMAL;
      else
        return this.old > 36 ? GROWTH_CHART_PROVIDERS_TOKEN.HEIGHT_20Y_OLD_MALE : GROWTH_CHART_PROVIDERS_TOKEN.HEIGHT_3Y_OLD_MALE;
    } else {
      if (this.gender == EUserGender.FEMME)
        return this.old > 36 ? GROWTH_CHART_PROVIDERS_TOKEN.WEIGHT_20Y_OLD_FEMAL : GROWTH_CHART_PROVIDERS_TOKEN.WEIGHT_3Y_OLD_FEMAL;
      else
        return this.old > 36 ? GROWTH_CHART_PROVIDERS_TOKEN.WEIGHT_20Y_OLD_MALE : GROWTH_CHART_PROVIDERS_TOKEN.WEIGHT_3Y_OLD_MALE;
    }
  }

  get plots(): Array<any> {
    return [
      { x: this.old, y: this.mode == GrowthChartMode.HEIGHT ? this.height : this.weight },
    ]
  }


  get data(): any {
    return {
      labels: Array.from({ length: this.old > 36 ? 240 : 36 }, (_, i) => i).filter(e => this.old > 36 ? e >= 24 : true),
      datasets: [
        ...this.GROWTH_CHART_PROVIDERS_DATA.get(this.providerToken)?.map((e, i) => ({
          label: e.label,
          data: e.values,
          borderColor: ['#f7e5ee', '#f7e5ee', '#f7e5ee', '#a8faf5', '#46d5cd', '#a8faf5', '#f7e5ee', '#f7e5ee', '#f7e5ee'][i],
          backgroundColor: ['#f7e5ee', '#f7e5ee', '#f7e5ee', '#a8faf5', '#46d5cd', '#a8faf5', '#f7e5ee', '#f7e5ee', '#f7e5ee'][i],
          fill: false,
          tension: 0.4, // Makes the line curved
          pointRadius: 0 // Removes point markers
        })) || []
        ,
        {
          label: this.mode == GrowthChartMode.HEIGHT ? 'Taille ' : 'Poids ',
          data: this.plots,
          borderWidth: 10,
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          pointRadius: 10, // Marker size
          pointHoverRadius: 12, // Marker hover size
          pointStyle: 'cross', // Marker style
          showLine: false // Do not connect points with lines
        }
      ]
    }
  }

  constructor(@Inject('GROWTH_CHART_PROVIDERS_DATA') private GROWTH_CHART_PROVIDERS_DATA: Map<GROWTH_CHART_PROVIDERS_TOKEN, Array<GrowthChartProviderValue>>) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.isValid && !!this.canvas) {
      this.chart?.destroy()
      this.fillChart()
    }
  }

  ngAfterViewInit(): void {
    if (this.isValid) this.fillChart()
  }
  fillChart(): void {
    this.chart = new Chart(this.canvasContext, {
      type: 'line',
      data: this.data,
      options: {
        responsive: true,
        layout: {
          padding: {
            right: 80 // Add padding to the right to prevent label cutoff
          }
        },
        scales: {
          x: {
            min: this.old > 36 ? 24 : 0
          },
          y: {

            min: this.old > 36 ? (this.mode === GrowthChartMode.HEIGHT ? 75 : 9) : (this.mode === GrowthChartMode.HEIGHT ? 40 : 2),
            max: this.old > 36 ? (this.mode === GrowthChartMode.HEIGHT ? 195 : 110) : (this.mode === GrowthChartMode.HEIGHT ? 106 : 19),
            //max: 15, // Set the maximum value of y-axis
            ticks: {
              stepSize: 1 // Set the step size for y-axis
            },
            grid: {
              display: true, // Show grid lines
              borderDash: [1, 1] // Dashed lines for the grid
            }
          }

        },

      },
      plugins: [this.endLabelPlugin]
    });
  }

  get isValid(): boolean { return this.isValidHeight && this.isValidWeight && this.isFitOld }

  get isValidHeight(): boolean { return this.height > 0 };

  get isValidWeight(): boolean { return this.weight > 0; }

  get isFitOld(): boolean { return this.old <= 216; /* 216 is 18 years old in months*/ }
}