import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Vaccin } from 'src/app/entities/vaccin.entity/vaccin';

@Component({
  selector: 'app-vaccination-form',
  templateUrl: './vaccination-form.component.html',
  styleUrls: ['./vaccination-form.component.css']
})
export class VaccinationFormComponent implements OnInit {

  @Input() vaccination: Vaccin = new Vaccin();
  @Output() vaccinationChanged: EventEmitter<Vaccin> = new EventEmitter();
  @Input() isSubmitted: boolean = false;
  constructor() { }

  ngOnInit(): void { }

  get isValid(): boolean {
    return this.vaccination.nom_Labo_Vaccin.length > 0 
  }

  _change(): void {
    this.vaccinationChanged.emit(this.vaccination);
  }

}
