import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Doctor } from "src/app/entities/user.entity/doctor.entity";
import { AuthService } from "src/app/services/auth.service/auth.service";
import { EmailService } from "src/app/services/email.service/email.service";

@Component({
  selector: "app-mail-box",
  templateUrl: "./mail-box.component.html",
  styleUrls: ["./mail-box.component.css"],
})
export class MailBoxComponent implements OnInit {
  @Input() messageEmail: { to: string; subject: string; objet: string } = {
    to: "",
    subject: "",
    objet: "",
  };
  @Output() messageEmailChange: EventEmitter<{
    to: string;
    subject: string;
    objet: string;
  }> = new EventEmitter();
  @Input() message: string = "";
  @Input() object: string = "";
  @Input() subject: string = "";
  @Input() pdfFile: any;
  isSubmitted: boolean = false;

  @Input() data: any
  @Output() closeChange: EventEmitter<boolean> = new EventEmitter()

  formVar!: FormGroup;
  get doctor(): Doctor {
    return this.authServices.getCurrentUser() as Doctor
  }
  constructor(
    private fb: FormBuilder,
    private EmailService: EmailService,
    private toasterService: ToastrService,
    private authServices: AuthService
  ) { }

  ngOnInit() {
    this.formVar = this.fb.group({
      email: new FormControl("", Validators.required),
      objet: new FormControl("", Validators.required),
      message: new FormControl("", Validators.required),
    });

  }
  get f() {
    return this.formVar.controls;
  }
  onSubmit() {
    this.isSubmitted = true;

    this.messageEmail.to = this.formVar.value.email;
    this.messageEmail.objet = this.formVar.value.message || this.object;
    this.messageEmail.subject = this.formVar.value.objet || this.subject;
    this.messageEmailChange.emit(this.messageEmail);
    let payLoad: FormData = new FormData();
    payLoad.append("*", this.pdfFile, `${this.doctor.firstName}${this.doctor.lastName}.${this.data.patient ? this.data.patient.fullName : this.data.appointment.patient.fullName}.${moment(
      new Date(this.data.date ? this.data.date : this.data.appointment.date)
    ).format("DD/MM/YYYY")}.pdf`);

    payLoad.append("to", this.messageEmail?.to);
    payLoad.append("subject", this.messageEmail.objet);
    payLoad.append("object", this.messageEmail.subject);
    payLoad.append("canonical", "5p");

    this.EmailService.sendEmail(payLoad).subscribe((doc) => {
      this.toasterService.success("mail envoyé avec succés");

      this.closeChange.emit(true)



    });


  }
  _change(): void {
    this.messageEmail.to = this.messageEmail.to;
    this.messageEmail.objet = this.object;
    this.messageEmail.subject = this.subject;
    this.messageEmailChange.emit(this.messageEmail)
  }
}
