<div>
    <input type="text" class="form-control my-2"  (keyup)="search($event)"  placeholder="Recherche medic">
    <nav class="tree-nav scroll" >

        <div class="flex" *ngFor="let medic of medics ;let i= index" >
            <span class="label">{{medic.nom}}</span>
            <input type="checkbox" name="" id=""  class="checkbox" (change)="changed(medic,$event,i)"   >
        </div>
        </nav>
        <button class="btn btn-primary ajouter" (click)="close()">Ajouter</button>
</div>
