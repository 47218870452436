import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'video-call-options',
  templateUrl: './video-call-options.component.html',
  styleUrls: ['./video-call-options.component.css']
})
export class VideoCallOptionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
