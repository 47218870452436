import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Medic } from 'src/app/entities/medic.entity/medic';

@Component({
  selector: 'app-medic-form',
  templateUrl: './medic-form.component.html',
  styleUrls: ['./medic-form.component.css']
})
export class MedicFormComponent implements OnInit {

  constructor() { }
  @Input() medic: Medic = new Medic();
  @Output() medicChange: EventEmitter<Medic> = new EventEmitter();
  @Input() isSubmitted: boolean = false;

  ngOnInit(): void {
  }

  _change(): void {
    this.medicChange.emit(this.medic)
  }

  get isValid(): boolean {
    return this.medic.nom.length > 0;
  }

}
