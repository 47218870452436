import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/common/modules/modal.module/modal/modal.component';
import { FilterPipe } from 'src/app/common/pipes/filter.pipe/filter.pipe';
import { Radiology } from 'src/app/entities/radiology.entity/radiology';
import { RadiologiesService } from 'src/app/services/radiologies.service/radiologies.service';
import { RadiologyFormComponent } from '../radiology-form.component/radiology-form.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-radiology-select-dropdown',
  templateUrl: './radiology-select-dropdown.component.html',
  styleUrls: ['./radiology-select-dropdown.component.css']
})
export class RadiologySelectDropdownComponent implements OnInit {

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.toggleModal(false)
    }
  }

  @Input() values: Array<Radiology> = new Array();
  @Input() placeHolder: string = "";
  @ViewChild('radiologyForm', { read: RadiologyFormComponent, static: false }) radiologyForm!: RadiologyFormComponent;
  searchPattern: string = '';
  radiologiesList: Array<Radiology> = new Array();
  @ViewChild(ModalComponent, { read: ModalComponent, static: false })

  dialog!: ModalComponent;
  @Input() set value(value: Radiology) {
    if (value) {
      this._value = value;
      this.valueChange.emit(this._value);
    }
  }
  modalToggled: boolean = false;
  currentTabIndex: number = 0;

  get value(): Radiology {
    return this._value || new Radiology();
  }

  private _value!: Radiology;
  @Output() valueChange: EventEmitter<Radiology> = new EventEmitter();

  constructor(private elementRef: ElementRef, private radiologiesService: RadiologiesService, private viewportScroller: ViewportScroller, private toasterService: ToastrService) { }

  ngOnInit(): void {

    this.radiologiesService.all().subscribe(radiologies => {
      this.radiologiesList = radiologies;
    }, error => this.toasterService.error('Enable to load radiologies'))
  }
  openDialoge(): void {
    this.dialog.open()
  }

  toggleModal(toggled?: boolean, elementId?: string): void {
    if (toggled != undefined) this.modalToggled = toggled;
    else this.modalToggled = !this.modalToggled;
    if (toggled == true && elementId != undefined) {
      this.currentTabIndex = 0;
      this.viewportScroller.scrollToAnchor(elementId);
    }
  }


  onValuesSelected(value: Radiology) {
    if (value) {
      this.value = value;
      this.toggleModal(false)
      this.searchPattern = ''
    }
  }

  onAddRadio(): void {
    this.radiologyForm.isSubmitted = true;
    if (this.radiologyForm.isValid) {
      this.radiologiesService.add(this.radiologyForm.radiology).subscribe(radiology => {
        this.value = Radiology.fromResponse(radiology);
        this.radiologyForm.isSubmitted = false;
        this.radiologiesList = [radiology, ...this.radiologiesList];
        this.dialog.close()

        this.toggleModal(false);
      }, error => alert('Enable to add radiology'));
    }
  }

  get radiologies(): Array<Radiology> {
    return new FilterPipe().transform(this.radiologiesList, this.searchPattern, { keyword: 'nom_Radio' });
  }


  get groupedRadiologies(): Array<{ keyName: string, items: Array<Radiology> }> {
    let groups: Array<string> = this.radiologies.map(item => String(item.type_Radio)).filter((item, index, self) => self.indexOf(item) === index)
    return Array.from(groups, group => {
      let items: Array<Radiology> = new Array();
      this.radiologies.forEach(biology => {
        if (String(group).toUpperCase() == String(biology.nom_Radio).toUpperCase()) items.push(biology);
      })
      return {
        keyName: group, items: items
      };
    });
  }
}
