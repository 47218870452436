import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageRequest, PaginationPage } from 'src/app/common/modules/data-table.module/interfaces';
import { EAppointmentState } from 'src/app/entities/appointment.entity';
import { DoctorAppointment } from 'src/app/entities/appointment.entity/doctor-appointment.entity/doctor-appointment';
import { EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';

@Component({
  selector: 'app-doctor-appointments-table',
  templateUrl: './doctor-appointments-table.component.html',
  styleUrls: ['./doctor-appointments-table.component.css']
})
export class DoctorAppointmentsTableComponent implements OnInit {

  @Input() currentPage: number = 0;
  @Input() perPage: number = 10;
  @Input() pageRequest!: (pageRequest: PageRequest) => Promise<PaginationPage<DoctorAppointment>>;

  @Output() onEditRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onPatientProfileRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onAssignPrescriptionRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onAssignConsultationRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onViewPrescriptionRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onViewConsultationRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onViewAttachmentsRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onViewAttestationsRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onViewTeleConsultationRequest: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onAccept: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onReject: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onAffect: EventEmitter<DoctorAppointment> = new EventEmitter();
  @Output() onBillingRequest: EventEmitter<DoctorAppointment> = new EventEmitter();

  @Input() actions: Partial<{ accept: boolean, reject: boolean, edit: boolean, delete: boolean, prescription: boolean, consultations: boolean, attachmentsView: boolean, payementsView: boolean, attestation: boolean; affect: boolean }> = {}
  @Input() emptyMessage?: string;

  doctorAppointments: Array<DoctorAppointment> = new Array();

  constructor() { }

  ngOnInit(): void {
    this.currentPage = 0;
  }

  canOpenTeleconsultation(appointment: DoctorAppointment): boolean {
    return appointment.status == EAppointmentState.ACCEPTED && (appointment.location == EDalyTimeSlotLocation.BOTH || appointment.location == EDalyTimeSlotLocation.ONLINE)
  }

  canAssignPrescription(appointemnt: DoctorAppointment): boolean {
    return !appointemnt.hasPrescription
  }
}
