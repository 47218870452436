import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDropDownComponent } from './select-drop-down.component/select-drop-down.component';
import { ScrollbarModule } from '../scrollbar.module/scrollbar.module';
import { FormsModule } from '@angular/forms';
import { InputComponent } from './input.component/input.component';


@NgModule({
  declarations: [
    SelectDropDownComponent,
    InputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ScrollbarModule
  ],
  exports: [
    SelectDropDownComponent
  ]
})
export class SelectDropDownModule { }
