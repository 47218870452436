<div class="row" style="background-color: transparent;">
    <div class="col-lg-11">
        <select-drop-down [items]="vaccinationsList" [(ngModel)]="value"
            [options]="{ filter:{ byKey:'nom_commercial_Vaccin' } }" name="vaccin">
            <ng-template let-item="item">{{item.nom_commercial_Vaccin}}</ng-template>
        </select-drop-down>


    </div>
    <div class="col-lg-1">
        <button class="openDialoge" (click)="openDialoge()">
            <i class="feather-plus-circle"></i>
        </button>
    </div>
</div>
<!-- <div *ngIf="modalToggled" class="drop-down-modal">
        <div class="drop-down-modal-body">
            <div class="tab-view">
                <ul class="nav-tabs">
                    <li class="nav-item" (click)="currentTabIndex = 0" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="{'active':currentTabIndex == 0}" data-bs-toggle="tab">All</a>
                    </li>
                  
                </ul>
                <div *ngIf="currentTabIndex == 0" class="tab-content">
                    <div class="input-control" style="height: 40px;">
                        <input type="text" placeholder="search"  id="test">
                        <span>
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                    <ng-container *ngIf="vaccinations.length else empty">
                        <scrollbar>
                            <div *ngFor="let vaccination of vaccinations" class="list-group-item"
                                (click)="onValuesSelected(vaccination)">
                                <span
                                    style="color: #d86800;font-size:14px;text-overflow: ellipsis;">{{vaccination.nom_commercial_Vaccin}}</span>
                                <p style="color: #6e6e6e;font-size:10px;text-overflow: ellipsis;">
                                    {{vaccination.nom_Labo_Vaccin}}</p>
                            </div>
                        </scrollbar>
                    </ng-container>
                    <ng-template #empty>
                        <span>No item found</span>
                    </ng-template>
                </div>
               
            </div>
        </div>
    </div> -->
<app-modal [title]="'Ajouter Nouveau vaccin'" #dialog>
    <app-vaccination-form #doctorVaccinationForm></app-vaccination-form>
    <button class="btn btn-outline-info mb-3 mt-3" (click)="onAddVaccination()">Ajouter Vaccin</button>
</app-modal>