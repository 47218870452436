<div class="mt-2">
    <div class="row">
        <div class="col-lg-12 col-sm-12">
            <label class="Label">Question</label>
            <input type="text" class="form-control" [(ngModel)]="textFilligns!.label">
            
        </div>
        <div class="col-lg-12 col-sm-12 mt-3">
            <label class="Label">Reponses</label>
            <app-fillings-form [(fillingsItmes)]="textFilligns.fillings"></app-fillings-form>
        </div>
    
    </div>
    <button (click)="_submit()" class="btn btn-sm btn-primary" style="margin-top: 25px;">{{submitName}}</button>

</div>