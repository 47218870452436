import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotesFillings } from 'src/app/entities/notes.entity/notes.fillings';

@Component({
  selector: 'app-liste-model-payment',
  templateUrl: './liste-model-payment.component.html',
  styleUrls: ['./liste-model-payment.component.css']
})
export class ListeModelPaymentComponent implements OnInit {
  @Input() submitName: string = ""
  @Input() note: NotesFillings = new NotesFillings({});
  @Input() open: boolean = true
  @Output() submit: EventEmitter<NotesFillings> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }
  _submit() { this.submit.emit(this.note) }

}
